import React, { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import WordGeneratorComponent from '../CurriculumPdf/Word';
import PdfComponent from '../CurriculumPdf/index';
//import global components
import { Modal } from '../../../imports';
//import icons from heroicons
import { ArrowPathIcon, XCircleIcon, DocumentIcon } from '@heroicons/react/24/outline';
//import services
import { ObtenerInfoContacto } from '../../../servicios/ServicioContacto';



export default function SelectContactComponent({ showPDFModal, idPersona, setShowPDFModal, downloadAction, selectedRows, showDownloadComponent, setShowDownloadComponent, setDownloadAction }) {



    //declare register to form
    //use react hook form to get values and make validations
    const { register, formState } = useForm({
        mode: 'all',
        defaultValues: {
            contactName: '',
        }
    });



    //use to get Education Type options
    const [contactOptions, setContactOptions] = useState([]);
    const [showPDF, setShowPDF] = useState(false);
    const [idContact, setIdContact] = useState(null);
    const [showWordComponent, setShowWordComponent] = useState(false);
    const [wordDownloaded, setWordDownloaded] = useState(false);
    const [isLoading, setIsLoading] = useState(true);



    //function to save 'estadoCivil' in list
    async function getContactInfo() {
        const response = await ObtenerInfoContacto();



        if (response !== undefined) {
            setContactOptions(response);
        }
    }



    useState(() => {
        getContactInfo();
    }, [])



    //get id from select options
    const selectContact = (event) => {
        const contactID = (event.target.value);



        if (contactID === undefined || contactID === '') {
            setIdContact(null);
        } else {
            setIdContact(contactID);
        }



    }
    useEffect(() => {



        if (downloadAction) {
            if (showPDF) {
                setIsLoading(true);



                const timeout = setTimeout(() => {
                    setIsLoading(false)
                    setDownloadAction(false)
                    setShowPDFModal(false)



                }, 6000);
                return () => clearTimeout(timeout)
                setIdContact(null)
            }



        } else {
            //loading action when modal is called
            if (showPDF) {
                setIsLoading(true);



                const timeout = setTimeout(() => {
                    setIsLoading(false)
                }, 5000);
                return () => clearTimeout(timeout)
                setIdContact(null)
            }
        }



    }, [downloadAction, showPDF])



    const downloadWordDocument = async () => {
        if (idContact !== null) {
            try {
                // Llama a la función para generar y descargar el documento Word
                await WordGeneratorComponent(idPersona, idContact);
                setWordDownloaded(true);
            } catch (error) {
                console.error('Error al generar y descargar el documento Word:', error);
            }
        }
    };



    useEffect(() => {
        if (wordDownloaded) {
            const timeout = setTimeout(() => {
                setWordDownloaded(false);
            }, 5000);
            return () => clearTimeout(timeout);
        }
    }, [wordDownloaded]);



    return (
        <>
            {!showPDF &&
                <div className="w-full container mx-auto items-center justify-center">
                    <div className="flex flex-col">
                        <div className="flex text-base m-3 justify-center mx-auto font-bold leading-tight tracking-tight text-green-500">
                            <label className="block mb-2 text-base text-gray-900 ml-2 ">Seleccione la información de contacto para agregar como encabezado del currículum</label>
                        </div>
                        <div>
                            <h1 className="flex text-base justify-center mx-auto font-bold leading-tight tracking-tight text-black-RS">
                                Lista contactos
                            </h1>



                            <div className="container">
                                <div className="mx-auto w-full m-3 px-2  md:w-80">



                                    <div className="relative flex items-center">
                                        <select
                                            id="contactName"
                                            {...register('contactName', { required: true })}
                                            type="select"
                                            className="text-black-RS sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS "
                                            name="contactName"
                                            onChange={selectContact}
                                        >
                                            <option value={""} defaultValue > --Seleccione un contacto --</option>



                                            {contactOptions?.map((option, index) => (
                                                <option key={index} value={option?.idContacto}>{option?.nombreEmpresa}</option>
                                            ))}



                                        </select>
                                    </div>
                                </div>
                            </div>



                        </div>
                        <div className=" flex flex-wrap z-10 mb-2 mx-auto items-center justify-center ">
                            <button type="button" className="py-2 px-4 mx-2 my-5 text-white bg-black-RS  hover:bg-primary-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                                onClick={() => setShowPDFModal(false)} >Cancelar<XCircleIcon className="w-12 h-12 ml-2" /></button>
                            <button type="button" className="py-2 px-4 mx-2 my-5 text-white bg-greenButton  hover:bg-primary-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                onClick={() => idContact !== null ? setShowPDF(true) : setShowPDF(false)}>PDF<DocumentIcon className="w-12 h-12 ml-2" /></button>
                            <button
                                type="button"
                                className="py-2 px-4 mx-2 my-5 text-white bg-blue-500 hover:bg-blue-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                onClick={() => setShowWordComponent(true)} // Muestra el componente WordGeneratorComponent al hacer clic
                                disabled={idContact === null}> Word<DocumentIcon className="w-12 h-12 ml-2" />
                            </button>
                        </div>
                    </div>
                </div>
            }



            {/* Mostrar el componente WordGeneratorComponent si se selecciona descarga individual de Word */}



            {showPDF && downloadAction && selectedRows?.length > 0 && showDownloadComponent === true ? (
                <div>
                    {/* Mapear y renderizar múltiples componentes de PdfComponent */}
                    {selectedRows.map((row, index) => (
                        <PdfComponent
                            key={index}
                            idPersona={row?.idPerson}
                            directDownload={true}
                            setShowDownloadComponent={setShowDownloadComponent}
                            isLoading={isLoading}
                            idContacto={idContact}
                        />
                    ))}

                </div>
            ) : null}



            {showPDF && showDownloadComponent === false ? (
                <PdfComponent
                    idPersona={idPersona}
                    directDownload={false}
                    isLoading={isLoading}
                    idContacto={idContact}
                />
            ) : null}



            {showWordComponent && downloadAction && selectedRows?.length > 0 && showDownloadComponent === true ? (
                <div>
                    {/* Mapear y renderizar múltiples componentes de WordGeneratorComponent */}
                    {selectedRows.map((row, index) => (
                        <WordGeneratorComponent
                            key={index}
                            idPersona={row?.idPerson}
                            idContacto={idContact}
                        />
                    ))}
                </div>
            ) : null}



            {showWordComponent && showDownloadComponent === false ? (
                <WordGeneratorComponent
                    idPersona={idPersona}
                    idContacto={idContact}
                />
            ) : null}



            {showDownloadComponent && downloadAction && (showPDF) && isLoading ? (
                <div className="w-full h-96 container mx-auto items-center justify-center">
                    {/* Mostrar mensaje de descarga en el modal */}
                    {isLoading && (
                        <div className="transition duration-150 ease-in-out inset-0 w-full py-2 p-4 max-h-screen">
                            <div className="flex flex-col justify-center items-center">
                                <h1 className="p-2 text-lg">Descargando...</h1>
                                <ArrowPathIcon className="animate-spin h-16 w-16 mr-3 text-red-RS inset-y-2 right-0"></ArrowPathIcon>
                            </div>
                        </div>
                    )}



                    {/* Cerrar el modal después de 5 segundos */}
                    {wordDownloaded && (
                        setTimeout(() => {
                            setWordDownloaded(false);
                            setShowWordComponent(false);
                        }, 5000)
                    )}
                </div>
            ) : null}

        </>
    );
}