import React, { useState, useRef, useCallback, useEffect, useContext, useMemo } from 'react';
import AlertContext from '../../GlobalComponents/Alert/contexts/alertContext';

import { Tab, Switch } from '@headlessui/react';
import { useForm, Controller } from 'react-hook-form';

import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';

//import services
import { ObtenerTecnologia, ObtenerTecnologiasPorIdEspecializacion } from '../../../servicios/ServicioTecnologia';
import { ObtenerEspecialidades } from '../../../servicios/ServicioInfoPersonal';
import { ObtenerPersonasCVFiltrado } from '../../../servicios/ServicioPersonaCV';
import { ObtenerGradoAcademico } from '../../../servicios/ServicioGradoAcademico';
import { ObtenerTipoEducacion } from '../../../servicios/ServicioGradoAcademico';



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

//component to render each tab from panel with specialty category
const CustomTabComponent = ({ idEspecializacion, onChangeListTech, listTechnologies, checkbox, detect, selectedIndex, selectedIndexSecondPanel, listUserTechnologies, setListUserTechnologies }) => {

    //change tech list with new category
    async function callAsyncList() {
        await onChangeListTech(idEspecializacion);
    }

    useState(() => {
        callAsyncList();
    }, [])



    const finalDataTech = useCallback(() => {

        return listUserTechnologies?.map((item, index) => {
            if (listUserTechnologies[index]?.checked) {

                if (checkbox.current[item.id]) {

                    checkbox.current[item.id].checked = true;
                }

            }
        })
    })


    useEffect(() => {

        if (selectedIndex === 0 && listUserTechnologies?.length > 0) {
            finalDataTech();
        }

    }, [detect])

    useEffect(() => {

        if (selectedIndex === 0) {
            setListUserTechnologies(prev => [...prev])
        }

    }, [selectedIndexSecondPanel])

    return (
        <>
            <div className="container grid grid-cols-4 gap-1 mx-auto pl-2 justify-center overflow-auto">

                {listTechnologies?.map((option, index) => (
                    <div className="py-4" key={option.idTecnologia}>
                        <input id={option.idTecnologia} type="checkbox" value={option.idTecnologia} ref={input => checkbox.current[option.idTecnologia] = input} onClick={detect} className={"cursor-pointer !bg-black-RS  border-grayDark-RS border-t-2 border-b-2 border-l-2 border-r-2"} />
                        <label className="ml-0.5 p-px text-xs sm:text-sm">
                            {option.tecnologia}
                        </label>
                    </div>

                ))}
            </div>
        </>
    )
}

const TabsFilter = ({ listUserData, setListUserData, setListCV, setShowAddModal }) => {

    //use alert context
    const alertCtx = useContext(AlertContext);

    //use to get list from service and later iterate
    const [listTechnologies, setListTechnologies] = useState([]);

    //current lists for tabs to keep data between
    const [listUserTechnologies, setListUserTechnologies] = useState([]);

    //use to get specialty options
    const [specialtyOptions, setSpecialtyOptions] = useState([]);

    //use to get academy grades options
    const [gradesOptions, setgradesOptions] = useState([]);

    //use to get educational level options
    const [educationallevelOptions, seteducationallevelOptions] = useState([]);

    //get selected tab
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedIndexSecondPanel, setSelectedIndexSecondPanel] = useState(0);

    //disable button aplicar filtro
    const [disableButton, setDisableButton] = useState(false);

    // Agrega una variable de estado adicional
    const [showExperienceError, setShowExperienceError] = useState(false);

    //state to manage working in Rossmon
    const [enabled, setEnabled] = useState(false);

    //use react hook form to get values and make validations
    const { register, getValues, watch, setValue, handleSubmit, formState, control } = useForm({
        //this mode will get all type of events to trigger validations
        mode: "all",
        defaultValues: {
            specialty: '0',
            grade: '0',
            edulevel: '0',
            minExperience: '0',
            maxExperience: '1',
            workInRossmon: false,
        }
    });

    const validateExperience = () => {
        const minExperience1 = parseInt(getValues('minExperience'), 10);
        const maxExperience1 = parseInt(getValues('maxExperience'), 10);
        if (minExperience1 >= maxExperience1) {
            setValue("maxExperience", minExperience1 + 1);
            const maxExperience2 = parseInt(getValues('maxExperience'), 10);
            const isValid = (maxExperience2 > minExperience1) && (maxExperience2 > 0);
            setShowExperienceError(!isValid);
            setDisableButton(!isValid);
        } else {
            const isValid = (maxExperience1 > minExperience1) && (maxExperience1 > 0);
            setShowExperienceError(!isValid);
            setDisableButton(!isValid);
        }

    };

    const validateSpecialty = () => {

        const specialty = getValues('specialty');
        if (specialty === "0") {
            setDisableButton(true);
        } else {
            setDisableButton(false)
        }

    };

    const validateGrade = () => {

        const grade = getValues('grade');
        if (grade === "0") {
            setDisableButton(true);
        } else {
            setDisableButton(false)
        }

    };
    const validateedulevel = () => {

        const edulevel = getValues('edulevel');
        if (edulevel === "0") {
            setDisableButton(true);
        } else {
            setDisableButton(false)
        }

    };

    const { errors, isDirty } = formState;

    //function to save 'especialidades' in list
    async function getEspecialties() {
        const response = await ObtenerEspecialidades();
        if (response !== undefined) {

            setSpecialtyOptions(response);

        }

    }

    //function to save 'grados academicos' in list
    async function getAcademicGrades() {
        const response = await ObtenerGradoAcademico();

        if (response !== undefined) {

            setgradesOptions(response);
        }


    }

    //function to save 'nivel educativo' in list
    async function getEducationallevel() {
        const response = await ObtenerTipoEducacion();

        if (response !== undefined) {

            seteducationallevelOptions(response);
        }

    }

    //function to save 'tecnologías' in list
    async function getTechnologies() {

        const response = await ObtenerTecnologia();

        setListTechnologies(response);
    }

    async function changeListTech(index) {
        const response = await ObtenerTecnologiasPorIdEspecializacion(index);
        setListTechnologies(response);
    }

    //Show tabs titles
    const categories = ['Tecnología', 'Especialidad', 'Experiencia', 'Grado Académico'];

    const checkbox = useRef([]);

    const detect = (event) => {
        const idTech = event.currentTarget.id

        //here we delete value from array if user uncheck input
        if (checkbox.current[idTech]?.checked) {


            let techCheckedObject = {
                id: idTech,
                checked: true,
            };


            //filter array to validate if object exists and then update value
            const filtered = listUserTechnologies.filter(obj => {
                return obj.id === idTech;
            });
            if (filtered.length > 0) {
                const newArray = listUserTechnologies.map(item => {
                    if (idTech === item.id) {
                        return { ...item };
                    } else {
                        return item;
                    }
                });
                setListUserTechnologies(newArray);
            } else {
                setListUserTechnologies(prev => [...prev, techCheckedObject])

            }
        } else {

            //filter array to validate if object exists and then update value
            const filtered = listUserTechnologies.filter(obj => {
                return obj.id === idTech;
            });

            if (filtered.length > 0) {
                const newArray = listUserTechnologies.filter(item => {
                    return item.id !== idTech;
                });
                setListUserTechnologies(newArray);
            } else if (filtered.length === 0) {
                listUserTechnologies.forEach((item) => {

                    listUserTechnologies.splice(item, 1);

                });
            }
        }
    }

    const onChangeInputs = () => {
        const tecnologias = listUserTechnologies.map(item => JSON.stringify([parseInt(item.id, 10)]))

        const specialty = getValues("specialty");
        const grade = getValues("grade");
        const edulevel = getValues("edulevel");
        const minExperience = parseInt(getValues("minExperience"), 10);
        const maxExperience = parseInt(getValues("maxExperience"), 10);

        if (
            tecnologias.toString() === "" &&
            specialty === "0" &&
            grade === "0" &&
            edulevel === "0" &&
            minExperience === 0 &&
            maxExperience === 0
        ) {
            setDisableButton(true);
        } else if (minExperience > maxExperience) {
            setDisableButton(true);
        }
        else {
            setDisableButton(false);
        }

    }

    //apply filter
    const onSaveFilter = async () => {

        //set all technologies id into json type to match parameter in sql server **"[1],[2],[3]"]**
        const tecnologias = listUserTechnologies.map(item => JSON.stringify([parseInt(item.id, 10)]))

        let specialty = getValues("specialty");
        let grade = getValues("grade");
        let edulevel = getValues("edulevel");
        let minExperience = getValues("minExperience");
        let maxExperience = getValues("maxExperience");
        let workInRossmon = getValues("workInRossmon");

        //this data will replace current values from tabs
        const data = {
            idTecnologias: tecnologias.toString() !== "" ? tecnologias.toString() : null,
            idEspecializacion: parseInt(specialty, 10),
            idGrado: parseInt(grade, 10),
            idNivelEducativo: parseInt(edulevel, 10),
            minExperiencia: parseInt(minExperience, 10),
            maxExperiencia: parseInt(maxExperience, 10),
            trabajaEnRossmon: workInRossmon,
        }

        const response = await ObtenerPersonasCVFiltrado(data);
        setListCV(response);

        setShowAddModal(false);
        onResetFilter();
       
    }

    const onResetFilter = () => {
        setValue("specialty", "0", { shouldDirty: true });
        setValue("grade", "0", { shouldDirty: true });
        setValue("edulevel", "0", { shouldDirty: true });
        setValue("minExperience", "0", { shouldDirty: true });
        setValue("maxExperience", "1", { shouldDirty: true });
        setValue("workInRossmon", false, { shouldDirty: true });
        setEnabled(false);
        setDisableButton(true);
        setListUserTechnologies([]);
        setListUserData({ ...listUserData, "listTechnologies": [] });


        // Uncheck all checkboxes
        Object.values(checkbox.current).forEach((input) => {
            if (input) {
                input.checked = false;
            }
        });
        setShowExperienceError(false);
    };


    useState(() => {
        getEspecialties();
        getAcademicGrades();
        getEducationallevel();
        setDisableButton(true);
        setShowExperienceError(false);

    }, [])

    useEffect(() => {

        onChangeInputs();
        if (listUserTechnologies) {

            setListUserData({ ...listUserData, "listTechnologies": listUserTechnologies })

        } else {
            if (listUserData["listTechnologies"]?.length > 0) {


                setListUserTechnologies(listUserData["listTechnologies"])

            }
        }

    }, [listUserTechnologies])

    return (
        <div className="container mx-auto w-9/12 px-2 py-2 sm:px-0">

            {/*Headless ui tab component*/}
            <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                {/*Tab list allow us to pass tab titles*/}
                <Tab.List className="flex space-x-4 rounded-xl bg-white shadow-lg p-2">
                    {categories.map((category) => (
                        <Tab
                            key={category}
                            className={({ selected }) =>
                                classNames(
                                    'w-full py-2.5 text-base font-bold font-sans text-grayDark-RS ',
                                    'ring-white ring-opacity-60 ring-offset-2  focus:outline-none focus:ring-2',
                                    selected
                                        ? 'text-black-RS border-b-4 border-t-0 border-l-0 border-r-0 border-black-RS'
                                        : 'text-black-RS  hover:bg-white/[0.12] hover:text-grayLight-RS'
                                )
                            }
                        >
                            {category}
                        </Tab>
                    ))}
                </Tab.List>
                {/*Tab Panels allow us to pass content for tabs*/}
                <Tab.Panels className="mt-2">
                    <Tab.Panel>
                        {/*Headless ui tab component*/}
                        <Tab.Group selectedIndex={selectedIndexSecondPanel} onChange={setSelectedIndexSecondPanel} vertical>
                            <div className="container flex flex-wrap  p-4 py-4 justify-center bg-white rounded-lg shadow-lg">

                                <div className="w-1/4 border border-grayDark-RS">

                                    {/*Tab list allow us to pass tab titles*/}
                                    <Tab.List className="flex flex-col ">
                                        {specialtyOptions.map((value, index) => (
                                            <Tab
                                                key={index}
                                                className={({ selected }) =>
                                                    classNames(
                                                        'w-full py-2.5 text-base font-bold font-sans text-black-RS ',
                                                        selected
                                                            ? 'bg-gray-500  text-grayLight-RS'
                                                            : 'text-black-RS hover:bg-grayLight-RS'
                                                    )
                                                }
                                            >
                                                {value.nombreEspecializacion}
                                            </Tab>
                                        ))}
                                    </Tab.List>
                                </div>

                                <div className="w-3/4 border border-grayDark-RS">
                                    {/*Tab Panels allow us to pass content for tabs*/}
                                    <Tab.Panels >
                                        {specialtyOptions.map((value, index) => (
                                            <Tab.Panel key={index} >
                                                <CustomTabComponent key={value.idEspecializacion} idEspecializacion={value.idEspecializacion} onChangeListTech={changeListTech}
                                                    listTechnologies={listTechnologies} checkbox={checkbox} detect={detect} selectedIndex={selectedIndex} selectedIndexSecondPanel={selectedIndexSecondPanel}
                                                    listUserTechnologies={listUserTechnologies} setListUserTechnologies={setListUserTechnologies} />
                                            </Tab.Panel>
                                        ))}
                                    </Tab.Panels>
                                </div>
                            </div>
                        </Tab.Group>
                    </Tab.Panel>

                    <Tab.Panel>
                        <div className="container  p-4 py-4 justify-center bg-white rounded-lg shadow-lg">

                            {/*Especialidad Input*/}
                            <div className="flex mx-2  items-center justify-center  px-2">
                                <div className="w-2/5 m-4">
                                    <label className="mb-2 text-sm font-medium text-gray-900 ">Especialidades</label>
                                </div>

                                <div className="w-3/5 m-4">

                                    <select
                                        id="specialty"
                                        {...register('specialty', { required: false, onChange: validateSpecialty })}
                                        type="select"
                                        className="text-black-RS sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS "
                                        name="specialty"
                                    >
                                        <option value={"0"} defaultValue > --Seleccione la especialidad --</option>

                                        {specialtyOptions.map((option, index) => (
                                            <option key={index} value={option.idEspecializacion}>{option.nombreEspecializacion}</option>
                                        ))}
                                    </select>
                                </div>

                            </div>

                        </div>

                    </Tab.Panel>

                    <Tab.Panel  >
                        <div className="container  p-4 py-4 justify-center bg-white rounded-lg shadow-lg">

                            <div className="flex flex-wrap mx-1 mx-auto items-left justify-center">
                                {/*Años esperiencia Input*/}

                                <div className="w-1/4 m-3">
                                    <label className="mb-1 text-sm font-medium text-gray-900 ">Mínimo de años</label>
                                </div>

                                <div className="w-1/4 m-3">
                                    <label className="mb-1 text-sm font-medium text-gray-900 ">Máximo de años</label>
                                </div>

                                <div className="w-1/4 m-3">
                                    <label className="mb-1 text-sm font-medium text-gray-900">¿Trabaja en It Rossmon?</label>
                                </div>

                            </div>

                            <div className="flex flex-wrap mx-1 mx-auto items-center justify-center">
                                <div className="w-1/4 m-3">

                                    <input type="number"
                                        name="minExperience"
                                        {...register("minExperience", { required: false, onChange: validateExperience })}
                                        id="minExperience"
                                        className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS "
                                        min="0"

                                    />
                                </div>

                                <div className="w-1/4 m-3">
                                    <input type="number"
                                        name="maxExperience"
                                        {...register("maxExperience", { required: false, onChange: validateExperience })}
                                        id="maxExperience"
                                        className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS "
                                        min="0"
                                    />
                                </div>

                                {/*Work in Rossmon*/}
                                <div className="w-1/4 m-3">
                                    <label className="flex flex-wrap mx-1 mx-auto items-center justify-left">
                                        <Controller
                                            control={control}
                                            name="workInRossmon"
                                            id="workInRossmon"
                                            render={({ field: { ref } }) => (
                                                <Switch
                                                    {...register('workInRossmon', { required: false })}
                                                    checked={enabled}
                                                    defaultValue="false"
                                                    onChange={(value) => {
                                                        setEnabled(!enabled)
                                                        setValue("workInRossmon", value) 

                                                    }}
                                                    className={`${enabled ? 'bg-red-RS ' : ' bg-grayLight-RS'}
                                                relative inline-flex h-[25px] w-[60px] shrink-0 cursor-pointer rounded-full  border-solid border-2 border-grayDark-RS  transition-colors duration-200 ease-in-out focus:outline-none  `}
                                                > 
                                                    {enabled ? <span className="translate-x-9 pointer-events-none inline-block h-[22px] w-[22px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out p-1">
                                                        <CheckIcon className="h-4 w-4  items-center text-dark-500" />
                                                    </span> : <span className="translate-x-0 pointer-events-none inline-block h-[22px] w-[22px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out p-1">
                                                        <XMarkIcon className="h-4 w-4 items-center  text-dark-500" />
                                                    </span>}

                                                </Switch>
                                            )}
                                        />
                                    </label>
                                </div>

                                <div className="w-60">

                                    {showExperienceError && (
                                        <span className="flex flex-row justify-content inset-y-0 left-0" >
                                            <p className=" text-xs font-bold text-red-RS justify-center"> La Experiencia Máxima debe ser mayor a Experiencia Mínima</p>
                                        </span>
                                    )}
                                </div>

                            </div>
                        </div>

                    </Tab.Panel>
                    <Tab.Panel>
                        <div className="container  p-4 py-4 justify-center bg-white rounded-lg shadow-lg">

                            {/*Grado Academico && Nivel Educativo select*/}
                            <div className="flex mx-2  items-center justify-center  px-2">
                                <div className="w-2/5 m-4">
                                    <label className="mb-2 text-sm font-medium text-gray-900 ">Grado</label>
                                </div>

                                <div className="w-3/5 m-4">

                                    <select
                                        id="grade"
                                        {...register('grade', { required: false, onChange: validateGrade })}
                                        type="select"
                                        className="text-black-RS sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS "
                                        name="grade"
                                    >
                                        <option value={"0"} defaultValue > --Seleccione el grado académico--</option>

                                        {gradesOptions.map((option, index) => (
                                            <option key={index} value={option.idGrado}>{option.grado}</option>
                                        ))}
                                    </select>
                                </div>

                            </div>
                            <div className="flex mx-2  items-center justify-center  px-2">
                                <div className="w-2/5 m-4">
                                    <label className="mb-2 text-sm font-medium text-gray-900 ">Nivel Educativo</label>
                                </div>

                                <div className="w-3/5 m-4">

                                    <select
                                        id="edulevel"
                                        {...register('edulevel', { required: false, onChange: validateedulevel })}
                                        type="select"
                                        className="text-black-RS sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS "
                                        name="edulevel"                               >
                                        <option value={"0"} defaultValue > --Seleccione el nivel educativo--</option>

                                        {educationallevelOptions.map((option, index) => (
                                            <option key={index} value={option.idTipoEducacion}>{option.tipoEducacion}</option>
                                        ))}
                                    </select>
                                </div>

                            </div>

                        </div>

                    </Tab.Panel>

                </Tab.Panels>
            </Tab.Group>
            <div className=" flex flex-wrap z-10 mb-2 mx-auto items-center justify-center ">
                <button type="button" className="py-2 px-4 mx-2 my-5 text-white bg-red-RS  hover:bg-primary-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    onClick={handleSubmit(onSaveFilter)} disabled={(!isDirty && disableButton) || disableButton}>Aplicar filtros</button>
                <button type="button" onClick={onResetFilter}
                    className="py-2 px-4 mx-2 my-5 text-white bg-grayDark-RS  hover:bg-primary-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                    Resetear</button>
            </div>
        </div>
    )
}

export default TabsFilter;