import { ProcesarDatosApi } from "../api/ApiFetch";

const controladorGradoAcademicoPersona = "gradoacademicopersona";



//-------------------------------------------------------------------------------------------------
export const ObtenerProfesionalesPorGradoAcademico = async () => {
    const url = `${controladorGradoAcademicoPersona}/ObtenerProfesionalesPorGradoAcademico`;
    return await ProcesarDatosApi('GET', url);
}
export const ObtenerProfesionalesPorCadaGradoAcademico = async (idGrado) => {
    const url = `${controladorGradoAcademicoPersona}/ObtenerProfesionalesPorCadaGradoAcademico/${idGrado}`;
    return await ProcesarDatosApi('GET', url);
}


//------------------------------------------------------------------------------------------------