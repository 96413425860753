import React from 'react';
//import global components
import { Modal } from '../../../imports';

//import icons from heroicons
import { PlusCircleIcon, ExclamationTriangleIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/outline';

const ModalSaveActions = ({ onSaveAction, actionType, setshowConfirmModal }) => {

    //use to show add component when 'Agregar' button is activated
    const SaveActionComponent = () => {
        return (
            <form className="space-y-2 md:space-y-4">
                {/*Button to close modal*/}
                <div className="flex justify-end m-2">
                    <button className="cursor-pointer text-black-400 hover:text-gray-600 transition duration-150 ease-in-out rounded ring-2 focus:outline-none ring-gray-600" onClick={() => setshowConfirmModal(false)} aria-label="close modal" role="button">
                        <XMarkIcon className="text-red-RS h-6 w-6" />
                    </button>
                </div>
                <div className="w-96 container mx-auto items-center justify-center">
                    <div className="flex flex-col">
                        <div className="flex text-base m-2 justify-center mx-auto font-bold leading-tight tracking-tight text-green-700">
                            <PlusCircleIcon className="w-8 h-8 ml-2"></PlusCircleIcon>
                        </div>

                        <div>
                            <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">¿Está seguro que desea agregar este elemento?</label>
                        </div>

                    </div>
                    <div className=" flex flex-wrap z-10 mb-2 mx-auto items-center justify-center ">
                        <button type="button" className="py-2 px-4 mx-2 my-5 text-white bg-black-RS  hover:bg-primary-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            onClick={() => setshowConfirmModal(false)} >Cancelar</button>
                        <button type="button" className="py-2 px-4 mx-2 my-5 text-white bg-greenButton  hover:bg-primary-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            onClick={() => onSaveAction()}>Confirmar</button>
                    </div>
                </div>
            </form>
        )
    }

    //use to show edut component when 'editar' button is activated
    const EditActionComponent = () => {
        return (
            <form className="space-y-2 md:space-y-4">
                {/*Button to close modal*/}
                <div className="flex justify-end m-2">
                    <button className="cursor-pointer text-black-400 hover:text-gray-600 transition duration-150 ease-in-out rounded ring-2 focus:outline-none ring-gray-600" onClick={() => setshowConfirmModal(false)} aria-label="close modal" role="button">
                        <XMarkIcon className="text-red-RS h-6 w-6" />
                    </button>
                </div>
                <div className="w-96 container mx-auto items-center justify-center">

                    <div className="flex flex-col">
                        <div className="flex text-base m-2 justify-center mx-auto font-bold leading-tight tracking-tight text-green-700">
                            <PencilIcon className="w-8 h-8 ml-2"></PencilIcon>
                        </div>

                        <div>
                            <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">¿Está seguro que desea editar este elemento?</label>
                        </div>

                    </div>
                    <div className=" flex flex-wrap z-10 mb-2 mx-auto items-center justify-center ">
                        <button type="button" className="py-2 px-4 mx-2 my-5 text-white bg-black-RS  hover:bg-primary-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            onClick={() => setshowConfirmModal(false)} >Cancelar</button>
                        <button type="button" className="py-2 px-4 mx-2 my-5 text-white bg-greenButton  hover:bg-primary-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            onClick={() => onSaveAction()}>Confirmar</button>
                    </div>
                </div>
            </form>
        )
    }

    //use to show edut component when 'editar' button is activated
    const DeleteActionComponent = () => {
        return (
            <form className="space-y-2 md:space-y-4">
                <div className="w-96 container mx-auto items-center justify-center">
                    <div className="flex flex-col">
                        <div className="flex text-base m-2 justify-center mx-auto font-bold leading-tight tracking-tight text-red-RS">
                            <ExclamationTriangleIcon className="w-8 h-8 ml-2"></ExclamationTriangleIcon>
                        </div>
                        <div>
                            <h1 className="flex text-base justify-center mx-auto font-bold leading-tight tracking-tight text-black-RS">
                                Advertencia
                            </h1>
                        </div>

                        <div>
                            <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">¿Está seguro que desea eliminar este elemento?</label>
                        </div>

                    </div>
                    <div className=" flex flex-wrap z-10 mb-2 mx-auto items-center justify-center ">
                        <button type="button" className="py-2 px-4 mx-2 my-5 text-white bg-black-RS  hover:bg-primary-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            onClick={() => setshowConfirmModal(false)} >Cancelar</button>
                        <button type="button" className="py-2 px-4 mx-2 my-5 text-white bg-greenButton  hover:bg-primary-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            onClick={() => onSaveAction()}>Confirmar</button>
                    </div>
                </div>
            </form>
        )
    }

    //use to show edut component when 'editar' button is activated
    const VerActionComponent = () => {
        return (
            <form className="space-y-2 md:space-y-4">
                {/*Button to close modal*/}
                <div className="flex justify-end m-2">
                    <button className="cursor-pointer text-black-400 hover:text-gray-600 transition duration-150 ease-in-out rounded ring-2 focus:outline-none ring-gray-600" onClick={() => setshowConfirmModal(false)} aria-label="close modal" role="button">
                        <XMarkIcon className="text-red-RS h-6 w-6" />
                    </button>
                </div>
                <div className="w-96 container mx-auto items-center justify-center">

                    <div className="flex flex-col">
                        <div className="flex text-base m-2 justify-center mx-auto font-bold leading-tight tracking-tight text-green-700">
                           
                        </div>

                        <div>
                            <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">¿Está seguro que desea editar este elemento?</label>
                        </div>

                    </div>
                    <div className=" flex flex-wrap z-10 mb-2 mx-auto items-center justify-center ">
                        <button type="button" className="py-2 px-4 mx-2 my-5 text-white bg-black-RS  hover:bg-primary-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            onClick={() => setshowConfirmModal(false)} >Cancelar</button>
                       
                    </div>
                </div>
            </form>
        )
    }

    if (actionType === "Agregar") {
        return (
            <>
                <SaveActionComponent />
            </>
         
        )
    } else if (actionType === "Editar") {
        return (
            <>
                <EditActionComponent />
            </>
        )
    } else if (actionType === "Ver") {
        return (
            <>
                <VerActionComponent />
            </>
        )
    } else {
        return (
            <>
                <DeleteActionComponent />
            </>
        )
    }

}

export default ModalSaveActions;