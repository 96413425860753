import { ProcesarDatosApi } from "../api/ApiFetch";
const controlador = "especializacion";

export const ObtenerEspecialidades = async () => {
    const url = `${controlador}/ObtenerEspecializacion`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerEspecializacionCompleto = async () => {
    const url = `${controlador}/ObtenerEspecializacionCompleto`;
    return await ProcesarDatosApi('GET', url);
}

export const EditarEspecializacion = async (data) => {
    const url = `${controlador}/EditarEspecializacion`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const AgregarEspecializacion = async (data) => {
    const url = `${controlador}/AgregarEspecializacion`;
    return await ProcesarDatosApi('POST', url, data);
}

export const DesactivarEspecializacion = async (data) => {
    const url = `${controlador}/DesactivarEspecializacion`;
    return await ProcesarDatosApi('PUT', url, data);
}