import React from "react";
import { useContext, useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import AlertContext from '../../../GlobalComponents/Alert/contexts/alertContext';

//import icons from heroicons
import { DocumentTextIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

//import global components
import { Star, Modal, ModalActionConfirm } from '../../../../imports';

import {  Tab } from '@headlessui/react'

//import services
import { ObtenerTecnologia, EditarTecnologiaPersona, EliminarTecnologiaPersona, ObtenerTecnologiasPorIdEspecializacion } from '../../../../servicios/ServicioTecnologia';

import { ObtenerEspecialidades } from '../../../../servicios/ServicioEspecializacion';

//component to render each tab from panel with specialty category
const CustomTabComponent = ({ idEspecializacion, onChangeListTech, listTechnologies, listRating, setListRating, allowEdit, EliminarTecnologia, userCompleteCV, idCurriculum }) => {

    //change tech list with new category
    async function callAsyncList() {
        await onChangeListTech(idEspecializacion);
    }

    useState(() => {
        callAsyncList();
    }, [])

    return (
        <>
            <div className="grid grid-cols-1 py-4 gap-2 overflow-auto md:grid-cols-2 xl:grid-cols-2 p-4  px-8 container">

                {/*iterate over list techs*/}
                {listTechnologies.map((option, index) => (
                    <div className="w-full md:w-72 px-2 mx-auto" key={option.idTecnologia}>
                        <Star index={index} id={option.idTecnologia} technologieName={option.tecnologia} techList={listRating} setListRating={setListRating} allowEdit={allowEdit} deleteElementCV={EliminarTecnologia} userCompleteCV={userCompleteCV} idCurriculum={idCurriculum} />
                    </div>
                ))}
            </div>
        </>
    )
}

export default function Tecnologias({ validationMessages, userData, setUserData, finalData, stateInfoPersonal, setstateInfoPersonal, stateEducacion, setstateEducacion, stateTech, setstateTech, otherTechnologiesData, setOtherTechnologiesData, allowEdit, listTechnologiesByPerson, technologieDataEdit, setTechnologieDataEdit, idCurriculum, isFirstLoadTechnologies, setIsFirstLoadTechnologies }) {

    //To keep information when changing steps
    //const { userData, setUserData } = useStepperContext();

    //use alert context
    const alertCtx = useContext(AlertContext);

    //use to get list from service and later iterate
    const [listTechnologies, setListTechnologies] = useState([]);

    //use to get selected technologies by user/**/child to parent in star component*/
    const [listRating, setListRating] = useState([]);

    //here we pass a default input property to save in userData
    //all inputs use this same event in 'onchange'
    const handleChange = (e) => {
        const { name, value } = e.target;

        setValue(name, e.target.value)

        setUserData({ ...userData, [name]: value });
        

    };

    const handleOtherTechnologies = (e) => {
        const { name, value } = e.target;

        setOtherTechnologiesData({ ...otherTechnologiesData, [name]: value });


    };

    const handleChangeUserCompleteCV = (e) => {
        const { name, value } = e.target;

        if (technologieDataEdit) {
            setTechnologieDataEdit({ ...technologieDataEdit, [name]: value })
        }

    };

    //use modal and show for save
    const [showConfirmSaveModal, setShowConfirmSaveModal] = useState(false);
    const [actionTypeConfirmModal, setActionTypeConfirmModal] = useState("");

    //validate if user already complete cv
    const userCompleteCV = sessionStorage.getItem("USER_COMPLETE_CV");


    //tab component 
    //get selected tab
    const [selectedIndex, setSelectedIndex] = useState(0);

    //use to get maritalStatus options
    const [specialtyList, setSpecialtyList] = useState([]);

    //use react hook form to get values and make validations
    const { register, getValues, setValue, handleSubmit, formState } = useForm({
        //this mode will get all type of events to trigger validations
        mode: "all",
        defaultValues: {
            otherTechnologies: '',
        }
    });
    const { errors } = formState;
    const { isValid } = formState;

    //use to get validations from props and later iterate
    const [validations, setValidations] = useState([]);

    //function to save 'tecnologías' in list
    async function getTechnologies() {

        const response = await ObtenerTecnologia();
        setListTechnologies(response);
    }

    //call services to delete elements from education
    async function EliminarTecnologia(idTecnologiaPersona, idPersona) {
        const response = await EliminarTecnologiaPersona(idTecnologiaPersona, idPersona);
        return response;
    }

    //get user data for table
    async function getSpecialties() {

        const response = await ObtenerEspecialidades();
 
        setSpecialtyList(response);

    }

    async function changeListTech(index) {
        const response = await ObtenerTecnologiasPorIdEspecializacion(index);
        setListTechnologies(response);
    }

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    
    //method to edit project from person by id
    const EditarTecnologiasPersona = async (e) => {

        //e.preventDefault();
        console.log("tec: ", listRating);
        if (listRating?.length >= 0) {

            const tecnologiasData = listRating.map(tecnologia => ({
                idTecnologia: tecnologia.idTecnologia,
                expertiz: tecnologia.expertiz,
                otrasTecnologias: getValues("otherTechnologies"),
                idPersona: idCurriculum
            }));

            const tecnologiasResponse = await EditarTecnologiaPersona(tecnologiasData);

            if (tecnologiasResponse.indicador === 0) {

                alertCtx.success(tecnologiasResponse.mensaje);

                setShowConfirmSaveModal(false);
            }
        }

    }

    const keepValuesFromListByPerson = () => {

        const otherTechnologies = getValues("otherTechnologies");
       

        if (technologieDataEdit) {
            setTechnologieDataEdit({
                ...technologieDataEdit, ["otherTechnologies"]: otherTechnologies, listRating
            })
        }
    }

    useState(() => {
      
        getSpecialties();
    

        setValidations(validationMessages);
        //validate for candidate view or admin view
        if (userCompleteCV === "true" && isFirstLoadTechnologies || allowEdit === true && isFirstLoadTechnologies) {
            //fill data by idPerson
            if (listTechnologiesByPerson?.length > 0) {
                setIsFirstLoadTechnologies(false)
                setListRating(listTechnologiesByPerson);
                setValue("otherTechnologies", listTechnologiesByPerson[listTechnologiesByPerson.length - 1]?.otrasTecnologias)

            } 
        }
    }, [])

    useEffect(() => {
      
        //validate for candidate view or admin view
        if (userCompleteCV === "true" || allowEdit === true) {
            if (listRating?.length > 0) {
                if (technologieDataEdit) {
                    setTechnologieDataEdit(listRating);
                }

            } else {                

                if (technologieDataEdit) {

                    setListRating(technologieDataEdit?.listRating);
                    setValue("otherTechnologies", technologieDataEdit?.otherTechnologies)
                }
            }

        } else {

            //here we detect if list were updated, then validate if final data contain data for list and update then global data
            if (listRating?.length > 0) {
                setstateTech(true);
                setUserData({ ...userData, "listTechnologies": listRating })
            } else {
                setstateTech(false);
                if (finalData["listTechnologies"]?.length > 0) {
                    setListRating(finalData["listTechnologies"])
                }
            }
            
        }

       

        

        return (() => {

            if (userCompleteCV === "true" || allowEdit === true) {
                if (isFirstLoadTechnologies) {
                    setIsFirstLoadTechnologies(false);
                }
                if (technologieDataEdit) {

                    setTechnologieDataEdit(listRating);
                }
                keepValuesFromListByPerson();
            } else {

                if (!listTechnologiesByPerson && listRating.length > 0) {
                    setUserData({ ...userData, "listTechnologies": listRating })
                }
            }
            
        })
    }, [listRating])



    return (
        <div className="w-full flex flex-col md:flex-row items-center justify-center px-1  mx-auto lg:py-0">
            {/*Block to manage form input 'tecnologías'--*/}
            <div className={userCompleteCV === "false" ? "w-full pt-1 bg-white rounded-lg shadow" : "w-full pt-1"}>
                {/*Inside container*/}
                <div className="container mx-auto items-center justify-center">
                    {/*Here we show view confirm save modal compononent*/}
                    <Modal isVisible={showConfirmSaveModal} onClose={() => { setShowConfirmSaveModal(false) }} handleSubmit={() => null} onSaveOptions={null} showFotter={false} showCloseIcon={false}>
                        <ModalActionConfirm onSaveAction={EditarTecnologiasPersona} actionType={actionTypeConfirmModal} setshowConfirmModal={setShowConfirmSaveModal} />
                    </Modal>

                    <div className="mt-2 flow-root">
                        <div className="float-left my-2">
                            {!allowEdit && <label className="block mb-2 text-base text-gray-900 ml-2 ">Seleccione la tecnología y luego el nivel respectivo</label>}
                        </div>


                        {!allowEdit && userCompleteCV === "true" &&
                            <div className="float-right my-2">
                                <button onClick={() => { setActionTypeConfirmModal("Editar"); setShowConfirmSaveModal(true); }} type="button" className="py-2 px-4 mx-2 mb-2 text-white bg-greenButton hover:bg-primary-700 font-medium rounded-lg text-sm text-center"
                                >Actualizar</button>
                            </div>
                        }
                    </div>

                    <div className="container w-full flex flex-wrap md:flex-col w-full py-8 overflow-auto">
                        <div className="mx-auto w-full">

                            {/*Headless ui tab component*/}
                            <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                                {/*Tab list allow us to pass tab titles*/}
                                <Tab.List className="grid grid-cols-2 py-4 gap-2 overflow-auto md:grid-cols-4 xl:grid-cols-4 space-x-2 rounded-xl bg-white shadow-lg p-2">
                                    {specialtyList.map((value, index) => (
                                        <Tab
                                            key={index}
                                            className={({ selected }) =>
                                                classNames(
                                                    'w-full py-2.5 text-base font-bold font-sans text-grayDark-RS ',
                                                    'ring-white ring-opacity-60 ring-offset-2  focus:outline-none focus:ring-2',
                                                    selected
                                                        ? 'bg-gray-500  text-white border-b-4 border-t-0 border-l-0 border-r-0 border-black-RS'
                                                        : 'text-black-RS  hover:bg-white/[0.12] hover:text-grayLight-RS'
                                                )
                                            }
                                        >
                                            {value.nombreEspecializacion}
                                        </Tab>
                                    ))}
                                </Tab.List>
                                {/*Tab Panels allow us to pass content for tabs*/}
                                <Tab.Panels className="mt-2 mx-auto items-center justify-center">
                                    {specialtyList.map((value, index) => (
                                        <Tab.Panel key={index} >
                                            <CustomTabComponent key={value.idEspecializacion} idEspecializacion={value.idEspecializacion} onChangeListTech={changeListTech} listTechnologies={listTechnologies} listRating={listRating}
                                                setListRating={setListRating} allowEdit={allowEdit} userCompleteCV={userCompleteCV} EliminarTecnologia={EliminarTecnologia} idCurriculum={idCurriculum} />
                                        </Tab.Panel>
                                    ))}
                                </Tab.Panels>
                            </Tab.Group>
                        </div>   
                       
                    </div>

                    {/*All input will get same colum line and width*/}
                    <form className="mt-2 mb-2">

                        {/*Line divider*/}
                        <hr className="w-full m-2 mt-4  px-4 mx-auto items-center justify-center mb-4 bg-grayDark-RS border-4" />

                        {/*second row*/}
                        <div className="flex mx-2 mx-auto items-center justify-center mb-4">
                            {/*Otras tecnologías Input*/}
                            <div className="flex-1 w-96 mx-4  mt-6 py-4 ">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Otras tecnologías</label>
                                <div className="relative flex items-center">
                                    <DocumentTextIcon className="h-5 w-5 absolute pointer-events-none text-dark-500 inset-y-2 right-0 mr-2.5" />
                                    <textarea type="textarea"
                                        name="otherTechnologies"
                                        value={userCompleteCV === "false" ? otherTechnologiesData["otherTechnologies"] : getValues("otherTechnologies")}
                                        {...register('otherTechnologies', { onChange: userCompleteCV === "true" ? handleChangeUserCompleteCV : handleOtherTechnologies, required: false, maxLength: 150 })}
                                        id="otherTechnologies"
                                        disabled={allowEdit}
                                        className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS "
                                        placeholder="Otras tecnologías"
                                    />
                                </div>
                                {/*iterate over validations and show message phone 1*/}
                                {validations.otherTechnologies?.map((values) => {
                                    return (
                                        errors.otherTechnologies?.type === values.type &&
                                        <span key="{values.type}" className="absolute flex w-64 items-center font-medium tracking-wide text-red-500 text-xs ml-1">
                                            <p className="text-sm font-bold text-red-RS mt-1">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    
    )
}