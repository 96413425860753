import { ProcesarDatosApi } from "../api/ApiFetch";
const controladorReportePersonas = "reportepersonas";

export const ReportePersonasTrabajanRossmon = async(trabajaEnRossmon) => {
    const url = `${controladorReportePersonas}/ReportePersonasTrabajanRossmon/${trabajaEnRossmon}`;
    return await ProcesarDatosApi('GET', url);
}

export const ReporteTotalPersonas = async () => {
    const url = `${controladorReportePersonas}/ReporteTotalPersonas`;
    return await ProcesarDatosApi('GET', url);
}

export const ReporteTotalPersonasPorEspecialidad = async () => {
    const url = `${controladorReportePersonas}/ReporteTotalPersonasPorEspecialidad`;
    return await ProcesarDatosApi('GET', url);
}