import React, { useState, useEffect, useRef, useContext } from 'react';
import { useForm } from 'react-hook-form';
import AlertContext from '../../../GlobalComponents/Alert/contexts/alertContext';
//import global components
import { Modal, SideBar, Table } from '../../../../imports';

//import services
import { ObtenerEspecializacionCompleto, AgregarEspecializacion, EditarEspecializacion, DesactivarEspecializacion } from '../../../../servicios/ServicioEspecializacion';

//import icons from heroicons
import { PlusCircleIcon, ExclamationTriangleIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/outline';

//use to show add new specialty component when 'agregar' button is activated
const AddSpecialtyComponent = ({ validations, setShowAddModal, usuario, getSpecialties }) => {

    //declare register to form
    //use react hook form to get values and make validations
    const { register, getValues, setValue, formState } = useForm({
        mode: 'all',
        defaultValues: {
            specialtyNameAdd: '',
        }
    });

    //use alert context
    const alertCtx = useContext(AlertContext);

    const { errors } = formState;
    const { isValid } = formState;

    //async method to insert into sql user
    async function AddSpecialty(name) {

        const data = {
            nombreEspecializacion: name,
            UsuarioCreacion: String(usuario.Correo),
            estado: true,
        }

        const response = await AgregarEspecializacion(data);

        if (response.indicador === 0) {
            alertCtx.success(response.mensaje);
        } else {
            alertCtx.error(response.mensaje);
        }
    }

    const resetValues = () => {
        setValue("specialtyNameAdd", "");
    }

    const onSaveAddOptions = async () => {

        if (isValid) {
            const name = getValues("specialtyNameAdd");
           
            AddSpecialty(name);

            setShowAddModal(false);

            resetValues();
            const timeout = setTimeout(() => {
                getSpecialties();
            }, 2000);
            return () => clearTimeout(timeout);
        } else {
            alertCtx.error("¡Error al registrar!");
        }

    }

    return (
        <>
            <div className="w-full container mx-auto items-center justify-center">
                <div className="flex flex-col">
                    <div className="flex text-base m-3 justify-center mx-auto font-bold leading-tight tracking-tight text-green-500">
                        <PlusCircleIcon className="w-8 h-8 ml-2"></PlusCircleIcon>
                    </div>
                    <div>
                        <h1 className="flex text-base justify-center mx-auto font-bold leading-tight tracking-tight text-black-RS">
                            Agregar Especialidad
                        </h1>
                    </div>
                </div>
                <form className="space-y-2 md:space-y-4">
                    {/*First row*/}
                    <div className="flex flex-row mt-2 mx-auto items-center justify-center">

                        <div className="w-full m-3 px-2  md:w-80">
                            <label className="flex mb-2 text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Nombre especialidad</label>
                            <div className="relative flex items-center">
                                <input
                                    type="text"
                                    name="specialtyNameAdd"
                                    {...register("specialtyNameAdd", { required: true, maxLength: 60 })}
                                    id="name"
                                    className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS"
                                    placeholder="Nombre"
                                />
                            </div>
                            {/*iterate over validations and show message name*/}
                            {validations.name?.map((values) => {
                                return (
                                    errors.specialtyNameAdd?.type === values.type &&
                                    <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                        <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                    </span>
                                )
                            })}
                        </div>
                    </div>

                    
                    <div className=" flex flex-wrap z-10 mb-2 mx-auto items-center justify-center ">
                        <button type="button" className="py-2 px-4 mx-2 my-5 text-white bg-black-RS  hover:bg-primary-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                            onClick={() => setShowAddModal(false)} >Cancelar</button>
                        <button type="button" className="py-2 px-4 mx-2 my-5 text-white bg-greenButton  hover:bg-primary-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            onClick={onSaveAddOptions}>Confirmar</button>
                    </div>
                </form>
            </div>
        </>
    )
}

//use to show edit role component when 'editar' button is activated
const EditCatalogComponent = ({ validations, setShowEditModal, rowValuesFromId, usuario, getSpecialties, idFromEdit, stateSpecialty }) => {

    //declare register to form
    //use react hook form to get values and make validations
    const { register, getValues, setValue, formState } = useForm({
        mode: 'all',
        defaultValues: {
            specialtyNameEdit: '',
        }
    });

    //use alert context
    const alertCtx = useContext(AlertContext);

    const { errors } = formState;
    const { isValid } = formState;


    const resetValues = () => {
        setValue("specialtyNameEdit", "");
    }

    //async method to insert into sql user
    async function EditSpecialty(id,name, state) {

        const data = {
            idEspecializacion: id,
            nombreEspecializacion: name,
            UsuarioModificacion: String(usuario.Correo),
            estado: !state,
        }

        const response = await EditarEspecializacion(data);
   
        if (response.indicador === 0) {
            alertCtx.success(response.mensaje);
        } else {
            alertCtx.error(response.mensaje);
        }
    }

    const onSaveEditOptions = async () => {

        if (isValid) {
            const name = getValues("specialtyNameEdit");

            EditSpecialty(idFromEdit, name, stateSpecialty);

            setShowEditModal(false);

            resetValues();
            const timeout = setTimeout(() => {
                getSpecialties();
            }, 2000);
            return () => clearTimeout(timeout);
        } else {
            alertCtx.error("¡Error al editar la especialidad!");
        }

    }

    useState(() => {

        setValue("specialtyNameEdit", rowValuesFromId?.nombreEspecializacion);

    }, [])

    //show jsx
    return (
        <>
            <div className="w-full container mx-auto items-center justify-center">
                <div className="flex flex-col">
                    <div className="flex text-base m-3 justify-center mx-auto font-bold leading-tight tracking-tight text-green-500">
                        <PencilIcon className="w-8 h-8 ml-2"></PencilIcon>
                    </div>
                    <div>
                        <h1 className="flex text-base justify-center mx-auto font-bold leading-tight tracking-tight text-black-RS">
                            Editar Usuario
                        </h1>
                    </div>
                </div>
                <form className="space-y-2 md:space-y-4">
                    {/*First row*/}
                    <div className="flex flex-row mt-2 mx-auto items-center justify-center">

                        <div className="w-full m-3 px-2  md:w-80">
                            <label className="flex mb-2 text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Nombre especialidad</label>
                            <div className="relative flex items-center">
                                <input
                                    type="text"
                                    name="specialtyNameEdit"
                                    {...register("specialtyNameEdit", { required: true, maxLength: 60 })}
                                    id="name"
                                    className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS"
                                    placeholder="Nombre"
                                />
                            </div>
                            {/*iterate over validations and show message name*/}
                            {validations.name?.map((values) => {
                                return (
                                    errors.specialtyNameEdit?.type === values.type &&
                                    <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                        <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                    </span>
                                )
                            })}
                        </div>
                    </div>


                    <div className=" flex flex-wrap z-10 mb-2 mx-auto items-center justify-center ">
                        <button type="button" className="py-2 px-4 mx-2 my-5 text-white bg-black-RS  hover:bg-primary-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                            onClick={() => setShowEditModal(false)} >Cancelar</button>
                        <button type="button" className="py-2 px-4 mx-2 my-5 text-white bg-greenButton  hover:bg-primary-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            onClick={onSaveEditOptions}>Confirmar</button>
                    </div>
                </form>
            </div>
        </>
    )
}
export default function EspecialidadCatalog({ }) {

    //use to get maritalStatus options
    const [specialtyList, setSpecialtyList] = useState([]);

    const [rowValuesFromId, setRowValuesFromId] = useState({});

    //use to get id with set options when click events
    const [idFromEdit, setIdFromEdit] = useState(null);
    const [idFromDeactivate, setIdFromDeactivate] = useState(0);

    //use alert context
    const alertCtx = useContext(AlertContext);

    //use modal and show for add
    const [showAddModal, setShowAddModal] = useState(false);

    //use modal and show for edit
    const [showEditModal, setShowEditModal] = useState(false);

    //use modal and show for delete
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    //use to get state from colums
    const [stateSpecialty, setStateSpecialty] = useState(null);

    //get current user id
    const jsonUsuario = sessionStorage.getItem("USER_DATA");
    const usuario = JSON.parse(jsonUsuario);


    //validations
    const validations = {
        name: [
            {
                type: 'required',
                message: 'Favor ingresar la especialidad',
            },
            {
                type: 'maxLength',
                message: 'La especialidad no puede contener mas de 60 caracteres ',
            }
        ],

    }

    const { handleSubmit } = useForm({
        mode: 'all',

    });

    //get user data for table
    async function getSpecialties() {

        const response = await ObtenerEspecializacionCompleto();
        setSpecialtyList(response);

    }

    async function DeactivateSpecialty(id, estado) {

        const data = {
            idEspecializacion: id,
            UsuarioModificacion: String(usuario.Correo),
            estado: estado
        }

        const response = await DesactivarEspecializacion(data);

        if (response.indicador === 0) {
            alertCtx.success(response.mensaje);
        } else {
            alertCtx.error(response.mensaje);
        }
    }

    const onSaveDeleteOptions = async () => {

        DeactivateSpecialty(idFromDeactivate, !stateSpecialty)

        setShowDeleteModal(false);
        const timeout = setTimeout(() => {
            getSpecialties();
        }, 1000);
        return () => clearTimeout(timeout);
    }

    //**change this colums with entities names or need data**
    const columns = [
        {
            name: 'Nombre especialidad',
            selector: row => row?.nombreEspecializacion,
            width: '290px',
        },
        {
            name: 'Estado',
            selector: (row) => {
                return (
                    row?.estado === true ? <div className="flex items-center">
                        <div className="h-2.5 w-2.5 rounded-full bg-green-500 mr-2"></div> <h2 className="text-sm text-green-500 font-bold" > Activo</h2 >
                    </div>
                        : <div className="flex items-center">
                            <div className="h-2.5 w-2.5 rounded-full bg-red-RS mr-2"></div> <h2 className="text-sm text-red-RS font-bold" > Inactivo</h2 >
                        </div>
                )
            },
        },
        {
            name: 'Acciones',
            selector: row => row?.estado,
            cell: (row) =>

                <button type="button" id={row?.idEspecializacion} onClick={() => { setShowEditModal(true); setIdFromEdit(row?.idEspecializacion); setRowValuesFromId(row); }} className="rounded-full text-sm w-32 py-2 px-4 border-solid border-2 border-black-RS text-black-RS font-bold  m-2 hover:border-gray-200  ">Editar</button>

            ,
            right: true,
            ignoreRowClick: true,
            allowOverflow: true,
        },
        {
            cell: (row) => {
                return (
                    row?.estado === true ? <button type="button" id={row?.idEspecializacion} onClick={() => { setStateSpecialty(row?.estado); setIdFromDeactivate(row?.idEspecializacion); setShowDeleteModal(true); }} className="rounded-full text-sm w-32 py-2 px-4 border-solid border-2 border-red-RS text-red-RS font-bold  m-2 hover:border-gray-200  " > Desactivar</button >
                        : <button type="button" id={row?.idEspecializacion} onClick={() => { setStateSpecialty(row?.estado); setIdFromDeactivate(row?.idEspecializacion); setShowDeleteModal(true); }} className="rounded-full text-sm w-32 py-2 px-4 border-solid border-2 border-greenButton text-greenButton font-bold  m-2 hover:border-gray-200" > Activar</button >
                )
            }
            ,
        },

    ]

    //when component start, get list
    useState(() => {
        getSpecialties();

    }, [])

    //use to show delete new role component when 'eliminar' button is activated
    const DeleteUserComponent = () => {
        return (
            <form className="space-y-2 md:space-y-4">
                <div className="w-96 container mx-auto items-center justify-center">
                    <div className="flex flex-col">
                        <div className="flex text-base m-2 justify-center mx-auto font-bold leading-tight tracking-tight text-red-RS">
                            <ExclamationTriangleIcon className="w-8 h-8 ml-2"></ExclamationTriangleIcon>
                        </div>
                        <div>
                            <h1 className="flex text-base justify-center mx-auto font-bold leading-tight tracking-tight text-black-RS">
                                Advertencia
                            </h1>
                        </div>

                        <div>
                            <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">¿Está seguro que desea cambiar el estado de este elemento?</label>
                        </div>

                    </div>
                </div>
            </form>
        )
    }

    return (
        <>

            <div className="flex flex-row">
                <div className="md:basis-1/4 xl:basis-1/5 ">
                    <SideBar />
                </div>
                <div className="md:basis-3/4 xl:basis-11/12">
                    <div className="bg-white rounded-lg shadow-lg shadow-slate-400 px-2 py-2  mt-2 m-2 mr-8">
                        <div className="px-2">
                            <div className="absolute z-20 ml-4 mt-1">

                                <h1 className="left-0 text-xl font-bold text-black-RS mb-1">Especialidades</h1>
                                <div className="col-start-1 col-end-3 ">
                                    <div className="items-center sm:flex">
                                        <button type="button" onClick={() => setShowAddModal(!showAddModal)} className="bg-greenButton hover:bg-primary-700 text-white text-sm  py-2 px-4 rounded inline-flex items-center">
                                            <span>Agregar</span>
                                            <PlusCircleIcon className="w-8 h-8 ml-2"></PlusCircleIcon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="static px-2 mt-2 m-2">
                                <Table columns={columns} title="especialidad" filteredItems={""} data={specialtyList} filterBy="nombreEspecializacion" />
                            </div>
                        </div>

                        {/*Here we show add modal compononent*/}
                        <Modal isVisible={showAddModal} onClose={() => { setShowAddModal(false) }} handleSubmit={() => null} onSaveOptions={null} showFotter={false} showCloseIcon={false} >
                            {/*Button to close modal*/}
                            <div className="flex justify-end m-2">
                                <button className="cursor-pointer text-black-400 hover:text-gray-600 transition duration-150 ease-in-out rounded ring-2 focus:outline-none ring-gray-600" onClick={() => setShowAddModal(false)} aria-label="close modal" role="button">
                                    <XMarkIcon className="text-red-RS h-6 w-6" />
                                </button>
                            </div>
                            <AddSpecialtyComponent validations={validations} setShowAddModal={setShowAddModal} usuario={usuario} getSpecialties={getSpecialties} />

                        </Modal>

                        {/*Here we show edit modal compononent*/}
                        <Modal isVisible={showEditModal} onClose={() => { setShowEditModal(false) }} handleSubmit={() => null} onSaveOptions={null} showFotter={false} showCloseIcon={false} >
                            {/*Button to close modal*/}
                            <div className="flex justify-end m-2">
                                <button className="cursor-pointer text-black-400 hover:text-gray-600 transition duration-150 ease-in-out rounded ring-2 focus:outline-none ring-gray-600" onClick={() => setShowEditModal(false)} aria-label="close modal" role="button">
                                    <XMarkIcon className="text-red-RS h-6 w-6" />
                                </button>
                            </div>
                            <EditCatalogComponent validations={validations} setShowEditModal={setShowEditModal} rowValuesFromId={rowValuesFromId} usuario={usuario} getSpecialties={getSpecialties} idFromEdit={idFromEdit} stateSpecialty={stateSpecialty} />
                        </Modal>

                        {/*Here we show delete modal compononent*/}
                        <Modal isVisible={showDeleteModal} onClose={() => { setShowDeleteModal(false) }} handleSubmit={handleSubmit} onSaveOptions={onSaveDeleteOptions} showCloseIcon={false} >
                            {/*Button to close modal*/}
                            <div className="flex justify-end m-2">
                                <button className="cursor-pointer text-black-400 hover:text-gray-600 transition duration-150 ease-in-out rounded ring-2 focus:outline-none ring-gray-600" onClick={() => setShowDeleteModal(false)} aria-label="close modal" role="button">
                                    <XMarkIcon className="text-red-RS h-6 w-6" />
                                </button>
                            </div>
                            <DeleteUserComponent />
                        </Modal>

                    </div>

                </div>

            </div>

        </>
    )
}
