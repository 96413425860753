import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from "react-router-dom";

import AlertContext from '../../GlobalComponents/Alert/contexts/alertContext';

//import assets
import Reset from '../../../assets/Resetear_contrasennia.png';
import SendEmail from '../../../assets/SendEmail.png';
import { EnvelopeIcon, UserIcon } from '@heroicons/react/24/outline';

//import services
import { ObtenerCorreoValido, ObtenerContrasenaTemporal, EnviarContrasenaTemporal } from '../../../servicios/ServicioUsuario';

const SendEmailPassword = ({email}) => {

    //use alert context
    const alertCtx = useContext(AlertContext);

    //use to send user to other paths
    const history = useHistory();

   //update password and send email
    const onSubmitEmail = async () => {

        //get new temporary password
        const response = await ObtenerContrasenaTemporal(email);

        if (response !== '') {
        
            const data = {
                correo: email,
                asunto: response.asunto,
                cuerpo: response.cuerpo
            }
            await EnviarContrasenaTemporal(data);

            history.push('/login');
            alertCtx.success("Verifique su bandeja de entrada");
        } else {
            alertCtx.error("Error al enviar la contraseña temporal.");
        }

    }

    return (
        <>
                <div className="px-4 m-4 container my-6 mx-auto  items-center justify-center flex  flex-col-reverse md:flex-row lg:py-0 md:m-36">
                    {/*First block to manage form input --Send figure to top when media querie is called--*/}
                    <div className="w-full md:w-2/5 pt-1 bg-white rounded-lg shadow  xl:p-0">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <div className="flex text-lg  font-bold leading-tight tracking-tight text-gray-900">
                                <p>Enviaremos una contraseña temporal a su correo</p>

                            </div>

                            {/*Line divider*/}
                            <hr className="w-full  px-1 mx-auto items-center justify-center bg-grayDark-RS border-1" />

                            <h3 className="flex text-base mx-auto font-medium leading-tight tracking-tight text-gray-900 mb-2 font-sans">
                                Correo electrónico
                                </h3>

                                <div className="relative flex flex-row">
                                    <div>
                                        <EnvelopeIcon className="h-5 w-5 pointer-events-none text-dark-500 mr-2.5" />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-bold text-gray-900 ">{email}</label>
                                    </div>
                               
                               
                                    <div className="rounded-full w-8 h-8 mb-4 absolute bg-grayLight-RS inset-y-0 right-0">
                                            <UserIcon className="w-8 h-8 " alt="user photo" />
                                        </div>
                                    </div>

                                    <div className="container flex right-0 justify-end mt-4 mb-4 md:mx-auto">
                                        { /*cancel button*/}
                                        <Link to="/login">
                                            <button
                                                type="button"
                                                className="w-32 mr-2 my-5 text-white bg-black-RS  hover:bg-primary-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                                Cancelar
                                            </button>
                                        </Link>

                                        { /*search button*/}

                                        <button type="button" onClick={onSubmitEmail} className="w-32 ml-2 my-5 text-white bg-red-RS hover:bg-primary-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Enviar</button>
                                </div>
                           
                        </div>
                    </div>
                    {/*Second block to manage image figure*/}
                    <div className="w-full md:w-2/5 xl:p-0">
                        <div className="p-4 overflow mt-8 space-y-8 md:space-y-4 sm:p-6 mx-auto justify-center">
                            <figure className="flex mx-auto justify-center">
                                    <img className="object-cover transform hover:scale-110 transition duration-700 ease-out" src={SendEmail} alt="Send" />
                            </figure>
                        </div>
                    </div>
                </div>
        </>
    )
  
}

const ResetPasswordComponent = ({ validationMessages }) => {

    //use alert context
    const alertCtx = useContext(AlertContext);

    //use to send user to other paths
    const history = useHistory();

    //use react hook form to get values and make validations
    const { register, formState, reset, handleSubmit, getValues } = useForm({
        mode: 'onChange',
        defaultValues: {
            email: '',
        }
    });
    const { errors } = formState;
    const { isValid } = formState;

    //use to get validations from props and later iterate
    const [validations, setValidations] = useState([]);
    const [email, setEmail] = useState('');
    const [stateSendPassword, setStateSendPassword] = useState(true);

    useState(() => {
        setValidations(validationMessages)
    })

    //here goes logic to validate user email with services
    const onSubmit = async () => {

        if (isValid) {
            //first get values from inputs with react hook form
            const correo = getValues("email");
            setEmail(correo)

            const response = await ObtenerCorreoValido(correo);

            if (response.indicador === 0) {
                alertCtx.success(response.mensaje);
                setStateSendPassword(false);
            } else {
                alertCtx.error(response.mensaje);
            }
      

        } else {
            alertCtx.error("Favor ingresar un correo registrado");
        }

    }

    return (
        <>
        {
        stateSendPassword &&
            <div className="px-4 m-4 container my-6 mx-auto  items-center justify-center flex  flex-col-reverse md:flex-row lg:py-0 md:m-36">

            {/*First block to manage form input --Send figure to top when media querie is called--*/}
            <div className="w-full md:w-2/5 pt-1 bg-white rounded-lg shadow  xl:p-0">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <div className="flex text-lg  font-bold leading-tight tracking-tight text-gray-900">
                        <p>Recupere su cuenta</p>
                     
                    </div>

                    {/*Line divider*/}
                    <hr className="w-full  px-1 mx-auto items-center justify-center bg-grayDark-RS border-1" />

                    <h3 className="flex text-base justify-center mx-auto font-medium leading-tight tracking-tight text-gray-900 mb-2 font-sans">
                        Ingrese su correo electrónico para buscar su cuenta.
                    </h3>
                    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
                        {/*Email input*/}
                        <label className="block text-sm font-medium text-gray-900 ">Correo electrónico</label>
                        <div className="relative flex items-center">
                            <EnvelopeIcon className="h-5 w-5 absolute pointer-events-none text-dark-500 inset-y-0 right-0 mr-2.5" />
                            <input type="text" name="email" id="email" className="text-gray-900 sm:text-sm block w-full p-2.5 " placeholder="correo electrónico"
                                {...register('email', { required: true, maxLength: 150, pattern: /^(.+)@(.+)$/ })} />
                        </div>
                        {/*iterate over validations and show message email*/}
                        {validations.email?.map((values) => {
                            return (
                                errors.email?.type === values.type &&
                                <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                    <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                </span>
                            )
                        })}

                
                        <div className="container flex right-0 justify-end mt-4 mb-4 md:mx-auto">
                            { /*cancel button*/}
                            <Link to="/login">
                                <button
                                    type="button"
                                    className="w-32 mr-2 my-5 text-white bg-black-RS  hover:bg-primary-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                    Cancelar
                                </button>
                            </Link>
                           
                            { /*search button*/}
               
                            <button type="submit" className="w-32 ml-2 my-5 text-white bg-red-RS hover:bg-primary-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Buscar</button>
                        </div>
                    </form>
                </div>
            </div>
            {/*Second block to manage image figure*/}
            <div className="w-full md:w-2/5 xl:p-0">
                <div className="p-4 overflow mt-8 space-y-8 md:space-y-4 sm:p-6 mx-auto justify-center">
                    <figure className="flex mx-auto justify-center">
                        <img className="object-cover transform hover:scale-110 transition duration-700 ease-out" src={Reset} alt="Reset Password" />
                    </figure>
                </div>
            </div>
        </div>
        }
            {/*Render send email component*/}
            {
                !stateSendPassword && <SendEmailPassword email={email} />
            }
        </>
    )
}

export default ResetPasswordComponent;
