import { ProcesarDatosApi } from "../api/ApiFetch";
const controladortipo = "tipoeducacion";



export const ObtenerTipoEducacionCompleto = async () => {
    const url = `${controladortipo}/ObtenerTipoEducacionCompleto`;
    return await ProcesarDatosApi('GET', url);
}

export const EditarTipoEducacion = async (data) => {
    const url = `${controladortipo}/EditarTipoEducacion`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const AgregarTipoEducacion = async (data) => {
    const url = `${controladortipo}/AgregarTipoEducacion`;
    return await ProcesarDatosApi('POST', url, data);
}

export const DesactivarTipoEducacion = async (data) => {
    const url = `${controladortipo}/DesactivarTipoEducacion`;
    return await ProcesarDatosApi('PUT', url, data);
}


