import React, { useState } from 'react';

const STATES = {
    SUCCESS: 'success',
    ERROR: 'error',
};

const AlertContext = React.createContext({
    notification: null,
    notificationText: null,
    success: () => { },
    error: () => { },
});

const AlertProvider = (props) => {
    const [notification, setNotification] = useState(null);
    const [notificationText, setNotificationText] = useState(null);
    const success = (text) => {
        window.scroll(0, 0);
        setNotificationText(text);
        setNotification(STATES.SUCCESS);
        const timeout = setTimeout(() => {
            clear();
        }, 5000);
        return () => clearTimeout(timeout);
    };
    const error = (text) => {
        window.scroll(0, 0);
        setNotificationText(text);
        setNotification(STATES.ERROR);
        const timeout = setTimeout(() => {
            clear();
        }, 5000);
        return () => clearTimeout(timeout);
    };
    const clear = () => {
        setNotificationText(null);
        setNotification(null);
    };
    return (
        <AlertContext.Provider
            value={{
                success, error, clear, notification, notificationText
            }}>
            {props.children}
        </AlertContext.Provider>
    );
};

export { AlertProvider };
export default AlertContext;