import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from "react-router-dom";//para router 

import AlertContext from '../../GlobalComponents/Alert/contexts/alertContext';

//import assets
import Logo from '../../../assets/logo.png';
import { EnvelopeIcon } from '@heroicons/react/24/outline';


const LoginComponent = ({ InicioSesion, validationMessages }) => {//linea principal

    //use alert context
    const alertCtx = useContext(AlertContext); 

    //use to send user to other paths
    const history = useHistory();

    //use react hook form to get values and make validations
    const { register, formState, reset, handleSubmit, getValues } = useForm({
        mode: 'onChange',
        defaultValues: {
            email: '',
            password: '',
        }
    });
    const { errors } = formState;
    const { isValid } = formState;

    //use to get validations from props and later iterate
    const [validations, setValidations] = useState([]);

    useState(() => {
        setValidations(validationMessages)
    })


    //here goes logic to validate user to login with services
    const onSubmit = async () => {
        
        if (isValid) {
         
            //first get values from inputs with react hook form
            const correo = getValues("email");
            const contrasena = getValues("password");

            const data = {
                correo: correo,
                contrasena: contrasena
            }

            const response = await InicioSesion(data);
            
            if (response.indicador === 0) {
                history.push('/');
                alertCtx.success(response.mensaje);
            } else {
                alertCtx.error(response.mensaje);
            }
           

        } else {
            alertCtx.error("¡Datos incorrectos!");
        }
    }

    return (

        <div className="px-4 m-4 container my-6 mx-auto items-center justify-center flex  flex-col-reverse md:flex-row lg:py-0 md:m-36">
         
            {/*First block to manage form input --Send figure to top when media querie is called--*/}
            <div className="w-full md:w-2/5 pt-1 bg-white rounded-lg shadow  xl:p-0">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1 className="flex text-base justify-center mx-auto font-bold text-gray-900 ">
                        Ingrese su correo y contraseña
                    </h1>
                    <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit(onSubmit)}>
                        {/*Email input*/ }
                        <label className="block mb-2 text-sm font-medium text-gray-900 ">Correo electrónico</label>
                        <div className="relative flex items-center">
                            <EnvelopeIcon className="h-5 w-5 absolute pointer-events-none text-dark-500 inset-y-0 right-0 mr-2.5" />
                            <input type="text" name="email" id="email" className="text-gray-900 sm:text-sm block w-full p-2.5 " placeholder="correo electrónico"
                                {...register('email', { required: true, maxLength: 150, pattern: /^(.+)@(.+)$/ })} />
                        </div>
                        {/*iterate over validations and show message email*/}
                        {validations.email?.map((values) => 
                        {
                            return (
                                errors.email?.type === values.type &&
                                <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                        <p   className="text-sm font-bold text-red-RS">{values.message}</p>
                                </span>
                            )
                        })}
                       
                        {/*Password input*/}
                        <label className="block mb-2 text-sm font-medium text-gray-900">Contraseña</label>
                        <div className="relative flex items-center">
                               
                        <input type="password" name="password" id="password" placeholder="••••••••" className="text-gray-900 sm:text-sm block w-full p-2.5 "
                                {...register('password', { required: true, maxLength: 32})} />
                        </div>
                        {/*iterate over validations and show message password*/}
                        {validations.password?.map((values) => {
                            return (
                                errors.password?.type === values.type &&
                                <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                    <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                </span>
                            )
                        })}
                           
                        <div className="flex items-center justify-between">

                            <Link to="/reset_password" className="text-sm font-medium text-primary-600 hover:underline">
                                ¿Olvidó su contraseña?
                            </Link>
                        </div>

                        

                        <button type="submit" className="w-full text-white bg-red-RS  hover:bg-primary-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Ingresar</button>
                        <Link className="flex justify-center underline text-sm font-bold text-gray-500 dark:text-gray-400" to="/register">¿No tienes una cuenta?</Link>
                    </form>
                </div>
            </div>
            {/*Second block to manage image figure*/}
            <div className="w-full md:w-2/5 xl:p-0">
                <div className="p-6 overflow mt-8 space-y-8 md:space-y-4 sm:p-6 mx-auto justify-center">
                    <figure className="flex mx-auto justify-center">
                        <img className="object-cover transform hover:scale-110 transition duration-700 ease-out" src={Logo} alt="Logo RS" />
                    </figure>
                </div>
            </div>
        </div>
    
    )
}

export default LoginComponent;
