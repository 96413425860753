import React from 'react';
import TecnologiasComponent from './Tecnologias';

const Tecnologias = ({ userData, setUserData, finalData = [], stateInfoPersonal, setstateInfoPersonal, stateEducacion, setstateEducacion, stateTech, setstateTech, otherTechnologiesData = "", setOtherTechnologiesData, allowEdit = false, listTechnologiesByPerson = [], technologieDataEdit, setTechnologieDataEdit, idCurriculum, isFirstLoadTechnologies, setIsFirstLoadTechnologies }) => {

    //validations
    const validationMessages = {
        otherTechnologies: [
            {
                type: 'maxLength',
                message: 'Este campo no puede contener mas de 150 caracteres',
            },
        ],

    }

    return (
        <div>
            <TecnologiasComponent validationMessages={validationMessages} userData={userData} setUserData={setUserData} finalData={finalData} stateInfoPersonal={stateInfoPersonal} setstateInfoPersonal={setstateInfoPersonal} stateEducacion={stateEducacion} setstateEducacion={setstateEducacion} stateTech={stateTech} setstateTech={setstateTech} otherTechnologiesData={otherTechnologiesData}
                setOtherTechnologiesData={setOtherTechnologiesData} allowEdit={allowEdit} listTechnologiesByPerson={listTechnologiesByPerson} technologieDataEdit={technologieDataEdit}
                setTechnologieDataEdit={setTechnologieDataEdit} idCurriculum={idCurriculum} isFirstLoadTechnologies={isFirstLoadTechnologies} setIsFirstLoadTechnologies={setIsFirstLoadTechnologies} />
        </div>
    );
}

export default Tecnologias;