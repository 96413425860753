import React from 'react';
import { useContext } from 'react';
import AlertContext from '../contexts/alertContext';

//import icons
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { XCircleIcon } from '@heroicons/react/24/outline';

const Alert = () => {
    const alertCtx = useContext(AlertContext);

    //call alert clear function to close notification
    const handleClose = () => {
        alertCtx.clear();
    } 

    if (alertCtx.notification == "success") {
        // Si el estado es 0, mostrar una alerta de éxito
        return (
            alertCtx.notification !== null && (
                <div className="relative z-50">
                    <div className="fixed bottom-0 md:right-0 mb-4  w-full px-4 md:mr-8 md:w-[28rem]">
                        <div className="bg-green-500 border-green-700 text-white rounded-md px-4 md:h-24  py-4 border-b-8 drop-shadow-lg ">
                            <div className="flex items-center space-x-4">

                                <div className="flex-shrink-0">
                                    <InformationCircleIcon className="h-8 w-8 mr-2" />
                                </div>
                                <div className="ml-2">
                                    <p className="text-sm font-sans">{ alertCtx.notificationText }</p>
                                </div>

                                <div className="absolute inset-y-0 right-0">
                                    <XCircleIcon onClick={handleClose} className="cursor-pointer animate-pulse h-12 w-12 ml-4 px-1 text-white" />
                                </div>

                            </div>
                        </div >
                    </div>
                </div>
            )
        );
    } else {
        // Si el estado es 1, mostrar una alerta de error
        return (
            alertCtx.notification !== null && (
                <div className="relative z-50">
                    <div className="fixed bottom-0 md:right-0 mb-4 w-full px-4 md:mr-8 md:w-[28rem]">
                        <div className="bg-red-500 border-red-700 text-white rounded-md px-4 md:h-24  py-4 border-b-8 drop-shadow-lg">
                            <div className="flex items-center space-x-4">

                                <div className="flex-shrink-0">
                                    <InformationCircleIcon className="h-8 w-8 mr-2" />
                                </div>
                                <div className="ml-2">
                                    <p className="text-sm font-sans">{ alertCtx.notificationText }</p>
                                </div>

                                <div className="absolute inset-y-0 right-0">
                                    <XCircleIcon onClick={handleClose} className="cursor-pointer animate-pulse h-12 w-12 ml-4 px-1 text-white" />
                                </div>

                            </div>
                        </div >
                    </div>
                </div>
            )
        );
    }
};
export default Alert;