import { ProcesarDatosApi } from "../api/ApiFetch";
const controladorTecnologia = "tecnologia";
const controladorTecnologiaPersona = "tecnologiapersona"

export const ObtenerTecnologia = async () => {
    const url = `${controladorTecnologia}/ObtenerTecnologia`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerTecnologiasCompleto = async () => {
    const url = `${controladorTecnologia}/ObtenerTecnologiasCompleto`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerCategoriasTecnologias = async () => {
    const url = `${controladorTecnologia}/ObtenerCategoriasTecnologias`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerTecnologiasPorIdEspecializacion = async (idEspecializacion) => {
    const url = `${controladorTecnologia}/ObtenerTecnologiasPorIdEspecializacion/${idEspecializacion}`;
    return await ProcesarDatosApi('GET', url);
}

export const AgregarTecnologiaPersona = async (data) => {
    const url = `${controladorTecnologiaPersona}/AgregarTecnologiaPersona`;
    return await ProcesarDatosApi('POST', url, data);
}
export const EditarTecnologia = async (data) => {
    const url = `${controladorTecnologia}/EditarTecnologia`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const EditarInactivoTecnologia = async (data) => {
    const url = `${controladorTecnologia}/EditarInactivoTecnologia`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const AgregarTecnologia = async (data) => {
    const url = `${controladorTecnologia}/AgregarTecnologia`;
    return await ProcesarDatosApi('POST', url, data);
}

export const ObtenerTecnologiasPorIdPersona = async (idPersona) => {
    const url = `${controladorTecnologiaPersona}/ObtenerTecnologiasPorIdPersona/${idPersona}`;
    return await ProcesarDatosApi('GET', url);
}

export const EditarTecnologiaPersona = async (data) => {
    const url = `${controladorTecnologiaPersona}/EditarTecnologiasPersona`;
    return await ProcesarDatosApi('PUT', url, data);
}


export const EliminarTecnologiaPersona = async (idTecnologiasPersona, idPersona) => {
    const url = `${controladorTecnologiaPersona}/EliminarTecnologiaPersona/${idTecnologiasPersona}/${idPersona}`;
    return await ProcesarDatosApi('DELETE', url);
}