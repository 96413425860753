import React from 'react';
import EducacionComponent from './Educacion';

const Educacion = ({ userData, setUserData, finalData = [], setstateEducacion, stateInfoPersonal, setstateInfoPersonal, allowEdit = false, listEducationByPerson = [], setListEducationByPerson, educationDataEdit, setEducationDataEdit, isFirstLoadEducation, setIsFirstLoadEducation, stateEducacion, idCurriculum }) => {

    //validations
    const validationMessages = {
        nameTitle: [
            {
                type: 'required',
                message: 'Favor ingresar el nombre de titulo',
            },
            {
                type: 'maxLength',
                message: 'El nombre del titulo no puede contener mas de 100 caracteres ',
            },
            {
                type: 'pattern',
                message: 'Solo se admiten letras y n�meros.',
            }
        ],
        institution: [
            {
                type: 'required',
                message: 'Favor ingresar el nombre de la institucion',
            },
            {
                type: 'maxLength',
                message: 'El nombre de la instuci�n no puede contener mas de 100 caracteres ',
            },
            {
                type: 'pattern',
                message: 'Solo se admiten letras.',
            }
        ],
        fileInput: [
            {
                type: 'required',
                message: 'Favor ingresar el titulo',
            }
        ],
        startDate: [
            {
                type: 'required',
                message: 'Favor ingresar la fecha inicio',
            }
        ],
        finishDate: [
            {
                type: 'required',
                message: 'Favor ingresar la fecha fin',
            }
        ]
    }

    return (
        <div>
            <EducacionComponent validationMessages={validationMessages} userData={userData} setUserData={setUserData} finalData={finalData} setstateEducacion={setstateEducacion} stateInfoPersonal={stateInfoPersonal} setstateInfoPersonal={setstateInfoPersonal} allowEdit={allowEdit} listEducationByPerson={listEducationByPerson} educationDataEdit={educationDataEdit} setEducationDataEdit={setEducationDataEdit}
                setListEducationByPerson={setListEducationByPerson} isFirstLoadEducation={isFirstLoadEducation} setIsFirstLoadEducation={setIsFirstLoadEducation} stateEducacion={stateEducacion} idCurriculum={idCurriculum} />
        </div>
    );
};

export default Educacion;