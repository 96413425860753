import React, { useEffect, useState } from "react";
import LoginComponent from './Login-Component';

//import services
import { IniciarSesion } from '../../../servicios/ServicioLogin';

const Login = ({ ValidarSesionActiva }) => {

    //validations
    const validationMessages = {
        email: [
            {
                type: 'required',
                message: 'Favor ingresar el correo',
            },
            {
                type: 'maxLength',
                message: 'El correo no puede contener mas de 150 caracteres ',
            },
            {
                type: 'pattern',
                message: 'Correo electrónico inválido',
            }
        ],
        password: [
        {
            type: 'required',
            message: 'Favor ingresar la contraseña',
        },
        {
            type: 'maxLength',
            message: 'La contraseña no puede contener mas de 32 caracteres ',
        }
        ]

    }

    //function to validate user
    async function InicioSesion(data) {
        const respuesta = await IniciarSesion(data);
        const { token, expiration, usuario } = respuesta;
        
        if (token != null) {

            const jsonUsuario = JSON.parse(usuario);

            sessionStorage.setItem("TOKEN_KEY", token);
            sessionStorage.setItem("TOKEN_EXPIRATION", expiration);
            sessionStorage.setItem("USER_DATA", usuario);
            const Rol = jsonUsuario.IdRol;
            sessionStorage.setItem("USER_ROLE", Rol);
            ValidarSesionActiva();
            const Indicador = jsonUsuario.Indicador;
            const Mensaje = jsonUsuario.Mensaje;
            const response = {
                rol: Rol,
                indicador: Indicador,
                mensaje: Mensaje
            }
            return response;
        } else {
            const response = {
                indicador: 1,
                mensaje: "Error: Usuario o contraseña invalido(a)."
            }
            return response;
        }
    }

    
    return (
        <div>
            <LoginComponent InicioSesion={InicioSesion} validationMessages={ validationMessages } />
        </div>
    );
};

export default Login;