import React, { useState, useEffect, useRef, useContext, useMemo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import  AlertContext from '../../../GlobalComponents/Alert/contexts/alertContext';
import  SideMenu  from '../../../GlobalComponents/Sidebar/AdminMenu';
//import global components
import { Modal, SideBar, Table } from '../../../../imports';

//import services
import { ObtenerRoles, AgregarRol, EditarRol, DesactivarRol } from '../../../../servicios/ServicioRol';

//import icons from heroicons
import { MagnifyingGlassIcon, PlusCircleIcon, ExclamationTriangleIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/outline';


export default function RoleCatalog({ validationMessages }) {

    const handleChange = (e) => {
        const value  = e.target.value;

        setRolName(value);

    };

    const [rolName, setRolName] = useState('');

    //list to save and send data to table
    const [listRoles, setListRoles] = useState([]);

    //use alert context
    const alertCtx = useContext(AlertContext);

    //use modal and show for add
    const [showAddModal, setShowAddModal] = useState(false);

    //use modal and show for edit
    const [showEditModal, setShowEditModal] = useState(false);

    //use modal and show for delete
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    //use to get id with set options when click events
    const [idFromEdit, setIdFromEdit] = useState(null);
    const [valueFromEdit, setValueFromEdit] = useState('');
    const [idFromDeactivate, setIdFromDeactivate] = useState(0);


    //use to get state from colums
    const [stateRol, setStateRol] = useState(null);

    //get current user id
    const jsonUsuario = sessionStorage.getItem("USER_DATA");
    const usuario = JSON.parse(jsonUsuario);

    //declare register to form
    //use react hook form to get values and make validations
    const { register, reset, getValues, watch, setValue, handleSubmit, formState } = useForm({
        mode: 'all',
        defaultValues: {
            rolName: ''
        }
    });

    const { errors } = formState;

    async function getRoles() {

        const response = await ObtenerRoles();

        setListRoles(response);
    }

    async function EditRole(id, rol) {

        const data = {
            idRol: id,
            rol: rol,
            usuarioCreacion: String(usuario.Correo),
        }

        const response = await EditarRol(data);

        if (response.indicador === 0) {
            alertCtx.success(response.mensaje);
        } else {
            alertCtx.error(response.mensaje);
        }
    }

    async function AddRole(rol) {

        const data = {
            rol: rol,
            usuarioCreacion: String(usuario.Correo),
            estado: true,
        }

        const response = await AgregarRol(data);

        if (response.indicador === 0) {
            alertCtx.success(response.mensaje);
        } else {
            alertCtx.error(response.mensaje);
        }
    }

    async function DeactivateRole(id, estado) {

        const data = {
            idRol: id,
            usuarioCreacion: String(usuario.Correo),
            estado: estado
        }

        const response = await DesactivarRol(data);

        if (response.indicador === 0) {
            alertCtx.success(response.mensaje);
        } else {
            alertCtx.error(response.mensaje);
        }
    }

    //**change this colums with entities names or need data**
    const columns = [
        {
            name: 'Rol',
            selector: row => row?.rol,
        },
        {
            name: 'Estado',
            selector: (row) => {
                return (
                    row?.estado === true ? <div className="flex items-center">
                        <div className="h-2.5 w-2.5 rounded-full bg-green-500 mr-2"></div> <h2 className="text-sm text-green-500 font-bold" > Activo</h2 >
                    </div>
                        : <div className="flex items-center">
                            <div className="h-2.5 w-2.5 rounded-full bg-red-RS mr-2"></div> <h2 className="text-sm text-red-RS font-bold" > Inactivo</h2 >
                        </div>
                )
            },
        },
        {
            name: 'Acciones',
            selector: row => row?.estado,
            cell: (row) =>

                <button type="button" id={row?.idRol} onClick={() => { setShowEditModal(true); setIdFromEdit(row?.idRol); setValue("rolName", row?.rol) }} className="rounded-full text-sm w-32 py-2 px-4 border-solid border-2 border-black-RS text-black-RS font-bold  m-2 hover:border-gray-200 ">Editar</button>
               
            ,
            right: true,
            ignoreRowClick: true,
            allowOverflow: true,
        },
        {
            cell: (row ) => {
                return (
                    row?.estado === true ? <button type="button" id={row?.idRol} onClick={() => { setStateRol(row?.estado); setIdFromDeactivate(row?.idRol); setShowDeleteModal(true); }} className="rounded-full text-sm w-32 py-2 px-4 border-solid border-2 border-red-RS text-red-RS font-bold  m-2 hover:border-gray-200" > Desactivar</button >
                        : <button type="button" id={row?.idRol} onClick={() => { setStateRol(row?.estado); setIdFromDeactivate(row?.idRol); setShowDeleteModal(true); }} className="rounded-full text-sm w-32 py-2 px-4 border-solid border-2 border-greenButton text-greenButton font-bold  m-2 hover:border-gray-200" > Activar</button >
                )
            }
            ,
        },
       
    ]

    const onSaveEditOptions = () => {
        const rol = getValues("rolName");

        EditRole(idFromEdit,rol)

        setShowEditModal(false);

        setValue("rolName", "");
    }

    const onSaveDeleteOptions = () => {

        DeactivateRole(idFromDeactivate, !stateRol)

        setShowDeleteModal(false);
    }

    const onSaveAddOptions = () => {
        const roleName = getValues("rolName");

        AddRole(roleName);

        setShowAddModal(false);
       
        setValue("rolName", "");
   
    }

    //when component start, get list
    useState(() => {
        getRoles();
    }, [])

    useEffect(() => {

        //refresh screen every time modal close
        if (!showEditModal) {

            const timeout = setTimeout(() => {
                getRoles();
            }, 1000);
            return () => clearTimeout(timeout);

        }
     
       if (!showDeleteModal) {
            const timeout = setTimeout(() => {
                getRoles();
            }, 1000);
            return () => clearTimeout(timeout);
        
        }
        if (!showAddModal) {
            const timeout = setTimeout(() => {
                getRoles();
            }, 1000);
            return () => clearTimeout(timeout);
        }
       
    }, [showAddModal, showDeleteModal, showEditModal])

    //use to show add new role component when 'agregar' button is activated
    const AddRoleComponent = () => {
        return (
            <form className="space-y-2 md:space-y-4">
                <div className="w-96 container mx-auto items-center justify-center">
                    <div className="flex flex-col">
                        <div className="flex text-base m-2 justify-center mx-auto font-bold leading-tight tracking-tight text-green-500">
                            <PlusCircleIcon className="w-8 h-8 ml-2"></PlusCircleIcon>
                        </div>
                        <div>
                            <h1 className="flex text-base justify-center mx-auto font-bold leading-tight tracking-tight text-black-RS">
                                Agregar Rol
                            </h1>
                        </div>

                        <div>
                            <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Nombre del rol</label>
                        </div>

                        <div>
                            <input key="rolNameAdd"
                                type="text"
                                name="rolName"
                                autoFocus="autoFocus"
                                {...register("rolName", { required: true, maxLength: 25 })}
                                id="rolName"
                                className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS"
                                placeholder="Nombre del rol"
                            />

                        </div>
                       
                    </div>
                </div>
            </form>
        )
    }

    //use to show edit role component when 'editar' button is activated
    const EditRoleComponent = () => {
        return (
            <form className="space-y-2 md:space-y-4">
                <div className="w-96 container mx-auto items-center justify-center">
                    <div className="flex flex-col">
                        <div className="flex text-base m-2 justify-center mx-auto font-bold leading-tight tracking-tight text-green-500">
                            <PencilIcon className="w-8 h-8 ml-2"></PencilIcon>
                        </div>
                        <div>
                            <h1 className="flex text-base justify-center mx-auto font-bold leading-tight tracking-tight text-black-RS">
                                Editar Rol
                            </h1>
                        </div>

                        <div>
                            <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Nombre del rol</label>
                        </div>

                        <div>
                            <input
                                key="rolNameEdit"
                                type="text"
                                autoFocus="autoFocus"
                                name="rolName"
                                {...register("rolName", { onChange: handleChange, required: true, maxLength: 25 })}
                                id="rolName"
                                value={getValues("rolName")}
                                className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS"
                                placeholder="Nombre del rol"
                            />
                        </div>

                    </div>
                </div>
            </form>
        )
    }

    //use to show delete new role component when 'eliminar' button is activated
    const DeleteRoleComponent = () => {
        return (
            <form className="space-y-2 md:space-y-4">
                <div className="w-96 container mx-auto items-center justify-center">
                    <div className="flex flex-col">
                        <div className="flex text-base m-2 justify-center mx-auto font-bold leading-tight tracking-tight text-red-RS">
                            <ExclamationTriangleIcon className="w-8 h-8 ml-2"></ExclamationTriangleIcon>
                        </div>
                        <div>
                            <h1 className="flex text-base justify-center mx-auto font-bold leading-tight tracking-tight text-black-RS">
                               Advertencia
                            </h1>
                        </div>

                        <div>
                            <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">¿Está seguro que desea cambiar el estado de este elemento?</label>
                        </div>

                    </div>
                </div>
            </form>
        )
    }
    return (
        <>

            <div className="flex flex-row">
                <div className="md:basis-1/4 xl:basis-1/5 ">
                    <SideBar />    
                </div>
                <div className="md:basis-3/4 xl:basis-11/12">
                    <div className="bg-white rounded-lg shadow-lg shadow-slate-400 px-2 py-2  mt-2 m-2 mr-6">

                        <div className="px-2">
                            <div className="absolute z-20 ml-4 mt-1">
                                <h1 className="left-0 text-xl font-bold text-black-RS mb-1">Roles</h1>
                                <div className="col-start-1 col-end-3 ">
                                    <div className="items-center sm:flex">
                                        <button type="button" onClick={() => { setShowAddModal(!showAddModal); setValue("rolName", "") }} className="bg-greenButton hover:bg-primary-700 text-white text-sm  py-2 px-4 rounded inline-flex items-center">
                                            <span>Agregar</span>
                                            <PlusCircleIcon className="w-8 h-8 ml-2"></PlusCircleIcon>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="static px-2 mt-2 m-2">
                                <Table columns={columns} title="rol" filteredItems={""} data={listRoles} filterBy="rol" />
                            </div>
                        </div>
                        {/*Here we show add modal compononent*/}
                        <Modal isVisible={showAddModal} onClose={() => { setShowAddModal(false) }} handleSubmit={handleSubmit} onSaveOptions={onSaveAddOptions} showCloseIcon={false}>
                            {/*Button to close modal*/}
                            <div className="flex justify-end m-2">
                                <button className="cursor-pointer text-black-400 hover:text-gray-600 transition duration-150 ease-in-out rounded ring-2 focus:outline-none ring-gray-600" onClick={() => setShowAddModal(false)} aria-label="close modal" role="button">
                                    <XMarkIcon className="text-red-RS h-6 w-6" />
                                </button>
                            </div>
                            <AddRoleComponent/>
                        </Modal>

                        {/*Here we show edit modal compononent*/}
                        <Modal isVisible={showEditModal} onClose={() => { setShowEditModal(false) }} handleSubmit={handleSubmit} onSaveOptions={onSaveEditOptions} showCloseIcon={false}>
                            {/*Button to close modal*/}
                            <div className="flex justify-end m-2">
                                <button className="cursor-pointer text-black-400 hover:text-gray-600 transition duration-150 ease-in-out rounded ring-2 focus:outline-none ring-gray-600" onClick={() => setShowEditModal(false)} aria-label="close modal" role="button">
                                    <XMarkIcon className="text-red-RS h-6 w-6" />
                                </button>
                            </div>
                            <EditRoleComponent />
                        </Modal>

                        {/*Here we show delete modal compononent*/}
                        <Modal isVisible={showDeleteModal} onClose={() => { setShowDeleteModal(false) }} handleSubmit={handleSubmit} onSaveOptions={onSaveDeleteOptions} showCloseIcon={false}>
                            {/*Button to close modal*/}
                            <div className="flex justify-end m-2">
                                <button className="cursor-pointer text-black-400 hover:text-gray-600 transition duration-150 ease-in-out rounded ring-2 focus:outline-none ring-gray-600" onClick={() => setShowDeleteModal(false)} aria-label="close modal" role="button">
                                    <XMarkIcon className="text-red-RS h-6 w-6" />
                                </button>
                            </div>
                            <DeleteRoleComponent />
                        </Modal>
                   
                    </div>
                    
                </div>

            </div>
      
        </>
    )
}
