import React, { useEffect, useState } from "react";
import EspecialidadCatalogComponent from './EspecialidadCatalog';

const EspecialidadCatalog = () => {

    //validations


    //function

    return (
        <div>
            <EspecialidadCatalogComponent/>
        </div>
    );

};

export default EspecialidadCatalog;