import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useForm } from 'react-hook-form';
import AlertContext from '../../../GlobalComponents/Alert/contexts/alertContext';

//import global components
import { Card, Modal, ModalActionConfirm } from '../../../../imports';


//import services
import { EditarProyecto, EliminarProyecto, AgregarProyectoIndividual } from '../../../../servicios/ServicioProyecto';


//import icons from heroicons
import { PlusCircleIcon, UserIcon, DocumentTextIcon, PencilIcon } from '@heroicons/react/24/outline';


export default function Proyectos({ validationMessages, userData, setUserData, finalData, setFinalData, allowEdit, listProjectsByPerson, isFirstLoadProject, setIsFirstLoadProject, projectDataEdit, setProjectDataEdit, idCurriculum }) {


    //use alert context
    const alertCtx = useContext(AlertContext);

    //here we pass a default input property to save in userData
    //all inputs use this same event in 'onchange'
    const handleChange = (e) => {
        const { name, value } = e.target;

        setValue(name, e.target.value)
    };

    //use react hook form to get values and make validations
    const { register, reset, getValues, setValue, handleSubmit, formState } = useForm({
        mode: 'all',
        defaultValues: {
            projectName: '',
            companyName: '',
            projectDescription: '',
            startDate: '',
            finishDate: ''
            
        }
    });

    const { errors } = formState;
    const { isValid } = formState;

    //use to get validations from props and later iterate
    const [validations, setValidations] = useState([]);

    const [showModal, setShowModal] = useState(false);

    //use modal and show for save
    const [showConfirmSaveModal, setShowConfirmSaveModal] = useState(false);
    const [actionTypeConfirmModal, setActionTypeConfirmModal] = useState("");
    const [showConfirmEditModal, setShowConfirmEditModal] = useState(false);

    const [listProjects, setListProjects] = useState([]);

    const [currentIdCard, setCurrentIdCard] = useState(null);

    const [idFromCard, setIdFromCard] = useState(0);

    //validate if user already complete cv
    const userCompleteCV = sessionStorage.getItem("USER_COMPLETE_CV");

    const [userSaveActionSuccess, setUserSaveActionSuccess] = useState(false);

    const [dataIndividualProject, setDataIndividualProject] = useState({});

    //call services to delete elements from education
    async function EliminarProyectoPersona(idProyecto) {
        const response = await EliminarProyecto(idProyecto);
        return response;
    }


    //method to add new project
    const AgregarProyectoPersona = async(e) => {
         
        const ProyectosData = {
            nombreProyecto: dataIndividualProject.dataForGlobalList?.projectName,
            nombreEmpresa: dataIndividualProject.dataForGlobalList?.companyName,
            fechaInicio: dataIndividualProject.dataForGlobalList?.startDate === "" ? null : dataIndividualProject.dataForGlobalList?.startDate,
            fechaFin: dataIndividualProject.dataForGlobalList?.finishDate === "" ? null : dataIndividualProject.dataForGlobalList?.finishDate,
            descripcionProyecto: dataIndividualProject.dataForGlobalList?.projectDescription,
            idPersona: idCurriculum
        }

        const proyectosResponse = await AgregarProyectoIndividual(ProyectosData);
 
        if (proyectosResponse.indicador === 0) {
     
            //save options in list
            if (listProjects) {
                setListProjects([...listProjects, dataIndividualProject])
            }

            alertCtx.success(proyectosResponse.mensaje);
            setUserSaveActionSuccess(true);
            setShowConfirmSaveModal(false);

            setDataIndividualProject({});

            setCurrentIdCard(null);
        }
     
    }

    //method to edit project from person by id
    const EditarProyectoPersona = async (e) => {
     
        const ProyectosData = {
            idProyecto: dataIndividualProject.dataForGlobalList?.idProject,
            nombreProyecto: dataIndividualProject.dataForGlobalList?.projectName,
            nombreEmpresa: dataIndividualProject.dataForGlobalList?.companyName,
            fechaInicio: dataIndividualProject.dataForGlobalList?.startDate === "" ? null : dataIndividualProject.dataForGlobalList?.startDate,
            fechaFin: dataIndividualProject.dataForGlobalList?.finishDate === "" ? null : dataIndividualProject.dataForGlobalList?.finishDate,
            descripcionProyecto: dataIndividualProject.dataForGlobalList?.projectDescription,
            idPersona: idCurriculum
        }

        const proyectosResponse = await EditarProyecto(ProyectosData);
     
        if (proyectosResponse.indicador === 0) {
            //save options in list
            if (currentIdCard) {
                if (listProjects.length > currentIdCard - 1 && listProjects[currentIdCard] !== undefined) {
              
                    //filter array of key value to validate if object exists and then update value
                    for (let item of Object.keys(listProjects)) {
                        if (listProjects[item] === listProjects[currentIdCard]) {

                            //update key value list to show recent info in cards by currentItem
                            listProjects[item] = dataIndividualProject
                        }
                    }
                }
            } else {
                setListProjects([...listProjects, dataIndividualProject])

            }

            alertCtx.success(proyectosResponse.mensaje);
            setUserSaveActionSuccess(true)
            setShowConfirmEditModal(false)

            setDataIndividualProject({})

            setCurrentIdCard(null);
        }
   
      
    }

    //function to save 'experience' in list
    const SaveProjectsOptions = () => {
        const startDate = getValues("startDate");
        const finishDate = getValues("finishDate");

        if (startDate > finishDate) {
            alertCtx.error("La fecha de finalización debe ser posterior a la fecha de inicio");
        } else {
            if (isValid) {

                const projectName = getValues("projectName");
                const companyName = getValues("companyName");
                const projectDescription = getValues("projectDescription");
                const startDate = getValues("startDate");
                const finishDate = getValues("finishDate");

                //we save global object and list with key and value to get easy in card iteration over data
                const dataForGlobalList = {
                    projectName: projectName,
                    companyName: companyName,
                    projectDescription: projectDescription,
                    startDate: startDate,
                    finishDate: finishDate,
                    idProject: idFromCard
                }

                const object = {
                    "dataHeader":
                    {
                        "Proyecto": projectName,
                    },
                    "dataBody":
                    {
                        "Empresa": companyName,
                    },
                    "dataID": {
                        "idProyecto": idFromCard
                    },
                    "dataForGlobalList": dataForGlobalList
                }

                //validation
                if (userCompleteCV === "true") {
                    setDataIndividualProject(object);

                    if (actionTypeConfirmModal === "Agregar") setShowConfirmSaveModal(true);

                    if (actionTypeConfirmModal === "Editar") setShowConfirmEditModal(true);

                    resetAllInputs();
                    
                } else {
                    //save options in list
                    if (currentIdCard) {
                        if (listProjects.length > currentIdCard - 1 && listProjects[currentIdCard] !== undefined) {

                            //filter array of key value to validate if object exists and then update value
                            for (let item of Object.keys(listProjects)) {
                                if (listProjects[item] === listProjects[currentIdCard]) {

                                    //update key value list to show recent info in cards by currentItem
                                    listProjects[item] = object
                                }
                            }
                        }
                    } else {
                        setListProjects([...listProjects, object])

                    }
                    resetAllInputs();

                    setCurrentIdCard(null);
                    //close modal and show alert
                    setShowModal(false);
                    setIdFromCard(0);

                    alertCtx.success("Datos guardados temporalmente");

                }
              
            
            } else {
                alertCtx.error("¡Datos incorrectos!");
            }
        }
    }

    const resetAllInputs = () => {
        //reset all default values
        reset({
            projectName: "",
            companyName: "",
            projectDescription: "",
            startDate: "",
            finishDate: "",
        }, {
            keepErrors: false,
            keepDirty: false,
        })
    }

    function setValuesById(list) {

        setValue("projectName", list.dataForGlobalList.projectName)
        setValue("companyName", list.dataForGlobalList.companyName)
        setValue("projectDescription", list.dataForGlobalList.projectDescription)
        setValue("startDate", list.dataForGlobalList.startDate)
        setValue("finishDate", list.dataForGlobalList.finishDate)
        setIdFromCard(list?.dataID.idProyecto);
        // setCourse(list.dataForGlobalList.InCourse)
    }

    //create custom object with list by person id to render cards
    const customObjectByPersonList = useCallback(() => {

        listProjectsByPerson.map((item) => {

            const startMonth = new Date(item?.fechaInicio).getMonth() + 1;
            const startYear = new Date(item?.fechaInicio).getFullYear();
            const startDay = new Date(item?.fechaInicio).getDay();
            const startDateFormatted = `${startYear}-${startMonth}-${startDay}`;
            const startDate = new Date(startDateFormatted)

            const endMonth = new Date(item?.fechaFin).getMonth() + 1;
            const endYear = new Date(item?.fechaFin).getFullYear();
            const endDay = new Date(item?.fechaFin).getDay();
            const endDateFormatted = `${endYear}-${endMonth}-${endDay}`;
            const endDate = new Date(endDateFormatted)

            //we save global object and list with key and value to get easy in card iteration over data
            const dataForGlobalList = {
                projectName: item?.nombreProyecto,
                companyName: item?.nombreEmpresa,
                projectDescription: item?.descripcionProyecto,
                startDate: item?.fechaInicio ? startDate.toLocaleDateString('fr-CA') : "",
                finishDate: item?.fechaFin ? endDate.toLocaleDateString('fr-CA') : "",
            }

            const object = {
                "dataHeader":
                {
                    "Proyecto": item?.nombreProyecto,
                },
                "dataBody":
                {
                    "Empresa": item?.nombreEmpresa,
                },
                "dataID": {
                    "idProyecto": item?.idProyecto
                },
                "dataForGlobalList": dataForGlobalList
            }

            if (listProjects !== undefined) {
                listProjects.push(object);
            }
            
        })

    },[])

    useState(() => {

        setValidations(validationMessages);

 
    }, [])

    useEffect(() => {
        if (userCompleteCV === "true" && isFirstLoadProject || allowEdit === true && isFirstLoadProject) {
            //fill data by idPerson
            if (listProjectsByPerson?.length > 0) {
                customObjectByPersonList();
                if (isFirstLoadProject) setIsFirstLoadProject(false)
            }

        }

        if (userCompleteCV === "true" || allowEdit === true) {
            if (listProjects?.length > 0) {
                if (projectDataEdit) {
                    setProjectDataEdit(listProjects);
                }

            } else {
                if (projectDataEdit) {
                    setListProjects(projectDataEdit);
                }
            }

        } else {
            //fill data by idPerson
            if (listProjects?.length > 0) {

                if (userData) {
                    setUserData({ ...userData, "listProjects": listProjects })
                    setFinalData(userData);
                }

            } else {

                if (userData["listProjects"]) {
                    setListProjects(userData["listProjects"])
                }

            }
        }
        
        
        if (userCompleteCV === "true" && !showModal || allowEdit === true && !showModal) {
            const timeout = setTimeout(() => {
                if (projectDataEdit) {

                    setProjectDataEdit(listProjects);
                }
            }, 1000);
            return () => clearTimeout(timeout);

        } else if (!showModal) {
            const timeout = setTimeout(() => {
                if (listProjects) {
                    if (userData) {
                        setUserData({ ...userData, "listProjects": listProjects });
                        setFinalData(userData);
                    }

                }
            }, 1000);
            return () => clearTimeout(timeout);

        }

        return (() => {
            if (userCompleteCV === "true" || allowEdit === true) {
                if (isFirstLoadProject) {
                    setIsFirstLoadProject(false);
                
                }
                if (projectDataEdit) {

                    setProjectDataEdit(listProjects);
                }
            } else {

                if (listProjects) {
                    setUserData({ ...userData, "listProjects": listProjects });
                }
            }
            setShowModal(false);
       
        })

    }, [showModal, listProjects, userSaveActionSuccess, dataIndividualProject, actionTypeConfirmModal])

    return (
        <div className="w-full flex flex-col md:flex-row items-center justify-center px-1  mx-auto lg:py-0">
            {/*Block to manage form input 'proyectos'--*/}
            <div className={userCompleteCV === "false" ? "w-full pt-1 bg-white rounded-lg shadow" : "w-full pt-1"}>
                <div className="relative container mx-auto items-center justify-center">

                    {!allowEdit && <div>
                        {listProjects?.length > 0 ? null : (
                            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                <h1 className="text-decoration-line: underline">Por favor, presione Agregar para completar el apartado de proyectos</h1>
                            </div>
                        )}
                    </div>
                    }
                   

                    {listProjects?.length > 0 &&
                        <div className="container flex flex-wrap w-full py-4 mt-6 mx-auto items-start justify-center">
                            {/*iterate over list techs */}
                            {listProjects?.map((item, index) => (
                                <div className="w-full m-1 p-1 mt-8 py-6 md:w-96" key={index}>
                                    <Card id={index} data={item} list={listProjects} setList={setListProjects} setCurrentIdCard={setCurrentIdCard} setShowModal={setShowModal} setValuesById={setValuesById} allowEdit={allowEdit}
                                        listByPerson={listProjectsByPerson} userCompleteCV={userCompleteCV} deleteElementCV={EliminarProyectoPersona} setActionTypeConfirmModal={setActionTypeConfirmModal} title="Proyectos"/>
                                </div>
                            ))}
                        </div>}
                    {/*here move button to top right if list is not null*/}
                    {!allowEdit && <div>
                        {listProjects?.length > 0 ? <div className="flex absolute top-0 right-0 md:justify-center items-center mb-8 px-2 py-6">
                            <button type="button" disabled={allowEdit} className="bg-greenButton hover:bg-primary-700 text-white text-sm  py-2 px-4 rounded inline-flex items-center" onClick={() => { setShowModal(true); setActionTypeConfirmModal("Agregar") }}>
                                <span>Agregar</span>
                                <PlusCircleIcon className="w-8 h-8 ml-2"></PlusCircleIcon>
                            </button>
                        </div> :
                            <div className="flex justify-center items-center mb-4 px-2">
                                <button type="button" disabled={allowEdit} className="bg-greenButton hover:bg-primary-700 text-white text-sm  py-2 px-4 rounded inline-flex items-center" onClick={() => { setShowModal(true); setActionTypeConfirmModal("Agregar") }}>
                                    <span>Agregar</span>
                                    <PlusCircleIcon className="w-8 h-8 ml-2"></PlusCircleIcon>
                                </button>
                            </div>}
                    </div>
                    }

                    {/*Here we show view confirm save modal compononent*/}
                    <Modal isVisible={showConfirmSaveModal} onClose={() => { setShowConfirmSaveModal(false) }} handleSubmit={() => null} onSaveOptions={() => null} showFotter={false} showCloseIcon={false}>
                        <ModalActionConfirm onSaveAction={AgregarProyectoPersona} actionType={actionTypeConfirmModal} setshowConfirmModal={setShowConfirmSaveModal} />
                    </Modal>

                    {/*Here we show view confirm edit modal compononent*/}
                    <Modal isVisible={showConfirmEditModal} onClose={() => { setShowConfirmEditModal(false) }} handleSubmit={() => null} onSaveOptions={() => null} showFotter={false} showCloseIcon={false}>
                        <ModalActionConfirm onSaveAction={EditarProyectoPersona} actionType={actionTypeConfirmModal} setshowConfirmModal={setShowConfirmEditModal} />
                    </Modal>
                  
        
                    <Modal isVisible={showModal} onClose={() => { setShowModal(false); resetAllInputs(); }} handleSubmit={handleSubmit} onSaveOptions={SaveProjectsOptions} >
                        <form className="space-y-1 md:space-y-2">
                            {/*First row*/}
                            <div className="flex flex-col  mx-auto items-center justify-center md:flex-row">

                                {/*Title current page*/}
                                <div className="w-full mx-px px-2 mt-4 md:w-80">
                                    <h1 className="block mb-2 text-xl font-bold text-black-RS">Proyectos</h1>
                                </div>

                                {/*Nombre proyecto Input*/}
                                <div className="w-full mx-px px-2 mb-2 md:w-80">
                                    <label className="block mb-2 text-sm font-medium text-gray-900">Nombre del proyecto</label>
                                    <label className="relative block">
                   
                                        <input type="text"
                                            name="projectName"
                                            {...register("projectName", { onChange: handleChange, required: true, maxLength: 150})}
                                            id="projectName"
                                            className="text-gray-900 sm:text-sm block w-full p-2.5 mb-2 pl-3 pr-10  rounded border-solid border-2 border-grayDark-RS"
                                            placeholder="Nombre del proyecto"
                                        />
                                        <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                                            <UserIcon className="h-5 w-5 pointer-events-none text-dark-500" />
                                        </span>
                                    </label>
                                    {/*iterate over validations and show message projectName*/}
                                    {validations.projectName?.map((values) => {
                                        return (
                                            errors.projectName?.type === values.type &&
                                            <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                                <p className="text-sm font-bold text-red-RS mt-2">{values.message}</p>
                                            </span>
                                        )
                                    })}
                                </div>


                                {/*Nombre de empresa Input*/}
                                <div className="w-full mx-px px-2 mb-2 md:w-80">
                                    <label className="block mb-2 text-sm font-medium text-gray-900">Nombre de empresa</label>
                                    <label className="relative block">
                                     
                                        <input type="text"
                                            name="companyName"
                                            {...register("companyName", { onChange: handleChange, required: true, maxLength: 150, pattern: /^[\wÀ-ÿ\u00f1\u00d1]+(\s*[\wÀ-ÿ\u00f1\u00d1]*)*[\wÀ-ÿ\u00f1\u00d1]+$/ })}
                                            id="companyName"
                                            className="text-gray-900 sm:text-sm block w-full p-2.5 mb-2 pl-3 pr-10  rounded border-solid border-2 border-grayDark-RS "
                                            placeholder="Nombre de Empresa"
                                        />
                                        <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                                            <UserIcon className="h-5 w-5 pointer-events-none text-dark-500" />
                                        </span>
                                    </label>
                                    {/*iterate over validations and show message companyName*/}
                                    {validations.companyName?.map((values) => {
                                        return (
                                            errors.companyName?.type === values.type &&
                                            <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                                <p className="text-sm font-bold text-red-RS mt-2">{values.message}</p>
                                            </span>
                                        )
                                    })}
                                </div>

                               
                                
                            </div>
                            {/*Second row*/}
                            <div className="flex flex-wrap mx-1 mx-auto items-center justify-center">

                                {/*Fecha Inicio Input*/}
                                <div className="w-full mx-px px-2 mb-2  md:w-80">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 ">Fecha de inicio</label>
                                    <div className="relative flex items-center">
                                        <input type="date"
                                            name="startDate"
                                            {...register("startDate", { onChange: handleChange, required: true, maxLength: 25 })}
                                            id="startDate"
                                            className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS "
                                            placeholder="Fecha inicio"
                                        />
                                    </div>
                                    {/*iterate over validations and show message birthdate*/}
                                    {validations.startDate?.map((values) => {
                                        return (
                                            errors.startDate?.type === values.type &&
                                            <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                                <p className="text-sm font-bold text-red-RS mt-2">{values.message}</p>
                                            </span>
                                        )
                                    })}
                                </div>

                                {/*Fecha Finalizacion Input*/}
                                <div className="w-full mx-px px-2 mb-2  md:w-80">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 ">Fecha de finalización</label>
                                    <div className="relative flex items-center">
                                        <input type="date"
                                            name="finishDate"
                                            {...register("finishDate", { onChange: handleChange, required: true, maxLength: 25 })}
                                            id="finishDate"
                                            className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS "
                                            placeholder="Fecha de finalización"
                                        />
                                    </div>
                                    {/*iterate over validations and show message birthdate*/}
                                    {validations.finishDate?.map((values) => {
                                        return (
                                            errors.finishDate?.type === values.type &&
                                            <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                                <p className="text-sm font-bold text-red-RS mt-2">{values.message}</p>
                                            </span>
                                        )
                                    })}
                                </div>

                                {/*Descripcion del proyecto Input*/}
                                <div className="w-full mx-px px-2 mb-2 md:w-80">
                                    <label className="block mb-2 text-sm font-medium text-gray-900">Descripción del proyecto</label>
                                    <label className="relative block">
                                      
                                        <textarea type="textarea"
                                            name="projectDescription"
                                            id="projectDescription"
                                            {...register("projectDescription", { onChange: handleChange, required: true, maxLength: 255 })}
                                            className="text-gray-900 sm:text-sm block w-full p-2.5 pl-3 pr-10 mb-2 rounded border-solid border-2 border-grayDark-RS "
                                            placeholder="Descripción del proyecto"
                                        />
                                        <span className="absolute inset-y-0 right-0 flex items-center pr-3 mb-2">
                                            <DocumentTextIcon className="h-5 w-5 pointer-events-none text-dark-500 " />
                                        </span>
                                    </label>
                                    {/*iterate over validations and show message projectDescription*/}
                                    {validations.projectDescription?.map((values) => {
                                        return (
                                            errors.projectDescription?.type === values.type &&
                                            <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                                <p className="text-sm font-bold text-red-RS mt-2">{values.message}</p>
                                            </span>
                                        )
                                    })}
                                </div>



                                
                            </div>
                        </form>
                    </Modal>
                </div>
            </div>
        </div>
    );
}
