import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import  AlertContext from '../../../GlobalComponents/Alert/contexts/alertContext';
//import global components
import { Modal, SideBar, Table } from '../../../../imports';

//import services
import { ObtenerRolUsuario, ObtenerUsuarios, EditarUsuario, AgregarUsuarios, DesactivarUsuario } from '../../../../servicios/ServicioUsuario';

//import icons from heroicons
import { PlusCircleIcon, ExclamationTriangleIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/outline';


//use to show add new user component when 'agregar' button is activated
const AddUserComponent = ({ validations, rolOptions, setShowAddModal, usuario, getUsuarios }) => {

    //declare register to form
    //use react hook form to get values and make validations
    const { register, getValues, watch, setValue, formState } = useForm({
        mode: 'all',
        defaultValues: {
            emailAdd: '',
            passwordAdd: '',
            nameAdd: '',
            lastNameAdd: '',
            secondSurnameAdd: '',
            phoneAdd: '',
            rolAdd: '',
        }
    });

    //use alert context
    const alertCtx = useContext(AlertContext);

    const { errors } = formState;
    const { isValid } = formState;

    //async method to insert into sql user
    async function AddUsuarios(correo, contrasena, nombre, primerapellido, segundoapellido, telefono, idrol) {

        const data = {
            correo: correo,
            contrasena: contrasena,
            nombre: nombre,
            primerApellido: primerapellido,
            segundoApellido: segundoapellido,
            telefono: telefono,
            usuarioCreacion: String(usuario.Correo),
            idRol: parseInt(idrol, 10),

        }


        const response = await AgregarUsuarios(data);

        if (response.indicador === 0) {
            alertCtx.success(response.mensaje);
        } else {
            alertCtx.error(response.mensaje);
        }
    }
    const resetValues = () => {
        setValue("emailAdd", "");
        setValue("nameAdd", "");
        setValue("lastNameAdd", "");
        setValue("secondSurnameAdd", "");
        setValue("phoneAdd", "");
        setValue("rolAdd", "");
        setValue("passwordAdd", "");
    }

    const onSaveAddOptions = async() => {

        if (isValid) {
            const email = getValues("emailAdd");
            const password = getValues("passwordAdd");
            const name = getValues("nameAdd");
            const lastName = getValues("lastNameAdd");
            const secondSurName = getValues("secondSurnameAdd");
            const phone = getValues("phoneAdd");
            const rol = getValues("rolAdd");

            AddUsuarios(email, password, name, lastName, secondSurName, phone, rol);

            setShowAddModal(false);

            resetValues();
            const timeout = setTimeout(() => {
                getUsuarios();
            }, 2000);
            return () => clearTimeout(timeout);
        } else {
            alertCtx.error("¡Error al registrar!");
        }

    }

    return (
        <>

            <div className="w-full container mx-auto items-center justify-center">
                <div className="flex flex-col">
                    <div className="flex text-base m-3 justify-center mx-auto font-bold leading-tight tracking-tight text-green-500">
                        <PlusCircleIcon className="w-8 h-8 ml-2"></PlusCircleIcon>
                    </div>
                    <div>
                        <h1 className="flex text-base justify-center mx-auto font-bold leading-tight tracking-tight text-black-RS">
                            Agregar Usuario
                        </h1>
                    </div>
                </div>
                <form className="space-y-2 md:space-y-4">
                    {/*First row*/}
                    <div className="flex flex-row mt-2 mx-auto items-center justify-center">

                        <div className="w-1/2 m-3 px-2  md:w-80">
                            <label className="flex mb-2 text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Nombre</label>
                            <div className="relative flex items-center">
                                <input
                                    type="text"
                                    name="nameAdd"
                                    {...register("nameAdd", { required: true, maxLength: 25 })}
                                    id="name"
                                    className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS"
                                    placeholder="Nombre"
                                />
                            </div>
                            {/*iterate over validations and show message name*/}
                            {validations.name?.map((values) => {
                                return (
                                    errors.nameAdd?.type === values.type &&
                                    <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                        <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                    </span>
                                )
                            })}
                        </div>

                        <div className="w-1/2 m-3 px-2  md:w-80">

                            <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Primer Apellido</label>
                            <div className="relative flex items-center">
                                <input
                                    type="text"
                                    name="lastName"
                                    {...register("lastNameAdd", { required: true, maxLength: 25 })}
                                    id="lastname"
                                    className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS"
                                    placeholder="Primer Apellido"
                                />

                            </div>
                            {/*iterate over validations and show message lastName*/}
                            {validations.lastName?.map((values) => {
                                return (
                                    errors.lastNameAdd?.type === values.type &&
                                    <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                        <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                    </span>
                                )
                            })}
                        </div>
                    </div>
                    {/*Second row*/}
                    <div className="flex flex-wrap mx-2 mx-auto items-center justify-center">

                        <div className="w-1/2 m-3 px-2  md:w-80">

                            <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Segundo Apellido</label>

                            <div>
                                <input
                                    type="text"
                                    name="secondSurname"
                                    {...register("secondSurnameAdd", { required: true, maxLength: 25 })}
                                    id="secondSurname"
                                    className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS"
                                    placeholder="Segundo Apellido"
                                />

                            </div>


                        </div>

                        <div className="w-1/2 m-3 px-2  md:w-80">

                            <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Correo eléctronico</label>

                            <div>
                                <input
                                    type="text"
                                    name="email"
                                    {...register("emailAdd", { required: true, maxLength: 150, pattern: /^(.+)@(.+)$/ })}
                                    id="email"
                                    className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS"
                                    placeholder="Correo eléctronico"
                                />

                            </div>
                            {/*iterate over validations and show message email*/}
                            {validations.email?.map((values) => {
                                return (
                                    errors.emailAdd?.type === values.type &&
                                    <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                        <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                    </span>
                                )
                            })}
                        </div>
                    </div>

                    {/*Third row*/}
                    <div className="flex flex-wrap mx-2 mx-auto items-center justify-center">

                        <div className="w-1/2 m-3 px-2  md:w-80">

                            <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Teléfono</label>


                            <div>
                                <input
                                    type="text"
                                    name="phone"
                                    {...register("phoneAdd", { required: true, maxLength: 10 })}
                                    id="phone"
                                    className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS"
                                    placeholder="Numero de telefono"
                                />

                            </div>
                            {/*iterate over validations and show message phone*/}
                            {validations.phone?.map((values) => {
                                return (
                                    errors.phoneAdd?.type === values.type &&
                                    <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                        <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                    </span>
                                )
                            })}
                        </div>

                        <div className="w-1/2 m-3 px-2  md:w-80">

                            <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Rol</label>

                            <div className="relative flex items-center">
                                <select
                                    type="select"
                                    name="rol"
                                    {...register("rolAdd", { required: true })}
                                    id="rol"
                                    className="text-black-RS sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS "

                                >
                                    <option value={""} defaultValue > --Seleccione el rol --</option>

                                    {rolOptions?.map((option, index) => (
                                        <option key={index} value={option.idRol}>{option.rol}</option>
                                    ))}
                                </select>

                            </div>
                            {/*iterate over validations and show message name*/}
                            {validations.role?.map((values) => {
                                return (
                                    errors.rolAdd?.type === values.type &&
                                    <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                        <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                    </span>
                                )
                            })}
                        </div>
                    </div>
                    {/*Third row*/}
                    <div className="flex flex-wrap mx-2 mx-auto items-center justify-center">

                        {/*Contraseña Input*/}
                        <div className="w-full m-3 px-2  md:w-80">

                            <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Contraseña</label>

                            <div className="relative flex items-center">
                                <input
                                    type="password"
                                    name="password"
                                    {...register("passwordAdd", {
                                        required: true,
                                        minLength: 6,
                                        maxLength: 12
                                    })}
                                    id="password"
                                    className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS"
                                    placeholder="Contraseña"
                                />

                            </div>
                            {/*iterate over validations and show message password*/}
                            {validations.password?.map((values) => {
                                return (
                                    errors.passwordAdd?.type === values.type &&
                                    <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                        <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                    </span>
                                )
                            })}
                        </div>
                        <div className="w-full m-3 px-2  md:w-80">

                            <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Confirmar Contraseña</label>


                            <div className="relative flex items-center">
                                <input type="password"
                                    name="passwordc"
                                    {...register("passwordc", {
                                        required: true,
                                        minLength: 6,
                                        maxLength: 12,
                                        validate: (value) => value == watch('passwordAdd')
                                    })}
                                    id="passwordc"
                                    className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS"
                                    placeholder="Confirmar Contraseña"
                                />

                            </div>
                            {/*iterate over validations and show message password*/}
                            {validations.repeatPassword?.map((values) => {
                                return (
                                    errors.passwordc?.type === values.type &&
                                    <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                        <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                    </span>
                                )
                            })}
                        </div>

                    </div>
                    <div className=" flex flex-wrap z-10 mb-2 mx-auto items-center justify-center ">
                        <button type="button" className="py-2 px-4 mx-2 my-5 text-white bg-black-RS  hover:bg-primary-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                            onClick={() => setShowAddModal(false)} >Cancelar</button>
                        <button type="button" className="py-2 px-4 mx-2 my-5 text-white bg-greenButton  hover:bg-primary-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            onClick={onSaveAddOptions}>Confirmar</button>
                    </div>
                </form>
            </div>

        </>
    )
}

//use to show edit role component when 'editar' button is activated
const EditUserComponent = ({ validations, rolOptions, setShowEditModal, rowValuesFromId, usuario, getUsuarios, idFromEdit }) => {

    //declare register to form
    //use react hook form to get values and make validations
    const { register, getValues, setValue, formState } = useForm({
        mode: 'all',
        defaultValues: {
            emailEdit: '',
            passwordEdit: '',
            nameEdit: '',
            lastNameEdit: '',
            secondSurnameEdit: '',
            phoneEdit: '',
            rolEdit: '',
        }
    });

    //use alert context
    const alertCtx = useContext(AlertContext);

    const { errors } = formState;
    const { isValid } = formState;

    const resetValues = () => {
        setValue("emailEdit", "");
        setValue("nameEdit", "");
        setValue("lastNameEdit", "");
        setValue("secondSurnameEdit", "");
        setValue("phoneEdit", "");
        setValue("rolEdit", "");
    }
     //async method to edit into sql user
    async function EditUsuarios(id, correo, nombre, primerapellido, segundoapellido, telefono, idrol) {

        const data = {
            idUsuario: id,
            correo: correo,
            nombre: nombre,
            primerApellido: primerapellido,
            segundoApellido: segundoapellido,
            telefono: telefono,
            usuarioModificacion: String(usuario.Correo),
            idRol: parseInt(idrol, 10),
        }

        const response = await EditarUsuario(data);

        if (response.indicador === 0) {
            alertCtx.success(response.mensaje);
        } else {
            alertCtx.error(response.mensaje);
        }
    }

    const onSaveEditOptions = () => {

        if (isValid) {

            const email = getValues("emailEdit");
            const name = getValues("nameEdit");
            const lastName = getValues("lastNameEdit");
            const secondSurName = getValues("secondSurnameEdit");
            const phone = getValues("phoneEdit");
            const rol = getValues("rolEdit");

            EditUsuarios(idFromEdit, email, name, lastName, secondSurName, phone, rol)

            setShowEditModal(false);


            resetValues()
            const timeout = setTimeout(() => {
                getUsuarios();
            }, 2000);
            return () => clearTimeout(timeout);
        } else {
            alertCtx.error("¡Error al editar el usuario!");
        }
    }

    useState(() => {

        setValue("nameEdit", rowValuesFromId?.nombre);
        setValue("lastNameEdit", rowValuesFromId?.primerApellido);
        setValue("secondSurnameEdit", rowValuesFromId?.segundoApellido);
        setValue("emailEdit", rowValuesFromId?.correo);
        setValue("phoneEdit", rowValuesFromId?.telefono);
        setValue("rolEdit", rowValuesFromId?.rol);

    }, [])

    return (
        <>
            <form className="space-y-2 md:space-y-4">
                <div className="w-full container mx-auto items-center justify-center">
                    <div className="flex flex-col">
                        <div className="flex text-base m-2 justify-center mx-auto font-bold leading-tight tracking-tight text-green-500">
                            <PencilIcon className="w-8 h-8 ml-2"></PencilIcon>
                        </div>
                        <div>
                            <h1 className="flex text-base justify-center mx-auto font-bold leading-tight tracking-tight text-black-RS">
                                Editar Usuario
                            </h1>
                        </div>
                        {/*First row*/}
                        <div className="flex flex-wrap mt-2 mx-auto items-center justify-center">

                            <div className="w-full m-3 px-2  md:w-80">

                                <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Nombre</label>
                                <div className="relative flex items-center">
                                    <input
                                        type="text"
                                        name="name"
                                        {...register("nameEdit", { required: true, maxLength: 25 })}
                                        id="name"
                                        className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS"
                                        placeholder="Nombre"
                                    />
                                </div>
                                {/*iterate over validations and show message name*/}
                                {validations.name?.map((values) => {
                                    return (
                                        errors.nameEdit?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>

                            <div className="w-full m-3 px-2  md:w-80">

                                <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Primer Apellido</label>


                                <div className="relative flex items-center">
                                    <input
                                        type="text"
                                        name="lastName"
                                        {...register("lastNameEdit", { required: true, maxLength: 25 })}
                                        id="lastname"
                                        className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS"
                                        placeholder="Primer Apellido"
                                    />

                                </div>
                                {/*iterate over validations and show message name*/}
                                {validations.lastName?.map((values) => {
                                    return (
                                        errors.lastNameEdit?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>
                        </div>
                        {/*Second row*/}
                        <div className="flex flex-wrap mx-2 mx-auto items-center justify-center">
                            <div className="w-full m-3 px-2  md:w-80">

                                <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Segundo Apellido</label>


                                <div className="relative flex items-center">
                                    <input
                                        type="text"
                                        name="secondSurname"
                                        {...register("secondSurnameEdit", { required: true, maxLength: 25 })}
                                        id="secondSurname"
                                        className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS"
                                        placeholder="Segundo Apellido"
                                    />

                                </div>
                                {/*iterate over validations and show message name*/}
                                {validations.secondSurname?.map((values) => {
                                    return (
                                        errors.secondSurnameEdit?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>

                            <div className="w-full m-3 px-2  md:w-80">

                                <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Correo eléctronico</label>


                                <div className="relative flex items-center">
                                    <input
                                        type="text"
                                        name="email"
                                        {...register("emailEdit", { required: true, maxLength: 32 })}
                                        id="email"
                                        className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS"
                                        placeholder="Correo eléctronico"
                                    />

                                </div>
                                {/*iterate over validations and show message name*/}
                                {validations.email?.map((values) => {
                                    return (
                                        errors.emailEdit?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>
                        </div>
                        {/*Third row*/}
                        <div className="flex flex-wrap mx-2 mx-auto items-center justify-center">
                            <div className="w-full m-3 px-2  md:w-80">

                                <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Teléfono</label>


                                <div className="relative flex items-center">
                                    <input
                                        type="text"
                                        name="phone"
                                        {...register("phoneEdit", { required: true, maxLength: 32 })}
                                        id="phone"
                                        className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS"
                                        placeholder="Numero de telefono"
                                    />

                                </div>
                                {/*iterate over validations and show message name*/}
                                {validations.phone?.map((values) => {
                                    return (
                                        errors.phoneEdit?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>

                            <div className="w-full m-3 px-2  md:w-80">

                                <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Rol</label>

                                <div className="relative flex items-center">

                                    <select
                                        type="select"
                                        name="rol"
                                        {...register("rolEdit", { required: true, maxLength: 32 })}
                                        id="rol"
                                        className="text-black-RS sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS "

                                    >
                                        <option value={""} defaultValue > --Seleccione el rol --</option>

                                        {rolOptions?.map((option, index) => (
                                            <option key={index} value={option.idRol}>{option.rol}</option>
                                        ))}
                                    </select>
                                </div>
                                {/*iterate over validations and show message name*/}
                                {validations.role?.map((values) => {
                                    return (
                                        errors.rolEdit?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>
                        </div>

                    </div>
                </div>
                <div className=" flex flex-wrap z-10 mb-2 mx-auto items-center justify-center ">
                    <button type="button" className="py-2 px-4 mx-2 my-5 text-white bg-black-RS  hover:bg-primary-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                        onClick={() => setShowEditModal(false)} >Cancelar</button>
                    <button type="button" className="py-2 px-4 mx-2 my-5 text-white bg-greenButton  hover:bg-primary-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        onClick={onSaveEditOptions}>Confirmar</button>
                </div>
            </form>
        </>
    )
}

export default function UsuarioCatalog() {


    //use to get maritalStatus options
    const [rolOptions, setrolOptions] = useState([]);


    //list to save and send data to table
    const [listUsuarios, setListUsuarios] = useState([]);

    const [rowValuesFromId, setRowValuesFromId] = useState({});

    //use alert context
    const alertCtx = useContext(AlertContext);

    //use modal and show for add
    const [showAddModal, setShowAddModal] = useState(false);

    //use modal and show for edit
    const [showEditModal, setShowEditModal] = useState(false);

    //use modal and show for delete
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    //use to get id with set options when click events
    const [idFromEdit, setIdFromEdit] = useState(null);
    const [idFromDeactivate, setIdFromDeactivate] = useState(0);


    //use to get state from colums
    const [stateUsuario, setStateUsuario] = useState(null);

    //get current user id
    const jsonUsuario = sessionStorage.getItem("USER_DATA");
    const usuario = JSON.parse(jsonUsuario);


    //validations
    const validations = {
        email: [
            {
                type: 'required',
                message: 'Favor ingresar el correo',
            },
            {
                type: 'maxLength',
                message: 'El correo no puede contener mas de 150 caracteres ',
            },
            {
                type: 'pattern',
                message: 'Correo electrónico inválido',
            }
        ],
        password: [
            {
                type: 'required',
                message: 'Favor ingresar la contraseña',
            },
            {
                type: 'minLength',
                message: 'La contraseña debe contener al menos 6 caracteres ',
            },
            {
                type: 'maxLength',
                message: 'La contraseña no puede contener mas de 32 caracteres ',
            }
        ],
        repeatPassword: [
            {
                type: 'required',
                message: 'Favor ingresar la contraseña',
            },
            {
                type: 'minLength',
                message: 'La contraseña debe contener al menos 6 caracteres ',
            },
            {
                type: 'maxLength',
                message: 'La contraseña no puede contener mas de 32 caracteres ',
            },
            {
                type: 'validate',
                message: 'Las contraseñas son distintas ',
            }
        ],
        name: [
            {
                type: 'required',
                message: 'Favor ingresar su nombre',
            },
            {
                type: 'maxLength',
                message: 'El nombre no puede contener mas de 25 caracteres ',
            }
        ],
        lastName: [
            {
                type: 'required',
                message: 'Favor ingresar el primer apellido',
            },
            {
                type: 'maxLength',
                message: 'El primer apellido no puede contener mas de 25 caracteres ',
            }
        ],
        phone: [
            {
                type: 'required',
                message: 'Favor ingresar el teléfono',
            },
            {
                type: 'maxLength',
                message: 'El número teléfónico no puede contener mas de 10 caracteres ',
            }
        ],
        role: [
            {
                type: 'required',
                message: 'Favor seleccionar un rol',
            },
        ]


    }

    const {handleSubmit } = useForm({
        mode: 'all',
       
    });

    //get user data for table
    async function getUsuarios() {

        const response = await ObtenerUsuarios();

        setListUsuarios(response);
        
    }
     //function to save 'usuario roles' in list
    async function getRoles() {
        const response = await ObtenerRolUsuario();

        if (response !== undefined) {

            setrolOptions(response);
        }
    }
    

    async function DeactivateUsuarios(id, estado) {

        const data = {
            idUsuario: id,
            usuarioModificacion: String(usuario.Correo),
            estado: estado
        }

        const response = await DesactivarUsuario(data);

        if (response.indicador === 0) {
            alertCtx.success(response.mensaje);
        } else {
            alertCtx.error(response.mensaje);
        }
    }


    //**change this colums with entities names or need data**
    const columns = [
        {
            name: 'Nombre',
            selector: row => row?.nombres,
            width: '290px',
        },
        {
            name: 'Rol',
            selector: row => row?.idRol,
        },
        {
            name: 'Estado',
            selector: (row) => {
                return (
                    row?.estado === true ? <div className="flex items-center">
                        <div className="h-2.5 w-2.5 rounded-full bg-green-500 mr-2"></div> <h2 className="text-sm text-green-500 font-bold" > Activo</h2 >
                    </div>
                        : <div className="flex items-center">
                            <div className="h-2.5 w-2.5 rounded-full bg-red-RS mr-2"></div> <h2 className="text-sm text-red-RS font-bold" > Inactivo</h2 >
                        </div>
                )
            },
        },
        {
            name: 'Acciones',
            selector: row => row?.estado,
            cell: (row) =>

                <button type="button" id={row?.idUsuario} onClick={() => { setShowEditModal(true); setIdFromEdit(row?.idUsuario); setRowValuesFromId(row); }} className="rounded-full text-sm w-32 py-2 px-4 border-solid border-2 border-black-RS text-black-RS font-bold  m-2 hover:border-gray-200 ">Editar</button>
               
            ,
            right: true,
            ignoreRowClick: true,
            allowOverflow: true,
        },
        {
            cell: (row ) => {
                return (
                    row?.estado === true ? <button type="button" id={row?.idUsuario} onClick={() => { setStateUsuario(row?.estado); setIdFromDeactivate(row?.idUsuario); setShowDeleteModal(true); }} className="rounded-full text-sm w-32 py-2 px-4 border-solid border-2 border-red-RS text-red-RS font-bold  m-2 hover:border-gray-200 " > Desactivar</button >
                        : <button type="button" id={row?.idUsuario} onClick={() => { setStateUsuario(row?.estado); setIdFromDeactivate(row?.idUsuario); setShowDeleteModal(true); }} className="rounded-full text-sm w-32 py-2 px-4 border-solid border-2 border-greenButton text-greenButton font-bold  m-2 hover:border-gray-200" > Activar</button >
                )
            }
            ,
        },
       
    ]


    const onSaveDeleteOptions = async() => {

        DeactivateUsuarios(idFromDeactivate, !stateUsuario)

        setShowDeleteModal(false);
        const timeout = setTimeout(() => {
            getUsuarios();
        }, 1000);
        return () => clearTimeout(timeout);
    }


    //when component start, get list
    useState(() => {
        getUsuarios();

        getRoles();

    },[])

    //use to show delete new role component when 'eliminar' button is activated
    const DeleteUserComponent = () => {
        return (
            <form className="space-y-2 md:space-y-4">
                <div className="w-96 container mx-auto items-center justify-center">
                    <div className="flex flex-col">
                        <div className="flex text-base m-2 justify-center mx-auto font-bold leading-tight tracking-tight text-red-RS">
                            <ExclamationTriangleIcon className="w-8 h-8 ml-2"></ExclamationTriangleIcon>
                        </div>
                        <div>
                            <h1 className="flex text-base justify-center mx-auto font-bold leading-tight tracking-tight text-black-RS">
                               Advertencia
                            </h1>
                        </div>

                        <div>
                            <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">¿Está seguro que desea cambiar el estado de este elemento?</label>
                        </div>

                    </div>
                </div>
            </form>
        )
    }
    return (
        <>

            <div className="flex flex-row">
                <div className="md:basis-1/4 xl:basis-1/5 ">
                    <SideBar />    
                </div>
                <div className="md:basis-3/4 xl:basis-11/12">
                    <div className="bg-white rounded-lg shadow-lg shadow-slate-400 px-2 py-2  mt-2 m-2 mr-8">
                        <div className="px-2">
                            <div className="absolute z-20 ml-4 mt-1">

                                <h1 className="left-0 text-xl font-bold text-black-RS mb-1">Usuarios</h1>
                            <div className="col-start-1 col-end-3 ">
                                <div className="items-center sm:flex">
                                    <button type="button" onClick={() => setShowAddModal(!showAddModal)} className="bg-greenButton hover:bg-primary-700 text-white text-sm  py-2 px-4 rounded inline-flex items-center">
                                        <span>Agregar</span>
                                        <PlusCircleIcon className="w-8 h-8 ml-2"></PlusCircleIcon>
                                    </button>
                                </div>
                                </div>
                            </div>
                            <div className="static px-2 mt-2 m-2">
                                <Table columns={columns} title="usuario" filteredItems={""} data={listUsuarios} filterBy="nombre" />
                            </div>
                        </div>

                        {/*Here we show add modal compononent*/}
                        <Modal isVisible={showAddModal} onClose={() => { setShowAddModal(false) }} handleSubmit={() => null} onSaveOptions={null} showFotter={false}  showCloseIcon={false} >
                            {/*Button to close modal*/}
                            <div className="flex justify-end m-2">
                                <button className="cursor-pointer text-black-400 hover:text-gray-600 transition duration-150 ease-in-out rounded ring-2 focus:outline-none ring-gray-600" onClick={() => setShowAddModal(false)} aria-label="close modal" role="button">
                                    <XMarkIcon className="text-red-RS h-6 w-6" />
                                </button>
                            </div>
                            <AddUserComponent validations={validations} rolOptions={rolOptions} setShowAddModal={setShowAddModal} usuario={usuario} getUsuarios={getUsuarios} />

                        </Modal>

                        {/*Here we show edit modal compononent*/}
                        <Modal isVisible={showEditModal} onClose={() => { setShowEditModal(false) }} handleSubmit={() => null} onSaveOptions={null} showFotter={false} showCloseIcon={false} >
                        {/*Button to close modal*/}
                            <div className="flex justify-end m-2">
                                <button className="cursor-pointer text-black-400 hover:text-gray-600 transition duration-150 ease-in-out rounded ring-2 focus:outline-none ring-gray-600" onClick={() => setShowEditModal(false)} aria-label="close modal" role="button">
                                    <XMarkIcon className="text-red-RS h-6 w-6" />
                                </button>
                            </div>
                            <EditUserComponent validations={validations} rolOptions={rolOptions} setShowEditModal={setShowEditModal} rowValuesFromId={rowValuesFromId} usuario={usuario} getUsuarios={getUsuarios} idFromEdit={idFromEdit} />
                        </Modal>
                        
                        {/*Here we show delete modal compononent*/}
                        <Modal isVisible={showDeleteModal} onClose={() => { setShowDeleteModal(false) }} handleSubmit={handleSubmit} onSaveOptions={onSaveDeleteOptions} showCloseIcon={false} >
                            {/*Button to close modal*/}
                            <div className="flex justify-end m-2">
                                <button className="cursor-pointer text-black-400 hover:text-gray-600 transition duration-150 ease-in-out rounded ring-2 focus:outline-none ring-gray-600" onClick={() => setShowDeleteModal(false)} aria-label="close modal" role="button">
                                    <XMarkIcon className="text-red-RS h-6 w-6" />
                                </button>
                            </div>
                            <DeleteUserComponent />
                        </Modal>

                    </div>
                   
                </div>

            </div>
      
        </>
    )
}
