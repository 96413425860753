import { ProcesarDatosApi } from "../api/ApiFetch";
const controlador = "roles";

export const ObtenerRoles = async () => {
    const url = `${controlador}/ObtenerRoles`;
    return await ProcesarDatosApi('GET', url);
}

export const EditarRol = async (data) => {
    const url = `${controlador}/EditarRol`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const DesactivarRol = async (data) => {
    const url = `${controlador}/DesactivarRol`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const AgregarRol = async (data) => {
    const url = `${controlador}/AgregarRol`;
    return await ProcesarDatosApi('POST', url, data);
}
