import React, { Component } from "react";
import StarComponent from "./components/GlobalComponents/StarRateBox/star";
import CardComponent from "./components/GlobalComponents/Card/card";
import ModalComponent from "./components/GlobalComponents/ModalForm/modal";
import SidebarComponent from "./components/GlobalComponents/Sidebar/Index";
import TableComponent from "./components/GlobalComponents/Table/table";
import ModalSaveActionsComponent from "./components/GlobalComponents/ModalSaveActions/modalSaveActions";
import AccordionComponent from "./components/GlobalComponents/AccordionCV/accordionCV";
import AttachCVComponent from "./components/GlobalComponents/AttachCV/attachCVComponent";
import DocumentViewerComponent from "./components/GlobalComponents/DocumentViewer/documentViewerComponent";
import ListViewCardComponent from "./components/GlobalComponents/ListViewerCard/listViewerCard";
import ChangePasswordComponent from "./components/GlobalComponents/ChangePassword/changePassword";

class CommonComponetsMain extends Component {
    render() {
        return (
            <div>
            </div>
        )
    }
}


export const Star = ({ index, id, technologieName, techList, setListRating, userData, setUserData, finalData, allowEdit, deleteElementCV, userCompleteCV, idCurriculum }) => {
    return <StarComponent index={index} id={id} technologieName={technologieName} techList={techList} setListRating={setListRating} userData={userData} setUserData={setUserData} finalData={finalData} allowEdit={allowEdit}
        deleteElementCV={deleteElementCV} userCompleteCV={userCompleteCV} idCurriculum={idCurriculum} />
}

export const Card = ({ id, data, list, setList, setCurrentIdCard, setShowModal, setValuesById, allowEdit, listByPerson, userCompleteCV = "false", deleteElementCV, setActionTypeConfirmModal, title }) => {
    return <CardComponent id={id} data={data} list={list} setList={setList} setCurrentIdCard={setCurrentIdCard} setShowModal={setShowModal} setValuesById={setValuesById}
        allowEdit={allowEdit} listByPerson={listByPerson} userCompleteCV={userCompleteCV} deleteElementCV={deleteElementCV} setActionTypeConfirmModal={setActionTypeConfirmModal} title={title} />
}

export const ListViewCard = ({ title, data, setModalListView }) => {
    return <ListViewCardComponent title={title} data={data} setModalListView={setModalListView} />
}

export const Modal = ({ isVisible, onClose, handleSubmit, onSaveOptions, children, showFotter = true, showCloseIcon = true, insideContainer }) => {
    return <ModalComponent isVisible={isVisible} onClose={onClose} handleSubmit={handleSubmit} onSaveOptions={onSaveOptions} children={children} showFotter={showFotter} showCloseIcon={showCloseIcon} insideContainer={insideContainer} />
}
export const SideBar = () => {
    return <SidebarComponent />
}

export const Table = ({ columns, title, filteredItems, data, filterText, setFilterText, filterBy, showFilter = true, selectable, setSelectedRows, selectedRows }) => {
    return <TableComponent columns={columns} title={title} filteredItems={filteredItems} data={data} filterText={filterText} setFilterText={setFilterText} filterBy={filterBy} showFilter={showFilter}
        selectable={selectable} setSelectedRows={setSelectedRows} selectedRows={selectedRows} />
}

export const AccordionCV = ({idCurriculum, setShowViewModal=false, showViewModal=false }) => {
    return <AccordionComponent idCurriculum={idCurriculum} setShowViewModal={setShowViewModal} showViewModal={showViewModal}/>
}

export const ModalActionConfirm = ({  onSaveAction, actionType, setshowConfirmModal }) => {
    return <ModalSaveActionsComponent  onSaveAction={onSaveAction} actionType={actionType} setshowConfirmModal={setshowConfirmModal}  />
}

export const AttachCV = ({ InfoDocumentData, setInfoDocumentData }) => {
    return <AttachCVComponent InfoDocumentData={InfoDocumentData} setInfoDocumentData={setInfoDocumentData} />
}

export const DocumentViewer = ({ fileType, typeView, filePath, fileName, fileExtension }) => {
    return <DocumentViewerComponent fileType={fileType} typeView={typeView} filePath={filePath} fileName={fileName} fileExtension={fileExtension} />
}

export const ChangePassword = ({ setShowModalEdit, fieldsData, idUsuario, getUserById }) => {
    return <ChangePasswordComponent setShowModalEdit={setShowModalEdit} fieldsData={fieldsData} idUsuario={idUsuario} getUserById={getUserById} />
}