import React from 'react';
import InfoPersonalComponent from './InfoPersonal';
import { useState } from 'react';

//import services
import { ObtenerUsuarioPorId } from '../../../../servicios/ServicioUsuario';
import { useEffect } from 'react';


const InfoPersonal = ({ InfoPersonalData, setInfoPersonalData, finalData, stateInfoPersonal, setstateInfoPersonal, allowEdit = false, listInfoByPerson = [], InfoPersonalDataEdit, setInfoPersonalDataEdit, idCurriculum, isFirstLoadPersonalInformation, setIsFirstLoadPersonalInformation, isFirstLoadUserInfo, setIsFirstLoadUserInfo}) => {

    const [listInfoByUser, setlistInfoByUser] = useState([]);

    async function getPersonalInfoByIdUsuario() {
        const jsonUsuario = sessionStorage.getItem("USER_DATA");
        const usuario = JSON.parse(jsonUsuario);
        const idUsuario = usuario.IdUsuario;

        const response = await ObtenerUsuarioPorId(idUsuario);
        setlistInfoByUser(response);

    }

    //validations
    const validationMessages = {
        firstName: [
            {
                type: 'required',
                message: 'Favor ingresar el primer nombre',
            },
            {
                type: 'maxLength',
                message: 'El primer nombre no puede contener mas de 25 caracteres ',
            },
            {
                type: 'pattern',
                message: 'Solo se admiten letras.',
            }
        ],
        secondName: [
            {
                type: 'maxLength',
                message: 'El segundo nombre no puede contener mas de 25 caracteres ',
            },
            {
                type: 'pattern',
                message: 'Solo se admiten letras.',
            }
        ],
        surname: [
            {
                type: 'required',
                message: 'Favor ingresar su primer apellido',
            },
            {
                type: 'maxLength',
                message: 'El primer apellido no puede contener mas de 25 caracteres ',
            },
            {
                type: 'pattern',
                message: 'Solo se admiten letras.',
            }
        ],
        secondSurname: [
            {
                type: 'required',
                message: 'Favor ingresar su segundo apellido',
            },
            {
                type: 'maxLength',
                message: 'El segundo apellido no puede contener mas de 25 caracteres ',
            },
            {
                type: 'pattern',
                message: 'Solo se admiten letras.',
            }
        ],
        identification: [
            {
                type: 'required',
                message: 'Favor ingresar su Identificación',
            },
            {
                type: 'maxLenght',
                message: 'La Identificación debe contener máximo 11 números'
            },
            {
                type: 'pattern',
                message: 'Solo se admiten números.',
            }
        ],
        birthDate: [
            {
                type: 'required',
                message: 'Favor ingresar la fecha de nacimiento',
            },
        ],
        maritalStatus: [
            {
                type: 'required',
                message: 'Favor ingresar el estado civil',
            },
        ],
        email: [
            {
                type: 'required',
                message: 'Favor ingresar el correo',
            },
            {
                type: 'maxLength',
                message: 'El correo no puede contener mas de 32 caracteres ',
            },
            {
                type: 'pattern',
                message: 'Correo electrónico inválido',
            }
        ],
        specialty: [
            {
                type: 'required',
                message: 'Favor ingresar la especialidad',
            },
        ],
        firstPhoneNumber: [
            {
                type: 'required',
                message: 'Favor ingresar el número de teléfono',
            },
            {
                type: 'maxLength',
                message: 'El número de teléfono no puede contener mas de 15 dígitos',
            },
            {
                type: 'pattern',
                message: 'Las letras no son validas',
            }
        ],
        secondPhoneNumber: [
            {
                type: 'maxLength',
                message: 'El número de teléfono no puede contener mas de 15 dígitos',
            },
            {
                type: 'pattern',
                message: 'Las letras no son validas',
            }
        ],
        residence: [
            {
                type: 'required',
                message: 'Favor ingresar el lugar de residencia',
            },
            {
                type: 'maxLength',
                message: 'El lugar de residencia excede el límite de caracteres',
            }
        ],
        linkedin: [
            {
                type: 'maxLength',
                message: 'Linkedin no puede exceder los 50 caracteres',
            }
        ],
        otherSocialMedia: [
            {
                type: 'maxLength',
                message: 'Otra red social no puede exceder los 50 caracteres',
            }
        ],
        personalSummary: [
            {
                type: 'required',
                message: 'Favor ingresar un resumen personal',
            },
            {
                type: 'maxLength',
                message: 'El resumen personal no puede contener mas de 255 caracteres',
            },
        ],

    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            //validate if user already complete cv and pass list from register info
            const userCompleteCV = sessionStorage.getItem("USER_COMPLETE_CV");
            if (userCompleteCV === "false") {
                getPersonalInfoByIdUsuario();
            }
         
        }, 1000);
        return () => clearTimeout(timeout);
       
    },[])

    return (
        <div>
            <InfoPersonalComponent validationMessages={validationMessages} InfoPersonalData={InfoPersonalData} setInfoPersonalData={setInfoPersonalData} finalData={finalData} stateInfoPersonal={stateInfoPersonal} setstateInfoPersonal={setstateInfoPersonal} allowEdit={allowEdit} listInfoByPerson={listInfoByPerson} InfoPersonalDataEdit={InfoPersonalDataEdit}
                setInfoPersonalDataEdit={setInfoPersonalDataEdit} idCurriculum={idCurriculum} isFirstLoadPersonalInformation={isFirstLoadPersonalInformation} setIsFirstLoadPersonalInformation={setIsFirstLoadPersonalInformation} isFirstLoadUserInfo={isFirstLoadUserInfo}
                setIsFirstLoadUserInfo={setIsFirstLoadUserInfo} listInfoByUser={listInfoByUser} />
        </div>
    );
}

export default InfoPersonal;