import React, { useState, useContext, useEffect, useRef } from 'react';
import { Document, Page, Text, View, Image, PDFViewer, StyleSheet, Font } from "@react-pdf/renderer";
import phone from "../../../../assets/icons/phone.png"
import mail from "../../../../assets/icons/mail.png"
import user from "../../../../assets/icons/user.png"
import specialization from "../../../../assets/icons/specialization.png"
import worker from "../../../../assets/icons/worker.png"
import logo from "../../../../assets/logo.png"




//import services
import { ObtenerProfesionalesPorCadaGradoAcademico } from '../../../../servicios/ServicioGradoAcademicoPersona';
//import icons from heroicons
import {  ArrowPathIcon } from '@heroicons/react/24/outline';

//import icons from heroicons

//--------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------------PDF-------------------------------------------------------------

const styles = StyleSheet.create({
    body: { margin: '15px', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', width: '100%', fontFamily: 'Helvetica' },
    col1: { width: '35%' },
    col2: { width: '50%', marginLeft: '5%' },
    col3: { width: '95%' },
    name: { color: '#2D2D38', fontSize: '16px', marginBottom: '10px', fontFamily: 'Helvetica-Bold' },
    jobPosition: { color: '#2D2D38', fontSize: '10px', marginBottom: '10px' },
    title: { color: '#B91D1C', fontSize: '16px', marginBottom: '14px', fontFamily: 'Helvetica-Bold' },
    title2: { color: '#B91D1C', fontSize: '18px', marginBottom: '14px', fontFamily: 'Helvetica-Bold', marginTop: '20px' },
    subtitle: { color: '#2D2D38', fontSize: '16px', marginBottom: '10px', fontFamily: 'Helvetica-Bold', justifyContent: 'space-between' },
    subtitleExp: { display: 'flex', flexWrap: 'wrap', flexDirection: 'row', marginLeft: '5px', width: '100%', justifyContent: 'space-between' },
    text: { color: '#2D2D38', fontSize: '16px', marginBottom: '10px', marginLeft: '5px', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', width: '22.5%' },
    text2: { color: '#2D2D38', fontSize: '16px', marginBottom: '10px', marginLeft: '5px', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', width: '85%' },
    text3: { color: '#2D2D38', fontSize: '16px', marginBottom: '10px', marginRight: '5px', marginLeft: '5px', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', width: '75%' },
    text4: { color: '#2D2D38', fontSize: '16px', marginBottom: '10px', marginRight: '35px', marginLeft: '5px', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', width: '55%' },
    text5: { color: '#2D2D38', fontSize: '16px', marginBottom: '10px', marginLeft: '5px', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', width: '60%' },
    topLine: { borderTopWidth: 2, borderTopColor: '#B91D1C', marginBottom: '14px' },
    bottomLine: { borderBottomWidth: 2, borderBottomColor: '#B91D1C', marginBottom: '10px' },
    section: { marginBottom: '14px' },
    company: { color: '#B91D1C', fontSize: '10px', marginBottom: '10px' },
    subtitleTech: { color: '#2D2D38', fontSize: '10px', marginBottom: '10px', fontFamily: 'Helvetica-Bold', width: '40%', },
    techRow: { display: 'flex', flexWrap: 'wrap', flexDirection: 'row', width: '40%', justifyContent: 'space-between', marginBottom: '14px' },
    fillPoint: { height: '10px', width: '10px', backgroundColor: '#B91D1C', borderRadius: '50%' },
    emptyPoint: { height: '10px', width: '10px', backgroundColor: '#718A93', borderRadius: '50%' },
    icon: { height: '10px', width: '10px', marginRight: '5px' },
    icon2: { height: '60px', width: '60px' },
    textIcon: { display: 'flex', flexWrap: 'wrap', flexDirection: 'row' },
    textIcon2: { display: 'flex', flexWrap: 'wrap', flexDirection: 'row', borderBottomWidth: 2, borderBottomColor: '#B91D1C', marginBottom: '10px' }
});


function MyPdf({ infoGrado }) {

    function PersonalInfoSection() { //imprime el resumen y contacto de la persona
        const Grados = infoGrado?.map((data) =>
            <View wrap={false} key={data.idGrado} >
                <View style={styles.subtitleExp}>
                    <Text style={styles.text4}>{"   "}</Text>
               
                    <View style={styles.textIcon}>
                        <Image src={user} style={styles.icon} />
                        <Text style={styles.subtitle}>{"Nombre completo:"}</Text>
                        <Text style={styles.text4}>{data?.primerNombre+" "+data?.primerApellido + " "+data?.segundoApellido}</Text>
                    </View>
                    <View style={styles.textIcon}>
                        <Image src={phone} style={styles.icon} />
                        <Text style={styles.subtitle}>{"Teléfono:"}</Text>
                        <Text style={styles.text3}>{data?.telefono1}</Text>
                    </View>
                    <View style={styles.textIcon}>
                        <Image src={mail} style={styles.icon} />
                        <Text style={styles.subtitle}>{"Correo:"}</Text>
                        <Text style={styles.text3}>{data?.correoElectronico}</Text>
                    </View>
                    <View style={styles.textIcon}>
                        <Image src={specialization} style={styles.icon} />
                        <Text style={styles.subtitle}>{"Especialización:"}</Text>
                        <Text style={styles.text4}>{data?.nombreEspecializacion}</Text>
                    </View>
                  
                    <View style={styles.textIcon}>
                        <Image src={worker} style={styles.icon} />
                        <Text style={styles.subtitle}>{"Planilla en Rossmon:"}</Text>
                        <Text style={styles.text4}>{data?.trabajaEnRossmon === true ? "Si" : "No"}</Text>
                    </View>
                    <Text style={styles.topLine}>{ }</Text>
                </View>
                <Text style={styles.text4}>{"   "}</Text>
                <View style={styles.topLine} />
            </View>
        )

        return (
            <>
                {infoGrado?.length != 0 && <View style={styles.section}>
                  
                    <View style={styles.textIcon2}>
                        <Image src={logo} style={styles.icon2} />
                        <Text style={styles.title2} > {"Reporte de Grado Académico por " + infoGrado[0].grado}</Text>
                    </View>
                    {Grados}
                </View>}
                </>
                
        )

    }


    return ( 
       
       
        <PDFViewer style={{ width: "100%", height: "90vh" }}>
            <Document file="somefile.pdf">
                <Page size="A4" style={styles.body} wrap>
                    <View style={styles.col3}>
                        <PersonalInfoSection />
                        

                    </View>
                   
                </Page>
            </Document>
            </PDFViewer>
           
              
    );
}





const PdfComponentProps = ({idGrado}) => {

    const [infoGrado, setInfoGrado] = useState({});
    const isMounted = useRef(true);
    const [isLoading, setIsLoading] = useState(true);

    async function getInfoGrado() {

        const response = await ObtenerProfesionalesPorCadaGradoAcademico(idGrado);
            setInfoGrado(response)
        
   }
        //obtiene la informacion del curriculum

    async function callmethods() {
        await getInfoGrado();

    }
        useState(() => {
            callmethods();
        }, [idGrado])

    useEffect(() => {

        if (isMounted.current) {
            //fill lists
            callmethods();
           
            setIsLoading(true);

            const timeout = setTimeout(() => {
                setIsLoading(false);
            }, 5000);
            return () => clearTimeout(timeout);
        }

        return () => {
            isMounted.current = false;
        };

    }, [idGrado])

        return (
            <>
                {isLoading ? (<div className="h-screen">
                    <div className="flex justify-center items-center h-full">
                        <ArrowPathIcon className="animate-spin h-16 w-16 mr-3 text-red-RS inset-y-2 right-0"></ArrowPathIcon>
                    </div>
                </div>) :
                    (<div>
                        <MyPdf infoGrado={infoGrado} />
                    </div>)}
            </>
        );
    };

//--------------------------------------------------------------------------------
    export default PdfComponentProps;