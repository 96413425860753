import React, { useEffect, useState } from "react";

//import global components
import { SideBar } from '../../imports';

const Admin = () => {

    //validations


    //function

    return (
        <>
            <div className="flex flex-row">
                <div className=" md:basis-1/4 xl:basis-1/5 ">
                    <SideBar />
                </div>
                <div className=" md:basis-3/4 xl:basis-11/12">
                    <h1 className="flex text-base justify-center mx-auto font-bold leading-tight tracking-tight text-gray-900 dark:text-white">
                        Bienvenido usuario administrador
                    </h1>
                </div>
            </div>
        </>




    )

};

export default Admin;