import React, { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
//import global components
import { Modal, ChangePassword } from '../../imports';

//import services
import { ObtenerUsuarioPorId } from '../../servicios/ServicioUsuario';

//import assets
import Logo from '../../assets/logo.png';
import { UserIcon, Bars3Icon, XMarkIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';

//phonecomponent
import PhoneInput from 'react-phone-input-2';
import '../../../src/customInput.css';


const Navbar = () => {
    const [nav, setNav] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [idUsuario, setIdUsuario] = useState(0);

    //show edit profile component
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [fieldsData, setFieldsData] = useState([])
    
    const handleNav = () => {
        setNav(!nav);
    };

    const { handleSubmit } = useForm({
        mode: 'all',

    });

    const onClosesession = () => {

        sessionStorage.clear();
        window.location.replace('/login');
    }

    //get user data for table
    async function getUserById() {
        const jsonUsuario = sessionStorage.getItem("USER_DATA");
        const usuario = JSON.parse(jsonUsuario);
        const response = await ObtenerUsuarioPorId(usuario.IdUsuario);
        setFieldsData(response)
    }

    useState(() => {
        getUserById()
        setShowModal(false);
        const jsonUsuario = sessionStorage.getItem("USER_DATA");
        const usuario = JSON.parse(jsonUsuario);
        setIdUsuario(usuario.IdUsuario);
    }, [])

    useEffect(() => {

    }, [showModal]);
    //use to show sing out component 
    const SingoutComponent = () => {
        return (
            <form className="space-y-2 md:space-y-4">
                <div className="w-96 container mx-auto items-center justify-center">
                    <div className="flex flex-col">
                        <div className="flex text-base m-2 justify-center mx-auto font-bold leading-tight tracking-tight text-red-RS">
                            <ExclamationTriangleIcon className="w-8 h-8 ml-2"></ExclamationTriangleIcon>
                        </div>
                        <div>
                            <h1 className="flex text-base justify-center mx-auto font-bold leading-tight tracking-tight text-black-RS">
                                Advertencia
                            </h1>
                        </div>

                        <div>
                            <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">¿Está seguro que desea cerrar sesión?</label>
                        </div>

                    </div>
                </div>
            </form>
        )
    }

    return (
       
        <nav className="bg-white border-gray-200 px-1 sm:px-4 py-2 rounded-lg shadow ">
            <div className='flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 '>
                <a href="https://www.rossmon.co.cr/" className="flex items-center">
                    <img src={Logo} className="h-6 mr-3 object-cover transform hover:scale-110 transition duration-700 ease-out sm:h-9" alt="Logo RS" />
                </a>
                <div className="flex items-center md:order-2">

                    <button type="button" onClick={() => { setShowModalEdit(true) }} className="flex mr-3 text-sm bg-gray-400 rounded-full w-6 h-6 md:mr-0 focus:ring-4 focus:ring-gray-300" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="user-dropdown" data-dropdown-placement="bottom">
                        <span className="sr-only">Open user menu</span>
                        <UserIcon className="w-6 h-6" alt="user photo" />
                    </button>
                  
                </div>
                <Modal isVisible={showModal} onClose={() => { setShowModal(false) }} handleSubmit={handleSubmit} onSaveOptions={onClosesession} showCloseIcon={true}>
                    {/*Button to close modal*/}

                    <SingoutComponent />
                </Modal>
                <Modal isVisible={showModalEdit} onClose={() => { setShowModalEdit(false) }} handleSubmit={() => null} onSaveOptions={null} showFotter={false} showCloseIcon={true}>
                    <ChangePassword setShowModalEdit={setShowModalEdit} fieldsData={fieldsData} idUsuario={idUsuario} getUserById={getUserById} />
                </Modal>
                

                <button type="button" cursor="pointer" onClick={handleNav} className='block md:hidden'>
                    {nav ? <XMarkIcon className="h-6 w-6" size={20} /> : <Bars3Icon className="h-6 w-6" size={20} />}
                </button>
                <ul className={nav ? 'fixed z-40 left-0 top-0 w-[60%] h-full bg-white border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500' : 'ease-in-out duration-500 fixed left-[-100%]'}>
                    <a href="https://www.rossmon.co.cr/" className="flex items-center">
                        <img src={Logo} className="h-6 mr-3 object-cover transform hover:scale-110 transition duration-700 ease-out sm:h-9" alt="Logo RS" />
                    </a>
                    <button type="button" onClick={() => { setShowModal(!showModal) }} className="text-black-RS " >
                        <h2 className="block py-2 pl-3 pr-4 text-gray-700  rounded  md:p-0" aria-current="page">Cerrar sesion</h2>
                    </button>
                    <Modal isVisible={showModal} onClose={() => { setShowModal(false) }} handleSubmit={handleSubmit} onSaveOptions={onClosesession} showCloseIcon={false}>
                        {/*Button to close modal*/}

                        <SingoutComponent />
                    </Modal>
                </ul>
                <div className="items-center font-sans justify-between hidden w-full md:flex md:w-auto md:order-1" id="mobile-menu-2">
                    <ul className="flex flex-col p-1 mt-2 font-sm rounded-lg bg-grayDark-RS md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white ">

                        <li>
                            <button type="button" onClick={() => { setShowModal(!showModal) }} className="text-black-RS " >
                                <h2 className="block py-2 pl-3 pr-4 text-gray-700  rounded  md:p-0" aria-current="page">Cerrar sesion</h2>
                            </button>
                    
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

    )
}

export default Navbar;