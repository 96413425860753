import { ProcesarDatosApi } from "../api/ApiFetch";
const controladorTipoEducacion = "tipoeducacion";
const controladorGradoAcademico = "gradoacademico";
const controladorEducacion = "educacion";


export const AgregarEducacion = async (data) => {
    const url = `${controladorEducacion}/AgregarEducacion`;
    return await ProcesarDatosApi('POST', url, data);
}

export const AgregarEducacionIndividual = async (data) => {
    const url = `${controladorEducacion}/AgregarEducacionIndividual`;
    return await ProcesarDatosApi('POST', url, data);
}

export const ObtenerEducacionPorIdPersona = async (idPersona) => {
    const url = `${controladorEducacion}/ObtenerEducacionPorIdPersona/${idPersona}`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerEducacionsinCertficacionesPorIdPersona = async (idPersona) => {
    const url = `${controladorEducacion}/ObtenerEducacionsinCertficacionesPorIdPersona/${idPersona}`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerCertificacionPorIdPersona = async (idPersona) => {
    const url = `${controladorEducacion}/ObtenerCertificacionPorIdPersona/${idPersona}`;
    return await ProcesarDatosApi('GET', url);
}


export const ObtenerTipoEducacion = async () => {
    const url = `${controladorTipoEducacion}/ObtenerTipoEducacion`;
    return await ProcesarDatosApi('GET', url);
}


export const ObtenerGradoAcademico = async () => {
    const url = `${controladorGradoAcademico}/ObtenerGradoAcademico`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerGradoAcademicoSinCertificacion = async () => {
    const url = `${controladorGradoAcademico}/ObtenerGradoAcademicoSinCertificacion`;
    return await ProcesarDatosApi('GET', url);
}

export const EditarEducacion = async (data) => {
    const url = `${controladorEducacion}/EditarEducacion`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const EliminarEducacion = async (idEducacion) => {
    const url = `${controladorEducacion}/EliminarEducacion/${idEducacion}`;
    return await ProcesarDatosApi('DELETE', url);
}
