import React, { useEffect, useState, useRef, useContext } from 'react';
import { Disclosure } from '@headlessui/react'

//import icons
import { XMarkIcon, ChevronUpIcon, ArrowPathIcon } from '@heroicons/react/24/outline';

//import from steps forms
import InfoPersonalComponent from '../../CVForm/Steps/InfoPersonal/index';
import EducationComponent from '../../CVForm/Steps/Educacion/index';
import TechnologieComponent from '../../CVForm/Steps/Tecnologías/index';
import ExperienceComponent from '../../CVForm/Steps/Experiencia/index';
import ProjectsComponent from '../../CVForm/Steps/Proyectos/index';
import CertificationsComponent from '../../CVForm/Steps/Certificaciones/index';
import AdjuntarCurriculum from '../../CVForm/Steps/AdjuntarCurriculum/index';

//import services
import { ObtenerEducacionsinCertficacionesPorIdPersona, ObtenerCertificacionPorIdPersona, ObtenerEducacionPorIdPersona } from '../../../servicios/ServicioEducacion';
import { ObtenerTecnologiasPorIdPersona } from '../../../servicios/ServicioTecnologia';
import { ObtenerExperienciaPorIdPersona } from '../../../servicios/ServicioExperiencia';
import { ObtenerInfoPersonalPorId, ObtenerIDPersona } from '../../../servicios/ServicioInfoPersonal';
import { ObtenerProyectosPorIdPersona } from '../../../servicios/ServicioProyecto';
import { ObtenerDocumentoPorId } from '../../../servicios/ServicioDocumento';


const AccordionCV = ({ idCurriculum, setShowViewModal, showViewModal }) => {

    const [rolId, setRolId] = useState(0);
    const [allowEdit, setAllowEdit] = useState(false);

    const isMounted = useRef(true);

    const rol = sessionStorage.getItem("USER_ROLE");

    const jsonUsuario = sessionStorage.getItem("USER_DATA");
    const usuario = JSON.parse(jsonUsuario);
    const idUsuario = usuario.IdUsuario;
    const [idPerson, setIdPerson] = useState(0);

    //validate if user already complete cv
    const userCompleteCV = sessionStorage.getItem("USER_COMPLETE_CV");

    const [isLoading, setIsLoading] = useState(false);
    const [reRender, setRerender] = useState(false);

    const [isFirstLoadEducation, setIsFirstLoadEducation] = useState(true);
    const [isFirstLoadProject, setIsFirstLoadProject] = useState(true);
    const [isFirstLoadDocument, setIsFirstLoadDocument] = useState(true);
    const [isFirstLoadExperience, setIsFirstLoadExperience] = useState(true);
    const [isFirstLoadTechnologies, setIsFirstLoadTechnologies] = useState(true);
    const [isFirstLoadPersonalInformation, setIsFirstLoadPersonalInformation] = useState(true);

    //force update when set new actions to Database
    const [forceUpdateComponent, setForceUpdateComponent] = useState(false);

    //list to save and fill each accordeon
    const [listEducationByPerson, setListEducationByPerson] = useState([]);

    const [listEducationByPersonAdmin, setListEducationByPersonAdmin] = useState([]);

    const [listCertificationsByPerson, setListCertificationsByPerson] = useState([]);

    const [listTechnologiesByPerson, setListTechnologiesByPerson] = useState([]);

    const [listExperienceByPerson, setListExperienceByPerson] = useState([]);

    const [listInfoByPerson, setlistInfoByPerson] = useState([]);

    const [listProjectsByPerson, setListProjectsByPerson] = useState([]);

    const [listDocumentByPerson, setListDocumentByPerson] = useState([]);

    //this will save data and later update
    const [userDataEdit, setUserDataEdit] = useState([]);
    const [InfoPersonalDataEdit, setInfoPersonalDataEdit] = useState([]);
    const [InfoDocumentDataEdit, setInfoDocumentDataEdit] = useState([]);
    const [educationDataEdit, setEducationDataEdit] = useState([]);
    const [technologieDataEdit, setTechnologieDataEdit] = useState([]);
    const [experienceDataEdit, setExperienceDataEdit] = useState([]);
    const [projectDataEdit, setProjectDataEdit] = useState([]);

    //function to fill tech list
    async function getTechnologiesByIdPerson() {
        const response = await ObtenerTecnologiasPorIdPersona(idCurriculum);
        setListTechnologiesByPerson(response);
    }

    //function to fill experience list
    async function getExperienceByIdPerson() {

        const response = await ObtenerExperienciaPorIdPersona(idCurriculum);
        setListExperienceByPerson(response);
    }
    //function to fill personal info
    async function getPersonalInfoByIdPerson() {

        const response = await ObtenerInfoPersonalPorId(idCurriculum);
        setlistInfoByPerson(response);
    }

    //function to fill project list
    async function getProjectsByIdPerson() {

        const response = await ObtenerProyectosPorIdPersona(idCurriculum);
        setListProjectsByPerson(response);
       
    }

    //function to fill project list
    async function getDocumentByIdPerson() {

        const response = await ObtenerDocumentoPorId(idCurriculum);
        setListDocumentByPerson(response);

    }

    //function to fill education list
    async function getEducationWithoutCertificationsByPerson() {

        const response = await ObtenerEducacionsinCertficacionesPorIdPersona(idCurriculum);
        setListEducationByPersonAdmin(response);
        
    }

    //function to fill education list
    async function getEducation() {

        const response = await ObtenerEducacionPorIdPersona(idCurriculum);
        setListEducationByPerson(response);
    
    }

    //function to fill education list
    async function getCertificationsByPerson() {

        const response = await ObtenerCertificacionPorIdPersona(idCurriculum);
        setListCertificationsByPerson(response);
    }

    //function to get id from candidate
    async function getPersonID() {


        const response = await ObtenerIDPersona(idUsuario);

        setIdPerson(response?.idPersona);

    }

    const loadListsByPerson = () => {
        setIsLoading(true);
        const timeout = setTimeout(() => {


            getTechnologiesByIdPerson();
            getPersonalInfoByIdPerson();
            getExperienceByIdPerson();

            getCertificationsByPerson();
            getProjectsByIdPerson();
            getDocumentByIdPerson();
            getEducationWithoutCertificationsByPerson()
            getEducation();
            setIsLoading(false);
        }, 5000);
        return () => clearTimeout(timeout);

    }

    useState(() => {

        getPersonID();


        setRolId(parseInt(rol, 10));

        loadListsByPerson();
        

    }, [])

    useEffect(() => {


        if (isMounted.current) {

            getPersonID();
        
            if (rolId === 2) {

               
                setAllowEdit(true);
            } else {

               
                setAllowEdit(false);
                

            }
        }


        return () => {
            isMounted.current = false;
            setlistInfoByPerson([]);
            setListDocumentByPerson([]);
            setListTechnologiesByPerson([]);
            setListEducationByPerson([]);
            setListExperienceByPerson([]);
            setListProjectsByPerson([]);
            setListCertificationsByPerson([]);
        };

    }, [rolId, forceUpdateComponent, idPerson, allowEdit])

    return (
        <>
            {isLoading ? (


                <div className="h-screen bg-white rounded-lg shadow-lg">
                    <div className="flex justify-center items-center h-full">
                        <ArrowPathIcon className="animate-spin h-16 w-16 mr-3 text-red-RS inset-y-2 right-0"></ArrowPathIcon>
                    </div>
                </div>
            ) :
                (
                    <div className="container w-full mx-auto py-2">
                        <div className="mt-2 flow-root">
                            <div className="float-left my-2">
                                <h2 className="font-base font-sans"><span>Currículum Vitae</span></h2>

                            </div>
                            {allowEdit &&
                                <div className="float-right my-2" >
                                    {/*Button to close modal*/}
                                    <button className="cursor-pointer text-black-400 hover:text-gray-600 transition duration-150 ease-in-out rounded ring-2 focus:outline-none ring-gray-600" onClick={() => setShowViewModal(false)} aria-label="close modal" role="button">
                                        <XMarkIcon className="text-red-RS h-6 w-6" />
                                    </button>
                                </div>
                            }
                        </div>


                        <div className="mx-auto w-full bg-white border rounded-md">

                            {/*Informacion personal*/}
                            <Disclosure className="space-y-4 border border-t-2">
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex w-full justify-between  px-2 py-4 text-left text-base font-sans text-black-RS border-b-2 border-t-0 border-l-0 border-r-0 border-grayDark-RS hover:bg-grayLight-RS focus:outline-none focus-visible:ring focus-visible:ring-bg-Dark-RS focus-visible:ring-opacity-75">
                                            <span className="font-bold">Información personal</span>
                                            <ChevronUpIcon
                                                className={`${open ? 'rotate-180 transform' : ''
                                                    } h-5 w-5 text-black-RS`}
                                            />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="pt-4 pb-2 text-sm text-black-RS">
                                            <InfoPersonalComponent allowEdit={allowEdit} listInfoByPerson={listInfoByPerson} InfoPersonalDataEdit={InfoPersonalDataEdit} setInfoPersonalDataEdit={setInfoPersonalDataEdit} idCurriculum={idCurriculum}
                                                isFirstLoadPersonalInformation={isFirstLoadPersonalInformation} setIsFirstLoadPersonalInformation={setIsFirstLoadPersonalInformation} />
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>

                            {/*Educacion*/}
                            <Disclosure className=" border">
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex w-full justify-between px-2 py-4 text-left text-base font-sans text-black-RS border-b-2 border-t-0 border-l-0 border-r-0 border-grayDark-RS hover:bg-grayLight-RS focus:outline-none focus-visible:ring focus-visible:ring-bg-Dark-RS focus-visible:ring-opacity-75">
                                            <span className="font-bold">Educación</span>
                                            <ChevronUpIcon
                                                className={`${open ? 'rotate-180 transform' : ''
                                                    } h-5 w-5 text-black-RS`}
                                            />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="pt-4 pb-2 text-sm text-black-RS">
                                            <EducationComponent allowEdit={allowEdit} listEducationByPerson={rolId === 2 ? listEducationByPersonAdmin : listEducationByPerson} setListEducationByPerson={setListEducationByPerson} educationDataEdit={educationDataEdit}
                                                setEducationDataEdit={setEducationDataEdit} isFirstLoadEducation={isFirstLoadEducation} setIsFirstLoadEducation={setIsFirstLoadEducation} idCurriculum={idCurriculum} />
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>

                            {allowEdit ?
                                <Disclosure >
                                    {({ open }) => (
                                        <>
                                            <Disclosure.Button className="flex w-full justify-between  px-2 py-4 text-left text-base font-sans text-black-RS border-b-2 border-t-0 border-l-0 border-r-0 border-grayDark-RS hover:bg-grayLight-RS focus:outline-none focus-visible:ring focus-visible:ring-bg-Dark-RS focus-visible:ring-opacity-75">
                                                <span className="font-bold">Certificaciones</span>
                                                <ChevronUpIcon
                                                    className={`${open ? 'rotate-180 transform' : ''
                                                        } h-5 w-5 text-black-RS`}
                                                />
                                            </Disclosure.Button>
                                            <Disclosure.Panel className="pt-4 pb-2 text-sm text-black-RS">
                                                <CertificationsComponent allowEdit={allowEdit} listCertificationsByPerson={listCertificationsByPerson} />
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                                : null
                            }
                            {/*Tecnologias*/}
                            <Disclosure>
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex w-full justify-between  px-2 py-4 text-left text-base font-sans text-black-RS border-b-2 border-t-0 border-l-0 border-r-0 border-grayDark-RS hover:bg-grayLight-RS focus:outline-none focus-visible:ring focus-visible:ring-bg-Dark-RS focus-visible:ring-opacity-75">
                                            <span className="font-bold">Tecnologías</span>
                                            <ChevronUpIcon
                                                className={`${open ? 'rotate-180 transform' : ''
                                                    } h-5 w-5 text-black-RS`}
                                            />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="pt-4 pb-2 text-sm text-black-RS">
                                            <TechnologieComponent allowEdit={allowEdit} listTechnologiesByPerson={listTechnologiesByPerson} technologieDataEdit={technologieDataEdit}
                                                setTechnologieDataEdit={setTechnologieDataEdit} idCurriculum={idCurriculum} isFirstLoadTechnologies={isFirstLoadTechnologies} setIsFirstLoadTechnologies={setIsFirstLoadTechnologies} />
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>

                            {/*Experiencia*/}
                            <Disclosure>
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex w-full justify-between  px-2 py-4 text-left text-base font-sans text-black-RS border-b-2 border-t-0 border-l-0 border-r-0 border-grayDark-RS hover:bg-grayLight-RS focus:outline-none focus-visible:ring focus-visible:ring-bg-Dark-RS focus-visible:ring-opacity-75">
                                            <span className="font-bold">Experiencia</span>
                                            <ChevronUpIcon
                                                className={`${open ? 'rotate-180 transform' : ''
                                                    } h-5 w-5 text-black-RS`}
                                            />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="px-4 ml-2  pt-4 pb-2 text-sm text-black-RS">
                                          <ExperienceComponent allowEdit={allowEdit} listExperienceByPerson={listExperienceByPerson} setListExperienceByPerson={setListExperienceByPerson} experienceDataEdit={experienceDataEdit}
                                                setExperienceDataEdit={setExperienceDataEdit} isFirstLoadExperience={isFirstLoadExperience} setIsFirstLoadExperience={setIsFirstLoadExperience} idCurriculum={idCurriculum} />
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                           
                            {/*Proyectos*/}
                            <Disclosure>
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex w-full justify-between  px-2 py-4 text-left text-base font-sans text-black-RS border-b-2 border-t-0 border-l-0 border-r-0 border-grayDark-RS hover:bg-grayLight-RS focus:outline-none focus-visible:ring focus-visible:ring-bg-Dark-RS focus-visible:ring-opacity-75">
                                            <span className="font-bold">Proyectos</span>
                                            <ChevronUpIcon
                                                className={`${open ? 'rotate-180 transform' : ''
                                                    } h-5 w-5 text-black-RS`}
                                            />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="pt-4 pb-2 text-sm text-black-RS">
                                            <ProjectsComponent allowEdit={allowEdit} listProjectsByPerson={listProjectsByPerson} isFirstLoadProject={isFirstLoadProject}
                                                setIsFirstLoadProject={setIsFirstLoadProject} projectDataEdit={projectDataEdit} setProjectDataEdit={setProjectDataEdit}
                                                idCurriculum={idCurriculum} />
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                            {/*Documento Adjunto*/}
                            <Disclosure>
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex w-full justify-between  px-2 py-4 text-left text-base font-sans text-black-RS border-b-2 border-t-0 border-l-0 border-r-0 border-grayDark-RS hover:bg-grayLight-RS focus:outline-none focus-visible:ring focus-visible:ring-bg-Dark-RS focus-visible:ring-opacity-75">
                                            <span className="font-bold">Currículum Adjunto</span>
                                            <ChevronUpIcon
                                                className={`${open ? 'rotate-180 transform' : ''
                                                    } h-5 w-5 text-black-RS`}
                                            />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="pt-4 pb-2 text-sm text-black-RS">
                                            <AdjuntarCurriculum allowEdit={allowEdit} listDocumentByPerson={listDocumentByPerson} setListDocumentByPerson={setListDocumentByPerson} isFirstLoadDocument={isFirstLoadDocument} setIsFirstLoadDocument={setIsFirstLoadDocument}
                                                idCurriculum={idCurriculum} InfoDocumentDataEdit={InfoDocumentDataEdit} setInfoDocumentDataEdit={setInfoDocumentDataEdit} getDocumentByIdPerson={getDocumentByIdPerson} />
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        </div>
                        <div className=" flex flex-wrap z-10 mb-2 justify-end ">
                            {allowEdit &&
                                <button type="button" onClick={() => setShowViewModal(false)}
                                    className="py-2 px-4 my-5 mr-4 text-white bg-grayDark-RS  hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-grayDark-RS font-medium rounded-lg text-base text-center">
                                    Cerrar</button>
                            }

                        </div>
                    </div >
                )}
        </>

    )
}

export default AccordionCV;