import React, { useState, useEffect, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link, useHistory } from "react-router-dom";

import AlertContext from '../../GlobalComponents/Alert/contexts/alertContext';

//import assets
import Nosotros from '../../../assets/Nosotros1-scaled.jpg';

//import icons from heroicons
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { UserIcon } from '@heroicons/react/24/outline';
import { PhoneIcon } from '@heroicons/react/24/outline';

import PhoneInput from 'react-phone-input-2';
import './customInputRegister.css';

const RegisterComponent = ({ AgregaUsuario, validationMessages }) => {

    //use alert context
    const alertCtx = useContext(AlertContext);

    //use to send user to other paths
    const history = useHistory();

    //use react hook form to get values and make validations
    const { register, getValues, setValue, watch, handleSubmit, control, resetField, formState } = useForm({
        mode: 'onChange',
        defaultValues: {
            email: '',
            password: '',
            name: '',
            lastName: '',
            secondSurname: '',
            phone: '',
        }
    });
    const { errors } = formState;
    const { isValid } = formState;

    //use to get validations from props and later iterate
    const [validations, setValidations] = useState([]);

    useState(() => {
        setValidations(validationMessages)
    })
    
    //here goes logic to validate user to register with services
    const onSubmit = async () => {
        
        if (isValid) {
            //first get values from inputs with react hook form
            const correo = getValues("email");
            const contrasena = getValues("password");
            const nombre = getValues("name");
            const primerApellido = getValues("lastName");
            const segundoApellido = getValues("secondSurname");
            const telefono = getValues("phone");

            const data = {
                correo: correo,
                contrasena: contrasena,
                nombre: nombre,
                primerApellido: primerApellido,
                segundoApellido: segundoApellido,
                telefono: telefono,
            }

            const response = await AgregaUsuario(data);


            if (response.indicador === 0) {
                history.push('/login');
                alertCtx.success(response.mensaje);
            } else {
                alertCtx.error(response.mensaje);
            }
        } else {
            alertCtx.error("¡Error en el registro!");
        }
        
    }

    return (


        <div className="px-4 m-4 container my-6 mx-auto items-center justify-center flex  flex-col-reverse md:flex-row lg:py-0 md:m-36">
            <div className="w-full md:w-3/5 pt-1 bg-white rounded-lg shadow  xl:p-0">
                <div className="p-4 space-y-2 md:space-y-4 sm:p-4">
                    <h1 className="flex text-base justify-center mx-auto font-bold text-gray-900">
                        Registro
                    </h1>
                    {/*All input will get same colum line and width*/}
                    <form className="space-y-4 md:space-y-6"  onSubmit={handleSubmit(onSubmit)}>
                        {/*First row*/}
                        <div className="flex flex-wrap mt-2 mx-auto items-center justify-center">
                            {/*nombre Input*/}
                            <div className="w-1/2 w-80 mr-0 md:mr-2 mt-6">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Nombre</label>
                                <div className="relative flex items-center">
                                    <UserIcon className="h-5 w-5 absolute pointer-events-none text-dark-500 inset-y-0 right-0 mr-2.5" />
                                    <input type="text" name="name" id="name" className="text-gray-900 sm:text-sm block w-full p-2.5 " placeholder="Nombre"
                                        {...register('name', { required: true, maxLength: 25 })} />
                                </div>
                                {/*iterate over validations and show message name*/}
                                {validations.name?.map((values) => {
                                    return (
                                        errors.name?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>
                            {/*PrimerApellido Input*/}
                            <div className="w-1/2 w-80 ml-0 md:ml-2 mt-6">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Primer apellido</label>
                                <div className="relative flex items-center">
                                    <UserIcon className="h-5 w-5 absolute pointer-events-none text-dark-500 inset-y-0 right-0 mr-2.5" />
                                    <input type="text" name="lastName" id="lastName" className="text-gray-900 sm:text-sm block w-full p-2.5 " placeholder="Primer apellido"
                                        {...register('lastName', { required: true, maxLength: 25 })} />
                                </div>
                                {/*iterate over validations and show message lastName*/}
                                {validations.lastName?.map((values) => {
                                    return (
                                        errors.lastName?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>
                        </div>

                        {/*Second row*/}
                        <div className="flex flex-wrap mt-2 mx-auto items-center justify-center">
                            {/*Segundo apellido Input*/}
                            <div className="w-1/2 w-80 mr-0 md:mr-2 mt-6">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Segundo apellido</label>
                                <div className="relative flex items-center">
                                    <UserIcon className="h-5 w-5 absolute pointer-events-none text-dark-500 inset-y-0 right-0 mr-2.5" />
                                    <input type="text" name="secondSurname" id="secondSurname" className="text-gray-900 sm:text-sm block w-full p-2.5 " placeholder="Segundo apellido"
                                        {...register('secondSurname', { required: true, maxLength: 25 })} />
                                </div>
                                {/*iterate over validations and show message secondSurname*/}
                                {validations.secondSurname?.map((values) => {
                                    return (
                                        errors.secondSurname?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>
                            {/*Correo Input*/}
                            <div className="w-1/2 w-80 ml-0 md:ml-2 mt-6">
                                {/*Email input*/}
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Correo electrónico</label>
                                <div className="relative flex items-center">
                                    <EnvelopeIcon className="h-5 w-5 absolute pointer-events-none text-dark-500 inset-y-0 right-0 mr-2.5" />
                                    <input type="text" name="email" id="email" className="text-gray-900 sm:text-sm block w-full p-2.5 " placeholder="correo electrónico"
                                        {...register('email', { required: true, maxLength: 150, pattern: /^(.+)@(.+)$/ })} />
                                </div>
                                {/*iterate over validations and show message email*/}
                                {validations.email?.map((values) => {
                                    return (
                                        errors.email?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>
                        </div>

                        {/*Third row*/}
                        <div className="flex flex-wrap mt-2 mx-auto items-center justify-center">
                            {/*Telefono Input*/}
                            <div className="w-1/2 w-80 mr-0 md:mr-2 mt-6">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Teléfono</label>
                                <label className="relative block">
                                    <Controller
                                        control={control}
                                        name="phone"
                                        render={({ field: { ref } }) => (
                                            <PhoneInput
                                                {...register('phone', { required: true })}
                                                specialLabel={''}
                                                country={'cr'}
                                                value={getValues("phone")}
                                                onChange={(value) => {
                                                    setValue("phone", value)
                                                    if (value.length > 0) {
                                                        resetField("phone", { keepError: false })
                                                        setValue("phone", value)
                                                    }
                                                    }
                                                }
                                               
                                                isValid={(value) => {
                                                    if (value.length === 0) {
                                                        return 'Campo requerido';
                                                    } else {
                                                        return true;
                                                    }
                                                }}
                                                placeholder="Teléfono"
                                                className="react-tel-input2"
                                                />
                                                

                                        )}

                                    />
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                                        <PhoneIcon className="h-5 w-5 pointer-events-none text-dark-500" />
                                    </span>
                                </label>

                            </div>
                            {/*Contraseña Input*/}
                            <div className="w-1/2 w-80 ml-0 md:ml-2 mt-6">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Contraseña</label>
                                <div className="relative flex items-center">

                                    <input type="password" name="password" id="password" className="text-gray-900 sm:text-sm block w-full p-2.5 " placeholder="Contraseña"
                                        {...register('password', { required: true, minLength: 6, maxLength: 32 })} />
                                </div>
                                {/*iterate over validations and show message password*/}
                                {validations.password?.map((values) => {
                                    return (
                                        errors.password?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>
                        </div>

                        <div className="flex items-center justify-center">
                            {/*Confirmar contraseña Input*/}
                            <div className="w1/2 w-80 ml-0 md:ml-2 mt-6">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Confirmar contraseña</label>
                                <div className="relative flex items-center">

                                    <input type="password" name="repeatPassword" id="repeatPassword" className="text-gray-900 sm:text-sm block w-full p-2.5 " placeholder="Confirmar contraseña"
                                        {...register('repeatPassword', {
                                            required: true,
                                            minLength: 6,
                                            maxLength: 32,
                                            validate: (value) => value == watch('password')
                                        })} />
                                </div>
                                {/*iterate over validations and show message password*/}
                                {validations.repeatPassword?.map((values) => {
                                    return (
                                        errors.repeatPassword?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>
                        </div>

                        <button type="submit" className="w-full text-white bg-red-RS  hover:bg-primary-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Enviar</button> 
                        <Link className="flex justify-center underline text-sm font-bold text-gray-500 dark:text-gray-400" to="/login">Ya tengo una cuenta</Link>
                    </form>
                </div>
            </div>
            {/*Second block to manage image figure*/}
            <div className="w-full md:w-2/5 xl:p-0">
                <div className="p-6 mt-8 space-y-8 md:space-y-4 sm:p-6 mx-auto justify-center">
                    <figure className="mx-auto justify-center">
                        <img className="object-contain transform hover:scale-110 transition duration-700 ease-out" src={Nosotros} alt="Logo RS" />
                    </figure>
                </div>
            </div>
            
        </div>
    )
}

export default RegisterComponent;
