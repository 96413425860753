import React from 'react';
import CertificacionesComponent from './Certificaciones';

const Certificacion = ({ userData, setUserData, finalData = [], setstateEducacion, allowEdit = false, listCertificationsByPerson = [] }) => {

    //validations
    const validationMessages = {
        nameTitle: [
            {
                type: 'required',
                message: 'Favor ingresar el nombre de título',
            },
            {
                type: 'maxLength',
                message: 'El nombre de la título no puede contener mas de 60 caracteres ',
            },
            {
                type: 'pattern',
                message: 'Solo se admiten letras y números.',
            }
        ],
        institution: [
            {
                type: 'required',
                message: 'Favor ingresar el nombre de la institución',
            },
            {
                type: 'maxLength',
                message: 'El nombre de la institución no puede contener mas de 60 caracteres ',
            },
            {
                type: 'pattern',
                message: 'Solo se admiten letras.',
            }
        ],
        fileInput: [
            {
                type: 'required',
                message: 'Favor ingresar el titulo',
            }
        ],
    }


    return (
        <div>
            <CertificacionesComponent validationMessages={validationMessages} userData={userData} setUserData={setUserData} finalData={finalData} setstateEducacion={setstateEducacion} allowEdit={allowEdit} listCertificationsByPerson={listCertificationsByPerson} />
        </div>
    );
}

export default Certificacion;