import { ProcesarDatosApi } from "../api/ApiFetch";
const controladorReporteProfesionalesEspecialidad = "reporteprofesionalesespecialidad";

export const ReporteProfesionalesPorCadaEspecialidad = async (idEspecializacion) => {
    const url = `${controladorReporteProfesionalesEspecialidad}/ReporteProfesionalesPorCadaEspecialidad/${idEspecializacion}`;
    return await ProcesarDatosApi('GET', url);
}

export const ReporteProfesionalesPorEspecialidad = async () => {
    const url = `${controladorReporteProfesionalesEspecialidad}/ReporteProfesionalesPorEspecialidad`;
    return await ProcesarDatosApi('GET', url);
}