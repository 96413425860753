import React, { useContext, useState} from "react";
import AlertContext from '../../GlobalComponents/Alert/contexts/alertContext';
import { AgregarPersona } from '../../../servicios/ServicioInfoPersonal';
import { AgregarTecnologiaPersona } from '../../../servicios/ServicioTecnologia';
import { AgregarEducacion } from '../../../servicios/ServicioEducacion';
import { AgregarExperiencia } from '../../../servicios/ServicioExperiencia';
import { AgregarProyecto } from '../../../servicios/ServicioProyecto';
import { AgregarDocumento } from '../../../servicios/ServicioDocumento';



const NavigationControl = ({ handleClick, currentStep, steps, InfoPersonalData, InfoDocumentData, otherTechnologiesData, stateInfoPersonal, stateEducacion, stateTech, finalData, isLoading, setIsLoading }) => {

    async function AgregaPersona(dataPersona) {
        const response = await AgregarPersona(dataPersona);
        return response;
    }
    async function AgregaOtrasTecnologias(OtherTechData) {
        const response = await AgregarTecnologiaPersona(OtherTechData);
        return response;
    }
    async function AgregaTecnologias(tecnologiasData) {
        const response = await AgregarTecnologiaPersona(tecnologiasData);
        return response;
    }
    async function AgregaEducacion(EducacionData) {
        const response = await AgregarEducacion(EducacionData);
        return response;
    }
    async function AgregaExperiencia(ExperienciaData) {
        const response = await AgregarExperiencia(ExperienciaData);
        return response;
    }
    async function AgregaProyecto(ProyectosData) {
        const response = await AgregarProyecto(ProyectosData);
        return response;
    }
    async function AgregaDocumento(DocumentoData) {
        const response = await AgregarDocumento(DocumentoData);
        return response;
    }

    const alertCtx = useContext(AlertContext);
    const jsonUsuario = sessionStorage.getItem("USER_DATA");
    const usuario = JSON.parse(jsonUsuario);
    const idUsuario = usuario.IdUsuario;

    const {
        email,
        firstName,
        secondName,
        surname,
        secondSurname,
        identification,
        birthDate,
        firstPhoneNumber,
        secondPhoneNumber,
        residence,
        maritalStatus,
        linkedin,
        otherSocialMedia,
        workInRossmon,
        personalSummary,
        specialty 
    } = InfoPersonalData;

    
    const dataPersona = {
        correoElectronico: email,
        primerNombre: firstName,
        segundoNombre: secondName,
        primerApellido: surname,
        segundoApellido: secondSurname,
        identificacion: identification,
        fechaNacimiento: birthDate,
        telefono1: firstPhoneNumber,
        telefono2: secondPhoneNumber,
        lugarResidencia: residence,
        idEstadoCivil: parseInt(maritalStatus, 10),
        linkedin: linkedin,
        otraRedSocial: otherSocialMedia,
        trabajaEnRossmon: workInRossmon,
        resumenPersonal: personalSummary,
        idUsuario: parseInt(idUsuario, 10),
        idEspecializacion: parseInt(specialty, 10)   
    };

    const handleSubmit = async (e) => {

        e.preventDefault();

        setIsLoading(true);
        const TechnologiesList = finalData?.listTechnologies || [];
        let ExperienceList = [];
        let ProjectsList = [];
        let EducationList = [];

        try {
       
            const response = await AgregaPersona(dataPersona);

            if (response.indicador === 0) {
                const IdPersona = response.idPersona;

                for (var index = 0; index < finalData?.listEducation.length; index++) {
                    EducationList.push(finalData?.listEducation[index].dataForGlobalList);
                }

                const EducacionData = EducationList.map(educacion => ({
                    nombreTitulo: educacion.nameTitle,
                    institucion: educacion.institution,
                    fechaInicio: educacion.startDate === "" ? null : educacion.startDate,
                    fechafinalizacion: educacion.finishDate === "" ? null : educacion.finishDate,
                    cursando: educacion.inCourse,
                    imgTitulo: educacion.filePathValue,
                    idGrado: parseInt(educacion.educationLevel, 10),
                    imgNombre: educacion.fileNameValue,
                    idPersona: IdPersona,
                    extension: educacion.fileExtensionValue
                }));


                const educacionResponse = await AgregaEducacion(EducacionData);
                if (educacionResponse.indicador != 0) {
                    alertCtx.error(educacionResponse.mensaje);
                }




                if (ExperienceList.length >= 0) {

                    for (var index = 0; index < finalData?.listExperience.length; index++) {
                        ExperienceList.push(finalData?.listExperience[index].dataForGlobalList);
                    }

                    const ExperienciaData = ExperienceList.map(experiencia => ({
                        nombreEmpresa: experiencia.nameCompany,
                        puestoDesempenado: experiencia.positionHeld,
                        fechaIngreso: experiencia.startDate === "" ? null : experiencia.startDate,
                        fechaSalida: experiencia.finishDate === "" ? null : experiencia.finishDate,
                        trabajando: experiencia.currentlyWorking,
                        funcionesRealizadas: experiencia.functionsPerformed,
                        idPersona: IdPersona,
                        idCategoriaExperiencia: parseInt(experiencia.categoryExperience, 10)
                    }));


                    const experienciaResponse = await AgregaExperiencia(ExperienciaData);
                    if (experienciaResponse.indicador != 0) {
                        alertCtx.error(experienciaResponse.mensaje);
                    }
                }
               
                if (TechnologiesList.length >= 0) {

                    const tecnologiasData = TechnologiesList.map(tecnologia => ({
                        idTecnologia: tecnologia.idTecnologia,
                        expertiz: tecnologia.expertiz,
                        otrasTecnologias: tecnologia.otrasTecnologias,
                        idPersona: IdPersona
                    }));

                    const tecnologiasResponse = await AgregaTecnologias(tecnologiasData);

                    if (tecnologiasResponse.indicador != 0) {
                        alertCtx.error(tecnologiasResponse.mensaje);
                    }
                }


                if (otherTechnologiesData) {

                    const {
                        otherTechnologies,

                    } = otherTechnologiesData;

                    const OtherTechData = [{
                        idTecnologia: null,
                        expertiz: null,
                        otrasTecnologias: otherTechnologies,
                        idPersona: IdPersona
                    }];


                    const OtrasTecnologiasResponse = await AgregaOtrasTecnologias(OtherTechData);
                    if (OtrasTecnologiasResponse.indicador != 0) {
                        alertCtx.error(OtrasTecnologiasResponse.mensaje);
                    }
                }


                if (finalData?.listProjects) {

                    for (var index = 0; index < finalData?.listProjects?.length; index++) {
                        ProjectsList.push(finalData?.listProjects[index].dataForGlobalList);
                    }

                    const ProyectosData = ProjectsList.map(proyectos => ({
                        nombreProyecto: proyectos.projectName,
                        nombreEmpresa: proyectos.companyName,
                        fechaInicio: proyectos.startDate === "" ? null : proyectos.startDate,
                        fechaFin: proyectos.finishDate === "" ? null : proyectos.finishDate,
                        descripcionProyecto: proyectos.projectDescription,
                        idPersona: IdPersona
                    }));

                    const proyectosResponse = await AgregaProyecto(ProyectosData);

                    if (proyectosResponse.indicador != 0) {
                        alertCtx.error(proyectosResponse.mensaje);
                    }

                }

                if (InfoDocumentData) {
                    const {
                        extension,
                        name,
                        document,
                    } = InfoDocumentData;

                    const dataDocument = {
                        nombreDocumento: name,
                        extension: extension,
                        documento: document,
                        idPersona: IdPersona,
                        UsuarioCreacion: String(usuario.Correo)
                    }

                    const documentResponse = await AgregaDocumento(dataDocument);

                    if (documentResponse.indicador != 0) {
                        alertCtx.error(educacionResponse.mensaje);
                    }

                }
               
                alertCtx.success('Los datos del curriculum han sido guardados satisfactoriamente!');
                
                   
                
            } else {
                alertCtx.error(response.mensaje);
            } 
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
       const timeout = setTimeout(() => {
            window.location.reload();
        }, 1000);
        return () => clearTimeout(timeout);
   
    };


    return (
        <div className="container flex right-0 justify-end mt-4 mb-4 md:mx-auto">
            { /*back button*/}
            <button
                type="button"
                onClick={() => handleClick()}
                className={`py-2 px-4 mx-2 my-5 text-white bg-black-RS  hover:bg-primary-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center  ${currentStep === 1 ? "hidden" : ""}`}>
                Anterior
            </button>
            { /*next button*/}
            <button
                type={currentStep === steps.length ? "submit" : "button"}
                disabled={
                    currentStep === 6 &&
                    (!stateInfoPersonal || !stateEducacion || !stateTech)
                }
                onClick={currentStep === steps.length ? handleSubmit : () => handleClick("Siguiente")}
                className="py-2 px-4 mx-2 my-5 text-white bg-red-RS  hover:bg-primary-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center ">
                {currentStep === steps.length ? "Registrar" : "Siguiente"}
            </button>
        </div>);
};


export default NavigationControl;