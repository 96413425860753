import { ProcesarDatosApi } from "../api/ApiFetch";
const controladorExperiencia = "experiencia";
const controladorCategoriaExperiencia = "categoriaexperiencia";

export const ObtenerExperiencia = async () => {
    const url = `${controladorExperiencia}/ObtenerExperiencia`;
    return await ProcesarDatosApi('GET', url);
}

export const AgregarExperiencia = async (data) => {
    const url = `${controladorExperiencia}/AgregarExperiencia`;
    return await ProcesarDatosApi('POST', url, data);
}

export const AgregarExperienciaIndividual = async (data) => {
    const url = `${controladorExperiencia}/AgregarExperienciaIndividual`;
    return await ProcesarDatosApi('POST', url, data);
}

//Categoria Experiencia
export const ObtenerCategoriaExperiencia = async () => {
    const url = `${controladorCategoriaExperiencia}/ObtenerCategoriaExperiencia`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerExperienciaPorIdPersona = async (idPersona) => {
    const url = `${controladorExperiencia}/ObtenerExperienciaPorIdPersona/${idPersona}`;
    return await ProcesarDatosApi('GET', url);
}

export const EditarExperiencia = async (data) => {
    const url = `${controladorExperiencia}/EditarExperiencia`;
    return await ProcesarDatosApi('PUT', url, data);
}
export const EliminarExperiencia = async (idExperiencia) => {
    const url = `${controladorExperiencia}/EliminarExperiencia/${idExperiencia}`;
    return await ProcesarDatosApi('DELETE', url);
}
