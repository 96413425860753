import React, { useState, useEffect, useRef, useContext } from 'react';

//import global components
import { AttachCV, DocumentViewer, Modal, ModalActionConfirm  } from '../../../../imports';
//import icons
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import AlertContext from '../../../GlobalComponents/Alert/contexts/alertContext';

//import services
import { EditarDocumento, AgregarDocumento} from '../../../../servicios/ServicioDocumento';

export default function AdjuntarCurriculum({ InfoDocumentData, setInfoDocumentData, allowEdit, listDocumentByPerson, setListDocumentByPerson, isFirstLoadDocument, setIsFirstLoadDocument, idCurriculum, InfoDocumentDataEdit, setInfoDocumentDataEdit, getDocumentByIdPerson }) {

    const [fileName, setFileName] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [finishLoadFile, setFinishLoadFile] = useState(false);
    const [filePath, setFilePath] = useState('');
    const [fileExtension, setFileExtension] = useState('');

    const [showViewDocumentComponent, setShowViewDocumentComponent] = useState(false);

    //use alert context
    const alertCtx = useContext(AlertContext);

    //validate if user already complete cv
    const userCompleteCV = sessionStorage.getItem("USER_COMPLETE_CV");
    const jsonUsuario = sessionStorage.getItem("USER_DATA");
    const usuario = JSON.parse(jsonUsuario);
    const rol = sessionStorage.getItem("USER_ROLE");

    //use modal and show for save edition
    const [showConfirmSaveModal, setShowConfirmSaveModal] = useState(false);
    const [actionTypeConfirmModal, setActionTypeConfirmModal] = useState("");
    const [showConfirmEditModal, setShowConfirmEditModal] = useState(false);

    const [userUpdateFile, setUserUpdateFile] = useState(false);

    //user select and set file
    const selectFile = (event) => {
        event.preventDefault();

        const userfile = event.target.files[0]; //get file selected
        const validTypes = ['application/pdf', 'application/doc', 'application/docx', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];//only pdf and word file

        if (userfile && validTypes.includes(userfile.type)) {

            setFileName(userfile.name)//show name of the file to user
            setSelectedFile(userfile.name);
            setFileExtension(userfile.type);
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result.split(",")[1];
                setFilePath(base64String);
                setFinishLoadFile(true)
            };
            reader.readAsDataURL(userfile);
           
        } else {
            setSelectedFile(null);
            alertCtx.error("¡Solo se aceptan pdf o archivos word!");
        }
    }

    //method to add new document
    const AgregarDocumentoPersona = async (e) => {

        const dataDocument = {
            nombreDocumento: fileName,
            extension: fileExtension,
            documento: filePath,
            idPersona: idCurriculum,
            UsuarioCreacion: String(usuario.Correo)
        }

        const responseDocument = await AgregarDocumento(dataDocument);

        if (responseDocument.indicador === 0) {

            alertCtx.success(responseDocument.mensaje);
            setShowConfirmSaveModal(false);
            setUserUpdateFile(true)
        }


    }

    //method to edit document from person by id
    const EditarDocumentoPersona = async (e) => {

        const dataDocument = {
            idDocumento: listDocumentByPerson?.idDocumento,
            nombreDocumento: fileName,
            extension: fileExtension,
            documento: filePath,
            idPersona: idCurriculum,
            UsuarioModificacion: String(usuario.Correo)
        }
        const responseDocument = await EditarDocumento(dataDocument);

        if (responseDocument.indicador === 0) {
          
            alertCtx.success(responseDocument.mensaje);
            setShowConfirmEditModal(false);
        }


    }
    //keep info between accordion
    const keepValuesFromListDocumentByPerson = () => {
        const data = {
            idDocumento: listDocumentByPerson?.idDocumento,
            nombreDocumento: fileName,
            extension: fileExtension,
            documento: filePath,
            idPersona: idCurriculum,
            UsuarioModificacion: String(usuario.Correo)
        }
        setListDocumentByPerson(data)
    }

    const setValuesByListDocument = () => {
        if (listDocumentByPerson) {
            setFileExtension(listDocumentByPerson?.extension)
            setFileName(listDocumentByPerson?.nombreDocumento)
            setFilePath(listDocumentByPerson?.documento)
            setSelectedFile(listDocumentByPerson?.nombreDocumento)
        }
    }

    // Display a base64 URL inside an iframe in another window.

    function showBase64File(base64URL, name) {
        var win = window.open();
       
        setTimeout(function () {
            win.document.title = name;
        }, 100);

        win.document.write('<iframe src="' + base64URL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
    }

    useState(() => {
        //validate for candidate view or admin view
        if (userCompleteCV === "true" && isFirstLoadDocument || allowEdit === true && isFirstLoadDocument) {
            setValuesByListDocument();
           
        } else {
            if (InfoDocumentData) {
                setSelectedFile(InfoDocumentData["name"])
            }
        }
    }, [])
    
    useEffect(() => {
        if (finishLoadFile) {
            if (InfoDocumentData) {
                setInfoDocumentData({
                    ...InfoDocumentData, ["name"]: fileName, ["extension"]: fileExtension, ["document"]: filePath
                })
            }
        }
        if (userCompleteCV === "true" && userUpdateFile) {
            //refresh data when user add new document and wants to edit at same time
            getDocumentByIdPerson()
        }
      
        
        return (() => {
            //validate for candidate view or admin view
            if (userCompleteCV === "true" || allowEdit === true) {
                keepValuesFromListDocumentByPerson();
            }

            if (InfoDocumentData) {
                setInfoDocumentData({
                    ...InfoDocumentData, ["name"]: fileName, ["extension"]: fileExtension, ["document"]: filePath
                })
            }
        
        })
    }, [filePath, finishLoadFile, userUpdateFile])
 
    return (
        <>
            <div className="w-full flex flex-col md:flex-row items-center justify-center px-1  mx-auto lg:py-0">
   
                {/*Block to manage form input 'proyectos'--*/}
                <div className={userCompleteCV === "false" ? "w-full pt-1 bg-white rounded-lg shadow" : "w-full pt-1"}>

                    {/*Here we show view confirm save modal compononent*/}
                    <Modal isVisible={showConfirmSaveModal} onClose={() => { setShowConfirmSaveModal(false) }} handleSubmit={() => null} onSaveOptions={null} showFotter={false} showCloseIcon={false}>
                        <ModalActionConfirm onSaveAction={AgregarDocumentoPersona} actionType={actionTypeConfirmModal} setshowConfirmModal={setShowConfirmSaveModal} />
                    </Modal>

                    {/*Here we show view confirm edit modal compononent*/}
                    <Modal isVisible={showConfirmEditModal} onClose={() => { setShowConfirmEditModal(false) }} handleSubmit={() => null} onSaveOptions={null} showFotter={false} showCloseIcon={false}>
                        <ModalActionConfirm onSaveAction={EditarDocumentoPersona} actionType={actionTypeConfirmModal} setshowConfirmModal={setShowConfirmEditModal} />
                    </Modal>

                    <div className="mt-2 flow-root">
                        {/*Front end validations for show current elements*/ }
                        {fileExtension ?
                            <div className="float-left my-2">
                                {!allowEdit && userCompleteCV === "true" ? <label className="block mb-2 text-base text-gray-900 ml-2 ">Agregue un documento y presione actualizar para guardar</label> : null}
                            </div>
                            : null
                        }
                        {/*Detect user permissions and show elements based on allowEdit*/ }
                        {!allowEdit && userCompleteCV === "true" || rol === "2" ?
                            <div className="float-right my-2">
                                {fileExtension ?
                                    fileExtension === 'application/pdf' ?
                                        <button onClick={() => showBase64File(`data:${fileExtension};base64,${filePath}`, fileName)} className="py-2 px-4 mx-2 mb-2 text-white bg-grayDark-RS hover:bg-primary-700 font-medium rounded-lg text-sm text-center" >Ver documento</button> :
                                        <a target="_blank" href={`data:${fileExtension};base64,${filePath}`} download={fileName}
                                        >
                                            <button className="py-2 px-4 mx-2 mb-2 text-white bg-grayDark-RS hover:bg-primary-700 font-medium rounded-lg text-sm text-center" >Descargar documento</button> </a> : null}

                                    {!allowEdit && userCompleteCV === "true" &&
                                    listDocumentByPerson &&
                                    <button disabled={allowEdit} type="button" onClick={() => {if (listDocumentByPerson?.idDocumento === undefined) { setActionTypeConfirmModal("Agregar"); setShowConfirmSaveModal(true); } else {  setActionTypeConfirmModal("Editar"); setShowConfirmEditModal(true); } }} className="py-2 px-4 mx-2 mb-2 text-white bg-greenButton hover:bg-primary-700 font-medium rounded-lg text-sm text-center"
                                    >Actualizar</button>
                                    
                                }
                            </div>
                        : null}
                    </div>
                    <div className="relative container mx-auto items-center justify-center">
                        <div className="mx-auto px-8 py-8">
                            <div className="flex items-center justify-center w-full px-4 py-4">
                          
                                <label disabled={allowEdit}  className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50">
                                    <div className="flex flex-col items-center justify-center pt-5 pb-6 container">
                                        {!allowEdit ?
                                            <>
                                                <ArrowUpTrayIcon className="text-black-RS h-8 w-8 mb-2 " />
                                                <p className="mb-2 text-sm font-sans text-black-RS font-bold"><span className="font-semibold">Presione para cargar un archivo</span></p>
                                                <p className="text-xs text-gray-500 font-sans ">PDF o Word</p>
                                            </>: null
                                        }
                                            {selectedFile && (
                                            <>
                                                <div className="flex flex-row w-full m-1 items-center justify-center mx-auto">
                                                 
                                                    <p className="text-sm text-truncate  overflow-hidden font-bold text-black-RS ">Archivo seleccionado: {selectedFile.split('/').pop()} </p>
                                                </div>
                                               
                                                </>
                                            )}
                                        </div>
                                        <input id="cvFile"
                                            name="cvFile"
                                            accept="application/docx, application/doc, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        onChange={selectFile} disabled={allowEdit} type="file" className="hidden" />
                                    </label>
                                
                            </div> 
                        </div>
                   
                    </div>
                </div>
                {/*Here we show view confirm delete modal compononent*/}
                <Modal isVisible={showViewDocumentComponent} onClose={() => { setShowViewDocumentComponent(false) }} handleSubmit={() => null} onSaveOptions={null} showFotter={false} showCloseIcon={true}>
                    <DocumentViewer typeView='pdf' filePath={listDocumentByPerson?.documento} fileName={listDocumentByPerson?.nombreDocumento} fileExtension={listDocumentByPerson?.extension} />
                </Modal>
            </div>
        </>
    )
}