import React, { useState, useEffect, useRef, useContext, useMemo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import AlertContext from '../../../GlobalComponents/Alert/contexts/alertContext';
import SideMenu from '../../../GlobalComponents/Sidebar/AdminMenu';
//import global components
import { Modal, SideBar, Table } from '../../../../imports';

//import services
import { ObtenerTecnologiasCompleto, AgregarTecnologia, EditarTecnologia, EditarInactivoTecnologia, ObtenerCategoriasTecnologias } from '../../../../servicios/ServicioTecnologia';
import { ObtenerEspecialidades } from '../../../../servicios/ServicioEspecializacion';

//import icons from heroicons
import { PlusCircleIcon, ExclamationTriangleIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/outline';


export default function TechCatalog({ validationMessages }) {


    //use to get maritalStatus options
    const [specialtyList, setSpecialtyList] = useState([]);

    //list to save and send data to table
    const [listTechnologies, setListTechnologies] = useState([]);

    //use alert context
    const alertCtx = useContext(AlertContext);

    //use modal and show for add
    const [showAddModal, setShowAddModal] = useState(false);

    //use modal and show for edit
    const [showEditModal, setShowEditModal] = useState(false);

    //use modal and show for delete
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    //use to get id with set options when click events
    const [idFromEdit, setIdFromEdit] = useState(null);
    const [idFromDeactivate, setIdFromDeactivate] = useState(0);


    //use to get state from colums
    const [stateTech, setStateTech] = useState(null);

    //get current user id
    const jsonUsuario = sessionStorage.getItem("USER_DATA");
    const usuario = JSON.parse(jsonUsuario);



    //declare register to form
    //use react hook form to get values and make validations
    const { register, getValues, setValue, handleSubmit } = useForm({
        mode: 'all',
        defaultValues: {
            techName: '',
            categoryTechnologie: ''
        }
    });


    
    async function getSpecialties() {

        const response = await ObtenerEspecialidades();

        setSpecialtyList(response);

    }

    async function getTechnologies() {

        const response = await ObtenerTecnologiasCompleto();

        setListTechnologies(response);
    }

    async function EditTech(id, tecnologia, category) {

        const data = {
            idTecnologia: id,
            tecnologia: tecnologia,
            idEspecializacion: parseInt(category, 10),
            usuarioCreacion: String(usuario.Correo),
        }


        const response = await EditarTecnologia(data);

        if (response.indicador === 0) {
            alertCtx.success(response.mensaje);
        } else {
            alertCtx.error(response.mensaje);
        }
    }

    async function AddTechnologie(tecnologia, category) {

        const data = {
            idEspecializacion: parseInt(category, 10),
            tecnologia: tecnologia,
            usuarioCreacion: String(usuario.Correo),
            estado: true,
        }

        const response = await AgregarTecnologia(data);

        if (response.indicador === 0) {
            alertCtx.success(response.mensaje);
        } else {
            alertCtx.error(response.mensaje);
        }
    }

    async function DeactivateTech(id, estado) {

        const data = {
            idTecnologia: id,
            usuarioCreacion: String(usuario.Correo),
            estado: estado
        }

        const response = await EditarInactivoTecnologia(data);

        if (response.indicador === 0) {
            alertCtx.success(response.mensaje);
        } else {
            alertCtx.error(response.mensaje);
        }
    }

    //**change this colums with entities names or need data**
    const columns = [
        {
            name: 'Tecnología',
            selector: row => row?.tecnologia,
        },
        {
            name: 'Especialidad',
            selector: row => row?.nombreEspecializacion,
        },
        {
            name: 'Estado',
            selector: (row) => {
                return (
                    row?.estado === true ? <div className="flex items-center">
                        <div className="h-2.5 w-2.5 rounded-full bg-green-500 mr-2"></div> <h2 className="text-sm text-green-500 font-bold" > Activo</h2 >
                    </div>
                        : <div className="flex items-center">
                            <div className="h-2.5 w-2.5 rounded-full bg-red-RS mr-2"></div> <h2 className="text-sm text-red-RS font-bold" > Inactivo</h2 >
                        </div>
                )
            },
        },
        {
            name: 'Acciones',
            selector: row => row?.estado,
            cell: (row) =>

                <button type="button" id={row?.idTecnologia} onClick={() => { setShowEditModal(true); setIdFromEdit(row?.idTecnologia); setValue("techName", row?.tecnologia); setValue("categoryTechnologie", row?.idEspecializacion); }} className="rounded-full text-sm w-32 py-2 px-4 border-solid border-2 border-black-RS text-black-RS font-bold  m-2 hover:border-gray-200 ">Editar</button>

            ,
            right: true,
            ignoreRowClick: true,
            allowOverflow: true,
        },
        {
            cell: (row) => {
                return (
                    row?.estado === true ? <button type="button" id={row?.idTecnologia} onClick={() => { setStateTech(row?.estado); setIdFromDeactivate(row?.idTecnologia); setShowDeleteModal(true); }} className="rounded-full text-sm w-32 py-2 px-4 border-solid border-2 border-red-RS text-red-RS font-bold  m-2 hover:border-gray-200"> Desactivar</button >
                        : <button type="button" id={row?.idTecnologia} onClick={() => { setStateTech(row?.estado); setIdFromDeactivate(row?.idTecnologia); setShowDeleteModal(true); }} className="rounded-full text-sm w-32 py-2 px-4 border-solid border-2 border-greenButton text-greenButton font-bold  m-2 hover:border-gray-200"> Activar</button >
                )
            }
            ,
        },

    ]

    const onSaveEditOptions = () => {
        const techName = getValues("techName");
        const category = getValues("categoryTechnologie");

        EditTech(idFromEdit, techName, category)

        setShowEditModal(false);

        setValue("techName", "");
        setValue("categoryTechnologie", "");
        setShowEditModal(false);

        const timeout = setTimeout(() => {
            getTechnologies();
        }, 1000);
        return () => clearTimeout(timeout);
    }

    const onSaveDeleteOptions = () => {

        DeactivateTech(idFromDeactivate, !stateTech)
  
        setShowDeleteModal(false);
        const timeout = setTimeout(() => {
            getTechnologies();
        }, 1000);
        return () => clearTimeout(timeout);
    }

    const onSaveAddOptions = () => {
        const techName = getValues("techName");
        const category = getValues("categoryTechnologie");


        AddTechnologie(techName, category);
        
        setShowAddModal(false);
      
        const timeout = setTimeout(() => {
            setValue("techName", "");
            setValue("categoryTechnologie", "");
            getTechnologies();
       
        }, 2000);
        return () => clearTimeout(timeout);
    }

    //when component start, get list
    useState(() => {
        getTechnologies();

        getSpecialties();
    }, [])

    useEffect(() => {

    }, [showAddModal, showDeleteModal, showEditModal, stateTech])
    
    //use to show add new role component when 'agregar' button is activated
    const AddTechComponent = () => {
        return (
            <>
            <form className="space-y-2 md:space-y-4">
                <div className="w-96 container mx-auto items-center justify-center">
                    <div className="flex flex-col">
                        <div className="flex text-base m-2 justify-center mx-auto font-bold leading-tight tracking-tight text-green-500">
                            <PlusCircleIcon className="w-8 h-8 ml-2"></PlusCircleIcon>
                        </div>
                        <div>
                            <h1 className="flex text-base justify-center mx-auto font-bold leading-tight tracking-tight text-black-RS">
                                Agregar Tecnología
                            </h1>
                        </div>


                        {/*First row*/}
                        <div className="flex flex-wrap mx-2 mx-auto items-center justify-center">
                            {/**/}
                            <div className="w-full m-3 px-2  md:w-80">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Nombre de la tecnología</label>
                                <div className="relative flex items-center">
                                    <input
                                        type="text"
                                        name="techName"
                                        {...register("techName", { required: true, maxLength: 25 })}
                                        id="techName"
                                        className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS"
                                        placeholder="Nombre de la tecnología"
                                    />
                                </div>
                               
                            </div>
                            {/*Categoria Input*/}
                            <div className="w-full m-3 px-2  md:w-80">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Especialidad tecnología</label>
                                <div className="relative flex items-center">

                                    <select
                                        id="categoryTechnologie"
                                        {...register('categoryTechnologie', { required: true })}
                                        type="select"
                                        className="text-black-RS sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS "
                                        name="categoryTechnologie"
                                        >
                                        <option value={""} defaultValue > --Seleccione la especialidad --</option>

                                            {specialtyList?.map((option, index) => (
                                                <option key={index} value={option.idEspecializacion}>{option.nombreEspecializacion}</option>
                                        ))}
                                    </select>
                                </div>
                               
                            </div>

                        </div>

                    </div>
                </div>
                </form>
            </>
        )
    }

    //use to show edit role component when 'editar' button is activated
    const EditTechComponent = () => {
        return (
            <form className="space-y-2 md:space-y-4">
                <div className="w-96 container mx-auto items-center justify-center">
                    <div className="flex flex-col">
                        <div className="flex text-base m-2 justify-center mx-auto font-bold leading-tight tracking-tight text-green-500">
                            <PencilIcon className="w-8 h-8 ml-2"></PencilIcon>
                        </div>
                        <div>
                            <h1 className="flex text-base justify-center mx-auto font-bold leading-tight tracking-tight text-black-RS">
                                Editar Tecnología
                            </h1>
                        </div>

                        {/*First row*/}
                        <div className="flex flex-wrap mx-2 mx-auto items-center justify-center">
                            {/**/}
                            <div className="w-full m-3 px-2  md:w-80">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Nombre de la tecnología</label>
                                <div className="relative flex items-center">
                                    <input
                                        type="text"
                                        name="techName"
                                        {...register("techName", { required: true, maxLength: 25 })}
                                        id="techName"
                                        className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS"
                                        placeholder="Nombre de la tecnología"
                                    />
                                </div>

                            </div>
                            {/*Categoria Input*/}
                            <div className="w-full m-3 px-2  md:w-80">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Especialidad tecnología</label>
                                <div className="relative flex items-center">

                                    <select
                                        id="categoryTechnologie"
                                        {...register('categoryTechnologie', { required: true })}
                                        type="select"
                                        className="text-black-RS sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS "
                                        name="categoryTechnologie"
                                        >
                                        <option value={""} defaultValue > --Seleccione la especialidad  --</option>

                                        {specialtyList?.map((option, index) => (
                                            <option key={index} value={option.idEspecializacion}>{option.nombreEspecializacion}</option>
                                        ))}
                                    </select>
                                </div>

                            </div>

                        </div>

                       

                    </div>
                </div>
            </form>
        )
    }

    //use to show delete new role component when 'eliminar' button is activated
    const DeleteTechComponent = () => {
        return (
            <form className="space-y-2 md:space-y-4">
                <div className="w-96 container mx-auto items-center justify-center">
                    <div className="flex flex-col">
                        <div className="flex text-base m-2 justify-center mx-auto font-bold leading-tight tracking-tight text-red-RS">
                            <ExclamationTriangleIcon className="w-8 h-8 ml-2"></ExclamationTriangleIcon>
                        </div>
                        <div>
                            <h1 className="flex text-base justify-center mx-auto font-bold leading-tight tracking-tight text-black-RS">
                                Advertencia
                            </h1>
                        </div>

                        <div>
                            <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">¿Está seguro que desea cambiar el estado de este elemento?</label>
                        </div>

                    </div>
                </div>
            </form>
        )
    }
    return (
        <>

            <div className="flex flex-row">
                <div className="md:basis-1/4 xl:basis-1/5 ">
                    <SideBar />
                </div>
                <div className="md:basis-3/4 xl:basis-11/12">
                    <div className="bg-white rounded-lg shadow-lg shadow-slate-400 px-2 py-2  mt-2 m-2 mr-8">

                        <div className="px-2">
                            <div className="absolute z-20 ml-4 mt-1">
                                <h1 className="left-0 text-xl font-bold text-black-RS mb-1">Tecnologías</h1>
                                <div className="col-start-1 col-end-3 ">
                                    <div className="items-center sm:flex">
                                        <button type="button" onClick={() => { setShowAddModal(!showAddModal); setValue("techName", ""); setValue("categoryTechnologie", "") }} className="bg-greenButton hover:bg-primary-700 text-white text-sm  py-2 px-4 rounded inline-flex items-center">
                                            <span>Agregar</span>
                                            <PlusCircleIcon className="w-8 h-8 ml-2"></PlusCircleIcon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="static px-2 mt-2 m-2">
                                <Table columns={columns} title="tecnología" filteredItems={""} data={listTechnologies} filterBy="tecnologia" />
                            </div>
                        </div>
                        {/*Here we show add modal compononent*/}
                        <Modal isVisible={showAddModal} onClose={() => { setShowAddModal(false) }} handleSubmit={handleSubmit} onSaveOptions={onSaveAddOptions} showCloseIcon={false} >
                            {/*Button to close modal*/}
                            <div className="flex justify-end m-2">
                                <button className="cursor-pointer text-black-400 hover:text-gray-600 transition duration-150 ease-in-out rounded ring-2 focus:outline-none ring-gray-600" onClick={() => setShowAddModal(false)} aria-label="close modal" role="button">
                                    <XMarkIcon className="text-red-RS h-6 w-6" />
                                </button>
                            </div>
                            <AddTechComponent />
                        </Modal>

                        {/*Here we show edit modal compononent*/}
                        <Modal isVisible={showEditModal} onClose={() => { setShowEditModal(false) }} handleSubmit={handleSubmit} onSaveOptions={onSaveEditOptions} showCloseIcon={false} >
                            {/*Button to close modal*/}
                            <div className="flex justify-end m-2">
                                <button className="cursor-pointer text-black-400 hover:text-gray-600 transition duration-150 ease-in-out rounded ring-2 focus:outline-none ring-gray-600" onClick={() => setShowEditModal(false)} aria-label="close modal" role="button">
                                    <XMarkIcon className="text-red-RS h-6 w-6" />
                                </button>
                            </div>
                            <EditTechComponent />
                        </Modal>

                        {/*Here we show delete modal compononent*/}
                        <Modal isVisible={showDeleteModal} onClose={() => { setShowDeleteModal(false) }} handleSubmit={handleSubmit} onSaveOptions={onSaveDeleteOptions} showCloseIcon={false}>
                            {/*Button to close modal*/}
                            <div className="flex justify-end m-2">
                                <button className="cursor-pointer text-black-400 hover:text-gray-600 transition duration-150 ease-in-out rounded ring-2 focus:outline-none ring-gray-600" onClick={() => setShowDeleteModal(false)} aria-label="close modal" role="button">
                                    <XMarkIcon className="text-red-RS h-6 w-6" />
                                </button>
                            </div>
                            <DeleteTechComponent />
                        </Modal>

                    </div>
                </div>

            </div>

        </>
    )
}
