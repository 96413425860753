import React, { useState, useEffect } from 'react';

const Footer = () => {

    const [date, setDate] = useState();

    const getYear = () => setDate(new Date().getFullYear())

    //getdate to show always current year
    useEffect(() => {
        getYear();
    }, [])

    return (

        <footer className=" h-16 mt-4 flex flex-wrap mx-auto items-center justify-center p-4 bg-white border-gray-200 rounded-lg shadow md:px-4 md:py-6 md:h-16">
            {/*Footer inset bottom to fix position and add other style class*/}
                <div className="container mx-auto w-full m-1 ">
                    <span className="block mx-auto items-center justify-content-center text-base text-gray-500 sm:text-center dark:text-gray-400"><a href="https://www.rossmon.co.cr/" className="hover:underline">Rossmon™</a>. {date} Copyright ©  All rights reserved...
                    </span>
                </div>
            </footer>
    );
}

export default Footer;
