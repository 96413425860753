import React, { useEffect } from 'react';
import { useState } from 'react';

//import global components
import { AccordionCV } from '../../imports';

import { ObtenerIDPersona } from '../../servicios/ServicioInfoPersonal';

export default function CandidateCV({ }) {

    const jsonUsuario = sessionStorage.getItem("USER_DATA");
    const usuario = JSON.parse(jsonUsuario);
    const idUsuario = usuario.IdUsuario;

    const [idCurriculum, setIdCurriculum] = useState();

    //function to get id from candidate
    async function getPersonID() {

        const response = await ObtenerIDPersona(idUsuario);
       

        const timeout = setTimeout(() => {
            setIdCurriculum(response?.idPersona);
        }, 1000);
        return () => clearTimeout(timeout);
    }


    useState(() => {
        getPersonID();
    }, [])

    return (
        <>{idCurriculum && <AccordionCV idCurriculum={idCurriculum} setShowViewModal={false} showViewModal={false} />}
            
        </>
    );
}