import React from 'react';
import ExperienciaComponent from './Experiencia';

const Experiencia = ({ userData, setUserData, finalData = [], setstateExperiencia, allowEdit = false, listExperienceByPerson = [], setListExperienceByPerson, experienceDataEdit, setExperienceDataEdit, isFirstLoadExperience, setIsFirstLoadExperience, idCurriculum }) => {

    //validations
    const validationMessages = {
        nameCompany: [
            {
                type: 'required',
                message: 'Favor ingresar el nombre de la compania',
            },
            {
                type: 'maxLength',
                message: 'El nombre de la compa�ia no puede contener mas de 150 caracteres ',
            },
            {
                type: 'pattern',
                message: 'Solo se admiten letras y n�meros.',
            }
        ],
        positionHeld: [
            {
                type: 'required',
                message: 'Favor ingresar el puesto desempenado',
            },
            {
                type: 'maxLength',
                message: 'El puesto desempe�ado no puede contener mas de 30 caracteres ',
            },
            {
                type: 'pattern',
                message: 'Solo se admiten letras.',
            }
        ],
        startDate: [
            {
                type: 'required',
                message: 'Favor ingresar la fecha de ingreso',
            },
        ],
        finishDate: [
            {
                type: 'required',
                message: 'Favor ingresar la fecha de salida',
            },
        ],
        functionsPerformed: [
            {
                type: 'required',
                message: 'Favor ingresar las funciones desempe�adas',
            },
            {
                type: 'pattern',
                message: 'Solo se admiten letras y n�meros.',
            }
        ],
    }
    return (
        <div>
            <ExperienciaComponent validationMessages={validationMessages} userData={userData} setUserData={setUserData} finalData={finalData} setstateExperiencia={setstateExperiencia} allowEdit={allowEdit} listExperienceByPerson={listExperienceByPerson} setListExperienceByPerson={setListExperienceByPerson} experienceDataEdit={experienceDataEdit}
                setExperienceDataEdit={setExperienceDataEdit} isFirstLoadExperience={isFirstLoadExperience} setIsFirstLoadExperience={setIsFirstLoadExperience} idCurriculum={idCurriculum}/>
        </div>
    );
};
export default Experiencia;