import React from 'react';

const ListViewerCard = ({ title, data, setModalListView }) => {

    return (
        <>
            <div className="container mx-auto mx-2 px-2 overflow-hidden">
                <div className="mx-2 px-2 p-1">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">Información detallada {title}</h3>
                </div>
                <div className="mt-6 border-t border-gray-100 mx-2 px-2 p-1">
                    <dl className="divide-y divide-gray-100 ">
                    {
                        data?.dataHeader &&
                       <>
                            {
                                Object.keys(data?.dataHeader).map((key, i) => {
                                    return (
                                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0" key={i}>
                                            <dt className="text-bold font-bold text-black-RS">{key} </dt>
                                            <dd className="mt-1 text-sm font-medium font-sans text-black-RS sm:col-span-2 sm:mt-0">{data?.dataHeader[key]}</dd>
                                         </div>
                                    )
                                })
                            }
                       </>
                    }
                    {
                        data?.dataBody &&
                        <div className=" mt-6 py-1 w-full font-sans left-0">
                            <>
                            {
                                Object.keys(data?.dataBody).map((key, i) => {
                                    return (
                                        <div key={key + i}>
                                            {data?.dataBody[key] !== "" &&
                                                 <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0" key={i}>
                                                    <dt className="text-bold font-bold text-black-RS">{key} </dt>
                                                    <dd className="mt-1 text-sm leading-6 font-sans text-black-RS sm:col-span-2 sm:mt-0">{data?.dataBody[key]}</dd>
                                                 </div>
                                            }
                                        </div>
                                    )
                                })
                               }
                            </>
                        </div>
                    }
                    {
                        data?.dataFooter &&
                            <div className=" mt-6 py-1 w-full font-sans left-0">
                            {
                                Object.keys(data?.dataFooter).map((key, i) => {
                                    return (

                                            <div key={key + i}>
                                                {data?.dataFooter[key] !== "" &&
                                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0" key={i}>
                                                        <dt className="text-bold font-bold text-black-RS">{key} </dt>
                                                        <dd className="mt-1 text-sm leading-6 font-sans text-black-RS sm:col-span-2 sm:mt-0">{data?.dataFooter[key]}</dd>
                                                    </div>
                                                }
                                            </div>
                                    )
                                })}
                        </div>

                        }
                        {data?.dataHeader.Proyecto &&
                        <>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-bold font-bold text-black-RS">Fecha Inicio</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{data?.dataForGlobalList?.startDate}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-bold font-bold text-black-RS">Fecha Finalización</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{data?.dataForGlobalList?.finishDate}</dd>
                            </div>
                        </>}
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            {data?.dataForGlobalList?.functionsPerformed ? <dt className="text-bold font-bold text-black-RS">Funciones desempeñadas</dt> :
                                <dt className="text-bold font-bold text-black-RS">Descripción del proyecto</dt>}
                            
                            <dd className=" break-all mt-1 text-sm leading-6 text-gray-700 md:col-span-2 sm:mt-0">{data?.dataForGlobalList?.functionsPerformed || data?.dataForGlobalList?.projectDescription}</dd>
                    </div>

                    
                    </dl>
                 
                </div>
                <div className="py-8 flex flex-col justify-center items-center">
                    <button
                        type="button"
                        onClick={() => setModalListView(false)}
                        className="py-2 px-4 mx-2 my-5 text-white bg-black-RS  hover:bg-primary-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                        Cerrar
                    </button>
                </div>
            
            </div>
        </>
    )
}

export default ListViewerCard;