import { ProcesarDatosApi } from "../api/ApiFetch";

const controladorProfesionlesTecnologia = "profesionalestecnologia";



//-------------------------------------------------------------------------------------------------
export const ObtenerProfesionalesPorTecnologia = async () => {
    const url = `${controladorProfesionlesTecnologia}/ObtenerProfesionalesPorTecnologia`;
    return await ProcesarDatosApi('GET', url);
}
export const ObtenerProfesionalesPorCadaTecnologia = async (idTecnologia) => {
    const url = `${controladorProfesionlesTecnologia}/ObtenerProfesionalesPorCadaTecnologia/${idTecnologia}`;
    return await ProcesarDatosApi('GET', url);
}


//------------------------------------------------------------------------------------------------