import { ProcesarDatosApi } from "../api/ApiFetch";

const controladorContacto = "contactos";



//-------------------------------------------------------------------------------------------------
export const ObtenerInfoContacto = async () => {
    const url = `${controladorContacto}/ObtenerInfoContacto`;
    return await ProcesarDatosApi('GET', url);
}
export const ObtenerInfoContactoPorId = async (idUsuario) => {
    const url = `${controladorContacto}/ObtenerContactoPorId/${idUsuario}`;
    return await ProcesarDatosApi('GET', url);
}

export const EditarInfoContacto = async (data) => {
    const url = `${controladorContacto}/EditarInfoContacto`;
    return await ProcesarDatosApi('PUT', url, data);
}
export const DesactivarContacto = async (data) => {
    const url = `${controladorContacto}/DesactivarContacto`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const AgregarContacto = async (data) => {
    const url = `${controladorContacto}/AgregarContacto`;
    return await ProcesarDatosApi('POST', url, data);
}
//------------------------------------------------------------------------------------------------