import { ProcesarDatosApi } from "../api/ApiFetch";
const controlador = "contacto";

export const ObtenerContactoPorId = async (idContacto) => {
    const url = `${controlador}/ObtenerContactoPorId/${idContacto}`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerInfoContacto = async () => {
    const url = `${controlador}/ObtenerInfoContacto`;
    return await ProcesarDatosApi('GET', url);
}