import React from 'react';
import RegiserComponent from './Register-Component';

//import services
import { AgregarUsuario } from '../../../servicios/ServicioRegistro';

const Register = () => {

    //validations
    const validationMessages = {
        email: [
            {
                type: 'required',
                message: 'Favor ingresar el correo',
            },
            {
                type: 'maxLength',
                message: 'El correo no puede contener mas de 150 caracteres ',
            },
            {
                type: 'pattern',
                message: 'Correo electrónico inválido',
            }
        ],
        password: [
            {
                type: 'required',
                message: 'Favor ingresar la contraseña',
            },
            {
                type: 'minLength',
                message: 'La contraseña debe contener al menos 6 caracteres ',
            },
            {
                type: 'maxLength',
                message: 'La contraseña no puede contener mas de 32 caracteres ',
            }
        ],
        repeatPassword: [
            {
                type: 'required',
                message: 'Favor ingresar la contraseña',
            },
            {
                type: 'minLength',
                message: 'La contraseña debe contener al menos 6 caracteres ',
            },
            {
                type: 'maxLength',
                message: 'La contraseña no puede contener mas de 32 caracteres ',
            },
            {
                type: 'validate',
                message: 'Las contraseñas son distintas ',
            }
        ],
        name: [
            {
                type: 'required',
                message: 'Favor ingresar su nombre',
            },
            {
                type: 'maxLength',
                message: 'El nombre no puede contener mas de 25 caracteres ',
            }
        ],
        lastName: [
            {
                type: 'required',
                message: 'Favor ingresar el primer apellido',
            },
            {
                type: 'maxLength',
                message: 'El primer apellido no puede contener mas de 25 caracteres ',
            }
        ],
        secondSurname: [
            {
                type: 'required',
                message: 'Favor ingresar el segundo apellido',
            },
            {
                type: 'maxLength',
                message: 'El primer segundo no puede contener mas de 25 caracteres ',
            }
        ],
        phone: [
            {
                type: 'required',
                message: 'Favor ingresar el teléfono',
            },
            {
                type: 'maxLength',
                message: 'El número teléfónico no puede contener mas de 15 caracteres ',
            }
        ]

    }

    //function to save user
    async function AgregaUsuario(data) {
        const response = await AgregarUsuario(data);

        return response;
    }

    return (
        <div>
            <RegiserComponent AgregaUsuario={AgregaUsuario} validationMessages={validationMessages} />
        </div>
    );
};

export default Register;