import React from 'react';
import ReporteGradoAcademico from './ReporteGradoAcademico';

const ReporteGrado_Academico = () => {


    return (
        <div>
            <ReporteGradoAcademico />
        </div>
    );
};

export default ReporteGrado_Academico;