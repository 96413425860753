import { ProcesarDatosApi } from "../api/ApiFetch";
const controladorProyecto = "proyecto";

export const AgregarProyecto = async (data) => {
    const url = `${controladorProyecto}/AgregarProyectos`;
    return await ProcesarDatosApi('POST', url, data);
}

export const AgregarProyectoIndividual = async (data) => {
    const url = `${controladorProyecto}/AgregarProyecto`;
    return await ProcesarDatosApi('POST', url, data);
}

export const ObtenerProyectosPorIdPersona = async (idPersona) => {
    const url = `${controladorProyecto}/ObtenerProyectosPorIdPersona/${idPersona}`;
    return await ProcesarDatosApi('GET', url);
}

export const EditarProyecto = async (data) => {
    const url = `${controladorProyecto}/EditarProyecto`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const EliminarProyecto = async (idProyecto) => {
    const url = `${controladorProyecto}/EliminarProyecto/${idProyecto}`;
    return await ProcesarDatosApi('DELETE', url);
}