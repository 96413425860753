import React, { useState, useCallback, useEffect, useContext } from 'react';
//import icons from heroicons
import { TrashIcon, PencilSquareIcon, EyeIcon, DocumentTextIcon, DocumentIcon, ListBulletIcon } from '@heroicons/react/24/outline';
import AlertContext from '../../GlobalComponents/Alert/contexts/alertContext';


//import global components
import { Modal, ModalActionConfirm, DocumentViewer, Modaldata, ListViewCard } from '../../../imports';

const Card = ({ id, data, list, setList, setCurrentIdCard, setShowModal, setValuesById, setShowModaldata, setValuesByIdListPerson, allowEdit, listByPerson, setListEducationByPerson, userCompleteCV, deleteElementCV, setActionTypeConfirmModal, title }) => {

    const [isDeleted, setIsDeleted] = useState(false);

    const alertCtx = useContext(AlertContext);
    const [modalConfirmVisible, setModalConfirmVisible] = useState(false);

    const [modalFileUser, setModalFileUser] = useState(false);

    const [modalListView, setModalListView] = useState(false);

    const showModal = event => {
        const id = event.currentTarget.id;

        if (list[id]) {
            setValuesById(list[id])
        }
      
        setCurrentIdCard(id);
        setActionTypeConfirmModal("Editar");
        setShowModal(true);
    }

    const showModaldata = event => {
        const id = event.currentTarget.id;

        if (list[id]) {
            setValuesById(list[id])
        }

        setCurrentIdCard(id);
        setActionTypeConfirmModal("Ver");
        setShowModal(true);
    }


    const deleteCard = async () => {
        const idCard = id

        if (userCompleteCV === "true") {


            let keys = Object.values(data?.dataID);
        
            const response = await deleteElementCV(keys[0]);
            
            if (response.indicador == 0) {
                alertCtx.success(response.mensaje);
                setModalConfirmVisible(false);
            }
        }

        //avoid error when list only have one element
        if (list?.length !== 1) {

            let copy = [];
            copy = [...list]

            copy.splice(idCard, 1);

            setList(copy);
        } else {
            if (list) {

                const array = list.forEach((item) => {

                    list.splice(item, 1);
                    
                });
                if (array === undefined) {
                    setList([])
                }

            }

        }
       
      
         

    }

    useEffect(() => {
        if (isDeleted && userCompleteCV === "false") {
            deleteCard();
        }

        return (() => {
            setIsDeleted(false);
        })
    },[list, isDeleted])
    
    return (
        <>       
            {/*Here we show view confirm delete modal compononent*/}
            <Modal isVisible={modalConfirmVisible} onClose={() => { setModalConfirmVisible(false) }} handleSubmit={() => null} onSaveOptions={null} showFotter={false} showCloseIcon={false}>
                <ModalActionConfirm onSaveAction={deleteCard} actionType={""} setshowConfirmModal={setModalConfirmVisible} />
            </Modal>

            {/*Here we show view document modal compononent*/}
            <Modal isVisible={modalFileUser} onClose={() => { setModalFileUser(false) }} handleSubmit={() => null} onSaveOptions={null} showFotter={false} showCloseIcon={true} insideContainer={true}>

              
                {data?.dataForGlobalList?.fileExtensionValue?.startsWith('image') ?
                    <div className="py-8 flex flex-col justify-center items-center">
                        <h1 className="font-bold font-sans text-base">Documento ingresado</h1>
                        <div className="flex items-center justify-center w-full px-4 py-4">
                            <label className="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg py-2">
                                <DocumentViewer typeView='web' filePath={data?.dataForGlobalList?.filePathValue} fileName={data?.dataForGlobalList?.fileNameValue} fileExtension={data?.dataForGlobalList?.fileExtensionValue} />
                            </label>
                        </div>

                        <button
                            type="button"
                            onClick={() => setModalFileUser(!modalFileUser)}
                            className="py-2 px-4 mx-2 my-5 text-white bg-black-RS  hover:bg-primary-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                            Cerrar
                        </button>
                    </div>
                    : <DocumentViewer typeView='pdf' filePath={data?.dataForGlobalList?.filePathValue} fileName={data?.dataForGlobalList?.fileNameValue}  fileExtension={data?.dataForGlobalList?.fileExtensionValue } />
                    }
            </Modal>

            {/*Here we show view list details modal compononent*/}
            <Modal isVisible={modalListView} onClose={() => { setModalListView(false) }} handleSubmit={() => null} onSaveOptions={null} showFotter={false} showCloseIcon={true} insideContainer={true }>
                <div key={data?.dataHeader[0] + 1}>
                    <ListViewCard title={title} data={data} setModalListView={setModalListView} />
                </ div>
            </Modal>

            {/*each card contains props title and data*/}
            <div className=" mt-9 block w-full md:w-96 rounded-lg bg-white text-left px-1 shadow-lg text-base text-black-RS font-sans border">
                <div className="container relative  items-center border-b-2 border-neutral-100 text-base p-2 ">
                    {
                        data?.dataHeader &&
                        <div className=" mt-6 py-1 w-full font-sans left-0">
                        {
                                    Object.keys(data?.dataHeader).map((key, i) => {
                                        return(
                                            <div className="flex flex-row w-full m-1" key={i}>
                                                <h1 className="w-1/2 text-bold font-bold text-black-RS">{key}: </h1>
                                                <p className="w-1/2 text-truncate hover:text-ellipsis overflow-hidden text-sm font-sans text-black-RS ml-1"> {data?.dataHeader[key]}</p>
                                            </div>
                                        )
                                })}
                        </div>
                        }
                    <div className="absolute inset-y-0 right-0 mx-8 mt-1">
                        {data?.dataForGlobalList?.fileExtensionValue ?
                            <button
                                id={id}
                                type="button"
                                onClick={() => setModalFileUser(!modalFileUser)}
                                className=" rounded h-9 px-2.5 pt-1 pb-2 text-sm font-medium uppercase leading-normal text-white shadow transition duration-150 ease-in-out hover:bg-primary-600 hover:-translate-y-1">
                                <DocumentTextIcon className="h-6 w-6 text-grayDark-RS" />
                            </button> : null}
                        {data?.dataHeader.Proyecto || data?.dataHeader.Empresa ?
                            <button
                                id={id}
                                type="button"
                                onClick={() => setModalListView(true)}
                                className=" rounded h-9 px-2.5 pt-1 pb-2 text-sm font-medium uppercase leading-normal text-white shadow transition duration-150 ease-in-out hover:bg-primary-600 hover:-translate-y-1">
                                <ListBulletIcon className="h-6 w-6 text-grayDark-RS" />
                            </button>: null}
                            <button
                                disabled={allowEdit}
                                id={id}
                                type="button"
                                onClick={showModal}
                                className=" rounded h-9 px-2.5 pt-1 pb-2 text-sm font-medium uppercase leading-normal text-white shadow transition duration-150 ease-in-out hover:bg-primary-600 hover:-translate-y-1">
                                <PencilSquareIcon className="h-6 w-6 text-grayDark-RS" />
                            </button>
                            <button
                                disabled={allowEdit}
                                id={id}
                                type="button"
                                onClick={() => { setIsDeleted(true); if(userCompleteCV === "true"){ setModalConfirmVisible(true);} } }
                                className="rounded h-9 px-2.5 pt-1 pb-2 text-sm font-medium uppercase leading-normal text-white shadow transition duration-150 ease-in-out hover:bg-primary-600  hover:-translate-y-1">
                                <TrashIcon className="h-6 w-6 text-red-RS" />
                            </button>
                        </div>
                    </div>
                    <div className="p-2">
                    {
                        data?.dataBody &&
                        <div className="font-sans left-0 w-full ">
                            {
                                    Object.keys(data?.dataBody).map((key, i) => {
                                    return (
                                        <div className="flex flex-row w-full p-1" key={i}>
                                            <h1 className="w-1/2 text-bold font-bold text-black-RS">{key}: </h1>
                                            <p className="w-1/2 text-truncate overflow-hidden text-sm font-sans text-black-RS ml-1"> {data?.dataBody[key]}</p>
                                        </div>
                                    )
                                })}
                        </div>
                    }
                    </div>
                    <div className="border-t-2 border-neutral-100 py-3 px-1 dark:border-neutral-600 dark:text-neutral-50">
                    {
                        data?.dataFooter &&
                        <div className="mb-2 font-sans left-0 w-full">
                            {
                                    Object.keys(data?.dataFooter).map((key, i) => {
                                    return (
                                        <div className="flex flex-row w-full p-1" key={i}>
                                            <h1 className="w-1/2 text-bold font-bold text-black-RS m-1">{key}:</h1>
                                            <p className="w-1/2 text-truncate overflow-hidden text-sm font-sans text-black-RS m-1">{data?.dataFooter[key]}</p>
                                        </div>
                                    )
                                })}
                        </div>

                    }
                </div>
                </div>
      
        </>
    )
}

export default Card;