import React, { useEffect, useState, useMemo, useCallback } from "react";
import DataTable from "react-data-table-component"
import { useForm } from 'react-hook-form';
//import icons from heroicons
import { MagnifyingGlassIcon, XMarkIcon, ArrowPathIcon } from '@heroicons/react/24/outline';

const Table = ({ columns, title, data, filterBy, showFilter, selectable, setSelectedRows }) => {

    //use react hook form to get values and make validations
    const { register, getValues, watch, resetField, setError, setValue, handleSubmit, formState } = useForm({
        //this mode will get all type of events to trigger validations
        mode: "all",
        defaultValues: {
            searchText: '',
        }
    });

    //filter input text
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    //get selected row event
    const handleRowSelected = useCallback(state => {
        
        setSelectedRows(state.selectedRows);
    }, []);

    //here filter by parameter when user type on input
    const filteredItems = showFilter ? data.filter(item => item[filterBy]?.toLowerCase().includes(filterText.toLowerCase()) ) : data;


    //loading animation
    const [pending, setPending] = useState(true);
    const [rows, setRows] = useState([]);

    const handleChange = (e) => {
        setFilterText(e.target.value)
    };

    const FilterComponent = ({ onClear, filterText }) => {
        return(
        <div className="rigth-0">
            <div className="relative flex items-center">
                <MagnifyingGlassIcon className="h-5 w-5 absolute pointer-events-none text-dark-500 inset-y-2 right-0 mr-2.5" />
                <input type="text"
                        name="searchText"
                        {...register("searchText")}
                        onChange={handleChange}
                        id="searchText"
                        className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS " placeholder="search"
                    />

             
                </div>
                <div>
                    <button className="bg-red-RS text-white text-sm  py-2 px-4 rounded inline-flex items-center">
                        <XMarkIcon className="w-8 h-8 ml-2"></XMarkIcon>
                    </button>
                </div>
            </div>
        )
    }

    const handleClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
            setValue("searchText", '');
        }
    };

    const subHeaderComponentMemo = useMemo(() => {
       
        
        return (
            <div className="flex flex-row mt-8 mb-4">
                <div className="relative flex items-center">
                    <div>
                        <MagnifyingGlassIcon className="h-5 w-5 absolute pointer-events-none text-dark-500 inset-y-2 right-0 mr-2.5" />
                        <input type="text"
                            name="searchText"
                            {...register("searchText", { onChange: handleChange })}
                            id="searchText"
                            className="text-gray-900 sm:text-sm block w-full p-2.5 mr-2 rounded border-solid border-2 border-grayDark-RS " placeholder={"Buscar por " +title}
                        />
                    </div>
               
                 
                </div>
                <div>
                    <button onClick={handleClear} className="bg-red-RS text-white text-sm  p-2.5 rounded inline-flex items-center hover:animate-pulse">
                        <XMarkIcon className="w-6 h-6"></XMarkIcon>
                    </button>
                </div>
            </div>
        );
    }, [filterText, resetPaginationToggle]);

    //change language text
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    //  Internally, customStyles will deep merges your customStyles with the default styling.
    let customStyles = {
        rows: {
            style: {
                minHeight: '72px', // override the row height
                backgroundColor: '#F2F1F0',
            },
        },
        headCells: {
            style: {
                paddingLeft: '4px', // override the cell padding for head cells
                paddingRight: '4px',

                'background-color': '#DCEBEC',
                'font-weight': 'bold',
                'font-family': "Work Sans",
                'font-style': 'normal',
                'font-size': '17px',
            },
        },
        cells: {
            style: {

                paddingLeft: '4px', // override the cell padding for data cells
                paddingRight: '4px',
                'font-family': "Work Sans",
                'font-style': 'normal',
                'font-size': '15px',
            },
        },

    };
   
    const CustomLoader = () => {
        return (
           <ArrowPathIcon className="animate-spin h-8 w-8 mr-3 text-red-RS inset-y-2 right-0"></ArrowPathIcon>
        )
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            setRows(filteredItems);
            setPending(false);
        }, 1000);
        return () => clearTimeout(timeout);
    }, [])

    return (
        <>
            {selectable ? <DataTable
                //title={title}
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                subHeader
                subHeaderComponent={showFilter ? subHeaderComponentMemo : null}
                customStyles={customStyles}
                paginationComponentOptions={paginationComponentOptions}
                noDataComponent="No hay datos para mostrar"
                persistTableHead
                selectableRows
                onSelectedRowsChange={handleRowSelected}
                progressPending={pending}
                progressComponent={<CustomLoader />}
            >
            </DataTable> :
                <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination
                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    subHeader
                    subHeaderComponent={showFilter ? subHeaderComponentMemo : null}
                    customStyles={customStyles}
                    paginationComponentOptions={paginationComponentOptions}
                    noDataComponent="No hay datos para mostrar"
                    persistTableHead
                    progressPending={pending}
                    progressComponent={<CustomLoader />}
                >
                </DataTable>
            }
           
        </>
    )
}

export default Table;