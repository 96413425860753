import React from 'react';
import ReportePersonas from './ReportePersonas';

const ReportePersonasTrabajando = () => {


    return (
        <div>
            <ReportePersonas />
        </div>
    );
};

export default ReportePersonasTrabajando;