import React, { useEffect,useState,useRef} from "react";
//import icons from heroicons
import { CheckIcon,XMarkIcon } from '@heroicons/react/24/outline';

const Stepper = ({ steps, currentStep, stateInfoPersonal, stateEducacion, stateTech }) => {

    const[newStep, setNewStep] = useState([]);
    const stepRef = useRef();


    const updateStep = (stepNumber, steps) => {
        const newSteps = [...steps]
        let count = 0;

        while (count < newSteps.length) {
            //current steps
            if (count === stepNumber) {
                newSteps[count] = {
                    ...newSteps[count],
                    highlighted: true,
                    selected: true,
                    completed: true,
                }
                count++;
            }
            //steps completed
            else if (count < stepNumber) {
                newSteps[count] = {
                    ...newSteps[count],
                    highlighted: false,
                    selected: true,
                    completed: true,
                }
                count++;
            }
            //steps pending
            else {
                newSteps[count] = {
                    ...newSteps[count],
                    highlighted: false,
                    selected: false,
                    completed: false,
                }
                count++;
            }
        }
        return newSteps;
    };
    useEffect(() => {
        const stepsState = steps.map((step, index) =>
        //Creacion de objeto
            Object.assign({}, {
                description:step,
                completed:false,
                highlighted: index === 0 ? true : false,
                selected: index === 0 ? true : false,


            })
        );
        stepRef.current = stepsState;
        const current = updateStep(currentStep - 1, stepRef.current);
        setNewStep(current);
    }, [steps, currentStep]);



    const displaySteps = newStep.map((step, index) => {
        let showXMarkIcon = false;
        if (step.completed) {
            if (index === 0 && !stateInfoPersonal) {
                showXMarkIcon = true;
            } else if (index === 1 && !stateEducacion) {
                showXMarkIcon = true;
            } else if (index === 2 && !stateTech) {
                showXMarkIcon = true;
            }
        }

        return (
            <div key={index} className={index !== newStep.length - 1 ? "w-full flex items-center" : "flex items-center"}>
                <div className=" w-66 relative flex flex-col items-center text-black">
                    <div
                        className={`w-5 h-5 rounded-full transition duration-500 ease-in-out flex items-center justify-center ${showXMarkIcon ? (
                            "bg-red-RS text-white font-bold") :
                            (step.selected ? "bg-zinc-900 text-white font-bold"
                                : "bg-grayDark-RS text-white font-bold")
                            }`}
                    >
                        {showXMarkIcon && step.completed ? (
                            <XMarkIcon className="text-white font-bold text-xl" />
                        ) : (
                                step.completed ? <CheckIcon className="rounded-full border-none bg-greenButton text-white font-bold text-xl" /> : index + 1
                        )}
                    </div>
                    <div
                        className={`absolute my-4 top-0 text-center mt-16 w-32 text-xs font-medium uppercase hidden md:block ${step.highlighted ? "text-gray-900 font-bold" : "text-grayDark-RS"
                            }`}
                    >
                        {step.description}
                    </div>
                </div>
                <div className="flex-auto border-t-2 border-grayLight-RS"></div>
            </div>
        );
    });
        
    return (
        <div className="mx-4 p-4 flex justify-between items-center">
            { displaySteps }
            
        </div>
    );

};


export default Stepper;