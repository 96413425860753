import { useEffect, useState, useCallback, useRef, useContext } from "react";
import React from "react";
import { useForm, Controller } from 'react-hook-form';
import AlertContext from '../../../GlobalComponents/Alert/contexts/alertContext';

//import global components
import { Card, Modal, ModalActionConfirm } from '../../../../imports';

//import icons from heroicons
import { EnvelopeIcon, UserIcon, PhoneIcon, MapPinIcon, ChatBubbleLeftIcon, DocumentTextIcon, CheckIcon, XMarkIcon, IdentificationIcon } from '@heroicons/react/24/outline';

//import services
import { ObtenerEspecialidades, ObtenerEstadoCivil, EditarPersona } from '../../../../servicios/ServicioInfoPersonal';
import list from "../../../../../node_modules/postcss/lib/list";

import PhoneInput from 'react-phone-input-2';
import '../../../../../src/customInput.css';

import { Switch } from '@headlessui/react'

export default function InfoPersonal({ validationMessages, InfoPersonalData, setInfoPersonalData, finalData, stateInfoPersonal, setstateInfoPersonal, allowEdit, listInfoByPerson, InfoPersonalDataEdit, setInfoPersonalDataEdit, idCurriculum, isFirstLoadPersonalInformation, setIsFirstLoadPersonalInformation, isFirstLoadUserInfo, setIsFirstLoadUserInfo, listInfoByUser }) {


    const isMounted = useRef(true);
    //use alert context
    const alertCtx = useContext(AlertContext);

    //validate if user already complete cv
    const userCompleteCV = sessionStorage.getItem("USER_COMPLETE_CV");

    //state to manage working in Rossmon
    const [enabled, setEnabled] = useState(false);

    //here we pass a default input property to save in userData
    //all inputs use this same event in 'onchange'
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (InfoPersonalData) {
            setInfoPersonalData({ ...InfoPersonalData, [name]: value })
        }
        validateForm();

    };

    //use react hook form to get values and make validations
    const { register, getValues, trigger, unregister, watch, setValue, formState, control, resetField } = useForm({
        //this mode will get all type of events to trigger validations
        mode: "all",
        defaultValues: {
            firstName: '',
            secondName: '',
            surname: '',
            secondSurname: '',
            identification: '',
            birthDate: '',
            maritalStatus: '',
            email: '',
            firstPhoneNumber: '',
            secondPhoneNumber: '',
            residence: '',
            linkedin: '',
            otherSocialMedia: '',
            specialty: '',
            personalSummary: '',
            workInRossmon: false,
        },

    });

    const { errors } = formState;
    const { isValid } = formState;

    const handleChangeUserCompleteCV = (e) => {
        const { name, value } = e.target;

        if (InfoPersonalDataEdit) {
            setInfoPersonalDataEdit({ ...InfoPersonalDataEdit, [name]: value })
        }

    };

    const handleSwitch = (e) => {

        //setEnabled(!enabled);
        if (userCompleteCV === "true") {
            if (InfoPersonalDataEdit) {
                setInfoPersonalDataEdit({
                    ...InfoPersonalDataEdit, "workInRossmon": !enabled
                })
            }
        } else {
            if (InfoPersonalData) {
                setInfoPersonalData({ ...InfoPersonalData, "workInRossmon": !enabled })
            }

        }
    }


    //use modal and show for save
    const [showConfirmSaveModal, setShowConfirmSaveModal] = useState(false);
    const [actionTypeConfirmModal, setActionTypeConfirmModal] = useState("");

    //set values from onchange in maritalStatus
    const selectMaritalStatus = (e) => {
        e.preventDefault();
        const maritalStatus = (e.target.value);

    }
    const jsonUsuario = sessionStorage.getItem("USER_DATA");
    const usuario = JSON.parse(jsonUsuario);
    const idUsuario = usuario.IdUsuario;


    //method to edit project from person by id
    const EditarInfoPersonalPersona = async (e) => {

        const email = getValues("email");
        const firstName = getValues("firstName");
        const secondName = getValues("secondName");
        const surname = getValues("surname");
        const secondSurname = getValues("secondSurname");
        const identification = getValues("identification");
        const birthDate = getValues("birthDate");
        const firstPhoneNumberValue = getValues("firstPhoneNumber");
        const secondPhoneNumberValue = getValues("secondPhoneNumber");        
        const residence = getValues("residence");
        const maritalStatus = getValues("maritalStatus");
        const linkedin = getValues("linkedin");
        const otherSocialMedia = getValues("otherSocialMedia");
        const personalSummary = getValues("personalSummary");
        const specialty = getValues("specialty");
        
        const dataPersona = {
            idPersona: idCurriculum,
            correoElectronico: email,
            primerNombre: firstName,
            segundoNombre: secondName,
            primerApellido: surname,
            segundoApellido: secondSurname,
            identificacion: identification,
            fechaNacimiento: birthDate,
            telefono1: firstPhoneNumberValue,
            telefono2: secondPhoneNumberValue,            
            lugarResidencia: residence,
            idEstadoCivil: parseInt(maritalStatus, 10),          
            linkedIn: linkedin,
            otraRedSocial: otherSocialMedia,
            trabajaEnRossmon: enabled,
            resumenPersonal: personalSummary,
            idUsuario: parseInt(idUsuario, 10),
            idEspecializacion: parseInt(specialty, 10),            
        };

        if (isValid) {

            const response = await EditarPersona(dataPersona);

            if (response.indicador === 0) {

                alertCtx.success(response.mensaje);

                setShowConfirmSaveModal(false);
            }
        } else {
            alertCtx.error("¡Error al editar la información!");
        }

    }

    

    //custom phone inputs
    const [firstPhoneNumber, setFirstPhoneNumber] = useState('');
    const [secondPhoneNumber, setSecondPhoneNumber] = useState('');

    const [isValidFirstPhoneNumber, setIsValidFirstPhoneNumber] = useState(true)

    //use to get validations from props and later iterate
    const [validations, setValidations] = useState([]);

    //use to get maritalStatus options
    const [maritalStatusOptions, setMaritalStatusOptions] = useState([]);

    //use to get specialty options
    const [specialtyOptions, setSpecialtyOptions] = useState([]);

    //function to save 'especialidades' in list
    async function getEspecialties() {
        const response = await ObtenerEspecialidades();

        if (response !== undefined) {

            setSpecialtyOptions(response);
        }
    }

    //function to save 'estadoCivil' in list
    async function getMaritalStatus() {
        const response = await ObtenerEstadoCivil();

        if (response !== undefined) {

            setMaritalStatusOptions(response);
        }
    }

    //unregister controls from select and then set again values to focus input
    const keepValuesFromList = useCallback(() => {
        if (getValues("maritalStatus")) {
            unregister(["maritalStatus"])

            setValue("maritalStatus", InfoPersonalData?.maritalStatus);
        }

        if (getValues("firstPhoneNumber")) {
            unregister(["firstPhoneNumber"])

            setValue("firstPhoneNumber", InfoPersonalData?.firstPhoneNumber);
        }

        if (finalData?.specialty) {
            const timeout = setTimeout(() => {
                unregister("specialty")
                setValue("specialty", InfoPersonalData?.specialty);

            }, 1000);
            return () => clearTimeout(timeout);
        }

    }, [])

    const keepValuesFromListByPerson = () => {

        const firstName = getValues("firstName");
        const secondName = getValues("secondName");
        const surname = getValues("surname");
        const secondSurname = getValues("secondSurname");
        const identification = getValues("identification");
        const birthDate = getValues("birthDate");
        const maritalStatus = getValues("maritalStatus");
        const email = getValues("email");
        const firstPhoneNumber = getValues("firstPhoneNumber");
        const secondPhoneNumber = getValues("secondPhoneNumber");
        const residence = getValues("residence");
        const linkedin = getValues("linkedin");
        const otherSocialMedia = getValues("otherSocialMedia");
        const specialty = getValues("specialty");
        const personalSummary = getValues("personalSummary");
        const workInRossmon = getValues("workInRossmon");
        
        if (InfoPersonalDataEdit) {
            setInfoPersonalDataEdit({
                ...InfoPersonalDataEdit, ["firstName"]: firstName, ["secondName"]: secondName, ["surname"]: surname, ["secondSurname"]: secondSurname, ["identification"]: identification, ["birthDate"]: birthDate, ["maritalStatus"]: maritalStatus, ["email"]: email, ["firstPhoneNumber"]: firstPhoneNumber, ["secondPhoneNumber"]: secondPhoneNumber, ["residence"]: residence, ["linkedIn"]: linkedin, ["otherSocialMedia"]: otherSocialMedia, ["specialty"]: specialty, ["personalSummary"]: personalSummary, ["workInRossmon"]: workInRossmon                 
            })
        }
    }

    const setValuesFromUserDataEdit = () => {

        if (InfoPersonalDataEdit) {
            setValue("firstName", InfoPersonalDataEdit?.firstName)
            setValue("secondName", InfoPersonalDataEdit?.secondName)
            setValue("surname", InfoPersonalDataEdit?.surname)
            setValue("secondSurname", InfoPersonalDataEdit?.secondSurname)
            setValue("identification", InfoPersonalDataEdit?.identification)
            setValue("birthDate", InfoPersonalDataEdit?.birthDate)
            setValue("maritalStatus", InfoPersonalDataEdit?.maritalStatus)
            setValue("email", InfoPersonalDataEdit?.email)
            setValue("firstPhoneNumber", InfoPersonalDataEdit?.firstPhoneNumber)
            setValue("secondPhoneNumber", InfoPersonalDataEdit?.secondPhoneNumber)
            setValue("residence", InfoPersonalDataEdit?.residence)
            setValue("linkedin", InfoPersonalDataEdit?.linkedIn)
            setValue("otherSocialMedia", InfoPersonalDataEdit?.otherSocialMedia)
            setValue("specialty", InfoPersonalDataEdit?.specialty)
            setValue("personalSummary", InfoPersonalDataEdit?.personalSummary)
            setValue("workInRossmon", InfoPersonalDataEdit?.workInRossmon)
            setEnabled(InfoPersonalDataEdit?.workInRossmon)
        }
    }

    const setValuesFromUserData = () => {
        if (InfoPersonalData) {
            setValue("firstName", InfoPersonalData?.firstName)
            setValue("secondName", InfoPersonalData?.secondName)
            setValue("surname", InfoPersonalData?.surname)
            setValue("secondSurname", InfoPersonalData?.secondSurname)
            setValue("identification", InfoPersonalData?.identification)
            setValue("birthDate", InfoPersonalData?.birthDate)
            setValue("maritalStatus", InfoPersonalData?.maritalStatus)
            setValue("email", InfoPersonalData?.email)
            setValue("firstPhoneNumber", InfoPersonalData?.firstPhoneNumber)
            setValue("secondPhoneNumber", InfoPersonalData?.secondPhoneNumber)
            setValue("residence", InfoPersonalData?.residence)
            setValue("linkedin", InfoPersonalData?.linkedin)
            setValue("otherSocialMedia", InfoPersonalData?.otherSocialMedia)
            setValue("specialty", InfoPersonalData?.specialty)
            setValue("personalSummary", InfoPersonalData?.personalSummary)
            setValue("workInRossmon", InfoPersonalData?.workInRossmon)
            setEnabled(InfoPersonalData?.workInRossmon)
        }
    }



    const validateForm = useCallback(() => {

        let state = false;

        isValid ? state = true : state = false;

        setstateInfoPersonal(state);



    }, [isValid])




    //create custom object with list by person id to render inputs
    function customObjectByPersonList() {

        if (listInfoByPerson) {
            const startMonth = new Date(listInfoByPerson?.fechaNacimiento).getMonth() + 1;
            const startYear = new Date(listInfoByPerson?.fechaNacimiento).getFullYear();
            const startDay = new Date(listInfoByPerson?.fechaNacimiento).getDate();
            const startDateFormatted = `${startYear}-${startMonth}-${startDay}`;
            const date = new Date(startDateFormatted)

            setValue("firstName", listInfoByPerson?.primerNombre)
            setValue("secondName", listInfoByPerson?.segundoNombre)
            setValue("surname", listInfoByPerson?.primerApellido)
            setValue("secondSurname", listInfoByPerson?.segundoApellido)
            setValue("identification", listInfoByPerson?.identificacion)
            setValue("birthDate", date.toLocaleDateString('fr-CA'))
            setValue("maritalStatus", listInfoByPerson?.idEstadoCivil)

            setValue("email", listInfoByPerson?.correoElectronico)
            setValue("firstPhoneNumber", listInfoByPerson?.telefono1)
            setValue("secondPhoneNumber", listInfoByPerson?.telefono2)
            setValue("residence", listInfoByPerson?.lugarResidencia)
            setValue("linkedin", listInfoByPerson?.linkedIn)
            setValue("otherSocialMedia", listInfoByPerson?.otraRedSocial)
            setValue("specialty", listInfoByPerson?.idEspecializacion)
            setValue("personalSummary", listInfoByPerson?.resumenPersonal)
            setValue("workInRossmon", listInfoByPerson?.trabajaEnRossmon)
            setEnabled(listInfoByPerson?.trabajaEnRossmon)

        }

    }


    const fillDatauser = () => {

        setValue("firstName", listInfoByUser?.nombre)
        setValue("secondName", listInfoByUser?.segundoNombre)
        setValue("surname", listInfoByUser?.primerApellido)
        setValue("secondSurname", listInfoByUser?.segundoApellido)
        setValue("email", listInfoByUser?.correo)
        setValue("firstPhoneNumber", listInfoByUser?.telefono)

        setInfoPersonalData({
            ...InfoPersonalData, ["firstName"]: listInfoByUser?.nombre, ["secondName"]: listInfoByUser?.segundoNombre, ["surname"]: listInfoByUser?.primerApellido, ["secondSurname"]: listInfoByUser?.segundoApellido, ["email"]: listInfoByUser?.correo, ["firstPhoneNumber"]: listInfoByUser?.telefono
        })


    }


    useEffect(() => {
        getEspecialties();
        getMaritalStatus();
        //validate for candidate view or admin view
        if (userCompleteCV === "true" && isFirstLoadPersonalInformation || allowEdit === true && isFirstLoadPersonalInformation) {
            //call methods to execute services
            
            customObjectByPersonList();
            if (isFirstLoadPersonalInformation) setIsFirstLoadPersonalInformation(false)
        }

        setValidations(validationMessages);
        //validate for candidate view or admin view
        if (userCompleteCV === "true" && !isFirstLoadPersonalInformation || allowEdit === true && !isFirstLoadPersonalInformation) {
            if (InfoPersonalDataEdit) {
                setValuesFromUserDataEdit();

            }
        } else {
            if (InfoPersonalData) {
                setValuesFromUserData();
                keepValuesFromList();
            }

        }


        return (() => {
            //validate for candidate view or admin view
            if (userCompleteCV === "true" || allowEdit === true) {
                if (isFirstLoadPersonalInformation) {
                    setIsFirstLoadPersonalInformation(false);
                }
                keepValuesFromListByPerson();
            } else {
                if (stateInfoPersonal) {
                    setstateInfoPersonal(isValid)
                }
            }
            setMaritalStatusOptions([]);
            setSpecialtyOptions([]);
        })


    }, [keepValuesFromList])

    useEffect(() => {
        if (userCompleteCV === "false") {

            const timeout = setTimeout(() => {
                if (isFirstLoadUserInfo) {
                    fillDatauser();

                    setIsFirstLoadUserInfo(false);
                }
            }, 1000);
            return () => clearTimeout(timeout);
        }
    }, [listInfoByUser])


    return (
        <div className="w-full flex flex-col md:flex-row items-center">
            {/*Block to manage form input 'info personal'--*/}
            <div className={userCompleteCV === "false" ? "w-full pt-1 bg-white rounded-lg shadow" : "w-full pt-1"}>
                <div className="space-y-1 md:space-y-2">
                    {/*Here we show view confirm save modal compononent*/}
                    <Modal isVisible={showConfirmSaveModal} onClose={() => { setShowConfirmSaveModal(false) }} handleSubmit={() => null} onSaveOptions={null} showFotter={false} showCloseIcon={false}>
                        <ModalActionConfirm onSaveAction={EditarInfoPersonalPersona} actionType={actionTypeConfirmModal} setshowConfirmModal={setShowConfirmSaveModal} />
                    </Modal>

                    {/*All input will get same colum line and width*/}
                    <form className="space-y-4 md:space-y-6">
                        <div className="mt-2 flow-root">

                            {!allowEdit && userCompleteCV === "true" &&
                                <div className="float-right my-2">
                                    <button onClick={() => { trigger(); if (isValid && getValues("firstPhoneNumber").length > 0) { setActionTypeConfirmModal("Editar"); setShowConfirmSaveModal(true); } }} type="button" className="py-2 px-4 mx-2 mb-2 text-white bg-greenButton hover:bg-primary-700 font-medium rounded-lg text-sm text-center"
                                    >Actualizar</button>
                                </div>
                            }
                        </div>


                        {/*First row*/}
                        <div className="flex flex-wrap  items-center justify-center">
                            {/*Primer nombre Input*/}
                            <div className="w-full m-1 px-2 sm:w-48">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Primer nombre</label>
                                <label className="relative block">
                                    <input type="text"
                                        name="firstName"
                                        maxLength="25"
                                        {...register("firstName", { onChange: userCompleteCV === "true" ? handleChangeUserCompleteCV : handleChange, required: true, maxLength: 25, pattern: /^[a-zA-ZñÑ\s\W]/ })}
                                        id="firstName"
                                        disabled={allowEdit}
                                        className="text-gray-900 sm:text-sm block w-full p-2.5 mb-2 pl-3 pr-10 rounded border-solid border-2 border-grayDark-RS " placeholder="Primer nombre"
                                    />
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                                        <UserIcon className="h-5 w-5 pointer-events-none text-dark-500" />
                                    </span>
                                </label>
                                {/*iterate over validations and show message firstName*/}
                                {validations.firstName?.map((values) => {
                                    return (
                                        errors.firstName?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS mt-2">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>
                            {/*Segundo nombre Input*/}
                            <div className="w-full m-1 px-2  sm:w-48">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Segundo nombre</label>
                                <label className="relative block">
                                    <input type="text"
                                        name="secondName"
                                        maxLength="25"
                                        {...register('secondName', {
                                            onChange: userCompleteCV === "true" ? handleChangeUserCompleteCV : handleChange, maxLength: 25, pattern: /^[a-zA-ZñÑ\s\W]/
                                        })}
                                        id="secondName"
                                        disabled={allowEdit}
                                        className="text-gray-900 sm:text-sm block w-full p-2.5 mb-2 pl-3 pr-10 rounded border-solid border-2 border-grayDark-RS"
                                        placeholder="Segundo nombre"
                                    />
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                                        <UserIcon className="h-5 w-5 pointer-events-none text-dark-500" />
                                    </span>
                                </label>
                                {/*iterate over validations and show message name*/}
                                {validations.secondName?.map((values) => {
                                    return (
                                        errors.secondName?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS mt-1">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>
                            {/*Primer apellido Input*/}
                            <div className="w-full m-1 px-2 sm:w-64">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Primer apellido</label>
                                <label className="relative block">
                                    <input type="text"
                                        name="surname"
                                        maxLength="25"
                                        {...register('surname', { onChange: userCompleteCV === "true" ? handleChangeUserCompleteCV : handleChange, required: true, maxLength: 25, pattern: /^[a-zA-ZñÑ\s\W]/ })}
                                        id="surname"
                                        disabled={allowEdit}
                                        className="text-gray-900 sm:text-sm block w-full p-2.5 mb-2 pl-3 pr-10 rounded border-solid border-2 border-grayDark-RS"
                                        placeholder="Primer apellido"
                                    />
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                                        <UserIcon className="h-5 w-5 pointer-events-none text-dark-500" />
                                    </span>
                                </label>
                                {/*iterate over validations and show message surname*/}
                                {validations.surname?.map((values) => {
                                    return (
                                        errors.surname?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS mt-1">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>
                            {/*Segundo apellido Input*/}
                            <div className="w-full m-1 px-2 sm:w-64">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Segundo apellido</label>
                                <label className="relative block">
                                    <input type="text"
                                        name="secondSurname"
                                        maxLength="25"
                                        {...register('secondSurname', { onChange: userCompleteCV === "true" ? handleChangeUserCompleteCV : handleChange, required: true, maxLength: 25, pattern: /^[a-zA-ZñÑ\s\W]/ })}
                                        id="secondSurname"
                                        disabled={allowEdit}
                                        className="text-gray-900 sm:text-sm block w-full p-2.5 mb-2 pl-3 pr-10 rounded border-solid border-2 border-grayDark-RS "
                                        placeholder="Segundo apellido"
                                    />
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                                        <UserIcon className="h-5 w-5 pointer-events-none text-dark-500" />
                                    </span>
                                </label>
                                {/*iterate over validations and show message secondSurname*/}
                                {validations.secondSurname?.map((values) => {
                                    return (
                                        errors.secondSurname?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS mt-1">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>
                        </div>

                        {/*Second row*/}
                        <div className="flex flex-wrap mx-auto items-center justify-center">
                            {/*Identificación Input*/}
                            <div className="w-full m-1 md:w-80">
                                <label className="block text-sm font-medium text-gray-900 mb-2">Identificación</label>
                                <label className="relative block">
                                    <input type="text"
                                        name="identification"
                                        maxLength="11"
                                        {...register('identification', {
                                            onChange: userCompleteCV === "true" ? handleChangeUserCompleteCV : handleChange, required: true, maxLength: 11, pattern: /[0-9]/
                                        })}
                                        id="identification"
                                        disabled={allowEdit}
                                        className="text-gray-900 sm:text-sm block w-full p-2.5 pl-3 pr-10 rounded border-solid border-2 border-grayDark-RS"
                                        placeholder="Identificación"
                                    />
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                                        <IdentificationIcon className="h-5 w-5 pointer-events-none text-dark-500" />
                                    </span>
                                </label>
                                {/*iterate over validations and show message name*/}
                                {validations.identification?.map((values) => {
                                    return (
                                        errors.identification?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS mt-1">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>
                            {/*Fecha nacimiento Input*/}
                            <div className="w-full m-1 md:w-80">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Fecha nacimiento</label>
                                <div className="relative flex items-center">
                                    <input type="date"
                                        name="birthDate"
                                        {...register('birthDate', { onChange: userCompleteCV === "true" ? handleChangeUserCompleteCV : handleChange, required: true })}
                                        id="birthDate"
                                        disabled={allowEdit}
                                        className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS "
                                        placeholder="Fecha nacimiento"
                                    />
                                </div>
                                {/*iterate over validations and show message birthdate*/}
                                {validations.birthDate?.map((values) => {
                                    return (
                                        errors.birthDate?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS mt-1">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>
                            {/*Estado civil Input*/}
                            <div className="w-full m-1  md:w-80">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Estado civil</label>
                                <div className="relative flex items-center">

                                    <select
                                        id="maritalStatus"
                                        {...register('maritalStatus', { onChange: userCompleteCV === "true" ? handleChangeUserCompleteCV : handleChange, required: true })}
                                        type="select"
                                        disabled={allowEdit}
                                        value={getValues("maritalStatus")}
                                        className="text-black-RS sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS "
                                        name="maritalStatus"
                                    >
                                        <option value={""} defaultValue > --Seleccione el estado civil --</option>

                                        {maritalStatusOptions?.map((option, index) => (
                                            <option key={index} value={option.idEstadoCivil}>{option.estado}</option>
                                        ))}
                                    </select>
                                </div>
                                {/*iterate over validations and show message marital...*/}
                                {validations.maritalStatus?.map((values) => {
                                    return (
                                        errors.maritalStatus?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS mt-1">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>
                        </div>

                        {/*Third row*/}
                        <div className="flex flex-wrap  mx-auto items-center justify-center">
                            {/*Correo electrónico Input*/}
                            <div className="w-full m-1 md:w-80">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Correo electrónico</label>
                                <label className="relative block">
                                    <input type="text"
                                        name="email"
                                        maxLength="32"
                                        {...register('email', { onChange: userCompleteCV === "true" ? handleChangeUserCompleteCV : handleChange, required: true, maxLength: 32, pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ })}
                                        id="email"
                                        disabled={allowEdit}
                                        className="text-gray-900 sm:text-sm block w-full p-2.5 pl-3 pr-10 rounded border-solid border-2 border-grayDark-RS "
                                        placeholder="Correo electrónico"
                                    />
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                                        <EnvelopeIcon className="h-5 w-5 pointer-events-none text-dark-500" />
                                    </span>
                                </label>
                                {/*iterate over validations and show message email*/}
                                {validations.email?.map((values) => {
                                    return (
                                        errors.email?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS mt-1">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>

                            {/*Telefono 1 Input*/}
                            <div className="w-full m-1 md:w-80">

                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Teléfono 1</label>
                                <label className="relative block">
                                    <Controller
                                        control={control}
                                        name="firstPhoneNumber"
                                        render={({ field: { ref } }) => (
                                            <PhoneInput
                                                {...register('firstPhoneNumber', { required: true })}
                                                specialLabel={''}
                                                country={'cr'}
                                                value={getValues("firstPhoneNumber")}
                                                disabled={allowEdit}
                                                onChange={(value) => {
                                                    setValue("firstPhoneNumber", value)
                                                    if (userCompleteCV === "true") {
                                                        if (InfoPersonalDataEdit) {
                                                            setInfoPersonalDataEdit({
                                                                ...InfoPersonalDataEdit, "firstPhoneNumber": value
                                                            })
                                                        }
                                                    } else {
                                                        if (InfoPersonalData) {
                                                            setInfoPersonalData({ ...InfoPersonalData, "firstPhoneNumber": value })
                                                        }
                                                        validateForm();
                                                        //use this to force detect isValid form state
                                                        if (value.length > 0) {
                                                            resetField("firstPhoneNumber", { keepError: false })
                                                            setValue("firstPhoneNumber", value)
                                                        }
                                                    }
                                                }
                                                }

                                                isValid={(value) => {
                                                    if (value.length === 0) {
                                                        return 'Campo requerido';
                                                    } else {
                                                        return true;
                                                    }
                                                }}
                                                placeholder="Teléfono 1"
                                                className="text-gray-900 sm:text-sm !hover:border-grayDark-RS" />


                                        )}

                                    />
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                                        <PhoneIcon className="h-5 w-5 pointer-events-none text-dark-500" />
                                    </span>
                                </label>
                            </div>
                            {/*Telefono 2 Input*/}
                            <div className="w-full m-1 md:w-80">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Teléfono 2</label>
                                <label className="relative block">
                                    <PhoneInput
                                        required
                                        specialLabel={''}
                                        country={'cr'}
                                        value={getValues("secondPhoneNumber")}
                                        disabled={allowEdit}
                                        onChange={(value) => {
                                            setValue("secondPhoneNumber", value)

                                            if (userCompleteCV === "true") {
                                                if (InfoPersonalDataEdit) {
                                                    setInfoPersonalDataEdit({
                                                        ...InfoPersonalDataEdit, "secondPhoneNumber": value
                                                    })
                                                }
                                            } else {
                                                if (InfoPersonalData) {
                                                    setInfoPersonalData({ ...InfoPersonalData, "secondPhoneNumber": value })
                                                }

                                            }
                                        }}

                                        placeholder="Teléfono 2"
                                        className="text-gray-900 block w-full sm:text-sm !hover:border-grayDark-RS" />
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                                        <PhoneIcon className="h-5 w-5 pointer-events-none text-dark-500" />
                                    </span>
                                </label>
                            </div>
                        </div>

                        {/*fourth row*/}
                        <div className="flex flex-wrap  mx-auto items-center justify-center">
                            {/*Lugar de residencia Input*/}
                            <div className="w-full m-1 md:w-80">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Lugar de residencia</label>
                                <label className="relative block">
                                    <input type="text"
                                        name="residence"
                                        maxLength="100"
                                        disabled={allowEdit}
                                        {...register('residence', { onChange: userCompleteCV === "true" ? handleChangeUserCompleteCV : handleChange, required: true, maxLength: 100 })}
                                        id="residence"
                                        className="text-gray-900 sm:text-sm block w-full p-2.5 pl-3 pr-10 rounded border-solid border-2 border-grayDark-RS "
                                        placeholder="Lugar de residencia"
                                    />
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                                        <MapPinIcon className="h-5 w-5 pointer-events-none text-dark-500" />
                                    </span>
                                </label>
                                {/*iterate over validations and show message phone 1*/}
                                {validations.residence?.map((values) => {
                                    return (
                                        errors.residence?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS mt-1">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>
                            {/*Linkedin Input*/}
                            <div className="w-full m-1 md:w-80">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Linkedin</label>
                                <label className="relative block">
                                    <input type="text"
                                        name="linkedin"
                                        maxLength="50"
                                        disabled={allowEdit}
                                        {...register('linkedin', { onChange: userCompleteCV === "true" ? handleChangeUserCompleteCV : handleChange, required: false, maxLength: 50 })}
                                        id="linkedin"
                                        className="text-gray-900 sm:text-sm block w-full p-2.5 pl-3 pr-10  rounded border-solid border-2 border-grayDark-RS "
                                        placeholder="Linkedin"
                                    />
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                                        <ChatBubbleLeftIcon className="h-5 w-5 pointer-events-none text-dark-500 " />
                                    </span>
                                </label>
                                {/*iterate over validations and show message Linkedin */}
                                {validations.linkedin?.map((values) => {
                                    return (
                                        errors.linkedin?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS mt-1">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>
                            {/*Otra red social Input*/}
                            <div className="w-full m-1 md:w-80">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Otra red social</label>
                                <label className="relative block">
                                    <input type="text"
                                        name="otherSocialMedia"
                                        maxLength="50"
                                        disabled={allowEdit}
                                        {...register('otherSocialMedia', { onChange: userCompleteCV === "true" ? handleChangeUserCompleteCV : handleChange, required: false, maxLength: 50 })}
                                        id="otherSocialMedia"
                                        className="text-gray-900 sm:text-sm block w-full p-2.5 pl-3 pr-10 rounded border-solid border-2 border-grayDark-RS "
                                        placeholder="Otra red social"
                                    />
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                                        <ChatBubbleLeftIcon className="h-5 w-5 pointer-events-none text-dark-500 " />
                                    </span>
                                </label>
                                {/*iterate over validations and show message phone 1*/}
                                {validations.otherSocialMedia?.map((values) => {
                                    return (
                                        errors.otherSocialMedia?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS mt-1">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>
                        </div>
                        {/*fifth row*/}
                        <div className="container">
                            <div className="flex m-1 flex-wrap  mx-auto items-center justify-center">
                                {/*We make 3 columns to fill space from center*/}

                                {/*Especialidad Input*/}
                                <div className="w-full m-1 md:w-80">
                                    <label className="block mb-2 text-sm font-medium text-gray-900">Especialidad</label>
                                    <div className="relative block">
                                        <select
                                            {...register('specialty', { onChange: userCompleteCV === "true" ? handleChangeUserCompleteCV : handleChange, required: true })}
                                            id="specialty"
                                            type="select"
                                            disabled={allowEdit}
                                            value={getValues("specialty")}
                                            className="text-black-RS sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS mb-5"
                                            name="specialty" >
                                            <option value={""} defaultValue > --Seleccione una especialidad --</option>

                                            {specialtyOptions.map((option, index) => (
                                                <option key={index} value={option.idEspecializacion}>{option.nombreEspecializacion}</option>
                                            ))}
                                        </select>

                                    </div>
                                    {/*iterate over validations and show message specialty*/}
                                    {validations.specialty?.map((values) => {
                                        return (
                                            errors.specialty?.type === values.type &&
                                            <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                                <p className="text-sm font-bold text-red-RS mt-1">{values.message}</p>
                                            </span>
                                        )
                                    })}
                                </div>

                                {/*Resumen personal Input*/}
                                <div className="w-full m-1 md:w-80">
                                    <label className="block mb-2 mt-1 text-sm font-medium text-gray-900 ">Resumen personal</label>
                                    <label className="relative block">
                                        <textarea type="textarea"
                                            name="personalSummary"
                                            disabled={allowEdit}
                                            {...register('personalSummary', { onChange: userCompleteCV === "true" ? handleChangeUserCompleteCV : handleChange, required: true })}
                                            id="personalSummary"
                                            className="text-gray-900 sm:text-sm block w-full p-2.5 pl-3 pr-10 mb-1.5  rounded border-solid border-2 border-grayDark-RS "
                                            placeholder="Resumen personal"
                                        />
                                        <span className="absolute inset-y-0 right-0 flex items-center pr-3 mb-2">
                                            <DocumentTextIcon className="h-5 w-5 pointer-events-none text-dark-500 " />
                                        </span>
                                    </label>
                                    {/*iterate over validations and show message phone 1*/}
                                    {validations.personalSummary?.map((values) => {
                                        return (
                                            errors.personalSummary?.type === values.type &&
                                            <span key="{values.type}" className="absolute flex w-64 items-center font-medium tracking-wide text-red-500 text-xs ml-1">
                                                <p className="text-sm font-bold text-red-RS mt-1">{values.message}</p>
                                            </span>
                                        )
                                    })}
                                </div>

                                {/*Trabaja It Rossmon Input*/}
                                <div className="w-full m-1 md:w-80">
                                    <label className="block mb-2 text-sm font-medium text-gray-900">¿Trabaja en IT Rossmon?</label>
                                    <label className="relative block mb-8">
                                        <Controller
                                            control={control}
                                            name="workInRossmon"
                                            id="workInRossmon"
                                            render={({ field: { ref } }) => (
                                                <Switch
                                                    {...register('workInRossmon', { required: false })}
                                                    disabled={allowEdit}
                                                    checked={enabled}
                                                    defaultValue="false"
                                                    onChange={(value) => {
                                                        setEnabled(!enabled)
                                                        setValue("workInRossmon", value)

                                                        if (userCompleteCV === "true") {
                                                            if (InfoPersonalDataEdit) {
                                                                setInfoPersonalDataEdit({
                                                                    ...InfoPersonalDataEdit, "workInRossmon": value
                                                                })
                                                            }
                                                        } else {

                                                            if (InfoPersonalData) {
                                                                setInfoPersonalData({ ...InfoPersonalData, "workInRossmon": value })
                                                            }

                                                        }
                                                    }}
                                                    className={`${enabled ? 'bg-red-RS ' : ' bg-grayLight-RS'}
                                                relative inline-flex h-[25px] w-[60px] shrink-0 cursor-pointer rounded-full  border-solid border-2 border-grayDark-RS  transition-colors duration-200 ease-in-out focus:outline-none`}
                                                >
                                                    {enabled ? <span className="translate-x-9 pointer-events-none inline-block h-[22px] w-[22px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out p-1">
                                                        <CheckIcon className="h-4 w-4  items-center text-dark-500" />
                                                    </span> : <span className="translate-x-0 pointer-events-none inline-block h-[22px] w-[22px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out p-1">
                                                        <XMarkIcon className="h-4 w-4 items-center  text-dark-500" />
                                                    </span>}


                                                </Switch>
                                            )}
                                        />
                                    </label>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
