import React from 'react';
import GridCurriculumComponent from './GridCurriculum';

const GridCurriculum = () => {


    return (
        <div>
            <GridCurriculumComponent />
        </div>
    );
};

export default GridCurriculum;