import React, { useEffect, useState } from "react";
import ResetPasswordComponent from './ResetPassword-Component';



const ResetPassword = ( ) => {

    //validations
    const validationMessages = {
        email: [
            {
                type: 'required',
                message: 'Favor ingresar el correo',
            },
            {
                type: 'maxLength',
                message: 'El correo no puede contener mas de 150 caracteres ',
            },
            {
                type: 'pattern',
                message: 'Correo electrónico inválido',
            }
        ],

    }


    return (
        <div>
            <ResetPasswordComponent validationMessages={validationMessages} />
        </div>
    );
};

export default ResetPassword;