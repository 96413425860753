import React, { useState, useEffect, useRef, useContext } from 'react';
import { useForm } from 'react-hook-form';
import AlertContext from '../../../GlobalComponents/Alert/contexts/alertContext';


//import global components
import { Card, Modal, ModalActionConfirm } from '../../../../imports';

//import services
import { ObtenerCategoriaExperiencia, EditarExperiencia, AgregarExperienciaIndividual, EliminarExperiencia } from '../../../../servicios/ServicioExperiencia';
//import icons from heroicons
import { PlusCircleIcon, UserIcon, DocumentTextIcon } from '@heroicons/react/24/outline';


export default function Experiencia({ validationMessages, userData, setUserData, finalData, setstateExperiencia, allowEdit, listExperienceByPerson, setListExperienceByPerson, experienceDataEdit, setExperienceDataEdit, isFirstLoadExperience, setIsFirstLoadExperience, idCurriculum}) {

   

    //use alert context
    const alertCtx = useContext(AlertContext); 

    //here we pass a default input property to save in userData
    //all inputs use this same event in 'onchange'
    const handleChange = (e) => {
        const { name, value } = e.target;

        setValue(name, e.target.value)

    };

    //use react hook form to get values and make validations
    const { register, reset ,getValues, setValue ,handleSubmit, formState } = useForm({
        mode: 'all',
        defaultValues: {
            nameCompany: '',
            positionHeld: '',
            startDate: '',
            finishDate: '',
            functionsPerformed: ''
        }
    });

    const { errors } = formState;
    const { isValid } = formState;

    //use to get validations from props and later iterate
    const [validations, setValidations] = useState([]);


    const [showModal, setShowModal] = useState(false);


    //flag to point into box selected 
    const [detectCheckBox, setDetect] = useState(false);

    const [OnWork, setWork] = useState(false);

    const [listExperience, setListExperience] = useState([]);

    const [currentIdCard, setCurrentIdCard] = useState(null);

    //use to get experience options
    const [experienceOptions, setExperienceOptions] = useState([]);


    //use modal and show for save
    const [showConfirmSaveModal, setShowConfirmSaveModal] = useState(false);
    const [actionTypeConfirmModal, setActionTypeConfirmModal] = useState("");
    const [showConfirmEditModal, setShowConfirmEditModal] = useState(false);

    const [idFromCard, setIdFromCard] = useState(0);

    //validate if user already complete cv
    const userCompleteCV = sessionStorage.getItem("USER_COMPLETE_CV");

    const [userSaveActionSuccess, setUserSaveActionSuccess] = useState(false);

    const [dataIndividualExperience, setDataIndividualExperience] = useState({});

    //use to change and detect checkbox
    const detectCheck = event => {
        event.preventDefault();

        setWork(!OnWork)

        setDetect(!detectCheckBox);
        resetinputs();
    }
    async function getExperienceType() {
        const response = await ObtenerCategoriaExperiencia();
        if (response !== undefined) {
            setExperienceOptions(response);
        }

    }

    const resetinputs = () => {
        //reset all default values
        reset({

            finishDate: "",
           
        }, {
            keepErrors: false,
            keepDirty: false,
        })
       
    }

    //call services to delete elements from education
    async function EliminarExperienciaPersona(idProyecto) {
        const response = await EliminarExperiencia(idProyecto);
        return response;
    }

    //method to add new project
    const AgregarExperienciaPersona = async (e) => {

        const ExperienciaData = {
            nombreEmpresa: dataIndividualExperience.dataForGlobalList?.nameCompany,
            puestoDesempenado: dataIndividualExperience.dataForGlobalList?.positionHeld,
            fechaIngreso: dataIndividualExperience.dataForGlobalList?.startDate === "" ? null : dataIndividualExperience.dataForGlobalList?.startDate,
            fechaSalida: dataIndividualExperience.dataForGlobalList?.finishDate === "" ? null : dataIndividualExperience.dataForGlobalList?.finishDate,
            trabajando: dataIndividualExperience.dataForGlobalList?.currentlyWorking,
            funcionesRealizadas: dataIndividualExperience.dataForGlobalList?.functionsPerformed,
            idPersona: idCurriculum,
            idCategoriaExperiencia: parseInt(dataIndividualExperience.dataForGlobalList?.categoryExperience, 10)            
        }

        const experienciaResponse = await AgregarExperienciaIndividual(ExperienciaData);


        if (experienciaResponse.indicador === 0) {

            //save options in list
            if (listExperience) {
                setListExperience([...listExperience, dataIndividualExperience])
            }

            alertCtx.success(experienciaResponse.mensaje);
            setUserSaveActionSuccess(true)
            setShowConfirmSaveModal(false)

            setDataIndividualExperience({})
            setCurrentIdCard(null);
            setDetect(false);
        }

    }

    //method to edit project from person by id
    const EditarExperienciaPersona = async (e) => {

        const ExperienciaData = {
            idExperiencia: dataIndividualExperience.dataForGlobalList?.idExperience,
            nombreEmpresa: dataIndividualExperience.dataForGlobalList?.nameCompany,
            puestoDesempenado: dataIndividualExperience.dataForGlobalList?.positionHeld,
            fechaIngreso: dataIndividualExperience.dataForGlobalList?.startDate === "" ? null : dataIndividualExperience.dataForGlobalList?.startDate,
            fechaSalida: dataIndividualExperience.dataForGlobalList?.finishDate === "" ? null : dataIndividualExperience.dataForGlobalList?.finishDate,
            trabajando: dataIndividualExperience.dataForGlobalList?.currentlyWorking,
            funcionesRealizadas: dataIndividualExperience.dataForGlobalList?.functionsPerformed,
            idCategoriaExperiencia: parseInt(dataIndividualExperience.dataForGlobalList?.categoryExperience, 10),
            idPersona: idCurriculum
        }

        const experienciaResponse = await EditarExperiencia(ExperienciaData);

        if (experienciaResponse.indicador === 0) {
            //save options in list
            if (currentIdCard) {
                if (listExperience?.length > currentIdCard - 1 && listExperience[currentIdCard] !== undefined) {

                    //filter array of key value to validate if object exists and then update value
                    for (let item of Object.keys(listExperience)) {
                        if (listExperience[item] === listExperience[currentIdCard]) {

                            //update key value list to show recent info in cards by currentItem
                            listExperience[item] = dataIndividualExperience
                        }
                    }
                }
            } else {
                setListExperience([...listExperience, dataIndividualExperience])

            }
            alertCtx.success(experienciaResponse.mensaje);
            setUserSaveActionSuccess(true)
           
            setShowConfirmEditModal(false)

            setDataIndividualExperience({})
            setCurrentIdCard(null);
            setDetect(false);
        }
   

    }

    //function to save 'experience' in list
    const SaveExperienceOptions = () => {
        const startDate = getValues("startDate");
        const finishDate = getValues("finishDate");

        if (startDate > finishDate && OnWork === false) {
            alertCtx.error("La fecha de finalización debe ser posterior a la fecha de inicio");
        } else {
            if (isValid) {

                const nameCompany = getValues("nameCompany");
                const categoryExperience = getValues("experienceCategory");
                const positionHeld = getValues("positionHeld");
                const currentlyWorking = OnWork;
                const startDate = getValues("startDate");                
                const finishDate = getValues("finishDate");
                const functionsPerformed = getValues("functionsPerformed");

                //we save global object and list with key and value to get easy in card iteration over data
                const dataForGlobalList = {
                    nameCompany: nameCompany,
                    categoryExperience: parseInt(categoryExperience, 10),
                    positionHeld: positionHeld,
                    currentlyWorking: currentlyWorking,
                    startDate: startDate,
                    finishDate: finishDate,
                    functionsPerformed: functionsPerformed,
                    idExperience: idFromCard
                }

                const object = {
                    "dataHeader":
                    {
                        "Empresa": nameCompany,
                        "Puesto Desempeñado": positionHeld,
                    },
                    "dataBody":
                    {
                        "Fecha ingreso": startDate,
                        "Fecha salida": finishDate,
                    },
                    "dataFooter":
                    {
                        "Trabaja actualmente": currentlyWorking ? "Si" : "No"
                    },
                    "dataID": {
                        "idExperiencia": idFromCard
                    },
                    "dataForGlobalList": dataForGlobalList
                }

                //validation
                if (userCompleteCV === "true") {
                    setDataIndividualExperience(object);

                    if (actionTypeConfirmModal === "Agregar") setShowConfirmSaveModal(true);

                    if (actionTypeConfirmModal === "Editar") setShowConfirmEditModal(true);

                    resetAllInputs();

                } else {

                    //save options in list
                    if (currentIdCard) {
                        if (listExperience?.length > currentIdCard - 1 && listExperience[currentIdCard] !== undefined) {

                            //filter array of key value to validate if object exists and then update value
                            for (let item of Object.keys(listExperience)) {
                                if (listExperience[item] === listExperience[currentIdCard]) {

                                    //update key value list to show recent info in cards by currentItem
                                    listExperience[item] = object
                                }
                            }
                        }
                    } else {
                        setListExperience([...listExperience, object])

                    }
                    resetAllInputs();

                    setCurrentIdCard(null);
                    //close modal and show alert
                    setShowModal(false);
                    alertCtx.success("Datos guardados temporalmente");
                    setIdFromCard(0)
                    
                }
            } else {
                alertCtx.error("¡Datos incorrectos!");
            }
        }
    }

    const resetAllInputs = () => {
        //reset all default values
        reset({
            nameCompany: "",
            experienceCategory:"",
            positionHeld: "",
            currentlyWorking: "",
            startDate: "",
            finishDate: "",
            functionsPerformed: ""
        }, {
            keepErrors: false,
            keepDirty: false,
        })
        setDetect(false);
        setWork(false);
    }

    function setValuesById(list) {

        setValue("nameCompany", list.dataForGlobalList?.nameCompany)
        setValue("experienceCategory", list.dataForGlobalList?.categoryExperience)
        setValue("positionHeld", list.dataForGlobalList?.positionHeld)
        setValue("currentlyWorking", list.dataForGlobalList?.currentlyWorking)
        setValue("startDate", list.dataForGlobalList?.startDate)
        setValue("finishDate", list.dataForGlobalList?.finishDate)
        setValue("functionsPerformed", list.dataForGlobalList?.functionsPerformed)
        setIdFromCard(list?.dataID?.idExperiencia);
        setDetect(list.dataForGlobalList?.currentlyWorking ? true : false)
        setWork(list.dataForGlobalList?.currentlyWorking);
    }

    //create custom object with list by person id to render cards
    function customObjectByPersonList() {

        listExperienceByPerson.map((item) => {
            const startMonth = new Date(item?.fechaIngreso).getMonth() + 1;
            const startYear = new Date(item?.fechaIngreso).getFullYear();
            const startDay = new Date(item?.fechaIngreso).getDay();
            const startDateFormatted = `${startYear}-${startMonth}-${startDay}`;
            const startDate = new Date(startDateFormatted)

            const endMonth = new Date(item?.fechaSalida).getMonth() + 1;
            const endYear = new Date(item?.fechaSalida).getFullYear();
            const endDay = new Date(item?.fechaSalida).getDay();
            const endDateFormatted = `${endYear}-${endMonth}-${endDay}`;
            const endDate = new Date(endDateFormatted)

            //we save global object and list with key and value
            const dataForGlobalList = {
                nameCompany: item?.nombreEmpresa,
                categoryExperience: item?.idCategoriaExperiencia,
                positionHeld: item?.puestoDesempenado,
                currentlyWorking: item?.trabajando,
                startDate: item?.fechaIngreso ? startDate.toLocaleDateString('fr-CA') : "",
                finishDate: item?.fechaSalida ? endDate.toLocaleDateString('fr-CA') : "",
                functionsPerformed: item?.funcionesRealizadas,
            }

            const object = {
                "dataHeader":
                {
                    "Empresa": item?.nombreEmpresa,
                    "Puesto Desempeñado": item?.puestoDesempenado,
                },
                "dataBody":
                {
                    "Fecha ingreso": item?.fechaIngreso ? startDate.toLocaleDateString('fr-CA') : "",
                    "Fecha salida": item?.fechaSalida ? endDate.toLocaleDateString('fr-CA') : "",
                },
                "dataFooter":
                {
                    "Trabaja actualmente": item?.trabajando ? "Si" : "No"
                },
                "dataID": {
                    "idExperiencia": item?.idExperiencia
                },
               "dataForGlobalList": dataForGlobalList
            }
            if (listExperience !== undefined) {
                listExperience.push(object);
            }
         
        })
        
    }

    useState(() => {
        getExperienceType();
        setValidations(validationMessages);
        setDetect(false);
        setWork(false);
        
    }, [])


    useEffect(() => {
        //validate for candidate view or admin view
        if (userCompleteCV === "true" && isFirstLoadExperience || allowEdit === true && isFirstLoadExperience) {
            //fill data by idPerson
            if (listExperienceByPerson?.length > 0) {
                customObjectByPersonList();
                if (isFirstLoadExperience) setIsFirstLoadExperience(false)
            }

        }
        //validate for candidate view or admin view
        if (userCompleteCV === "true" || allowEdit === true) {
            if (listExperience?.length > 0) {
                if (experienceDataEdit) {
                    setExperienceDataEdit(listExperience);
                }

            } else {
                if (experienceDataEdit) {
                    setListExperience(experienceDataEdit);
                }
            }

        } else {
            //fill data by idPerson
            if (listExperience?.length > 0) {
                //set form to valid
                setstateExperiencia(true);

                if (userData) {
                    setUserData({ ...userData, "listExperience": listExperience })
                }

            } else {
                //set form to valid
                setstateExperiencia(false);

                if (userData["listExperience"]) {
                    setListExperience(userData["listExperience"])
                }

            }
        }

        if (userCompleteCV === "true" && !showModal || allowEdit === true && !showModal) {
            const timeout = setTimeout(() => {
                if (experienceDataEdit) {

                    setExperienceDataEdit(listExperience);
                }
            }, 1000);
            return () => clearTimeout(timeout);

        } else if (!showModal) {
            const timeout = setTimeout(() => {
                if (listExperience) {
                    if (userData) {
                        setUserData({ ...userData, "listExperience": listExperience });
                    }

                }
            }, 1000);
            return () => clearTimeout(timeout);

        }

        return (() => {
        
            if (userCompleteCV === "true" || allowEdit === true) {
                if (isFirstLoadExperience) {
                    setIsFirstLoadExperience(false);

                }
                if (experienceDataEdit) {

                    setExperienceDataEdit(listExperience);
                }
            } else {

                if (listExperience) {
                    setUserData({ ...userData, "listExperience": listExperience });
                }
            }
            setShowModal(false);
        })
       
    }, [showModal, listExperience, userSaveActionSuccess, dataIndividualExperience, actionTypeConfirmModal])

    return (
        <div className="w-full flex flex-col md:flex-row items-center justify-center px-1  mx-auto lg:py-0">
            {/*Block to manage form input 'experiencia'--*/}
            <div className={userCompleteCV === "false" ? "w-full pt-1 bg-white rounded-lg shadow" : "w-full pt-1"}>
                <div className="relative container mx-auto items-center justify-center">

                    {!allowEdit && <div>
                        {listExperience?.length > 0 ? null : (
                            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                <h1 className="text-decoration-line: underline">Por favor, presione Agregar para completar el apartado de experiencia</h1>
                            </div>
                        )}
                    </div>
                    }

                    {listExperience?.length > 0 &&
                        <div className="container flex flex-wrap w-full py-4 mt-6 mx-auto items-start justify-center mt-2">
                            {/*iterate over list techs */}
                            {listExperience?.map((item, index) => (
                                <div className="w-full m-1 mt-8 p-1 py-6 md:w-96" key={index}>
                                    <Card id={index} data={item} list={listExperience} setList={setListExperience} setCurrentIdCard={setCurrentIdCard} setShowModal={setShowModal} setValuesById={setValuesById} allowEdit={allowEdit}
                                        listByPerson={listExperienceByPerson} setListExperienceByPerson={setListExperienceByPerson} userCompleteCV={userCompleteCV} deleteElementCV={EliminarExperienciaPersona} setActionTypeConfirmModal={setActionTypeConfirmModal} title="Experiencia"/>
                                </div>
                            ))}
                        </div>}
                  
                    {!allowEdit && <div>
                        {/*here move button to top right if list is not null*/}
                        {listExperience?.length > 0 ? <div className="flex absolute top-0 right-0 md:justify-center items-center mb-8 px-2 py-6">
                            <button disabled={allowEdit} className="bg-greenButton hover:bg-primary-700 text-white text-sm  py-2 px-4 rounded inline-flex items-center" onClick={() => { setShowModal(true); setActionTypeConfirmModal("Agregar") }}>
                                <span>Agregar</span>
                                <PlusCircleIcon className="w-8 h-8 ml-2"></PlusCircleIcon>
                            </button>
                        </div> :
                            <div className="flex justify-center items-center mb-4 px-2 py-2">
                                <button disabled={allowEdit} className="bg-greenButton hover:bg-primary-700 text-white text-sm  py-2 px-4 rounded inline-flex items-center" onClick={() => { setShowModal(true); setActionTypeConfirmModal("Agregar") }}>
                                    <span>Agregar</span>
                                    <PlusCircleIcon className="w-8 h-8 ml-2"></PlusCircleIcon>
                                </button>
                            </div>
                        }
                    </div>
                    }


                    {/*Here we show view confirm save modal compononent*/}
                    <Modal isVisible={showConfirmSaveModal} onClose={() => { setShowConfirmSaveModal(false) }} handleSubmit={() => null} onSaveOptions={() => null} showFotter={false} showCloseIcon={false}>
                        <ModalActionConfirm onSaveAction={AgregarExperienciaPersona} actionType={actionTypeConfirmModal} setshowConfirmModal={setShowConfirmSaveModal} />
                    </Modal>

                    {/*Here we show view confirm edit modal compononent*/}
                    <Modal isVisible={showConfirmEditModal} onClose={() => { setShowConfirmEditModal(false) }} handleSubmit={() => null} onSaveOptions={() => null} showFotter={false} showCloseIcon={false}>
                        <ModalActionConfirm onSaveAction={EditarExperienciaPersona} actionType={actionTypeConfirmModal} setshowConfirmModal={setShowConfirmEditModal} />
                    </Modal>

                    <Modal isVisible={showModal} onClose={() => { setShowModal(false); resetAllInputs(); }} handleSubmit={handleSubmit} onSaveOptions={SaveExperienceOptions} >
                        <form className="space-y-1 md:space-y-2">
                            {/*First row*/}
                            <div className="flex flex-col  mx-auto items-center justify-center md:flex-row">

                                {/*Title current page*/}
                                <div className="w-full mx-px px-2 mt-4 md:w-40">
                                    <h1 className="block mb-2 text-xl font-bold text-black-RS">Experiencia</h1>
                                </div>

                                {/*Nombre Empresa Input*/}
                                <div className="w-full mx-px px-2 mb-2 md:w-80">
                                    <label className="block mb-2 text-sm font-medium text-gray-900">Nombre de la empresa</label>
                                    <label className="relative block">
                                      
                                        <input type="text"
                                            name="nameCompany"
                                            {...register("nameCompany", { onChange: handleChange, required: true, maxLength: 150 })}
                                            id="nameCompany"
                                            className="text-gray-900 sm:text-sm block w-full p-2.5 mb-2 pl-3 pr-10 rounded border-solid border-2 border-grayDark-RS"
                                            placeholder="Nombre de la empresa"
                                        />
                                        <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                                            <UserIcon className="h-5 w-5 pointer-events-none text-dark-500" />
                                        </span>
                                    </label>
                                    {/*iterate over validations and show message nameCompany*/}
                                    {validations.nameCompany?.map((values) => {
                                        return (
                                            errors.nameCompany?.type === values.type &&
                                            <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                                <p className="text-sm font-bold text-red-RS mt-2">{values.message}</p>
                                            </span>
                                        )
                                    })}
                                </div>                   

                                <div className="w-full mx-px px-2 mb-2 md:w-72">

                                    <label className="block mb-2 text-sm font-medium text-gray-900 ">Tipo de experiencia</label>
                                    <div className="relative flex items-center">
                                        <select
                                            id="experienceCategory"
                                            type="select"
                                            {...register('experienceCategory', { onChange: handleChange, required: true })}
                                            className="text-black-RS sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS mb-2 "
                                            name="experienceCategory" >
                                            <option value={""} >--Seleccione la categoría--</option>

                                            {experienceOptions.map((option, index) => (
                                                <option key={index} value={option.idCategoriaExperiencia}>{option.tipoExperiencia}</option>
                                            ))}
                                        </select>
                                    </div>
                     
                                </div>

                                {/*Puesto desempeñado Input*/}
                                <div className="w-full mx-px px-2 mb-2 md:w-72">
                                    <label className="block mb-2 text-sm font-medium text-gray-900">Puesto desempeñado</label>
                                    <label className="relative block">
                  
                                        <input type="text"
                                            name="positionHeld"
                                            {...register("positionHeld", { onChange: handleChange, required: true, maxLength: 30, pattern: /^[a-zA-ZñÑ\s\W]/ })}
                                            id="positionHeld"
                                            className="text-gray-900 sm:text-sm block w-full p-2.5 pl-3 pr-10 rounded border-solid border-2 border-grayDark-RS mb-2" placeholder="Puesto Desempeñado"
                                        />
                                        <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                                            <UserIcon className="h-5 w-5 pointer-events-none text-dark-500" />
                                        </span>
                                    </label>
                                    {/*iterate over validations and show message position held*/}
                                    {validations.positionHeld?.map((values) => {
                                        return (
                                            errors.positionHeld?.type === values.type &&
                                            <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                                <p className="text-sm font-bold text-red-RS mt-2">{values.message}</p>
                                            </span>
                                        )
                                    })}
                                </div>

                               
                            </div>

                            {/*Second row*/}
                            <div className="flex flex-col mt-4 mx-2 mx-auto items-center justify-center md:flex-row">
                                {/*Trabajando Input*/}
                                <div className="w-full mx-px px-2 mb-2 md:w-40">
                                    <label className="block mb-2 text-sm font-medium text-gray-900">¿Trabaja actualmente?</label>
                                    <div className="relative flex items-center">
                                        <div className="flex items-center mb-2">
                                            <fieldset value={OnWork}  {...register("OnWork")} onChange={(event) => detectCheck(event)}>
                                                <label htmlFor="1" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                    <input type="radio"
                                                        id="1"
                                                        name="workOption"
                                                        value={OnWork}
                                                        className={detectCheckBox ? "cursor-pointer bg-black-RS  border-solid border-2 border-grayDark-RS rounded-full" : "cursor-pointer bg-white    border-solid border-2 border-grayDark-RS rounded-full"}
                                                    />
                                                    Si
                                                </label>
                                                <label htmlFor="2" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                    <input type="radio"
                                                        id="2"
                                                        name="workOption"
                                                        value={OnWork}
                                                        className={detectCheckBox ? "cursor-pointer bg-white  border-solid border-2 border-grayDark-RS rounded-full" : "cursor-pointer bg-black-RS  border-solid border-2 border-grayDark-RS rounded-full"}
                                                    />
                                                    No
                                                </label>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>

                                {/*funciones desempeñado Input*/}
                                <div className="w-full mx-px px-2 mb-2 md:w-80">
                                    <label className="block mb-2 text-sm font-medium text-gray-900">Funciones desempeñadas</label>
                                    <label className="relative block">
                                        <textarea type="textarea"
                                            name="functionsPerformed"
                                            id="functionsPerformed"
                                            {...register("functionsPerformed", { onChange: handleChange, required: true })}
                                            className="text-gray-900 sm:text-sm block w-full p-2.5 pl-3 pr-10 mb-2 rounded border-solid border-2 border-grayDark-RS "
                                            placeholder="Funciones desempeñadas"
                                        />
                                        <span className="absolute inset-y-0 right-0 flex items-center pr-3 mb-2">
                                            <DocumentTextIcon className="h-5 w-5 pointer-events-none text-dark-500 " />
                                        </span>
                                    </label>
                                    {/*iterate over validations and show message functionsPerformed*/}
                                    {validations.functionsPerformed?.map((values) => {
                                        return (
                                            errors.functionsPerformed?.type === values.type &&
                                            <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                                <p className="text-sm font-bold text-red-RS mt-1">{values.message}</p>
                                            </span>
                                        )
                                    })}
                                </div>

                                {/*Fecha Ingreso Input*/}
                                <div className="w-full mx-px px-2 mb-2  md:w-72">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 ">Fecha ingreso</label>
                                    <div className="relative flex items-center">
                                        <input type="date"
                                            name="startDate"
                                            maxLength="25"
                                            {...register("startDate", { onChange: handleChange, required: true, maxLength: 25 })}
                                            id="startDate"
                                            className="text-gray-900 sm:text-sm block w-full p-2.5 mb-2 rounded border-solid border-2 border-grayDark-RS "
                                            placeholder="Fecha inicio"
                                        />
                                    </div>
                                    {/*iterate over validations and show message birthdate*/}
                                    {validations.startDate?.map((values) => {
                                        return (
                                            errors.startDate?.type === values.type &&
                                            <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                                <p className="text-sm font-bold text-red-RS mt-2">{values.message}</p>
                                            </span>
                                        )
                                    })}
                                </div>

                                {/*Fecha Salida Input*/}
                                {!OnWork === true ? <div className="w-full mx-px px-2 mb-2  md:w-72">
                                        <label className="block mb-2 text-sm font-medium text-gray-900 ">Fecha salida</label>
                                        <div className="relative flex items-center">
                                            <input type="date"
                                                name="finishDate"
                                                maxLength="25"
                                            {...register("finishDate", { onChange: handleChange, required: OnWork, maxLength: 25 })}
                                                id="finishDate"
                                                className="text-gray-900 sm:text-sm block w-full p-2.5 mb-2 rounded border-solid border-2 border-grayDark-RS"
                                                placeholder="Fecha finalización"
                                            />
                                        </div>
                                        {/*iterate over validations and show message birthdate*/}
                                        {validations.finishDate?.map((values) => {
                                            return (
                                                errors.finishDate?.type === values.type &&
                                                <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                                    <p className="text-sm font-bold text-red-RS mt-2">{values.message}</p>
                                                </span>

                                            )
                                        })}
                                 </div> : null}

                            </div>                   
                        </form>
                    </Modal>
                </div>
            </div>
        </div>
    );
}
    