import { ProcesarDatosApi } from "../api/ApiFetch";
const controlador = "documento";

export const AgregarDocumento = async (data) => {
    const url = `${controlador}/AgregarDocumento`;
    return await ProcesarDatosApi('POST', url, data);
}

export const ObtenerDocumentoPorId = async (idPersona) => {
    const url = `${controlador}/ObtenerDocumentoPorId/${idPersona}`;
    return await ProcesarDatosApi('GET', url);
}

export const EditarDocumento = async (data) => {
    const url = `${controlador}/EditarDocumento`;
    return await ProcesarDatosApi('PUT', url, data);
}
