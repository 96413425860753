import React from 'react';
import ProyectosComponent from './Proyectos';

const Proyectos = ({ userData, setUserData, finalData, setFinalData, allowEdit = false, listProjectsByPerson = [], isFirstLoadProject, setIsFirstLoadProject, projectDataEdit, setProjectDataEdit, idCurriculum  }) => {

    //validations
    const validationMessages = {
        projectName: [
            {
                type: 'required',
                message: 'Favor ingresar el nombre del proyecto',
            },
            {
                type: 'maxLength',
                message: 'El nombre del proyecto no debe contener más de 150 caracteres',
            },
            {
                type: 'pattern',
                message: 'Solo se admiten letras.',
            }
        ],
        companyName: [
            {
                type: 'required',
                message: 'Favor ingresar el nombre de la compañía',
            },
            {
                type: 'maxLength',
                message: 'El nombre de la compañía no puede contener mas de 150 caracteres ',
            },
            {
                type: 'pattern',
                message: 'Solo se admiten letras.',
            }
        ],
        startDate: [
            {
                type: 'required',
                message: 'Favor ingresar la fecha inicio',
            }
        ],
        finishDate: [
            {
                type: 'required',
                message: 'Favor ingresar la fecha fin',
            }
        ],
        projectDescription: [
           {
               type: 'required',
               message: 'Favor ingresar la descripción del proyecto',
           },
           {
               type: 'maxLength',
               message: 'La descripción del proyecto no puede contener mas de 255 caracteres ',
           }
        ],
    }

    return (
        <div>
            <ProyectosComponent validationMessages={validationMessages} userData={userData} setUserData={setUserData} finalData={finalData} setFinalData={setFinalData} allowEdit={allowEdit}
                listProjectsByPerson={listProjectsByPerson} isFirstLoadProject={isFirstLoadProject} setIsFirstLoadProject={setIsFirstLoadProject} projectDataEdit={projectDataEdit}
                setProjectDataEdit={setProjectDataEdit} idCurriculum={idCurriculum} />
        </div>
    );
};

export default Proyectos;