import React, { useState, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';

//alert
import AlertContext from '../Alert/contexts/alertContext';

//import services
import { ObtenerContrasenaActual, EditarPerfilUsuario } from '../../../servicios/ServicioUsuario';

//import assets
import { UserIcon, PhoneIcon, EnvelopeIcon } from '@heroicons/react/24/outline';

//phonecomponent
import PhoneInput from 'react-phone-input-2';
import '../../../../src/customInput.css';

const ChangePassword = ({ setShowModalEdit, fieldsData, idUsuario, getUserById }) => {

    //validations
    const validations = {
        email: [
            {
                type: 'required',
                message: 'Favor ingresar el correo',
            },
            {
                type: 'maxLength',
                message: 'El correo no puede contener mas de 150 caracteres ',
            },
            {
                type: 'pattern',
                message: 'Correo electrónico inválido',
            }
        ],
        password: [
            {
                type: 'required',
                message: 'Favor ingresar la contraseña',
            },
            {
                type: 'minLength',
                message: 'La contraseña debe contener al menos 6 caracteres ',
            },
            {
                type: 'maxLength',
                message: 'La contraseña no puede contener mas de 32 caracteres ',
            }
        ],
        currentPassword: [
            {
                type: 'required',
                message: 'Favor ingresar su contraseña actual',
            },
        ],
        repeatPassword: [
            {
                type: 'required',
                message: 'Favor ingresar la contraseña',
            },
            {
                type: 'minLength',
                message: 'La contraseña debe contener al menos 6 caracteres ',
            },
            {
                type: 'maxLength',
                message: 'La contraseña no puede contener mas de 32 caracteres ',
            },
            {
                type: 'validate',
                message: 'Las contraseñas son distintas ',
            }
        ],
        name: [
            {
                type: 'required',
                message: 'Favor ingresar su nombre',
            },
            {
                type: 'maxLength',
                message: 'El nombre no puede contener mas de 25 caracteres ',
            },
            {
                type: 'pattern',
                message: 'Solo se admiten letras.',
            }
        ],
        lastName: [
            {
                type: 'required',
                message: 'Favor ingresar el primer apellido',
            },
            {
                type: 'maxLength',
                message: 'El primer apellido no puede contener mas de 25 caracteres ',
            },
            {
                type: 'pattern',
                message: 'Solo se admiten letras.',
            }
        ],
        secondSurname: [
            {
                type: 'required',
                message: 'Favor ingresar su segundo apellido',
            },
            {
                type: 'maxLength',
                message: 'El segundo apellido no puede contener mas de 25 caracteres ',
            },
            {
                type: 'pattern',
                message: 'Solo se admiten letras.',
            }
        ],
        phone: [
            {
                type: 'required',
                message: 'Favor ingresar el teléfono',
            },
            {
                type: 'maxLength',
                message: 'El número teléfónico no puede contener mas de 10 caracteres ',
            }
        ],


    }

    //declare register to form
    //use react hook form to get values and make validations
    const { register, getValues, watch, setValue, control, resetField, formState } = useForm({
        mode: 'all',
        defaultValues: {
            email: '',
            password: '',
            passwordc: '',
            name: '',
            lastName: '',
            secondSurname: '',
            phone: '',
            currentPassword: ''
        }
    });

    //use alert context
    const alertCtx = useContext(AlertContext);

    const { errors } = formState;
    const { isValid } = formState;

    const [currentPasswordValid, setCurrentPasswordValid] = useState(false);

    //get user data for table
    const fillInputs = () => {

        setValue("name", fieldsData?.nombre)
        setValue("lastName", fieldsData?.primerApellido)
        setValue("secondSurname", fieldsData?.segundoApellido)
        setValue("phone", fieldsData?.telefono)
        setValue("email", fieldsData?.correo)

    }

    //if user set empty field, then delete values from password
    const validateEmptyPassword = () => {
        const input = getValues("currentPassword")

        if (input === '') {
            setValue("password", "")
            setValue("passwordc", "")

            setCurrentPasswordValid(false)
        }
    }

    //function to save validate user input password to BD
    async function validateCurrentPassword() {
        const currentPassword = getValues("currentPassword")

        const data = {
            idUsuario: idUsuario,
            contrasena: currentPassword,
            correo: '',
            nombre: '',
            primerApellido: '',
            segundoApellido: '',
            telefono: '',
        }

        const response = await ObtenerContrasenaActual(data);

        if (response.indicador === 0) {
            alertCtx.success(response.mensaje);
            setCurrentPasswordValid(true);
        } else {
            alertCtx.error(response.mensaje);
            setCurrentPasswordValid(false);
        }

    }

    useState(() => {
        //set inputs values from BD
        fillInputs();
    }, [])

    //edit profile data and/or update password
    const onSaveAddOptions = async () => {

        const email = getValues("email");
        const name = getValues("name");
        const lastName = getValues("lastName");
        const secondSurName = getValues("secondSurname");
        const phone = getValues("phone");
        const password = getValues("password");

        const jsonUsuario = sessionStorage.getItem("USER_DATA");
        const usuario = JSON.parse(jsonUsuario);

        //only when inputs are valid
        if (isValid) {
            const data = {
                idUsuario: idUsuario,
                correo: email,
                nombre: name,
                primerApellido: lastName,
                segundoApellido: secondSurName,
                telefono: phone,
                usuarioModificacion: String(usuario.Correo),
                contrasena: password
            }

            const response = await EditarPerfilUsuario(data);

            if (response.indicador === 0) {

                alertCtx.success(response.mensaje);
                setShowModalEdit(false);
                //recall user last data
                getUserById();
            } else {
                alertCtx.error(response.mensaje);
            }
        } else {
            alertCtx.error("Datos incorrectos");
        }


    }

    return (
        <>
            <div className="w-full container mx-auto items-center justify-center">
                <div className="flex flex-col">

                    <div className="flex text-base m-3 justify-center mx-auto font-bold leading-tight tracking-tight text-green-500 rounded-full w-8 h-8 mb-4 bg-grayLight-RS">
                        <UserIcon className="w-8 h-8 " alt="user photo" />
                    </div>

                    <div>
                        <h1 className="flex text-base justify-center mx-auto font-bold leading-tight tracking-tight text-black-RS">
                            Editar Perfil
                        </h1>
                    </div>
                </div>
                <form className="space-y-2 md:space-y-4">
                    {/*First row*/}
                    <div className="flex flex-col mt-2 mx-auto items-center justify-center md:flex-row">

                        <div className="w-full m-1 px-1  md:w-80">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nombre</label>
                            <label className="relative flex items-center">
                                <input
                                    type="text"
                                    name="name"
                                    {...register("name", { required: true, maxLength: 25, pattern: /^[a-zA-ZñÑ\s\W]/ })}
                                    id="name"
                                    className="text-gray-900 sm:text-sm block w-full p-2.5 mb-2 pl-3 pr-10 rounded border-solid border-2 border-grayDark-RS"
                                    placeholder="Nombre"
                                />
                                <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                                    <UserIcon className="h-5 w-5 pointer-events-none text-dark-500" />
                                </span>
                            </label>

                            {/*iterate over validations and show message name*/}
                            {validations.name?.map((values) => {
                                return (
                                    errors.name?.type === values.type &&
                                    <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                        <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                    </span>
                                )
                            })}
                        </div>

                        <div className="w-full m-1 px-1  md:w-80">

                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Primer Apellido</label>
                            <label className="relative flex items-center">
                                <input
                                    type="text"
                                    name="lastName"
                                    {...register("lastName", { required: true, maxLength: 25, pattern: /^[a-zA-ZñÑ\s\W]/ })}
                                    id="lastname"
                                    className="text-gray-900 sm:text-sm block w-full  p-2.5 mb-2 pl-3 pr-10 rounded border-solid border-2 border-grayDark-RS"
                                    placeholder="Primer Apellido"
                                />
                                <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                                    <UserIcon className="h-5 w-5 pointer-events-none text-dark-500" />
                                </span>
                            </label>
                            {/*iterate over validations and show message lastName*/}
                            {validations.lastName?.map((values) => {
                                return (
                                    errors.lastName?.type === values.type &&
                                    <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                        <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                    </span>
                                )
                            })}
                        </div>
                        <div className="w-full m-1 px-1  md:w-80">

                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Segundo Apellido</label>
                            <label className="relative flex items-center">
                                <input
                                    type="text"
                                    name="secondSurname"
                                    {...register("secondSurname", { required: true, maxLength: 25, pattern: /^[a-zA-ZñÑ\s\W]/ })}
                                    id="secondSurname"
                                    className="text-gray-900 sm:text-sm block w-full  p-2.5 mb-2 pl-3 pr-10 rounded border-solid border-2 border-grayDark-RS"
                                    placeholder="Segundo Apellido"
                                />
                                <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                                    <UserIcon className="h-5 w-5 pointer-events-none text-dark-500" />
                                </span>
                            </label>
                            {/*iterate over validations and show message lastName*/}
                            {validations.secondSurname?.map((values) => {
                                return (
                                    errors.secondSurname?.type === values.type &&
                                    <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                        <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                    </span>
                                )
                            })}

                        </div>
                    </div>
                    {/*Second row*/}
                    <div className="flex flex-col mx-2 mx-auto items-center justify-center md:flex-row">

                        <div className="w-full m-1  md:w-80">

                            <label className="block mb-2 text-sm font-medium text-gray-900">Correo eléctronico</label>
                            <label className="relative flex items-center">
                                <input
                                    type="text"
                                    name="email"
                                    {...register("email", { required: true, maxLength: 150, pattern: /^(.+)@(.+)$/ })}
                                    id="email"
                                    className="text-gray-900 sm:text-sm block w-full p-2.5 mb-2 pl-3 pr-10 rounded border-solid border-2 border-grayDark-RS"
                                    placeholder="Correo eléctronico"
                                />
                                <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                                    <EnvelopeIcon className="h-5 w-5 pointer-events-none text-dark-500" />
                                </span>
                            </label>
                            {/*iterate over validations and show message email*/}
                            {validations.email?.map((values) => {
                                return (
                                    errors.email?.type === values.type &&
                                    <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                        <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                    </span>
                                )
                            })}
                        </div>
                        <div className="w-full m-1 md:w-80 mb-2.5">

                            <label className="block mb-2 text-sm font-medium text-gray-900 ">Teléfono</label>
                            <label className="relative block">
                                <Controller
                                    control={control}
                                    name="phone"
                                    render={({ field: { ref } }) => (
                                        <PhoneInput
                                            {...register('phone', { required: true })}
                                            specialLabel={''}
                                            country={'cr'}
                                            value={getValues("phone")}
                                            onChange={(value) => {
                                                setValue("phone", value)
                                                //validateForm();
                                                //use this to force detect isValid form state
                                                if (value.length > 0) {
                                                    resetField("phone", { keepError: false })
                                                    setValue("phone", value)
                                                } else {
                                                    resetField("phone", { keepError: true })
                                                }
                                            }
                                            }

                                            isValid={(value) => {
                                                if (value.length === 0) {
                                                    return 'Campo requerido';
                                                } else {
                                                    return true;
                                                }
                                            }}
                                            placeholder="Teléfono"
                                            className="text-gray-900 sm:text-sm  !hover:border-grayDark-RS" />


                                    )}

                                />
                                <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                                    <PhoneIcon className="h-5 w-5 pointer-events-none text-dark-500" />
                                </span>
                            </label>
                        </div>
                        {/*Contraseña actual Input*/}
                        <div className="w-full m-1 px-1  md:w-80">

                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Contraseña actual </label>
                            <label className="relative flex items-center">
                                <input
                                    type="password"
                                    name="currentPassword"
                                    {...register("currentPassword", {
                                        required: false, onChange: validateEmptyPassword
                                    })}
                                    id="currentPassword"
                                    className="text-gray-900 sm:text-sm block w-full p-2.5 mb-2 pl-3 pr-10 rounded border-solid border-2 border-grayDark-RS"
                                    placeholder="Contraseña actual"
                                />
                                <button type="button" className="mx-2 text-white bg-greenButton mb-2 hover:bg-primary-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                    onClick={validateCurrentPassword}>Validar</button>
                            </label>
                            {/*iterate over validations and show message password*/}
                            {validations.currentPassword?.map((values) => {
                                return (
                                    errors.currentPassword?.type === values.type &&
                                    <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                        <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                    </span>
                                )
                            })}
                        </div>
                    </div>

                    {currentPasswordValid &&

                        <div className="flex flex-col mx-2 mx-auto items-center justify-center md:flex-row">

                            {/*Contraseña Input*/}
                            <div className="w-full m-1 px-1  md:w-80">

                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nueva Contraseña </label>
                                <div className="relative flex items-center">
                                    <input
                                        type="password"
                                        name="password"
                                        {...register("password", {
                                            required: true,
                                            minLength: 6,
                                            maxLength: 12
                                        })}
                                        id="password"
                                        className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS"
                                        placeholder="••••••••"
                                    />

                                </div>
                                {/*iterate over validations and show message password*/}
                                {validations.password?.map((values) => {
                                    return (
                                        errors.password?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>
                            <div className="w-full m-1 px-1  md:w-80">

                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirmar Contraseña </label>

                                <div className="relative flex items-center">
                                    <input type="password"
                                        name="passwordc"
                                        {...register("passwordc", {
                                            required: true,
                                            minLength: 6,
                                            maxLength: 12,
                                            validate: (value) => value == watch('password')
                                        })}
                                        id="passwordc"
                                        className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS"
                                        placeholder="Confirmar Contraseña"
                                    />

                                </div>
                                {/*iterate over validations and show message password*/}
                                {validations.repeatPassword?.map((values) => {
                                    return (
                                        errors.passwordc?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>

                        </div>

                    }
                    {!currentPasswordValid &&
                        <div className="flex text-base m-3 justify-center mx-auto font-bold leading-tight tracking-tight text-black-RS mb-4 bg-grayLight-RS w-4/5">
                            <p className="font-sans text-base font-bold">Si desea cambiar su contraseña, escriba su contraseña actual y presione el botón validar</p>
                        </div>
                    }
                    <div className=" flex flex-wrap z-10 mb-2 mx-auto items-center justify-center ">
                        <button type="button" className="py-2 px-4 mx-2 my-5 text-white bg-black-RS  hover:bg-primary-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                            onClick={() => setShowModalEdit(false)} >Cancelar</button>
                        <button type="button" className="py-2 px-4 mx-2 my-5 text-white bg-greenButton  hover:bg-primary-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            onClick={onSaveAddOptions}>Actualizar</button>
                    </div>
                </form>
            </div>
        </>
    )

}

export default ChangePassword;