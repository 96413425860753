import React, { useState, useRef, useCallback, useContext, useEffect } from 'react';

import { StarIcon } from '@heroicons/react/24/outline';
import AlertContext from '../../GlobalComponents/Alert/contexts/alertContext';

//import global components
import { Modal, ModalActionConfirm } from '../../../imports';

const Star = ({ index, id, technologieName, techList, setListRating, allowEdit, deleteElementCV, userCompleteCV, idCurriculum }) => {

    //use to set colors in stars when hover and click events
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);

    //flag to point into box selected 
    const [detectStar, setDetect] = useState(false);
    const [modalConfirmVisible, setModalConfirmVisible] = useState(false);
    const [actionTypeConfirmModal, setActionTypeConfirmModal] = useState("");

    const alertCtx = useContext(AlertContext);

    const checkbox = useRef([]);

    const deleteTech = async () => {
        if (userCompleteCV === "true") {
    
            const response = await deleteElementCV(id, idCurriculum);
           
            if (response.indicador == 0) {
                alertCtx.success(response.mensaje);
                setModalConfirmVisible(false);
            }
        }
    }
    const detect = () =>  {
        const idStar = id;// = event.currentTarget.id

        if (!checkbox.current[id]?.checked) {
            //here we delete value from array if user uncheck input
            if (userCompleteCV === "true") {
                setActionTypeConfirmModal("Eliminar")
                setModalConfirmVisible(true);
            }
            //filter array of key value to validate if object exists and then update value
            let newArr = techList?.filter(object => {
                return object.idTecnologia !== idStar;

            });

            if (newArr.length === 0) {
                techList.forEach((item) => {

                    techList.splice(item, 1);

                });

            } else {
                setListRating(newArr);
            }
        } 
    
        setDetect(!detectStar);
    }

    //we call
    const finalDataTech =  useCallback(() => {

        return techList?.map((item, index) => {
            if (item.idTecnologia === id) {
                if (techList[index]) {
                    checkbox.current[id].checked = true;
                    setDetect(!detectStar);
                    setRating(techList[index]?.expertiz);
                }
            }
        })


    }, [detect])

    useEffect(() => {
        if (techList?.length > 0) {
            finalDataTech();
        }
     
        return (() => {
           finalDataTech();
        })
    }, [])

    return (
        <>

            {/*Here we show view confirm delete modal compononent*/}
            <Modal isVisible={modalConfirmVisible} onClose={() => { setModalConfirmVisible(false) }} handleSubmit={() => null} onSaveOptions={null} showFotter={false} showCloseIcon={false}>
                <ModalActionConfirm onSaveAction={deleteTech} actionType={""} setshowConfirmModal={setModalConfirmVisible} />
            </Modal>

            {/*each box contains input checkbox and 5 stars*/ }
            <div className="flex w-full sm:w-80 mt-1 h-16 bg-white rounded-md shadow-lg items-center justify-center shadow-gray-200 border border-r-4 border-grayDark-RS hover:-translate-y-1">
                {/*input checkbox*/}
                <div className="w-1/3 sm:w-2/5 ml-1 ">
                    <input id={id} type="checkbox" value={id} onClick={detect} ref={input => checkbox.current[id] = input} disabled={allowEdit} className={detectStar ? "cursor-pointer !bg-black-RS  border-grayDark-RS border-t-2 border-b-2 border-l-2 border-r-2" : "cursor-pointer !bg-grayLight-RS  border-grayDark-RS border-t-2 border-b-2 border-l-2 border-r-2" }  />
                    <label className="ml-0.5 p-px text-xs sm:text-sm">
                        {technologieName}
                    </label>
                </div>
                {/*5 stars*/}
                <div className={allowEdit ? "pointer-events-none w-2/3 sm:w-3/5" : "w-2/3 sm:w-3/5"}>
                    {[...Array(5)].map((star, index) => {
                        index += 1;
                        //use new key for avoid problem with duplicate keys for buttons
                        let indexForKey = index;
                        indexForKey += 1;
                        //here if user select stars and unchecked, we dissapear stars rating
                        if (rating && !checkbox.current[id]?.checked) {
                            index = 6;
                        }

                        return (
                            <button
                                type="button"
                                key={indexForKey}
                                disabled={!detectStar}
                                className={index <= (hover || rating) ? "!fill-red-RS !text-red-RS h-5 w-5 sm:h-8 w-8" : "!fill-grayLight-RS !text-grayLight-RS h-5 w-5 sm:h-8 w-8"}
                                onClick={() => {

                                    //pass current star selected or 0 if detect is unchecked 
                                    setRating(index)

                                    //set id and ratevalue base on index into object
                                    let rateObject = {
                                        idTecnologia: id,
                                        expertiz: index,
                                        checked: true,
                                    };

                                    //filter array to validate if object exists and then update value
                                    if (techList) {
                                        const filtered = techList.filter(obj => {
                                            return obj.idTecnologia === id;
                                        });

                                        if (filtered.length > 0) {
                                            const newArray = techList.map(item => {
                                                if (id === item.idTecnologia) {
                                                    return { ...item, expertiz: index };
                                                } else {
                                                    return item;
                                                }
                                            });
                                            setListRating(newArray);
                                        } else {
                                            setListRating(prev => [...prev, rateObject])
                                        }
                                    }
                                  

                                   

                                }}
                                onMouseEnter={() => setHover(index)}
                                onMouseLeave={() => setHover(rating)}
                            >
                                <StarIcon className={index <= (hover || rating) ? "!fill-red-RS !text-red-RS h-5 w-5 sm:h-8 w-8" : "!fill-grayLight-RS !text-grayLight-RS h-5 w-5 sm:h-8 w-8"} />
                            </button>
                        );
                    })}
                </div>
            </div>
        </>
    )
}

export default Star;