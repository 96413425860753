import { ProcesarDatosApi } from "../api/ApiFetch";
const controladorUsuario = "usuario";



export const ObtenerUsuarios = async () => {
    const url = `${controladorUsuario}/ObtenerUsuarios`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerRolUsuario = async () => {
    const url = `${controladorUsuario}/ObtenerRolesPorUsuario`;
    return await ProcesarDatosApi('GET', url);
}


export const EditarUsuario = async (data) => {
    const url = `${controladorUsuario}/EditarUsuario`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const ObtenerUsuarioPorId = async (id) => {
    const url = `${controladorUsuario}/ObtenerUsuarioPorId/${id}`;
    return await ProcesarDatosApi('GET', url);
}

export const AgregarUsuarios = async (data) => {
    const url = `${controladorUsuario}/AgregarUsuarios`;
    return await ProcesarDatosApi('POST', url, data);
}

export const DesactivarUsuario = async (data) => {
    const url = `${controladorUsuario}/DesactivarUsuario`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const ObtenerCorreoValido = async (correo) => {
    const url = `${controladorUsuario}/ObtenerCorreoValido/${correo}`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerContrasenaTemporal = async (correo) => {
    const url = `${controladorUsuario}/ObtenerContrasenaTemporal/${correo}`;
    return await ProcesarDatosApi('GET', url);
}

export const EnviarContrasenaTemporal = async (data) => {
    const url = `${controladorUsuario}/EnviarContrasenaTemporal`;
    return await ProcesarDatosApi('POST', url, data);
}

export const ObtenerContrasenaActual = async (data) => {
    const url = `${controladorUsuario}/ObtenerContrasenaActual`;
    return await ProcesarDatosApi('POST', url, data);
}

export const EditarPerfilUsuario = async (data) => {
    const url = `${controladorUsuario}/EditarPerfilUsuario`;
    return await ProcesarDatosApi('PUT', url, data);
}

