import React, { useState, useEffect, useRef, useContext } from 'react';
import { useForm } from 'react-hook-form';
import AlertContext from '../../../GlobalComponents/Alert/contexts/alertContext';

//import global components
import { Card, Modal } from '../../../../imports';

//import services
import { ObtenerGradoAcademico } from '../../../../servicios/ServicioEducacion';

//import icons from heroicons
import { AcademicCapIcon, PlusCircleIcon } from '@heroicons/react/24/outline';

export default function Certificaciones({ validationMessages, userData, setUserData, finalData, setstateEducacion, allowEdit, listCertificationsByPerson }) {

    //use alert context
    const alertCtx = useContext(AlertContext);

    //here we pass a default input property to save in userData
    //all inputs use this same event in 'onchange'
    const handleChange = (e) => {
        const { name, value } = e.target;

        setValue(name, e.target.value)
    };


    //use react hook form to get values and make validations
    const { register, reset, getValues, setValue, handleSubmit, formState } = useForm({
        mode: 'all',
        defaultValues: {
            nameTitle: '',
            institution: '',
            fileInput: '',
        }
    });


    const { errors } = formState;
    const { isDirty, isValid } = formState;

    //use to get validations from props and later iterate
    const [validations, setValidations] = useState([]);

    const [showModal, setShowModal] = useState(false);

    const [selectedFile, setSelectedFile] = useState(null);


    const [listCertifications, setListCertifications] = useState([]);

    const [currentIdCard, setCurrentIdCard] = useState(null);

    const [fileName, setFileName] = useState('');

    const [filePath, setFilePath] = useState('');

    const SaveCertificationOptions = () => {

    }

    const resetAllInputs = () => {
        //reset all default values
        reset({
            nameTitle: "",
            institution: "",
            fileInput: "",
        }, {
            keepErrors: false,
            keepDirty: false,
        })

        setSelectedFile("");
    }


    function setValuesById(list) {

        setValue("nameTitle", list.dataForGlobalList?.nameTitle)
        setValue("institution", list.dataForGlobalList?.institution)
        setValue("fileInput", list.dataForGlobalList?.fileInput)

        setFileName(list.dataForGlobalList?.fileNameValue)
        setFilePath(list.dataForGlobalList?.filePathValue)
        setSelectedFile(list.dataForGlobalList?.fileNameValue)
    }

    const selectFile = (event) => {
        event.preventDefault();

        const userfile = event.target.files[0]; //get file selected
        const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];

        if (userfile && validTypes.includes(userfile.type)) {
            setValue("fileInput", userfile.name)
            setFileName(userfile.name)//show name of the file to user
            setSelectedFile(userfile.name)
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result.split(",")[1];
                setFilePath(base64String);
            };
            reader.readAsDataURL(userfile);
        } else {
            alertCtx.error("¡Solo se aceptan imagenes o pdf!");
        }
    }

    /*const validateForm = () => {

        let state = false;

        listEducation?.length > 0 ? state = true : state = false;

        setstateEducacion(state);
    }*/

    //create custom object with list by person id to render cards
    function customObjectByPersonList() {

        listCertificationsByPerson.map((item) => {

            const startMonth = new Date(item?.fechaInicio).getMonth() + 1;
            const startYear = new Date(item?.fechaInicio).getFullYear();
            const startDay = new Date(item?.fechaInicio).getDay();
            const startDateFormatted = `${startYear}-${startMonth}-${startDay}`;
            const startDate = new Date(startDateFormatted)

            const endMonth = new Date(item?.fechaFin).getMonth() + 1;
            const endYear = new Date(item?.fechaFin).getFullYear();
            const endDay = new Date(item?.fechaFin).getDay();
            const endDateFormatted = `${endYear}-${endMonth}-${endDay}`;
            const endDate = new Date(endDateFormatted);

            //we save global object and list with key and value to get easy in card iteration over data
            const dataForGlobalList = {
                nameTitle: item?.nombreTitulo,
                institution: item?.institucion,
                educationLevel: item?.idGrado,
                startDate: item?.fechaInicio ? startDate.toLocaleDateString('fr-CA') : "",
                finishDate: item?.fechaFin ? endDate.toLocaleDateString('fr-CA') : "",
                inCourse: item?.cursando ? true : false,
                fileInput: item?.imgTitulo,
                fileNameValue: item?.imgNombre,
                filePathValue: item?.imgTitulo,
                grade: item?.grado,
                fileExtensionValue: item?.extension,
            }

            const object = {
                "dataHeader":
                {
                    "Titulo": item?.nombreTitulo,
                    "Nivel Educacion": item?.grado,
                },
                "dataBody":
                {
                    "Institucion": item.institucion,
                },
                "dataForGlobalList": dataForGlobalList
            }


            listCertifications.push(object);
        })

    }


    useEffect(() => {


        setValidations(validationMessages);
        //fill data by idPerson
        if (listCertificationsByPerson.length > 0) {
            customObjectByPersonList()
        } 
    }, [listCertifications, listCertificationsByPerson])

    return (
        <div className="w-full flex flex-col md:flex-row items-center justify-center px-1  mx-auto lg:py-0">
            {/*Block to manage form input 'info personal'--*/}
            <div className="w-full pt-1 bg-white rounded-lg shadow-xl xl:p-0">
                <div className="relative container mx-auto items-center justify-center">

                    {!allowEdit && <div>
                        {listCertifications?.length > 0 ? null : (
                            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                <h1 className="text-decoration-line: underline">Por favor, presione Agregar para completar el apartado de certificaciones</h1>
                            </div>
                        )}
                    </div>
                    }

                    {listCertifications?.length > 0 &&
                        <div className="container flex flex-wrap w-full  mx-auto items-start justify-center">
                            {/*iterate over list techs */}
                            {listCertifications?.map((item, index) => (
                                <div className="w-full m-1 p-1 py-24 md:w-96" key={index}>
                                    <Card id={index} data={item} list={listCertifications} setList={setListCertifications} setCurrentIdCard={setCurrentIdCard} setShowModal={setShowModal} setValuesById={setValuesById} allowEdit={allowEdit} />
                                </div>
                            ))}
                        </div>
                    }
                    {/*here move button to top right if list is not null*/}
                    {!allowEdit && <div>
                        {listCertifications?.length > 0 ? <div className="flex absolute top-0 right-0 md:justify-center items-center mb-4 px-2 ">
                            <button disabled={allowEdit} className="bg-greenButton hover:bg-primary-700 text-white text-sm  py-2 px-4 rounded inline-flex items-center" onClick={() => setShowModal(true)}>
                                <span>Agregar</span>
                                <PlusCircleIcon className="w-8 h-8 ml-2"></PlusCircleIcon>
                            </button>
                        </div> :
                            <div className="flex justify-center items-center mb-4 px-2">
                                <button disabled={allowEdit} className="bg-greenButton hover:bg-primary-700 text-white text-sm  py-2 px-4 rounded inline-flex items-center" onClick={() => setShowModal(true)}>
                                    <span>Agregar</span>
                                    <PlusCircleIcon className="w-8 h-8 ml-2"></PlusCircleIcon>
                                </button>
                            </div>
                        }
                    </div>
                    }

                    <Modal isVisible={showModal} onClose={() => { setShowModal(false); resetAllInputs(); }} handleSubmit={handleSubmit} onSaveOptions={SaveCertificationOptions}>
                        <form className="mt-2 mb-2">
                            {/*First row*/}
                            <div className="flex flex-wrap mx-2 mx-auto items-center justify-center">
                                {/*Nombre titulo Input*/}
                                <div className="w-full m-2 px-1 sm:w-48">
                                    <label className="block mb-2 text-sm font-medium text-gray-900">Nombre del titulo</label>
                                    <div className="relative flex items-center">
                                        <AcademicCapIcon className="h-5 w-5 absolute pointer-events-none text-dark-500 inset-y-2 right-0 mr-2.5" />
                                        {/*here we don´t pass setUserData directly, because we are managing a list*/}
                                        <input
                                            type="text"
                                            maxLength="60"
                                            name="nameTitle"
                                            {...register("nameTitle", { required: true, maxLength: 60, pattern: /^[\wÀ-ÿ\u00f1\u00d1]+(\s*[\wÀ-ÿ\u00f1\u00d1]*)*[\wÀ-ÿ\u00f1\u00d1]+$/ })}
                                            id="nameTitle"
                                            className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS " placeholder="Nombre del titulo"
                                        />
                                    </div>
                                    {/*iterate over validations and show message tittle name*/}
                                    {validations.nameTitle?.map((values) => {
                                        return (
                                            errors.nameTitle?.type === values.type &&
                                            <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                                <p className="text-sm font-bold text-red-RS mt-2">{values.message}</p>
                                            </span>
                                        )
                                    })}
                                </div>


                                {/*Institucion Input*/}
                                <div className="w-full m-2 px-1 sm:w-48">
                                    <label className="block mb-2 text-sm font-medium text-gray-900">Institucion</label>
                                    <div className="relative flex items-center">
                                        <AcademicCapIcon className="h-5 w-5 absolute pointer-events-none text-dark-500 inset-y-2 right-0 mr-2.5" />
                                        <input type="text"
                                            maxLength="60"
                                            name="institution"
                                            {...register("institution", { required: true, maxLength: 60, pattern: /^[\wÀ-ÿ\u00f1\u00d1]+(\s*[\wÀ-ÿ\u00f1\u00d1]*)*[\wÀ-ÿ\u00f1\u00d1]+$/ })}
                                            id="institution"
                                            className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS " placeholder="Institución"
                                        />
                                    </div>
                                    {/*iterate over validations and show message institution*/}
                                    {validations.institution?.map((values) => {
                                        return (
                                            errors.institution?.type === values.type &&
                                            <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                                <p className="text-sm font-bold text-red-RS mt-2">{values.message}</p>
                                            </span>
                                        )
                                    })}
                                </div>
                            </div>
                            {/*Second row*/}
                            <div className="flex flex-wrap mx-2 mt-4 mx-auto items-center justify-center">

                                {/*Adjuntar Input*/}
                                <div className="w-full m-3 px-2 md:w-96">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">Adjuntar titulo</label>
                                    <div className="relative">
                                        <div className="flex flex-col">
                                            <input type="file"
                                                id="fileInput"
                                                name="fileInput"
                                                accept=".jpg,.jpeg,.png,.pdf"
                                                onChange={selectFile}
                                                className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS "
                                                placeholder="Adjuntar titulo"
                                            />


                                        </div>
                                    </div>

                                    {selectedFile && (
                                        <span className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-black-RS mt-2">{selectedFile.split('/').pop()}</p>
                                        </span>
                                    )}
                                    {/*iterate over validations and show message tittle*/}
                                    {validations.fileInput?.map((values) => {
                                        return (
                                            errors.fileInput?.type === values.type &&
                                            <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                                <p className="text-sm font-bold text-red-RS mt-2">{values.message}</p>
                                            </span>
                                        )
                                    })}
                                </div>
                            </div>
                        </form>
                    </Modal>
                </div>
            </div>
        </div>
    );
}