import { ProcesarDatosApi } from "../api/ApiFetch";
const controladorEsp = "especializacion";
const controladorEst = "estadocivil";
const controladorInfo = "infopersona";


export const ObtenerEspecialidades = async () => {
    const url = `${controladorEsp}/ObtenerEspecializacion`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerEstadoCivil = async () => {
    const url = `${controladorEst}/ObtenerEstadoCivil`;
    return await ProcesarDatosApi('GET', url);
}

export const AgregarPersona = async (data) => {
    const url = `${controladorInfo}/AgregarPersona`;
    return await ProcesarDatosApi('POST', url, data);
}

export const ObtenerInfoPersonalPorId = async (idPersona) => {
    const url = `${controladorInfo}/ObtenerInfoPersonalPorId/${idPersona}`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerIDPersona = async (idUsuario) => {
    const url = `${controladorInfo}/ObtenerIDPersona/${idUsuario}`;
    return await ProcesarDatosApi('GET', url);
}

export const EditarPersona = async (data) => {
    const url = `${controladorInfo}/EditarPersona`;
    return await ProcesarDatosApi('PUT', url, data);
}
