import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
//import global components
import { Modal, ChangePassword } from '../../../imports';
//import icons from heroicons
import { Bars3Icon, ChevronDownIcon, DocumentTextIcon, ExclamationTriangleIcon, WrenchScrewdriverIcon, ArrowRightOnRectangleIcon, UserCircleIcon, XMarkIcon, ClipboardDocumentListIcon} from '@heroicons/react/24/outline';
import Logo from '../../../assets/logo.png';

//import services
import { ObtenerUsuarioPorId } from '../../../servicios/ServicioUsuario';

const Sidebar = ({ email }) => {
    //use modal and show for delete
    const [showModal, setShowModal] = useState(false);

    const [isSubMenuHidden, setIsSubMenuHidden] = useState(true);
    const [isSubMenuReportsHidden, setIsSubMenuReportsHidden] = useState(true);
    const [isSidebarHidden, setIsSidebarHidden] = useState(false);
    const [isControlShow, setIsControlShow] = useState(false)

    //for show profile data
    //show edit profile component
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [fieldsData, setFieldsData] = useState([])
    const [idUsuario, setIdUsuario] = useState(0);

    const history = useHistory();

    const { handleSubmit } = useForm({
        mode: 'all',

    });

    const onClosesession = () => {

        sessionStorage.clear();
        window.location.replace('/login');
    }


    //get user data for table
    async function getUserById() {
        const jsonUsuario = sessionStorage.getItem("USER_DATA");
        const usuario = JSON.parse(jsonUsuario);
        const response = await ObtenerUsuarioPorId(usuario.IdUsuario);
        setFieldsData(response)
        setIdUsuario(usuario.IdUsuario);
    }

    useState(() => {
        getUserById();
        setShowModal(false);
    }, [])

    const toggleSubMenu = () => {
        setIsSubMenuHidden(!isSubMenuHidden);
    }

    const toggleSubMenuReports = () => {
        setIsSubMenuReportsHidden(!isSubMenuReportsHidden);
    }

    const toggleSidebar = () => {
        setIsSidebarHidden(!isSidebarHidden);
    }



    //use to show sing out component 
    const SingoutComponent = () => {
        return (
            <form className="space-y-2 md:space-y-4">
                <div className="w-96 container mx-auto items-center justify-center">
                    <div className="flex flex-col">
                        <div className="flex text-base m-2 justify-center mx-auto font-bold leading-tight tracking-tight text-red-RS">
                            <ExclamationTriangleIcon className="w-8 h-8 ml-2"></ExclamationTriangleIcon>
                        </div>
                        <div>
                            <h1 className="flex text-base justify-center mx-auto font-bold leading-tight tracking-tight text-black-RS">
                                Advertencia
                            </h1>
                        </div>

                        <div>
                            <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">¿Está seguro que desea cerrar sesión?</label>
                        </div>

                    </div>
                </div>
            </form>
        )
    }

    useEffect(() => {


        if (window.innerWidth < 900) {
            setIsControlShow(true);
        }

        const handleResize = () => {
            if (window.innerWidth >= 640) {
                setIsSidebarHidden(false);
                setIsControlShow(false);
            } else {
                setIsControlShow(true);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [showModal]);

    return (
        <>

            <div className="mr-2">

                <span
                    className={`text-black-RS text-4xl top-5 left-4 cursor-pointer ${isControlShow ? 'absolute' : 'hidden'}`}
                    onClick={toggleSidebar}
                >
                    <Bars3Icon className="h-8 w-8 mr-2" />

                </span>
                <div
                    className={`h-[990px] px-4 py-8 fixed z-40 top-0 bottom-0 left-0 p-2 w-80 md:z-0 md:relative  text-center bg-black-RS ${isSidebarHidden ? 'translate-x-[-100%]' : 'translate-x-0'} transition-transform duration-300 ease-in`}
                >
                <div className="absolute h-full px-2 overflow-y-auto">
                <figure className="flex mx-auto justify-center w-40 h-28 py-4">
                    <img src={Logo} alt="Logo RS" />
                </figure>
                   


                        <Link to="/curriculums" className="flex items-center text-white hover:text-black-RS">
                            <div className="p-2.5 mt-3 w-full flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-grayLight-RS text-white hover:text-black-RS ">
                                <DocumentTextIcon className="h-8 w-8 mr-2" />
                                <span className="text-[15px] ml-4 font-bold">Currículums</span>
                            </div>
                        </Link>
                        <div className="my-4 bg-gray-600 h-[1px]"></div>

                        <div
                            className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-grayLight-RS text-white hover:text-black-RS"
                            onClick={toggleSubMenu}>

                            <WrenchScrewdriverIcon className="h-8 w-8 mr-2" />

                            <div className="flex justify-between w-full hover:text-black-RS items-center">

                                <span className="text-[15px] ml-4 font-bold">Parámetros</span>
                                <span
                                    className={`text-sm transform ${isSubMenuHidden ? '' : 'rotate-180'} transition-transform duration-300 ease-in`}
                                >
                                    <ChevronDownIcon className="h-8 w-8" />
                                </span>

                            </div>

                        </div>

                        <div
                            className={`text-left text-sm mt-2 w-4/5 mx-auto text-gray-200 font-bold transition-opacity duration-300 ease-in ${isSubMenuHidden ? 'opacity-0 h-0 overflow-hidden' : 'opacity-100 h-auto'}`}>

                            <Link to="/role_catalog" className="text-white hover:bg-grayLight-RS">
                                <h1 className="cursor-pointer p-2 hover:bg-grayLight-RS hover:text-black-RS rounded-md mt-1">
                                    Roles
                                </h1>
                            </Link>
                            <Link to="/usuario_catalog" className="text-white hover:bg-grayLight-RS">
                                <h1 className="cursor-pointer p-2 hover:bg-grayLight-RS hover:text-black-RS rounded-md mt-1">
                                    Usuarios
                                </h1>
                            </Link>

                            <Link to="/tecnologia_catalog" className="text-white hover:bg-grayLight-RS">
                                <h1 className="cursor-pointer p-2 hover:bg-grayLight-RS hover:text-black-RS rounded-md mt-1">
                                    Tecnologías
                                </h1>
                            </Link>

                            <Link to="/grade_catalog" className="text-white hover:bg-grayLight-RS">
                                <h1 className="cursor-pointer p-2 hover:bg-grayLight-RS hover:text-black-RS rounded-md mt-1">
                                    Grados Académicos
                                </h1>
                            </Link>

                            <Link to="/especialidad_catalog" className="text-white hover:bg-grayLight-RS">
                                <h1 className="cursor-pointer p-2 hover:bg-grayLight-RS hover:text-black-RS rounded-md mt-1">
                                    Especialidades
                                </h1>
                            </Link>

                            <Link to="/tipoedu_catalog" className="text-white hover:bg-grayLight-RS">
                                <h1 className="cursor-pointer p-2 hover:bg-grayLight-RS hover:text-black-RS rounded-md mt-1">
                                    Tipo Educación
                                </h1>
                            </Link>
                            <Link to="/contacto_catalog" className="text-white hover:bg-grayLight-RS">
                                <h1 className="cursor-pointer p-2 hover:bg-grayLight-RS hover:text-black-RS rounded-md mt-1">
                                    Contactos
                                </h1>
                            </Link>

                        </div>

                        {/* MENU REPORTES */}

                        <div
                            className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-grayLight-RS text-white hover:text-black-RS"
                            onClick={toggleSubMenuReports}>

                            <ClipboardDocumentListIcon className="h-8 w-8 mr-2" />

                            <div className="flex justify-between w-full hover:text-black-RS items-center">

                                <span className="text-[15px] ml-4 font-bold">Reportes</span>
                                <span
                                    className={`text-sm transform ${isSubMenuReportsHidden ? '' : 'rotate-180'} transition-transform duration-300 ease-in`}
                                >
                                    <ChevronDownIcon className="h-8 w-8" />
                                </span>

                            </div>

                        </div>

                        <div
                            className={`text-left text-sm mt-2 w-4/5 mx-auto text-gray-200 font-bold transition-opacity duration-300 ease-in ${isSubMenuReportsHidden ? 'opacity-0 h-0 overflow-hidden' : 'opacity-100 h-auto'}`}>
                            {/*cambiar la ruta del catalogo*/}
                            <Link to="/reporte_gradoacademico" className="text-white hover:bg-grayLight-RS">
                                <h1 className="cursor-pointer p-2 hover:bg-grayLight-RS hover:text-black-RS rounded-md mt-1">
                                    Personas por Grado Académico
                                </h1>
                            </Link>
                            <Link to="/reporte_personas" className="text-white hover:bg-grayLight-RS">
                                <h1 className="cursor-pointer p-2 hover:bg-grayLight-RS hover:text-black-RS rounded-md mt-1">
                                    Personas Trabajando en Rossmon
                                </h1>
                            </Link>

                            <Link to="/reporte_tecnologias" className="text-white hover:bg-grayLight-RS">
                                <h1 className="cursor-pointer p-2 hover:bg-grayLight-RS hover:text-black-RS rounded-md mt-1">
                                    Profesionales por Tecnología
                                </h1>
                            </Link>

                            <Link to="/reporte_profesionalesespecialidad" className="text-white hover:bg-grayLight-RS">
                                <h1 className="cursor-pointer p-2 hover:bg-grayLight-RS hover:text-black-RS rounded-md mt-1">
                                    Profesionales por Especialidad
                                </h1>
                            </Link>

                        </div>

                        <button type="button" onClick={() => { setShowModal(!showModal) }} className="w-full flex items-center text-white hover:text-black-RS" >
                            <div className="p-2.5 mt-3 w-full flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-grayLight-RS text-white hover:text-black-RS ">
                                <ArrowRightOnRectangleIcon className="h-8 w-8 mr-2" />


                                <h1 className="text-[15px] ml-4 font-bold">
                                    Cerrar sesión
                                </h1>
                            </div>

                    </button>
                 
                   <div className="my-4 bg-gray-600 h-[1px]"></div>
                 
                <div className="bottom-0 left-0 right-0 p-2 text-center">
                    <button type="button" onClick={() => { setShowModalEdit(true) }} className="w-full flex items-center text-white hover:text-black-RS" >
                        <div className="p-2.5 mt-3 w-full flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-grayLight-RS text-white hover:text-black-RS ">
                            <UserCircleIcon className="h-8 w-8 mr-2" />


                            <h1 className="text-[15px] ml-4 font-bold">
                                {email}
                            </h1>
                        </div>

                    </button>
                </div>
                       



                       

                        <span
                            className={`text-white top-5 right-4 cursor-pointer ${isControlShow ? 'absolute' : 'hidden'}`}
                            onClick={toggleSidebar}
                        >
                            <XMarkIcon className="h-8 w-8 mr-2 top-5 right-4 cursor-pointer" />
                        </span>
                    </div>
                </div>

                <Modal isVisible={showModal} onClose={() => { setShowModal(false) }} handleSubmit={handleSubmit} onSaveOptions={onClosesession} showCloseIcon={false}>
                    {/*Button to close modal*/}

                    <SingoutComponent />
                </Modal>
                <Modal isVisible={showModalEdit} onClose={() => { setShowModalEdit(false) }} handleSubmit={() => null} onSaveOptions={null} showFotter={false} showCloseIcon={true}>
                    <ChangePassword setShowModalEdit={setShowModalEdit} fieldsData={fieldsData} idUsuario={idUsuario} getUserById={getUserById} />
                </Modal>
                </div>
            
        </>
    )
}

export default Sidebar;