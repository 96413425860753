import React from 'react';

//es la conexion con contactos GRid
import ContactosGridComponent from './ContactosGrid';

const ContactosCatalog= () => {


    return (
        <div>
            <ContactosGridComponent />
        </div>
    );
};

export default ContactosCatalog;