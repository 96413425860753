import React from 'react';
import ReporteProfesionalesEspecialidad from './ReporteProfesionalesEspecialidad';

const ReporteProfesionales = () => {


    return (
        <div>
            <ReporteProfesionalesEspecialidad />
        </div>
    );
};

export default ReporteProfesionales;