import { ProcesarDatosApi } from "../api/ApiFetch";

const controladorPersonaCV = "personacv";


export const ObtenerPersonasCV = async () => {
    const url = `${controladorPersonaCV}/ObtenerPersonasCV`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerPersonasCVFiltrado = async (data) => {
    const url = `${controladorPersonaCV}/ObtenerPersonasCVFiltrado`;
    return await ProcesarDatosApi('POST', url, data);
}

export const ObtenerPersonaConCV = async (idUsuario) => {
    const url = `${controladorPersonaCV}/ObtenerPersonaConCV/${idUsuario}`;
    return await ProcesarDatosApi('GET', url);
}