import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

//import adminComponents
import RoleCatalog from './components/AdminPages/Catalogs/Roles/RoleCatalog';
import UsuariosCatalog from './components/AdminPages/Catalogs/Usuarios/UsuariosCatalog';
import TechnologiesCatalog from './components/AdminPages/Catalogs/Technologies/TechnologiesCatalog';
import TipoEducacionCatalog from './components/AdminPages/Catalogs/TipoEducacion/TipoEducacion';
import Admin from './components/AdminPages/index';
import GridCurriculum from './components/AdminPages/GridCurriculum/index';
import ContactosCatalog from './components/AdminPages/Catalogs/Contactos/index';
import ReporteGradoAcademico from './components/AdminPages/Catalogs/ReporteGradoAcademico/index';
import ReportePersonas from './components/AdminPages/Catalogs/ReportePersonas/index';
import ReporteProfesionalesTecnologias from './components/AdminPages/Catalogs/ReporteProfesionalesTecnologias/index';
import ReporteProfesionalesEspecialidad from './components/AdminPages/Catalogs/ReporteProfesionalesEspecialidad/index';

//import components
import Login from './components/Authentication/Login/index';
import ResetPassword from './components/Authentication/ResetPassword/index';
import Register from './components/Authentication/Register/index';
import CVForm from './components/CVForm/CVForm-Component';
import Footer from './components/Footer/footer';
import CandidateCV from './components/CandidateCurriculum/candidateCV';
import Navbar from './components/Navbar/navbar';
import { AlertProvider } from './components/GlobalComponents/Alert/contexts/alertContext';
import Alert from './components/GlobalComponents/Alert/AlertComponent/alert';
//import CerrarSesion from './components/GlobalComponents/Sidebar/Index';
import { useHistory } from "react-router-dom";

//import directives from tailwind on index css file
import './index.min.css';
import GradeAcademicCatalog from './components/AdminPages/Catalogs/GradoAcademico/GradoAcademicoCatalog';

//import services
import { ObtenerPersonaConCV } from './servicios/ServicioPersonaCV';
import EspecialidadCatalog from './components/AdminPages/Catalogs/Especialidad/index';

//import TipoEducacionCatalog from './components/AdminPages/Catalogs/TipoEducacion/TipoEducacion';




const App = () => {

    const [sesionActiva, setSesionActiva] = useState(false);
    const [rol, setRol] = useState(null);
    const history = useHistory();

    //use to get specialty options
    const [userCompleteCV, setUserCompleteCV] = useState(false);


   

    const ValidarSesionActiva = () => {
        const fecha = new Date();
        const expiracion = sessionStorage.getItem("TOKEN_EXPIRATION");
        const token = sessionStorage.getItem("TOKEN_KEY");
        const jsonUsuario = sessionStorage.getItem("USER_DATA");
        const usuario = JSON.parse(jsonUsuario);
        const tokenExpiration = new Date(expiracion);
        const rol = sessionStorage.getItem("USER_ROLE");
 
        let usuarioValido = false;
        if (usuario !== null && usuario !== undefined) {
            usuarioValido = true;
            setRol(parseInt(rol, 10));
            const idUsuario = usuario.IdUsuario;
            getUserCompleteCV(idUsuario);
        }
        setSesionActiva(usuarioValido && token !== null && fecha <= tokenExpiration ? true : false);
    }

    useState(() => {
        ValidarSesionActiva();
    }, [])

    //function to save 'especialidades' in list
    async function getUserCompleteCV(idUsuario) {
        const response = await ObtenerPersonaConCV(idUsuario);

        if (response.indicador === 0) {

            setUserCompleteCV(true);
            const userComplete = true;
            sessionStorage.setItem("USER_COMPLETE_CV", userComplete);
        } else {
            setUserCompleteCV(false);
            const userComplete = false;
            sessionStorage.setItem("USER_COMPLETE_CV", userComplete);
        }
    }

    const CerrarSession = () => {
        setSesionActiva(false);
        sessionStorage.clear();
        history.push('/login');
    }

    return (
        <>
            <AlertProvider>
                <Alert />
                <Router>
                    <Switch>
                        <Route path="/register"><Register /></Route>
                        <Route path="/reset_password"><ResetPassword /></Route>
                        {sesionActiva &&  (
                            <>
                                {(() => {
                                    if (rol === 1) {
                                        if (userCompleteCV === true) {
                                            return (
                                                <>
                                                    <div className="flex flex-col h-screen">
                                                        <Navbar />

                                                        <main className="mb-auto">
                                                            <Route path="/" exact><CandidateCV /></Route>
                                                            <Route path="/candidate_curriculum"><CandidateCV /></Route>
                                                        </main>

                                                        <footer className="text-center items-center justify-center">
                                                            <Footer />
                                                        </footer>
                                                    </div>
                                                </>
                                            )
                                        }
                                        else  {
                                            return (
                                                <>
                                                    <div className="flex flex-col h-screen">
                                                        <Navbar />

                                                        <main className="mb-auto">
                                                            <Route path="/" exact><CVForm /></Route>
                                                            <Route path="/resume_register"><CVForm /></Route>
                                                        </main>
                                                       
                                                        <footer className=" text-center items-center justify-center">
                                                            <Footer/>
                                                        </footer>
                                                    </div>
                                                </>
                                            )
                                        }
                                    } else if (rol === 2) {
                                        return (
                                            <>
                                                <Route path="/" exact><Admin /></Route>
                                                <Route path="/admin"><Admin /></Route>
                                                <Route path="/curriculums"><GridCurriculum /></Route>
                                                <Route path="/role_catalog"><RoleCatalog /></Route>
                                                <Route path="/usuario_catalog"><UsuariosCatalog /></Route>
                                                <Route path="/especialidad_catalog"><EspecialidadCatalog /></Route>
                                                <Route path="/tecnologia_catalog"><TechnologiesCatalog /></Route>
                                                <Route path="/grade_catalog"><GradeAcademicCatalog /></Route>
                                                <Route path="/tipoedu_catalog"><TipoEducacionCatalog /></Route>
                                                <Route path="/contacto_catalog"><ContactosCatalog /></Route> 
                                                <Route path="/reporte_gradoacademico"><ReporteGradoAcademico /></Route>
                                                <Route path="/reporte_personas"><ReportePersonas /></Route>
                                                <Route path="/reporte_tecnologias"><ReporteProfesionalesTecnologias /></Route>
                                                <Route path="/reporte_profesionalesespecialidad"><ReporteProfesionalesEspecialidad /></Route>

                                            </>
                                        )
                                    }


                                })()}


                            </>
                        )}
                        {!sesionActiva && (
                   
                            <Switch>
                                <Route path="/" exact><Login ValidarSesionActiva={ValidarSesionActiva} /></Route>
                                <Route path="/login"><Login ValidarSesionActiva={ValidarSesionActiva} /></Route> 
                                  
                            </Switch>
                              
                        )}
                    </Switch>
                </Router>
            </AlertProvider>
        </>
    );
}

export default App;