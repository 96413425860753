import { ProcesarDatosApi } from "../api/ApiFetch";
const controlador = "gradoacademico";
const controladortipo = "tipoeducacion";

export const ObtenerGradoAcademico = async () => {
    const url = `${controlador}/ObtenerGradoAcademico`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerGradoAcademicoCompleto = async () => {
    const url = `${controlador}/ObtenerGradoAcademicoCompleto`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerTipoEducacion = async () => {
    const url = `${controladortipo}/ObtenerTipoEducacion`;
    return await ProcesarDatosApi('GET', url);
}

export const EditarGrado = async (data) => {
    const url = `${controlador}/EditarGrado`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const AgregarGrado = async (data) => {
    const url = `${controlador}/AgregarGrado`;
    return await ProcesarDatosApi('POST', url, data);
}

export const DesactivarGrado = async (data) => {
    const url = `${controlador}/DesactivarGrado`;
    return await ProcesarDatosApi('PUT', url, data);
}


