import React, { useState, useContext, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import AlertContext from '../../../GlobalComponents/Alert/contexts/alertContext';
//PhoneInput
import PhoneInput from 'react-phone-input-2';
import '../../../../../src/customInput.css';
//import global components
import { Modal, SideBar, AccordionCV, Table } from '../../../../imports';
//import services
import { ObtenerInfoContacto, EditarInfoContacto, DesactivarContacto, ObtenerInfoContactoPorId, AgregarContacto } from '../../../../servicios/ServicioContactos';
//import icons from heroicons
import { PlusCircleIcon, ExclamationTriangleIcon, PencilIcon, XMarkIcon, FunnelIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
//import icons from heroicons



const AddContactComponent = ({ validations, rolOptions, setShowAddModal, contacto, getContact, idFromEdit }) => {

    //use to get user in session
    const jsonUsuario = sessionStorage.getItem("USER_DATA");
    const usuario = JSON.parse(jsonUsuario);

    //declare register to form
    //use react hook form to get values and make validations
    const { register, getValues, watch, setValue, formState } = useForm({
        mode: 'all',
        defaultValues: {
            nombreEmpresa: '',
            correoElectronico: '',
            telefono1: '',
            telefono2: '',
            ubicacion: '',
            nombreDocumento: '',
            documento: '',
            extension: '',
            //   estado: true,
            idUsuario: String(usuario.Correo),
        }
    });

    //use alert context
    const alertCtx = useContext(AlertContext);

    const { errors } = formState;
    const { isValid } = formState;

    const [fileName, setFileName] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [filePath, setFilePath] = useState('');
    const [fileExtension, setFileExtension] = useState('');
    //async method to insert into sql user

    async function AddContactos(nombreEmpresa, correoElectronico, telefono1, telefono2, ubicacion, nombreDocumento, documento, extension, usuarioCreacion) {

        const data = {
            nombreEmpresa: nombreEmpresa,
            correoElectronico: correoElectronico,
            telefono1: telefono1,
            telefono2: telefono2,
            ubicacion: ubicacion,
            nombreDocumento: nombreDocumento,
            documento: documento,
            extension: extension,
            usuarioCreacion: String(usuario.Correo),

        }


        const response = await AgregarContacto(data);

        if (response.indicador === 0) {
            alertCtx.success(response.mensaje);
        } else {
            alertCtx.error(response.mensaje);
        }
    }
    const resetValues = () => {
        setValue("companyNameAdd", "");
        setValue("emailAdd", "");
        setValue("telephone1Add", "");
        setValue("telephone2Add", "");
        setValue("locationAdd", "");
        setValue("documentNameAdd", "");
        setValue("documentAdd", "");
        setValue("extensionAdd", "");
        setValue("creationUserAdd", "");
    }
    //---------------------File---------------------------
    const selectFile = (event) => {
        event.preventDefault();

        const userfile = event.target.files[0]; //get file selected
        const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];


        if (userfile && validTypes.includes(userfile.type)) {

            setValue("fileInput", userfile.name)
            setFileName(userfile.name)//show name of the file to user
            setSelectedFile(userfile.name);
            setFileExtension(userfile.type);
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result.split(",")[1];
                setFilePath(base64String);
            };
            reader.readAsDataURL(userfile);
        } else {
            alertCtx.error("¡Solo se aceptan imagenes de tipo png, jpg o jpeg!");
        }
    }
    const onSaveAddOptions = async () => {

        if (isValid) {
            const companyName = getValues("companyNameAdd");
            const email = getValues("emailAdd");
            const telephone1 = getValues("telephone1Add");
            const telephone2 = getValues("telephone2Add");
            const location = getValues("locationAdd");
            const documentName = fileName;
            const document = filePath;
            const extension = fileExtension;
            const creationUser = getValues("creationUserAdd");



            AddContactos(companyName, email, telephone1, telephone2, location, documentName, document, extension, creationUser);

            setShowAddModal(false);

            resetValues();
            const timeout = setTimeout(() => {
                getContact();
            }, 2000);
            return () => clearTimeout(timeout);
        } else {
            alertCtx.error("¡Error al registrar!");
        }

    }

    return (
        <>

            <div className="w-full container mx-auto items-center justify-center">
                <div className="flex flex-col">
                    <div className="flex text-base m-3 justify-center mx-auto font-bold leading-tight tracking-tight text-green-500">
                        <PlusCircleIcon className="w-8 h-8 ml-2"></PlusCircleIcon>
                    </div>
                    <div>
                        <h1 className="flex text-base justify-center mx-auto font-bold leading-tight tracking-tight text-black-RS">
                            Agregar Contacto
                        </h1>
                    </div>
                </div>
                <form className="space-y-2 md:space-y-4">
                    {/*First row*/}
                    <div className="flex flex-row mt-2 mx-auto items-center justify-center">

                        <div className="w-1/2 m-3 px-2  md:w-80">
                            <label className="flex mb-2 text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Nombre Empresa</label>
                            <div className="relative flex items-center">
                                <input
                                    type="text"
                                    name="companyNameAdd"
                                    {...register("companyNameAdd", { required: true, maxLength: 150 })}
                                    id="name"
                                    className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS"
                                    placeholder="Nombre Empresa"
                                />
                            </div>
                            {/*iterate over validations and show message company name*/}
                            {validations.companyName?.map((values) => {
                                return (
                                    errors.companyNameAdd?.type === values.type &&
                                    <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                        <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                    </span>
                                )
                            })}
                        </div>

                        <div className="w-1/2 m-3 px-2  md:w-80">

                            <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Correo eléctronico</label>

                            <div>
                                <input
                                    type="text"
                                    name="email"
                                    {...register("emailAdd", { required: true, maxLength: 150, pattern: /^(.+)@(.+)$/ })}
                                    id="email"
                                    className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS"
                                    placeholder="Correo eléctronico"
                                />
                            </
                            div>
                            {/*iterate over validations and show message email*/}
                            {validations.email?.map((values) => {
                                return (
                                    errors.emailAdd?.type === values.type &&
                                    <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                        <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                    </span>
                                )
                            })}
                        </div>
                    </div>
                    {/*Second row*/}
                    <div className="flex flex-row mt-2 mx-auto items-center justify-center">

                        <div className="w-1/2 m-3 px-0  md:w-80">

                            <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Teléfono 1</label>
                            <div>
                                <PhoneInput
                                    type="text"
                                    name="telephone1"
                                    {...register("telephone1Add", { required: false, maxLength: 15 })}
                                    specialLabel={''}
                                    country={'cr'}
                                    value={getValues("telephone1Add")}
                                    disabled={false}
                                    onChange={(value) => {
                                        setValue("telephone1Add", value)
                                    }}
                                    id="telephone1"
                                    className="text-gray-900 sm:text-sm block w-full p-0 rounded border-solid border-grayDark-RS"
                                    placeholder="Teléfono 1"
                                />

                            </div>
                            {/*iterate over validations and show message telephone 1*/}
                            {validations.telephone1?.map((values) => {
                                return (
                                    errors.telephone1Add?.type === values.type &&
                                    <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                        <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                    </span>
                                )
                            })}
                        </div>


                        <div className="w-1/2 m-3 px-0  md:w-80">

                            <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Teléfono 2</label>

                            <div>
                                <PhoneInput
                                    type="text"
                                    name="telephone2"
                                    {...register("telephone2Add", { required: false, maxLength: 15 })}
                                    specialLabel={''}
                                    country={'cr'}
                                    value={getValues("telephone2Add")}
                                    disabled={false}
                                    onChange={(value) => {
                                        setValue("telephone2Add", value)
                                    }}
                                    id="telephone"
                                    className="text-gray-900 sm:text-sm block w-full p-0 rounded border-solid border-grayDark-RS"
                                    placeholder="Teléfono 2"
                                />
                            </div>
                            {/*iterate over validations and show message telephone 2*/}
                            {validations.telephone2?.map((values) => {
                                return (
                                    errors.telephone2Add?.type === values.type &&
                                    <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                        <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                    </span>
                                )
                            })}
                        </div>

                    </div>

                    {/*Third row*/}
                    <div className="flex flex-wrap mx-2 mx-auto items-center justify-center">

                        <div className="w-1/2 m-3 px-2  md:w-80">

                            <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Ubicación</label>


                            <div>
                                <input
                                    type="text"
                                    name="location"
                                    {...register("locationAdd", { required: true, maxLength: 250 })}
                                    id="location"
                                    className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS"
                                    placeholder="Ubicación"
                                />

                            </div>
                            {/*iterate over validations and show message phone*/}
                            {validations.location?.map((values) => {
                                return (
                                    errors.locationAdd?.type === values.type &&
                                    <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                        <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                    </span>
                                )
                            })}

                        </div>
                        {/*-----------------------------------------------------------------------------------------------------------------------------------------------------}
                       ---------------------------------------------------------------DOCUMENT--------------------------------------------------------------------------¡/}
                       ----------------------------------------------------------------------------------------------------------------------------------*/}

                        <div className="w-1/2 m-3 px-2  md:w-80">

                            <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Imagen</label>

                            <div className="flex flex-col">
                                <input type="file"
                                    id="fileInput"
                                    name="fileInput"
                                    accept=".jpg,.jpeg,.png"
                                    onChange={selectFile}
                                    className="text-gray-900 sm:text-sm bg-white block w-full p-2 rounded border-solid border-2 border-grayDark-RS"

                                    placeholder="Adjuntar Imagen"
                                />


                            </div>

                        </div>



                        {/*-----------------------------------------------------------------------------------------------------------------------------------------------------}
                       ---------------------------------------------------------------END DOCUMENT--------------------------------------------------------------------------¡/}
                       -----------------------------------------------------------------------------------------------------------------------------------*/}



                    </div>

                    <div className=" flex flex-wrap z-10 mb-2 mx-auto items-center justify-center ">
                        <button type="button" className="py-2 px-4 mx-2 my-5 text-white bg-black-RS  hover:bg-primary-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                            onClick={() => setShowAddModal(false)} >Cancelar</button>
                        <button type="button" className="py-2 px-4 mx-2 my-5 text-white bg-greenButton  hover:bg-primary-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            onClick={onSaveAddOptions}>Confirmar</button>
                    </div>
                </form>
            </div>

        </>
    )

}
//---------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------EDIT CONTACT---------------------------------
//---------------------------------------------------------------------------------------------------------------------------

//use to show edit role component when 'editar' button is activated
const EditContactComponent = ({ validations, rolOptions, setShowEditModal, rowValuesFromId, contacto, getContact, idFromEdit }) => {

    //use to get user in session
    const jsonUsuario = sessionStorage.getItem("USER_DATA");
    const usuario = JSON.parse(jsonUsuario);
    //-------------------file
    const [fileName, setFileName] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [filePath, setFilePath] = useState('');
    const [fileExtension, setFileExtension] = useState('');
    //declare register to form
    //use react hook form to get values and make validations
    const { register, getValues, setValue, formState } = useForm({
        mode: 'all',
        defaultValues: {
            nombreEmpresa: '',
            correoElectronico: '',
            telefono1: '',
            telefono2: '',
            ubicacion: '',
            nombreDocumento: '',
            documento: '',
            extension: '',
            usuarioModificacion: String(usuario.Correo),
        }
    });

    //use alert context
    const alertCtx = useContext(AlertContext);

    const { errors } = formState;
    const { isValid } = formState;

    const resetValues = () => {
        setValue("companyNameEdit", "");
        setValue("emailEdit", "");
        setValue("telephone1Edit", "");
        setValue("telephone2Edit", "");
        setValue("ubicationEdit", "");
        setValue("documentNameEdit", "");
        setValue("documentEdit", "");
        setValue("extensionEdit", "");
        setValue("modificationUserEdit", "");
    }
    //-------------------Select File
    const selectFile = (event) => {
        event.preventDefault();

        const userfile = event.target.files[0]; //get file selected
        const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];


        if (userfile && validTypes.includes(userfile.type)) {

            setValue("fileInput", userfile.name)
            setFileName(userfile.name)//show name of the file to user
            setSelectedFile(userfile.name);
            setFileExtension(userfile.type);
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result.split(",")[1];
                setFilePath(base64String);
            };
            reader.readAsDataURL(userfile);
        } else {
            alertCtx.error("¡Solo se aceptan imagenes de tipo png, jpg o jpeg!");
        }
    }


    //async method to edit into sql user
    async function EditContactos(id, nombreEmpresa, correoElectronico, telefono1, telefono2, ubicacion, nombreDocumento, documento, extension) {

        const data = {
            idContacto: id,
            nombreEmpresa: nombreEmpresa,
            correoElectronico: correoElectronico,
            telefono1: telefono1,
            telefono2: telefono2,
            ubicacion: ubicacion,
            nombreDocumento: nombreDocumento,
            documento: documento,
            extension: extension,
            usuarioModificacion: String(usuario.Correo),
        }
        console.log(data);
        const response = await EditarInfoContacto(data);

        if (response.indicador === 0) {
            alertCtx.success(response.mensaje);
        } else {
            alertCtx.error(response.mensaje);
        }
    }

    const onSaveEditOptions = () => {

        if (isValid) {

            const modificactionUser = getValues("modificationUserEdit");
            const companyName = getValues("companyNameEdit");
            const email = getValues("emailEdit");
            const telephone1 = getValues("telephone1Edit");
            const telephone2 = getValues("telephone2Edit");
            const ubication = getValues("ubicationEdit");
            const documentName = fileName;
            const document = filePath;
            const extension = fileExtension;



            EditContactos(idFromEdit, companyName, email, telephone1, telephone2, ubication, documentName, document, extension)

            setShowEditModal(false);


            resetValues()
            const timeout = setTimeout(() => {
                getContact();
            }, 2000);
            return () => clearTimeout(timeout);
        } else {
            alertCtx.error("¡Error al editar el usuario!");
        }
    }

    useState(() => {

        setValue("companyNameEdit", rowValuesFromId?.nombreEmpresa);
        setValue("emailEdit", rowValuesFromId?.correoElectronico);
        setValue("telephone1Edit", rowValuesFromId?.telefono1);
        setValue("telephone2Edit", rowValuesFromId?.telefono2);
        setValue("ubicationEdit", rowValuesFromId?.ubicacion);
        setValue("documentNameEdit", rowValuesFromId?.nombreDocumento);
        setValue("documentEdit", rowValuesFromId?.document);
        setValue("extensionEdit", rowValuesFromId?.extension);
        setValue("modificationUserEdit", rowValuesFromId?.usuarioModificacion);

    }, [])

    return (
        <>
            <form className="space-y-2 md:space-y-4">
                <div className="w-full container mx-auto items-center justify-center">
                    <div className="flex flex-col">
                        <div className="flex text-base m-2 justify-center mx-auto font-bold leading-tight tracking-tight text-green-500">
                            <PencilIcon className="w-8 h-8 ml-2"></PencilIcon>
                        </div>
                        <div>
                            <h1 className="flex text-base justify-center mx-auto font-bold leading-tight tracking-tight text-black-RS">
                                Editar Contacto
                            </h1>
                        </div>
                        {/*First row*/}
                        <div className="flex flex-wrap mt-2 mx-auto items-center justify-center">

                            <div className="w-full m-3 px-2  md:w-80">

                                <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Nombre Empresa</label>
                                <div className="relative flex items-center">
                                    <input
                                        type="text"
                                        name="name"
                                        {...register("companyNameEdit", { required: true, maxLength: 150 })}
                                        id="name"
                                        className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS"
                                        placeholder="Nombre Empresa"
                                    />
                                </div>
                                {/*iterate over validations and show message name*/}
                                {validations.companyName?.map((values) => {
                                    return (
                                        errors.companyNameEdit?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>

                            <div className="w-full m-3 px-2  md:w-80">

                                <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Teléfono 1</label>


                                <div className="relative flex items-center">
                                    <PhoneInput
                                        type="text"
                                        name="telephone1"
                                        {...register("telephone1Edit", { required: false, maxLength: 15 })}
                                        specialLabel={''}
                                        country={'cr'}
                                        value={getValues("telephone1Edit")}
                                        disabled={false}
                                        onChange={(value) => {
                                            setValue("telephone1Edit", value)
                                        }}
                                        id="telephone1"
                                        className="text-gray-900 sm:text-sm block w-full p-0 rounded border-solid border-grayDark-RS"
                                        placeholder="Teléfono 1"
                                    />


                                </div>
                                {/*iterate over validations and show message name*/}
                                {validations.telephone1?.map((values) => {
                                    return (
                                        errors.telephone1Edit?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>
                        </div>
                        {/*Second row*/}
                        <div className="flex flex-wrap mx-2 mx-auto items-center justify-center">
                            <div className="w-full m-3 px-2  md:w-80">

                                <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Telephone 2</label>


                                <div className="relative flex items-center">
                                    <PhoneInput
                                        type="text"
                                        name="telephone2"
                                        {...register("telephone2Edit", { required: false, maxLength: 15 })}
                                        specialLabel={''}
                                        country={'cr'}
                                        value={getValues("telephone2Edit")}
                                        disabled={false}
                                        onChange={(value) => {
                                            setValue("telephone2Edit", value)
                                        }}
                                        id="telephone2"
                                        className="text-gray-900 sm:text-sm block w-full p-0 rounded border-solid border-grayDark-RS"
                                        placeholder="Teléfono 2"
                                    />


                                </div>
                                {/*iterate over validations and show message name*/}
                                {validations.telephone2?.map((values) => {
                                    return (
                                        errors.telephone2Edit?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>

                            <div className="w-full m-3 px-2  md:w-80">

                                <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Correo eléctronico</label>


                                <div className="relative flex items-center">
                                    <input
                                        type="text"
                                        name="email"
                                        {...register("emailEdit", { required: true, maxLength: 32 })}
                                        id="email"
                                        className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS"
                                        placeholder="Correo eléctronico"
                                    />

                                </div>
                                {/*iterate over validations and show message name*/}
                                {validations.email?.map((values) => {
                                    return (
                                        errors.emailEdit?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>
                        </div>
                        {/*Third row*/}

                        {/*-----------------------------------------------------------------------------------------------------------------------------------------------------}
                       ---------------------------------------------------------------DOCUMENT--------------------------------------------------------------------------¡/}
                       -----------------------------------------------------------------------------------------------------------------------------------*/}
                        <div className="flex flex-wrap mx-2 mx-auto items-center justify-center">
                            <div className="w-full m-3 px-2  md:w-80">

                                <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Imagen</label>

                                <div className="flex flex-col">
                                    <input type="file"
                                        id="fileInput"
                                        name="fileInput"
                                        accept=".jpg,.jpeg,.png"
                                        onChange={selectFile}
                                        className="text-gray-900 sm:text-sm bg-white block w-full p-2 rounded border-solid border-2 border-grayDark-RS"

                                        placeholder="Adjuntar Imagen"
                                    />


                                </div>

                            </div>



                            {/*-----------------------------------------------------------------------------------------------------------------------------------------------------}
                      ---------------------------------------------------------------END DOCUMENT--------------------------------------------------------------------------¡/}
                      -----------------------------------------------------------------------------------------------------------------------------------*/}


                            <div className="w-full m-3 px-2  md:w-80">

                                <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">Ubicación</label>


                                <div className="relative flex items-center">
                                    <input
                                        type="text"
                                        name="ubication"
                                        {...register("ubicationEdit", { required: true, maxLength: 250 })}
                                        id="ubication"
                                        className="text-gray-900 sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS"
                                        placeholder="Ubicación"
                                    />

                                </div>
                                {/*iterate over validations and show message name*/}
                                {validations.ubication?.map((values) => {
                                    return (
                                        errors.ubicationEdit?.type === values.type &&
                                        <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                            <p className="text-sm font-bold text-red-RS">{values.message}</p>
                                        </span>
                                    )
                                })}
                            </div>
                        </div>

                    </div>
                </div>
                <div className=" flex flex-wrap z-10 mb-2 mx-auto items-center justify-center ">
                    <button type="button" className="py-2 px-4 mx-2 my-5 text-white bg-black-RS  hover:bg-primary-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                        onClick={() => setShowEditModal(false)} >Cancelar</button>
                    <button type="button" className="py-2 px-4 mx-2 my-5 text-white bg-greenButton  hover:bg-primary-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        onClick={onSaveEditOptions}>Confirmar</button>
                </div>
            </form>
        </>
    )
}

//----------------------------------------------------------------------------END EDIT CONTACT-------------------------------
//---------------------------------------------------------------------------------------------------------------------------

export default function ContactosGrid({ }) {

    //list to save and send data to table
    const [listContact, setListContact] = useState([]);
    //use modal and show for add
    const [showAddModal, setShowAddModal] = useState(false);

    //use modal and show for edit
    const [showEditModal, setShowEditModal] = useState(false);

    //use modal and show for delete
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    //use modal and show for add
    const [currentIdContact, setCurrentIdContact] = useState();

    //user Contact lists
    const [listContactData, setListContactData] = useState([]);
    const [rowValuesFromId, setRowValuesFromId] = useState({});
    //use to get id with set options when click events
    const [idFromEdit, setIdFromEdit] = useState(null);
    const [idFromDeactivate, setIdFromDeactivate] = useState(0);

    //use modal and show for add
    const [showViewModal, setShowViewModal] = useState(false);

    //use to get state from colums
    const [stateContact, setStateContact] = useState(null);

    //use to get user in session
    const jsonUsuario = sessionStorage.getItem("USER_DATA");
    const usuario = JSON.parse(jsonUsuario);

    //use alert context
    const alertCtx = useContext(AlertContext);

    //validations
    const validations = {
        email: [
            {
                type: 'required',
                message: 'Favor ingresar el correo',
            },
            {
                type: 'maxLength',
                message: 'El correo no puede contener mas de 150 caracteres ',
            },
            {
                type: 'pattern',
                message: 'Correo electrónico inválido',
            }
        ],

        companyName: [
            {
                type: 'required',
                message: 'Favor ingresar ek nombre de la empresa',
            },
            {
                type: 'maxLength',
                message: 'El nombre no puede contener mas de 150 caracteres ',
            }
        ],
        telephone1: [
            {
                type: 'required',
                message: 'Favor ingresar el número telefónico',
            },
            {
                type: 'maxLength',
                message: 'El número telefónico no puede contener mas de 15 caracteres ',
            }
        ],
        telephone2: [
            {
                type: 'required',
                message: 'Favor ingresar el número telefónico',
            },
            {
                type: 'maxLength',
                message: 'El número telefónico no puede contener mas de 15 caracteres ',
            }
        ],
        location: [
            {
                type: 'required',
                message: 'Favor ingresar una ubicación',
            },
            {
                type: 'maxLength',
                message: 'La ubicación no puede contener más de 250 caracteres ',
            }
        ],
        document: [
            {
                type: 'required',
                message: 'Favor ingresar una imagen',
            },

        ]

    }
    //----------------




    const { handleSubmit } = useForm({
        mode: 'all',

    });

    //function to fill contact list
    async function getContact() {

        const response = await ObtenerInfoContacto();
        // console.log(response);
        setListContact(response);
    }



    //when component start, get list
    useState(() => {

        getContact();

    }, [])
    //-------------------------------------------------------DELETE----------------------------------------------
    //-----------------------------------------------------------------------------------------------------------
    async function DeactivateContact(id, estado) {

        const data = {
            idContacto: id,
            estado: estado,
            UsuarioModificacion: String(usuario.Correo),

        }

        const response = await DesactivarContacto(data);

        if (response.indicador === 0) {
            alertCtx.success(response.mensaje);
        } else {
            alertCtx.error(response.mensaje);
        }
    }
    //**change this colums with entities names or need data**
    const columns = [
        {
            name: 'Nombre Empresa',
            selector: row => row?.nombreEmpresa,
            width: '250px',
        },
        {
            name: 'Correo',
            selector: row => row?.correoElectronico,
        },
        {
            name: 'Teléfono',
            selector: row => row?.telefono1,
        },
        {
            name: 'Ubicación',
            selector: row => row?.ubicacion,
        },
        {
            name: 'Estado',
            selector: (row) => {
                return (
                    row?.estado === true ? <div className="flex items-center">
                        <div className="h-2.5 w-2.5 rounded-full bg-green-500 mr-2"></div> <h2 className="text-sm text-green-500 font-bold" > Activo</h2 >
                    </div>
                        : <div className="flex items-center">
                            <div className="h-2.5 w-2.5 rounded-full bg-red-RS mr-2"></div> <h2 className="text-sm text-red-RS font-bold" > Inactivo</h2 >
                        </div>
                )
            },
        },
        {
            name: 'Acciones',
            selector: row => row?.estado,
            cell: (row) =>

                <button type="button" id={row?.idUsuario} onClick={() => { console.log(row); setShowEditModal(true); setIdFromEdit(row?.idContacto); setRowValuesFromId(row); }} className="rounded-full text-sm w-32 py-2 px-4 ring-2 ring-black-RS text-black-RS font-bold  m-2 hover:ring-gray-200 ">Editar</button>

            ,
            right: true,
            ignoreRowClick: true,
            allowOverflow: true,
        },
        {
            cell: (row) => {
                return (
                    row?.estado === true ? <button type="button" id={row?.idContacto} onClick={() => { setStateContact(row?.estado); setIdFromDeactivate(row?.idContacto); setShowDeleteModal(true); }} className="rounded-full text-sm w-32 py-2 px-4 ring-2 ring-red-RS text-red-RS font-bold  m-2 hover:ring-gray-200 " > Desactivar</button >
                        : <button type="button" id={row?.idContacto} onClick={() => { setStateContact(row?.estado); setIdFromDeactivate(row?.idContacto); setShowDeleteModal(true); }} className="rounded-full text-sm w-32 py-2 px-4 ring-2 ring-greenButton text-greenButton font-bold  m-2 hover:ring-gray-200" > Activar</button >
                )
            }
            ,
        },

    ]

    const onSaveDeleteOptions = async () => {

        DeactivateContact(idFromDeactivate, !stateContact)

        setShowDeleteModal(false);
        const timeout = setTimeout(() => {
            getContact();
        }, 1000);
        return () => clearTimeout(timeout);
    }
    //when component start, get list
    useState(() => {
        getContact();


    }, [])

    //use to show delete new role component when 'desactivar' button is activated
    const DeleteUserComponent = () => {
        return (
            <form className="space-y-2 md:space-y-4">
                <div className="w-96 container mx-auto items-center justify-center">
                    <div className="flex flex-col">
                        <div className="flex text-base m-2 justify-center mx-auto font-bold leading-tight tracking-tight text-red-RS">
                            <ExclamationTriangleIcon className="w-8 h-8 ml-2"></ExclamationTriangleIcon>
                        </div>
                        <div>
                            <h1 className="flex text-base justify-center mx-auto font-bold leading-tight tracking-tight text-black-RS">
                                Advertencia
                            </h1>
                        </div>

                        <div>
                            <label className="flex text-base justify-center mx-auto m-2 py-2 text-sm font-medium text-gray-900">¿Está seguro que desea cambiar el estado de este elemento?</label>
                        </div>

                    </div>
                </div>
            </form>
        )
    }

    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------

    return (


        <div className="flex flex-row ">
            <div className=" md:basis-1/4 xl:basis-1/5">
                <SideBar />
            </div>
            <div className=" md:basis-3/4 xl:basis-11/12">
                <div className="bg-white rounded-lg shadow-lg shadow-slate-400 px-2 py-2 ml-4  mt-2 m-2 mr-4">
                    <div className="px-2 mt-2 m-2 flow-root">
                        <div className="float-left mt-8">
                            <h1 className="text-xl font-bold text-black-RS">Contactos</h1>
                            <div className="col-start-1 col-end-3 ">
                                <div className="items-center sm:flex">
                                    <button type="button" onClick={() => { setShowAddModal(!showAddModal); }} className="bg-greenButton hover:bg-primary-700 text-white text-sm  py-2 px-4 rounded inline-flex items-center">
                                        <span>Agregar</span>
                                        <PlusCircleIcon className="w-8 h-8 ml-2"></PlusCircleIcon>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>


                    {/*Here we show add modal compononent*/}
                    <Modal isVisible={showAddModal} onClose={() => { setShowAddModal(false) }} handleSubmit={() => null} onSaveOptions={null} showFotter={false} showCloseIcon={false} >
                        {/*Button to close modal*/}
                        <div className="flex justify-end m-2">
                            <button className="cursor-pointer text-black-400 hover:text-gray-600 transition duration-150 ease-in-out rounded ring-2 focus:outline-none ring-gray-600" onClick={() => setShowAddModal(false)} aria-label="close modal" role="button">
                                <XMarkIcon className="text-red-RS h-6 w-6" />
                            </button>
                        </div>
                        <AddContactComponent validations={validations} setShowAddModal={setShowAddModal} getContact={getContact} />

                    </Modal>
                    {/*Here we show edit modal compononent*/}
                    <Modal isVisible={showEditModal} onClose={() => { setShowEditModal(false) }} handleSubmit={() => null} onSaveOptions={null} showFotter={false} showCloseIcon={false} >
                        {/*Button to close modal*/}
                        <div className="flex justify-end m-2">
                            <button className="cursor-pointer text-black-400 hover:text-gray-600 transition duration-150 ease-in-out rounded ring-2 focus:outline-none ring-gray-600" onClick={() => setShowEditModal(false)} aria-label="close modal" role="button">
                                <XMarkIcon className="text-red-RS h-6 w-6" />
                            </button>
                        </div>
                        <EditContactComponent validations={validations} setShowEditModal={setShowEditModal} rowValuesFromId={rowValuesFromId} usuario={usuario} getContact={getContact} idFromEdit={idFromEdit} />
                    </Modal>

                    {/*Here we show delete modal compononent*/}
                    <Modal isVisible={showDeleteModal} onClose={() => { setShowDeleteModal(false) }} handleSubmit={handleSubmit} onSaveOptions={onSaveDeleteOptions} showCloseIcon={false} >
                        {/*Button to close modal*/}
                        <div className="flex justify-end m-2">
                            <button className="cursor-pointer text-black-400 hover:text-gray-600 transition duration-150 ease-in-out rounded ring-2 focus:outline-none ring-gray-600" onClick={() => setShowDeleteModal(false)} aria-label="close modal" role="button">
                                <XMarkIcon className="text-red-RS h-6 w-6" />
                            </button>
                        </div>
                        <DeleteUserComponent />
                    </Modal>

                    <div className="px-2 mt-2 m-2 flow-root">
                        <Table columns={columns} filteredItems={""} data={listContact} filterBy="nombreEmpresa" title="Nombre Empresa" showFilter={true} />
                    </div>
                </div>

            </div>

        </div>
    )

}


