import { useState } from "react";
import { UseContextProvider } from "./contexts/StepperContext";
import React from "react";
import NavigationControl from "./FormComponents/NavigationControl";
import Stepper from "./FormComponents/Stepper";
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import InfoPersonal from "./Steps/InfoPersonal/index";
import Educacion from "./Steps/Educacion/index";
import Tecnologias from "./Steps/Tecnologías/index";
import Experiencia from "./Steps/Experiencia/index";
import Proyectos from "./Steps/Proyectos/index";
import AdjuntarCurriculum from "./Steps/AdjuntarCurriculum/index";

function MultiStepForm() {

    const [currentStep, setCurrentStep] = useState(1);
    const [userData, setUserData] = useState([]);
    const [InfoPersonalData, setInfoPersonalData] = useState([]);
    const [otherTechnologiesData, setOtherTechnologiesData] = useState("");
    const [InfoDocumentData, setInfoDocumentData] = useState([]);
    const [finalData, setFinalData] = useState([]);
    const [stateInfoPersonal, setstateInfoPersonal] = useState(false);
    const [stateEducacion, setstateEducacion] = useState(false);
    const [stateTech, setstateTech] = useState(false);
    const [stateExperiencia, setstateExperiencia] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [isFirstLoadUserInfo, setIsFirstLoadUserInfo] = useState(true);

    const steps = [
        "Información personal",
        "Educación",
        "Tecnologías",
        "Experiencia",
        "Proyectos",
        "Adjuntar CV"
    ];

    const displayStep = (step) => {
        switch (step) {
            case 1:
                return <InfoPersonal InfoPersonalData={InfoPersonalData} setInfoPersonalData={setInfoPersonalData} finalData={finalData} stateInfoPersonal={stateInfoPersonal} setstateInfoPersonal={setstateInfoPersonal} isFirstLoadUserInfo={isFirstLoadUserInfo} setIsFirstLoadUserInfo={setIsFirstLoadUserInfo}/>
            case 2:
                return <Educacion userData={userData} setUserData={setUserData} finalData={finalData} setstateEducacion={setstateEducacion} stateEducacion={stateEducacion} stateInfoPersonal={stateInfoPersonal} setstateInfoPersonal={setstateInfoPersonal} />
            case 3:
                return <Tecnologias userData={userData} setUserData={setUserData} finalData={finalData} otherTechnologiesData={otherTechnologiesData} setOtherTechnologiesData={setOtherTechnologiesData} stateTech={stateTech} setstateTech={setstateTech} stateInfoPersonal={stateInfoPersonal} setstateInfoPersonal={setstateInfoPersonal} setstateEducacion={setstateEducacion} stateEducacion={stateEducacion}/>
            case 4:
                return <Experiencia userData={userData} setUserData={setUserData} finalData={finalData} setstateExperiencia={setstateExperiencia} />
            case 5:
                return <Proyectos userData={userData} setUserData={setUserData} finalData={finalData} setFinalData={setFinalData} />
            case 6:
                return <AdjuntarCurriculum InfoDocumentData={InfoDocumentData} setInfoDocumentData={setInfoDocumentData} />
            default:
        }
    }

    const handleClick = (direction) => {
        let newStep = currentStep;
        direction === "Siguiente" ? newStep++ : newStep--;
        setFinalData(userData);
        //check if steps are within bounds
        newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
    }

 

    return (
        <div className="w-82 mx-5 flex flex-col-reverse md:flex-row items-center justify-center px-2 py-4 m-2 mx-auto lg:py-0">
            <div className="w-full pt-1 bg-white rounded-lg  items-center justify-center">
                {isLoading ? (

                    
                    <div className="h-screen bg-white">
                        <div className="flex justify-center items-center h-full">
                            <ArrowPathIcon className="animate-spin h-16 w-16 mr-3 text-red-RS inset-y-2 right-0"></ArrowPathIcon>
                        </div>
                    </div>
                    

                ) : (
                    <>
                        { /* Stepper */}
                        <div className="container horizontal mt-2 ">
                            <Stepper
                                    steps={steps}
                                    currentStep={currentStep}
                                    stateInfoPersonal={stateInfoPersonal}
                                    stateEducacion={stateEducacion}
                                    stateTech={stateTech}
                            />
                        </div>

                        { /* Display components */}
                        <div className="my-8 p-8">
                            <UseContextProvider>{displayStep(currentStep)}</UseContextProvider>
                    
                        </div>


                        { /* Navigation Controls */}

                        <NavigationControl
                            handleClick={handleClick}
                            currentStep={currentStep}
                            steps={steps}
                            InfoPersonalData={InfoPersonalData}
                            InfoDocumentData={InfoDocumentData}
                            stateInfoPersonal={stateInfoPersonal}
                            otherTechnologiesData={otherTechnologiesData}
                            stateEducacion={stateEducacion}
                            stateTech={stateTech}
                            finalData={finalData}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                        />
                    </>
                )}
            </div>
        </div>
    );
}

export default MultiStepForm;