import React, { useEffect, useState, useRef, useCallback, useLayoutEffect, useMemo } from "react";
import { Document, Page, Text, View, Image, PDFViewer, StyleSheet, pdf, BlobProvider, PDFDownloadLink, usePDF } from "@react-pdf/renderer";
import { PDFtoIMG, toPng } from 'react-pdf-to-image';
import { saveAs } from 'file-saver';
import phone from "../../../assets/icons/phone.png"
import phoneRed from "../../../assets/icons/phoneRedRS.png"
import mail from "../../../assets/icons/mail.png"
import mailRed from "../../../assets/icons/mailRedRS.png"
import link from "../../../assets/icons/link.png"
import linkedin from "../../../assets/icons/linkedin.png"
import location from "../../../assets/icons/locationRedRS.png"
import whatsappRed from "../../../assets/icons/whatsappRedRS.png"
import especialidad from "../../../assets/icons/especialidad.png"

const styles = StyleSheet.create({
    body: {
        margin: '0 auto', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', width: "100vw",
        height: "100vh", fontFamily: 'Helvetica', padding: '18px 20px 15px'
    },
    rowHeader: {
        width: '100%', height: '75px', position: 'static', marginBottom: '20px'
    },
    col1: { width: '35%' },
    col2: { width: '60%', marginLeft: '5%' },
    name: { color: '#2D2D38', fontSize: '16px', marginBottom: '10px', fontFamily: 'Helvetica-Bold' },
    jobPosition: { color: '#2D2D38', fontSize: '10px', marginBottom: '10px' },
    title: { color: '#B91D1C', fontSize: '16px', marginBottom: '14px', fontFamily: 'Helvetica-Bold',  },
    titleDocs: { color: '#B91D1C', fontSize: '16px', marginBottom: '14px', fontFamily: 'Helvetica-Bold', textAlign: 'center' },
    subtitle: { color: '#2D2D38', fontSize: '10px', marginBottom: '10px', fontFamily: 'Helvetica-Bold' },
    subtitleDocs: { color: '#2D2D38', fontSize: '14px', fontFamily: 'Helvetica-Bold', textAlign: 'center', marginBottom: '5px' },
    subtitleExp: { display: 'flex', flexWrap: 'wrap', flexDirection: 'row', width: '100%', justifyContent: 'space-between' },
    technology: { padding: '8px' },
    text: { color: '#2D2D38', fontSize: '10px', marginBottom: '10px' },
    headerText: { fontSize: '10px', marginBottom: '10px', marginRight: '5px' },
    topLine: { borderTopWidth: 2, borderTopColor: '#B91D1C', marginBottom: '14px' },
    bottomLine: { borderBottomWidth: 2, borderBottomColor: '#B91D1C', marginBottom: '10px' },
    section: { marginBottom: '14px'},
    company: { color: '#B91D1C', fontSize: '10px', marginBottom: '10px' },
    subtitleTech: { color: '#2D2D38', fontSize: '10px', marginBottom: '10px', fontFamily: 'Helvetica-Bold', width: '40%', },
    techRow: { display: 'flex', flexWrap: 'wrap', flexDirection: 'row', width: '40%', justifyContent: 'space-between', marginBottom: '14px' },
    fillPoint: { height: '10px', width: '10px', backgroundColor: '#B91D1C', borderRadius: '50%' },
    emptyPoint: { height: '10px', width: '10px', backgroundColor: '#718A93', borderRadius: '50%' },
    icon: { height: '10px', width: '10px', marginRight: '5px' },
    headerIcon: { height: '12px', width: '12px', marginRight: '5px' },
    textIcon: { display: 'flex', flexWrap: 'wrap', flexDirection: 'row' },
    headerPosition: { display: 'flex', flexWrap: 'wrap', flexDirection: 'row', paddingBottom: '20px'},
    headerElements: { display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', margin: '2px'},
    imgContact: { left: '0', top: '0', width: '20%', height: '110px', position: 'absolute' },
    imgDoc: { width: '400px', height: '400px', objectPosition: 'center', display: 'flex', alignSelf: 'center' },
    containerDoc: { height: '300px', marginTop: '4px'  },
    rowDocs: { width: '100%' },
    footer: {
        width: '100%', position: 'absolute',
        bottom: 20,
    },
    secondColHeader: { marginLeft: '35%', width: '55%', marginTop: '20px' },
    pageNumbers: {
        textAlign: 'center',
        fontSize: '10px',
        marginLeft: '10%'
    },
});

const PDFViewerComponent = ({  userValidDoc, DocumentPDF, DocumentPDFWithoutDocs }) => {

    return (
        <>
            {/*if user not downloading, then show pdfviewer */
                <PDFViewer style={{ width: "100%", height: "90vh" }}>
                    {
                        userValidDoc ? <DocumentPDF /> : <DocumentPDFWithoutDocs />
                    }
                </PDFViewer >
            }
        </>
    );
}

const DownloadPDFComponentWithDocuments = ({ userValidDoc, DocumentPDF, infoPersonal, setShowDownloadComponent, isMounted }) => {

   async function SetBlobData(blob, name, resolve) {
        if (blob) {

            // Creating new object of PDF file
            var binaryData = [];
            binaryData.push(blob);

            const fileURL = window.URL.createObjectURL(new Blob(binaryData, { type: "application/pdf" }))

            // Setting various property values
            if (fileURL) {
                
                //custom event to manage click button
                const linkElement = document.createElement('a');
                linkElement.href = fileURL;
                linkElement.rel = "noreferrer";
                linkElement.target = "_blank"
                linkElement.download = name;
                linkElement.addEventListener('click', resolve);
                linkElement.style.display = 'none';
                document.body.appendChild(linkElement);
                linkElement.click();
                document.body.removeChild(linkElement);

                //stop download when user check and uncheck
                setShowDownloadComponent(false);

            }
        }
       
    }

    const DownloadPDF = (name) =>  {
         //here we use pdf await function and then get blob result from document
        new Promise(async resolve => {
            const blob = await pdf((
                <DocumentPDF />
            )).toBlob().then((result) => {

                const timeout = setTimeout(() => {
                      
                    SetBlobData(result, name, resolve);

                }, 1000);
                return () => clearTimeout(timeout)
          
            });
        })
    }

    useEffect(() => {
        if (isMounted.current === false) {

            //show pdf section with documents    
            if (userValidDoc) {
                const name = infoPersonal?.primerNombre + infoPersonal?.primerApellido + infoPersonal?.segundoApellido;
            
                if (name !== '') {
               
                   //call method and pass name when to save file
                    DownloadPDF(name)
                }
            }
        }

        return () => {
            isMounted.current = true;
        };

    }, [])

    return null;
    
}


const DownloadPDFComponentWithOutDocuments = ({ userValidDoc, DocumentPDF, infoPersonal, setShowDownloadComponent, isMounted }) => {

    function SetBlobData(blob, name, resolve) {
        if (blob) {

            // Creating new object of PDF file
            var binaryData = [];
            binaryData.push(blob);

            const fileURL = window.URL.createObjectURL(new Blob(binaryData, { type: "application/pdf" }))

            // Setting various property values
            if (fileURL) {

                //custom event to manage click button
                const linkElement = document.createElement('a');
                linkElement.href = fileURL;
                linkElement.rel = "noreferrer";
                linkElement.target = "_blank"
                linkElement.download = name;
                linkElement.addEventListener('click', resolve);
                linkElement.style.display = 'none';
                document.body.appendChild(linkElement);
                linkElement.click();
                document.body.removeChild(linkElement);

                //stop download when user check and uncheck
                setShowDownloadComponent(false);

            }
        }

    }

    function DownloadPDF(name) {
        //here we use pdf await function and then get blob result from document
        new Promise(async resolve => {
            const blob = await pdf((
                <DocumentPDF />
            )).toBlob().then((result) => {

                const timeout = setTimeout(() => {
                    resolve();
                    //method to save documents
                    SetBlobData(result, name, resolve);

                }, 1000);
                return () => clearTimeout(timeout)
       
            });
        })
    }

    useEffect(() => {
     
        if (isMounted.current === false) {
         
            //show pdf section with documents    
            if (!userValidDoc) {
                const name = infoPersonal?.primerNombre + infoPersonal?.primerApellido + infoPersonal?.segundoApellido;
          
                if (name !== '') {
                    //call method and pass name when to save file
                    DownloadPDF(name)
                }
            }
        }

        return () => {
            isMounted.current = true;
        };

    }, [])

    return null;
}

function MyPdf({ infoPersonal, infoExperiencia, infoEducacion, infoCertificacion, infoProyecto, infoTecnologia, contactInfo, directDownload, setShowDownloadComponent, setBlobList, setFilesReady, blobList, setCounterDocsMap, counterDocsMap }) {

   
    //detect when user contains docs
    const [userValidDoc, setUserValidDoc] = useState(false);
    const isMounted = useRef(false);
    const [client, setClient] = useState(false);

    function ContactInfoSection() { //imprime el encabezado con la información de la empresa de contacto
        const fileType = contactInfo?.extension;

        //CHANGE this to contactInfo?.documento
        const doc = contactInfo?.documento;
        const url = `data:${fileType};base64,${doc}`;
        return (
            <>
                <View style={styles.headerPosition}>
                    <View style={styles.imgContact}>
                        <Image src={url} />
                    </View>
                    <View style={styles.secondColHeader}>
                        <View style={styles.headerElements}>
                            <Text style={styles.headerText}> <Image src={phoneRed} style={styles.headerIcon} /> {contactInfo?.telefono1}</Text>
                            <Text style={styles.headerText}> <Image src={location} style={styles.headerIcon} /> {contactInfo?.ubicacion}</Text>
                        </View>
                        <View style={styles.headerElements}>
                            <Text style={styles.headerText}> <Image src={whatsappRed} style={styles.headerIcon} /> {contactInfo?.telefono2}</Text>
                            <Text style={styles.headerText}> <Image src={mailRed} style={styles.headerIcon} /> {contactInfo?.correoElectronico}</Text>
                        </View>
                    </View>
                </View >
            </>
        )
    }

    function PersonalInfoSection() { //imprime el resumen y contacto de la persona

        return (
            <View style={styles.section}>
                <Text style={styles.title}>Perfil</Text>
                <Text style={styles.text}>{infoPersonal?.resumenPersonal}</Text>
                <View style={styles.textIcon}>
                    <Image src={phone} style={styles.icon} />
                    <Text style={styles.text}>{infoPersonal?.telefono1}</Text>
                </View>
                <View style={styles.textIcon}>
                    <Image src={mail} style={styles.icon} />
                    <Text style={styles.text}>{infoPersonal?.correoElectronico}</Text>
                </View>
            </View>
        )
    }

    function NombreSection() { //imprime el nombre y redes de la persona

        return (
            <View style={styles.section}>
                <Text style={styles.name}>{infoPersonal?.primerNombre + ' ' + (infoPersonal?.segundoNombre === null ? '' : infoPersonal?.segundoNombre + ' ') + infoPersonal?.primerApellido + ' ' + infoPersonal?.segundoApellido}</Text>
                <View style={styles.textIcon}>
                    <Image src={especialidad} style={styles.icon} />
                    <Text style={styles.text}>{infoPersonal?.nombreEspecializacion}</Text>
                </View>
            </View>
        )
    }

    function ExperienciaSection() { //imprime la experiencia de la persona

        const Experiencias = infoExperiencia?.map((data) =>
            <View wrap={false} key={data.idExperiencia} >
                <View style={styles.subtitleExp}>
                    <Text style={styles.subtitle}>{data.puestoDesempenado}</Text>
                    <Text style={styles.company}>{data.nombreEmpresa}</Text>
                    <Text style={styles.text}>{(data.trabajando ? new Date(data.fechaIngreso).getFullYear() + " - " + "Actualmente" : new Date(data.fechaIngreso).getFullYear() === new Date(data.fechaSalida).getFullYear() ? new Date(data.fechaIngreso).getFullYear() : new Date(data.fechaIngreso).getFullYear() + " - " + new Date(data.fechaSalida).getFullYear())}</Text>
                </View>
                <Text style={styles.text}>{data.funcionesRealizadas}</Text>
                <View style={styles.topLine} />
            </View>
        )

        return (
            <>
                {infoExperiencia?.length != 0 && <View style={styles.section}>
                    <Text style={styles.title}>Experiencia</Text>
                    {Experiencias}
                </View>}
            </>
        )
    }

    function EducacionSection() { //imprime la educacion de la persona

        const Educaciones = infoEducacion?.map((data) =>
            <View wrap={false} key={data.idEducacion} >
                <Text style={styles.subtitle}>{data.nombreTitulo}</Text>
                <Text style={styles.text}>{(data.cursando ? new Date(data.fechaInicio).getFullYear() + " - " + "En curso" : new Date(data.fechaInicio).getFullYear() === new Date(data.fechaFin).getFullYear() ? new Date(data.fechaInicio).getFullYear() : new Date(data.fechaInicio).getFullYear() + " - " + new Date(data.fechaFin).getFullYear())}</Text>
                <Text style={styles.text}>{data.institucion}</Text>
                <View style={styles.topLine} />
            </View>
        )

        return (
            <>
                {infoEducacion?.length != 0 && <View style={styles.section}>
                    <Text style={styles.title}>Educación</Text>
                    {Educaciones}
                </View>}
            </>
        )
    }

    function CertificacionSection() { //imprime la certificacion de la persona

        const Certificaciones = infoCertificacion?.map((data) =>
            <View wrap={false} key={data.idEducacion} >
                <Text style={styles.subtitle}>{data.nombreTitulo}</Text>
                <Text style={styles.text}>{data.institucion}</Text>
                <View style={styles.topLine} />
            </View>
        )

        return (
            <>
                {infoCertificacion?.length != 0 && <View style={styles.section}>
                    <Text style={styles.title}>Certificación</Text>
                    {Certificaciones}
                </View>}
            </>
        )
    }

    function ProyectoSection() { //imprime los proyectos de la persona

        const Proyectos = infoProyecto?.map((data, index) =>
            <View wrap={false} key={index} >
                <View style={styles.subtitleExp}>
                    <Text style={styles.subtitle}>{data.nombreProyecto}</Text>
                    <Text style={styles.company}>{data.nombreEmpresa}</Text>
                    <Text style={styles.text}>{(data.fechaFin === null ? new Date(data.fechaInicio).getFullYear() + " - " + "Actualmente" : new Date(data.fechaInicio).getFullYear() === new Date(data.fechaFin).getFullYear() ? new Date(data.fechaInicio).getFullYear() : new Date(data.fechaInicio).getFullYear() + " - " + new Date(data.fechaFin).getFullYear())}</Text>
                </View>
                <Text style={styles.text}>{data.descripcionProyecto}</Text>
                <View style={styles.topLine} />
            </View>
        )

        return (
            <>
                {infoProyecto?.length != 0 && <View style={styles.section}>
                    <Text style={styles.title}>Proyectos</Text>
                    {Proyectos}
                </View>}
            </>
        )
    }

    function TecnologiaSection() { //imprime las tecnologias de la persona

        const Tecnologias = infoTecnologia?.map((data, index) =>
            <View style={styles.subtitleExp} wrap={false} key={data.idTecnologia}>
                <>
                    <Text style={styles.subtitleTech}>{data.tecnologia}</Text>
                    <Expertiz expertiz={data.expertiz} />
                </>
            </View>
        )

        return (
            <>
                {infoTecnologia?.length != 0 && <View /*break={infoEducacion.length >= 3}*/ style={styles.section}>
                    <Text style={styles.title}>Tecnologías</Text>
                    {Tecnologias}
                </View>}
            </>
        )
    }

    function Expertiz({ expertiz }) { //imprime la expertiz de la tecnologia
        switch (expertiz) {
            case 1:
                return (<View style={styles.techRow}>
                    <View style={styles.fillPoint} />
                    <View style={styles.emptyPoint} />
                    <View style={styles.emptyPoint} />
                    <View style={styles.emptyPoint} />
                    <View style={styles.emptyPoint} />
                </View>)
            case 2:
                return (<View style={styles.techRow}>
                    <View style={styles.fillPoint} />
                    <View style={styles.fillPoint} />
                    <View style={styles.emptyPoint} />
                    <View style={styles.emptyPoint} />
                    <View style={styles.emptyPoint} />
                </View>)
            case 3:
                return (<View style={styles.techRow}>
                    <View style={styles.fillPoint} />
                    <View style={styles.fillPoint} />
                    <View style={styles.fillPoint} />
                    <View style={styles.emptyPoint} />
                    <View style={styles.emptyPoint} />
                </View>)
            case 4:
                return (<View style={styles.techRow}>
                    <View style={styles.fillPoint} />
                    <View style={styles.fillPoint} />
                    <View style={styles.fillPoint} />
                    <View style={styles.fillPoint} />
                    <View style={styles.emptyPoint} />
                </View>)
            case 5:
                return (<View style={styles.techRow}>
                    <View style={styles.fillPoint} />
                    <View style={styles.fillPoint} />
                    <View style={styles.fillPoint} />
                    <View style={styles.fillPoint} />
                    <View style={styles.fillPoint} />
                </View>)
            default:
                return (<View style={styles.techRow}>
                    <View style={styles.emptyPoint} />
                    <View style={styles.emptyPoint} />
                    <View style={styles.emptyPoint} />
                    <View style={styles.emptyPoint} />
                    <View style={styles.emptyPoint} />
                </View>)
        }
    }

    function UserDocs() {
        
        let Educaciones;
        if (infoEducacion) {
            Educaciones = infoEducacion.map((data) => {
                if (data?.extension === 'application/pdf') {
                    return (
                        <PDFtoIMG file={`data:${data?.extension};base64,${data?.imgTitulo}`} key={data.idEducacion}>
                            {({ pages }) => {
                                return pages.map((page, index) =>
                                    <View wrap={false} style={styles.containerDoc} key={index} >
                                        <Text style={styles.subtitleDocs}>{data.nombreTitulo}</Text>
                                        <View style={styles.imgDoc}>
                                            <Image src={page} />
                                        </View>
                                        <View style={styles.topLine} />
                                    </View>
                                );
                            }}
                        </PDFtoIMG>
                    )
                } else {
                    if (data?.imgTitulo !== '') {
                        return (
                            <View wrap={false} key={data.idEducacion} style={styles.containerDoc} >
                                <Text style={styles.subtitleDocs}>{data.nombreTitulo}</Text>
                                <View style={styles.imgDoc}>
                                    <Image src={`data:${data?.extension};base64,${data?.imgTitulo}`} />
                                </View>
                                <View style={styles.topLine} />
                            </View>
                        )
                    }
                }

            }
            )
        }

        let Certificaciones;
        if (infoCertificacion) {
            Certificaciones = infoCertificacion.map((data) => {
           
                if (data?.extension === 'application/pdf') {
                    return (
                        <PDFtoIMG file={`data:${data?.extension};base64,${data?.imgTitulo}`} key={data.idEducacion}>
                            {({ pages }) => {
                                return pages.map((page, index) =>
                                    <View wrap={false} style={styles.containerDoc} key={`image_${index + 1}`} >
                                        <Text style={styles.subtitleDocs}>{data.nombreTitulo}</Text>
                                        <View style={styles.imgDoc}>
                                            <Image src={page} alt={`Page ${index + 1}`} />
                                        </View>
                                        <View style={styles.topLine} />
                                    </View>
                                );
                            }}
                        </PDFtoIMG>
                    )
                } else {
                    if (data?.imgTitulo !== '') {
                        return (
                            <View wrap={false} key={data.idEducacion} style={styles.containerDoc} >
                                <Text style={styles.subtitleDocs}>{data.nombreTitulo}</Text>
                                <View style={styles.imgDoc}>
                                    <Image src={`data:${data?.extension};base64,${data?.imgTitulo}`} />
                                </View>
                                <View style={styles.topLine} />
                            </View>
                        )
                    }
                }

            }
            )
        }

        return (
            <View>
                <Text style={styles.titleDocs}>Títulos</Text>
                <View>
                    {Educaciones}
                </View>
                <View>
                    {Certificaciones}
                </View>

            </View>
        )

    }

    const validateUserDocs = () => {
        if (infoEducacion) {
            infoEducacion.map((data) => {
                data?.extension === 'application/pdf' || data?.extension === 'image/png' || data?.extension === 'image/jpeg' ? setUserValidDoc(true) : setUserValidDoc(false);
            })
        }

        if (infoCertificacion) {
            infoCertificacion.map((data) => {
                data?.extension === 'application/pdf' || data?.extension === 'image/png' || data?.extension === 'image/jpeg' ? setUserValidDoc(true) : setUserValidDoc(false);
            })
        }
    }

    //return pdf structure with user docs
    const DocumentPDF = () => (
        <Document>
            <Page size="A4" style={styles.body} wrap>
                <View style={styles.rowHeader}>
                    <ContactInfoSection />
                </View>
                <View style={styles.col1}>
                    <EducacionSection />
                    <CertificacionSection />
                    <View>
                        <TecnologiaSection />
                    </View>

                </View>
                <View style={styles.col2}>
                    <NombreSection />
                    <ExperienciaSection />
                    <ProyectoSection />
                </View>
                <View break style={styles.rowDocs}>
                    <UserDocs />
                </View>

                <View style={styles.footer} fixed>
                    <Text style={styles.pageNumbers} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} />
                </View>

            </Page>
        </Document>
    )

    //return pdf structure without user docs
    const DocumentPDFWithoutDocs = () => (
        <Document>
            <Page size="A4" style={styles.body} wrap>
                <View style={styles.rowHeader}>
                    <ContactInfoSection />
                </View>
                <View style={styles.col1}>
                    <EducacionSection />
                    <CertificacionSection />
                    <View>
                        <TecnologiaSection />
                    </View>

                </View>
                <View style={styles.col2}>
                    <NombreSection />
                    <ExperienciaSection />
                    <ProyectoSection />
                </View>
                <View style={styles.footer} fixed>
                    <Text style={styles.pageNumbers} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} />
                </View>

            </Page>
        </Document>


    )

    useState(() => {
        //set initial value from existence documents from user
        validateUserDocs();

        return () => {
            isMounted.current = true;
          
        };
    }, [])
    
    return (

        <>
            {/*if user not downloading, then show pdfviewer */
                directDownload === false ? <PDFViewerComponent userValidDoc={userValidDoc} DocumentPDF={DocumentPDF} DocumentPDFWithoutDocs={DocumentPDFWithoutDocs} /> :
                    <>
                        {
                            userValidDoc && contactInfo ? <DownloadPDFComponentWithDocuments userValidDoc={userValidDoc} DocumentPDF={DocumentPDF} infoPersonal={infoPersonal}
                                setShowDownloadComponent={setShowDownloadComponent} isMounted={isMounted} />
                                : <DownloadPDFComponentWithOutDocuments userValidDoc={userValidDoc} DocumentPDF={DocumentPDFWithoutDocs} infoPersonal={infoPersonal}
                                    setShowDownloadComponent={setShowDownloadComponent} isMounted={isMounted} />

                        }
                    </>
            }
        </>
    );
}

export default MyPdf;
