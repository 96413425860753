import React, { useState, useContext, useEffect, useRef } from 'react';
import { Tab } from '@headlessui/react'
import { useForm } from 'react-hook-form';
import AlertContext from '../../../GlobalComponents/Alert/contexts/alertContext';
import PdfComponent from './PdfReportePersonas';
import PdfComponentProps from './PdfReportePersonasTrabajanRossmon';

//import global components
import { Modal, SideBar, AccordionCV, Table } from '../../../../imports';

//import services
import { ReportePersonasTrabajanRossmon, ReporteTotalPersonas, ReporteTotalPersonasPorEspecialidad } from '../../../../servicios/ServicioReportePersonas';

//import icons from heroicons
import { FunnelIcon, XMarkIcon, EyeIcon } from '@heroicons/react/24/outline';

export default function ReportePersonas() {
    const [nameButon, setNameButon] = useState(null);

    //set para opcoines de trabaja en Rossmon
    const [workOptions, setworkOptions] = useState([]);

    //get selected tab
    const [selectedIndex, setSelectedIndex] = useState(0);

    //disable button aplicar filtro
    const [disableButton, setDisableButton] = useState(false);

    //list to save and send data to table
    const [listPersonas, setListPersonas] = useState([]);

    const [work, setWork] = useState(0);

    //use modal and show for add Modal Filtrar
    const [showAddModal, setShowAddModal] = useState(false);

    //Show or not pdf modal
    const [showPDFModal, setShowPDFModal] = useState(false);

    //verifica si aplicar filtros es utilizado
    const [appliedFilters, setAppliedFilters] = useState(true);

    const { handleSubmit } = useForm({
        mode: 'all',

    });

    const { register, getValues, setValue, formState } = useForm({
        //this mode will get all type of events to trigger validations
        mode: "all",
        defaultValues: {

            work: '0',

        }
    });

    const validateWork = () => {

        const work = getValues('work');

        if (work === "0") {
            setDisableButton(true);
        } else {
            setDisableButton(false);
        }

        setWork(work); //mande el id al pdf

    };

    const { isDirty } = formState;

    //function to fill person list
    async function getPersons() {

        const response = await ReporteTotalPersonasPorEspecialidad();
        setListPersonas(response);

    }

    //when component start, get list
    useState(() => {

        getPersons();
        setNameButon("PDF");
        setDisableButton(true);

    }, [])

    //******columns for custom table******
    //**change this colums with entities names or need data**
    const columns = [
        {
            name: 'Nombre',
            selector: row => row?.nombre,
            width: '300px',
        },
        {
            name: 'Correo Electrónico',
            selector: row => row?.correoElectronico,
            width: '250px',
        },
        {
            name: 'Teléfono',
            selector: row => row?.telefono1,
        },
        {
            name: 'Especialidad',
            selector: row => row?.nombreEspecializacion,
        },

        {
            name: 'En Planilla',
            selector: (row) => {
                return (
                    row?.trabajaEnRossmon === true ? <div className="flex items-center">
                        <div className="h-2.5 w-2.5 rounded-full bg-green-500 mr-2"></div>
                        <h2 className="text-sm text-green-500 font-bold" > Si </h2 >
                    </div>
                        : <div className="flex items-center">
                            <div className="h-2.5 w-2.5 rounded-full bg-red-RS mr-2"></div>
                            <h2 className="text-sm text-red-RS font-bold" > No </h2 >
                        </div>
                )
            },
        },

    ];

    const TabsFilter = ({ setListCV, setShowAddModal }) => {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        //use alert context
        const alertCtx = useContext(AlertContext);

        //Show or not pdf modal
        const [showPDFModal, setShowPDFModal] = useState(false);

        //Show tabs titles
        const option = ['Trabajan en Rossmon'];

        const workOptions = {
            "Planilla": true,
            "Postulante": false,
            "Todos": 3
        }

        const onChangeInputs = () => {

            const work = getValues("work");

            if (work === "0") {
                setDisableButton(true);
            }
            else {
                setDisableButton(false);
            }

        }

        const onSaveFilter = async () => {

            //set all technologies id into json type to match parameter in sql server **"[1],[2],[3]"]**
            let work = getValues("work");

            if (work === "3") {
                const response = await ReporteTotalPersonas();
                setListCV(response);
                setShowAddModal(false);
                onResetFilter();
                setAppliedFilters(false);
            } else {
                //this data will replace current values from tabs
                const response = await ReportePersonasTrabajanRossmon(work);
                setListCV(response);
                setShowAddModal(false);
                onResetFilter();
                setAppliedFilters(false);
            }           

        }

        const onResetFilter = () => {

            setAppliedFilters(true);
            setValue("work", "0", { shouldDirty: true });
            setDisableButton(true);

        };

        useEffect(() => {

            onChangeInputs();

        }, [])

        return (
            <div className="container mx-auto w-9/12 px-2 py-2 sm:px-0">

                {/*Headless ui tab component*/}
                <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                    {/*Tab list allow us to pass tab titles*/}
                    <Tab.List className="flex space-x-4 rounded-xl bg-white shadow-lg p-2">
                        {option.map((category) => (
                            <Tab
                                key={category}
                                className={({ selected }) =>
                                    classNames(
                                        'w-full py-2.5 text-base font-bold font-sans text-grayDark-RS ',
                                        'ring-white ring-opacity-60 ring-offset-2  focus:outline-none focus:ring-2',
                                        selected
                                            ? 'text-black-RS border-b-4 border-t-0 border-l-0 border-r-0 border-black-RS'
                                            : 'text-black-RS  hover:bg-white/[0.12] hover:text-grayLight-RS'
                                    )
                                }
                            >
                                {category}
                            </Tab>
                        ))}
                    </Tab.List>

                    {/*Tab Panels allow us to pass content for tabs*/}
                    <Tab.Panels className="mt-2">

                        <Tab.Panel>
                            <div className="container  p-4 py-4 justify-center bg-white rounded-lg shadow-lg">

                                <div className="flex mx-2  items-center justify-center  px-2">
                                    <div className="w-2/5 m-4">
                                        <label className="mb-2 text-sm font-medium text-gray-900 ">Seleccione por:</label>
                                    </div>

                                    <div className="w-3/5 m-4">

                                        <select
                                            id="work"
                                            {...register('work', { required: false, onChange: validateWork })}
                                            type="select"
                                            className="text-black-RS sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS "
                                            name="work"
                                        >
                                            <option value={"0"} defaultValue > --Seleccione una opción--</option>

                                            {Object.keys(workOptions).map((key, i) => {
                                                return (
                                                    <option key={i} value={workOptions[key]}>{key}</option>
                                                )
                                            })}

                                        </select>
                                    </div>

                                </div>

                            </div>

                        </Tab.Panel>

                    </Tab.Panels>
                </Tab.Group>

                <div className=" flex flex-wrap z-10 mb-2 mx-auto items-center justify-center ">
                    <button type="button" className="py-2 px-4 mx-2 my-5 text-white bg-red-RS  hover:bg-primary-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        onClick={handleSubmit(onSaveFilter)} disabled={(!isDirty && disableButton) || disableButton}>Aplicar filtros
                    </button>

                    <button type="button" onClick={onResetFilter}
                        className="py-2 px-4 mx-2 my-5 text-white bg-grayDark-RS  hover:bg-primary-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                        Resetear
                    </button>

                    <Modal isVisible={showPDFModal} onClose={() => { setShowPDFModal(false) }} disable={appliedFilters} handleSubmit={() => null} onSaveOptions={() => { console.log('Salir') }} showFotter={false}>
                    </Modal>

                </div>
            </div>
        )

    }

    return (
        <div className="flex flex-row ">
            <div className=" md:basis-1/4 xl:basis-1/5">
                <SideBar />
            </div>

            <div className=" md:basis-3/4 xl:basis-11/12">
                <div className="bg-white rounded-lg shadow-lg shadow-slate-400 px-2 py-2 ml-4  mt-2 m-2 mr-4">
                    <div className="px-2 mt-2 m-2 flow-root">
                        <div className="float-left mt-8">
                            <h1 className="text-xl font-bold text-black-RS">Reporte de Personas que Trabajan en Rossmon</h1>
                        </div>

                        <div className="flex flex-row float-right mt-2" >
                            <button type="button" onClick={() => setShowAddModal(!showAddModal)} className="bg-grayDark-RS hover:bg-primary-700 text-white text-sm py-2 px-4 rounded inline-flex items-center">
                                <span>Filtrar</span>
                                <FunnelIcon className="w-8 h-8 ml-2"></FunnelIcon>
                            </button>

                            <button onClick={() => { getPersons(); setAppliedFilters(true); }} className="ml-1 bg-grayDark-RS text-white text-sm py-2 px-4 rounded inline-flex items-center">

                                <svg
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="w-8 h-8"
                                >
                                    <path d="M19.36 2.72l1.42 1.42-5.72 5.71c1.07 1.54 1.22 3.39.32 4.59L9.06 8.12c1.2-.9 3.05-.75 4.59.32l5.71-5.72M5.93 17.57c-2.01-2.01-3.24-4.41-3.58-6.65l4.88-2.09 7.44 7.44-2.09 4.88c-2.24-.34-4.64-1.57-6.65-3.58z" />
                                </svg>
                            </button>

                            <button id={nameButon} hidden={false} type="button" onClick={() => { setShowPDFModal(true); }} className={`bg-grayDark-RS hover:bg-primary-700 text-white text-sm  py-2 px-4 rounded inline-flex items-center ${appliedFilters ? 'ring-gray-400 text-gray-400 cursor-not-allowed' : 'ring-greenButton text-greenButton font-bold'} ml-1 hover:ring-gray-200`} disabled={appliedFilters}  >Ver Reporte <EyeIcon className="w-8 h-8 ml-2"></EyeIcon> </button >

                        </div>
                    </div>

                    {/*Here we show add modal compononent*/}
                    <Modal isVisible={showAddModal} onClose={() => { setShowAddModal(false) }} handleSubmit={() => null} onSaveOptions={null} showFotter={false} showCloseIcon={false}>
                        {/*Button to close modal*/}
                        <div className="flex justify-end m-2">
                            <button className="cursor-pointer text-black-400 hover:text-gray-600 transition duration-150 ease-in-out rounded ring-2 focus:outline-none ring-gray-600" onClick={() => setShowAddModal(false)} aria-label="close modal" role="button">
                                <XMarkIcon className="text-red-RS h-6 w-6" />
                            </button>
                        </div>

                        <TabsFilter setListCV={setListPersonas} setShowAddModal={setShowAddModal} />
                    </Modal>

                    <div className="px-2 mt-2 m-2 flow-root">
                        <Table columns={columns} filteredItems={""} data={listPersonas} filterBy="trabajaEnRossmon" showFilter={false} />
                    </div>

                    <Modal isVisible={showPDFModal} onClose={() => { setShowPDFModal(false) }} handleSubmit={() => null} onSaveOptions={() => { console.log('Salir') }} showFotter={false}>
                        {work === "3" ? <PdfComponent dato1={true} dato2={false} /> : <PdfComponentProps trabajaEnRossmon={work} />}
                        
                    </Modal>
                </div>

            </div>
        </div>
    );
}