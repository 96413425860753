import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

const Modal = ({ isVisible, onClose, handleSubmit, onSaveOptions, children, showFotter, showCloseIcon, insideContainer }) => {

    //if current admin view, then show modal inside container accordion
    const rol = sessionStorage.getItem("USER_ROLE");

    return (
        <>
            {isVisible &&
                <div className={insideContainer && rol === "2" ? "custom-filter transition duration-150 ease-in-out z-40 absolute inset-0 w-full overflow-x-hidden py-2 overflow-y-auto  p-4 h-full max-h-screen" :
                    "custom-filter transition duration-150 ease-in-out z-40 fixed inset-0 w-full overflow-x-hidden overflow-y-auto py-2 p-4 h-full max-h-screen"} id="modal">
                    <div className="fixed  w-full inset-0 flex items-center justify-center overflow-x-hidden overflow-y-auto  py-6 mt-4 pt-4">
                        {/*Block to manage and center modal with all details colors, borders...--*/}
                        <div className="mx-auto w-11/12  py-8 mt-2 shadow-xl transition-all bg-gradient-to-r from-[#718A93] to-[#DCEBEC] border-black border-1   m-auto  rounded-tl-lg rounded-br-lg  drop-shadow-xl ">
                                    {children}
                                    <div className="mt-4">
                                {showFotter && <div className=" flex flex-wrap z-10 mb-2 mx-auto items-center justify-center ">
                                    <button type="button" className="py-2 px-4 mx-2 my-5 text-white bg-black-RS  hover:bg-primary-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                                        onClick={() => onClose()} >Cancelar</button>
                                    <button className="py-2 px-4 mx-2 my-5 text-white bg-greenButton  hover:bg-primary-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                        onClick={handleSubmit(onSaveOptions)}>Confirmar</button>
                                </div>}
                                {showCloseIcon && <button className="cursor-pointer absolute top-0 right-0 mt-2 mr-5 text-black-400 hover:text-gray-600 border-gray-600 border-2  transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600" onClick={() => onClose()} aria-label="close modal" role="button">
                                    <XMarkIcon className="text-red-RS h-6 w-6" />

                                        </button>}
                                </div>
                        </div>
                    </div>
                </div >
            }
        </>
    )
}

export default Modal;
