import React, { useEffect } from 'react';
import { ObtenerInfoPersonalPorId } from '../../../servicios/ServicioInfoPersonal';
import { ObtenerExperienciaPorIdPersona } from '../../../servicios/ServicioExperiencia';
import { ObtenerEducacionsinCertficacionesPorIdPersona, ObtenerCertificacionPorIdPersona } from '../../../servicios/ServicioEducacion';
import { ObtenerProyectosPorIdPersona } from '../../../servicios/ServicioProyecto';
import { ObtenerTecnologiasPorIdPersona } from '../../../servicios/ServicioTecnologia';
import { ObtenerContactoPorId } from '../../../servicios/ServicioContacto';
import { saveAs } from 'file-saver';



const WordGeneratorComponent = ({ idPersona, idContacto }) => {
    useEffect(() => {
        const generateHtmlContent = async (idPersona, idContacto) => {
            try {
                const personalInfo = await ObtenerInfoPersonalPorId(idPersona);
                const infoExperiencia = await ObtenerExperienciaPorIdPersona(idPersona);
                const infoEducacion = await ObtenerEducacionsinCertficacionesPorIdPersona(idPersona);
                const infoCertificacion = await ObtenerCertificacionPorIdPersona(idPersona);
                const infoProyecto = await ObtenerProyectosPorIdPersona(idPersona);
                const infoTecnologia = await ObtenerTecnologiasPorIdPersona(idPersona);
                const contactInfo = await ObtenerContactoPorId(idContacto);
                const formatDate = (date) => {
                    if (!date) return '';
                    return new Date(date).getFullYear();
                };

                let htmlContent = `
                    <!DOCTYPE html>
                    <html>
                    <head>
                    <meta charset="UTF-8">
                    <style>
                        body {
                            font-family: Arial, sans-serif;
                            margin: 20px;
                        }

                        h1 {
                            font-size: 24px;
                            margin-bottom: 10px;
                            text-align: center;
                            color: red;
                        }

                        strong {
                            font-weight: bold;
                        }

                        .section {
                            margin-bottom: 20px;
                            border-bottom: 1px solid #ddd;
                            padding-bottom: 15px;
                            text-align: center;
                        }

                        .titulo {
                            font-size: 18px;
                            color: red;
                            font-weight: bold;
                        }

                        .titulo1 {
                            font-size: 18px;
                            color: red;
                            font-weight: bold;
                            padding-left: 15px;
                        }

                        h2 {
                            font-size: 12px;
                            color: black;
                        }

                        .nombre {
                            font-size: 20px;
                            color: black;
                            font-weight: bold;
                            padding-left: 16px;
                        }

                        .texto {
                            font-size: 12px;
                            color: black;
                        }

                        .texto1 {
                            font-size: 12px;
                            color: red;
                        }

                        .texto2 {
                            font-size: 12px;
                            color: black;
                            padding-left: 15px;
                        }

                        .texto3 {
                            font-size: 12px;
                            color: red;
                            padding-left: 20px;
                        }

                        hr {
                            color: red;
                            padding-left: 20px;
                        }

                        .linea {
                            color: red;
                            padding-left: 10px;
                            width: 70%;
                        }

                        .linea1 {
                            color: red;
                            padding-left: 10px;
                            width: 27%;
                        }

                        .estrellas {
                            font-size: 20px;
                            color: red;
                        }
                    </style>
                    </head>
                    <body>
                `;

                if (contactInfo) {
                    htmlContent += `
                        <!-- Contact Info -->
                        <table width="600" align="center">
                            <tr>
                                <td rowspan="2"><center><img width="100" height="90" src="data:${contactInfo.extension};base64,${contactInfo.documento}" alt="Contact Image"  /></center></td>
                                <td class="texto1"><br />${contactInfo.telefono1}</td>
                                <td class="texto1"><br />${contactInfo.ubicacion}</td>
                            </tr>
                            <tr>
                                <td class="texto1">${contactInfo.telefono2}</td>
                                <td class="texto1">${contactInfo.correoElectronico}</td>
                            </tr>
                        </table><br />
                    `;
                }

                if (infoEducacion.length > 0) {
                    htmlContent += `
                        <table width="600" align="center">
                            <tr>
                                <td class="titulo" width=160>
                                    Educación
                                </td>
                                <td colspan="3" class="nombre">
                                    ${personalInfo?.primerNombre || ''} ${personalInfo?.primerApellido || ''} ${personalInfo?.segundoApellido || ''}
                                </td>
                                    
                            </tr>
                            <tr>
                                <td class="texto">
                                    ${infoEducacion.map(edu => `
                                        <strong>${edu.nombreTitulo || ''}</strong> <br/>${formatDate(edu.fechaInicio || '')} ${formatDate(edu.fechaFin) !== formatDate(edu.fechaInicio) ? ' - ' + formatDate(edu.fechaFin) : ''} ${edu.fechaFin === null ? ' En curso' : ''}  <br/>${edu.institucion} <hr class="linea1"/>
                                    `).join('')}
                                </td>
                                <td class="texto2" colspan="3">
                                    ${personalInfo.nombreEspecializacion}
                                    <table width="100%">
                                        <tr>
                                            <td colspan="3" class="titulo">Experiencia</td>
                                        </tr>
                                        <tr>
                                            ${infoExperiencia.map(exp => `
                                                <td class="texto"><strong>${exp.puestoDesempenado || ''}</strong></td>
                                                <td align="center" class="texto1">${exp.nombreEmpresa || ''}</td>
                                                <td align="right" class="texto">${formatDate(exp.fechaIngreso || '')} - ${exp.fechaSalida ? formatDate(exp.fechaSalida) : 'Actualmente'}</td>
                                                <tr>
                                                    <td colspan="3" class="texto">${exp.funcionesRealizadas} <hr class="linea" /></td>
                                                </tr>
                                            `).join('')}
                                        </tr>
                                        
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td class="titulo">${infoCertificacion.length > 0 ? 'Certificación' : ''}</td>
                                ${infoProyecto.length > 0 ? `<td colspan="3" class="titulo1">Proyectos</td>` : ''}
                            </tr>
                            <tr>
                                <td>
                                    ${infoCertificacion.length > 0 ? `
                                        <table width="100%">
                                            ${infoCertificacion.map(cert => `
                                                <tr>
                                                    <td class="texto"><strong>${cert.nombreTitulo || ''}</strong> <br /> ${cert.institucion || ''} <hr class="linea1"/></td>
                                            `).join('')}
                                                </tr>
                                        </table>
                                    ` : ''}
                                </td>
                                <td colspan="3">
                                    ${infoProyecto.length > 0 ? `
                                        <table width="100%">
                                            <tr>
                                                ${infoProyecto.map(proyecto => `
                                                    <td class="texto2"><strong>${proyecto.nombreProyecto || ''}</strong></td>
                                                    <td align="center" class="texto1">${proyecto.nombreEmpresa || ''}</td>
                                                    <td align="right" class="texto">${formatDate(proyecto.fechaInicio || '')} ${formatDate(proyecto.fechaFin) !== formatDate(proyecto.fechaInicio) ? ' - ' + formatDate(proyecto.fechaFin) : ''}</td>
                                                    <tr>
                                                        <td colspan="3" class="texto2">${proyecto.descripcionProyecto} <hr class="linea"/></td>
                                                    </tr>
                                                `).join('')}
                                            </tr>
                                        </table>
                                    ` : ''}
                                </td>
                            </tr>
                            <tr>
                                <td class="titulo">Tecnologías</td>
                            </tr>
                            <tr>
                                <td>
                                    ${infoTecnologia.length > 0 ? `
                                        <table>
                                            <tr>
                                                ${infoTecnologia.map(tec => `
                                                    <td class="texto"><strong>${tec.tecnologia || ''}</strong></td>
                                                    <td class="estrellas">${' * '.repeat(tec.expertiz)}</td>
                                            </tr>
                                                `).join('')}
                                        </table>
                                    ` : ''}
                                </td>
                            </tr>
                        </table>
                    `;

                    if (infoCertificacion.length > 0) {
                        htmlContent += `
                            <center><h1>Títulos</h1></center>
                            <center><div class="section">
                                ${infoCertificacion.map(cert => `
                                    <br /><br /><strong>${cert.nombreTitulo || ''}</strong>
                                    <br />
                                    <img width="400" height="300" src="data:${cert.extension};base64,${cert.imgTitulo}" alt="Certification Image" /><hr />
                                `).join('')}
                            </div></center>
                        `;
                    }
                    if (infoEducacion.length > 0) {
                        htmlContent += `
                            <center><h1>Títulos</h1></center>
                            <center><div class="section">
                                ${infoEducacion.map(edu => `
                                    <br /><br /><strong>${edu.nombreTitulo || ''}</strong>
                                    <br />
                                    <img width="400" height="300" src="data:${edu.extension};base64,${edu.imgTitulo}" alt="Certification Image" /><hr />
                                `).join('')}
                            </div></center>
                        `;
                    }
                }

                htmlContent += `
                    </body>
                    </html>
                `;

                const blob = new Blob([htmlContent], {
                    type: 'text/html',
                });

                const fileName = `Curriculum_${personalInfo?.primerNombre}_${personalInfo?.primerApellido}.doc`;
                saveAs(blob, fileName);

            } catch (error) {
                console.error('Error al generar el documento Word:', error);
            }
        };

        generateHtmlContent(idPersona, idContacto);
    }, [idPersona, idContacto]);

    return null;
};

export default WordGeneratorComponent;