import React from 'react';
import AdminMenuComponent from './AdminMenu';
import { useEffect, useState } from "react";



const SideMenu = () => {



    const [email, setEmail] = useState("");



    const getEmail = () => {
        const jsonUsuario = sessionStorage.getItem("USER_DATA");
        const usuario = JSON.parse(jsonUsuario);
        setEmail(usuario.Correo);
    }
    useEffect(() => {
        getEmail();
    }, []);



    return (
        <>
            <AdminMenuComponent email={email} />
        </>
    );
}



export default SideMenu;