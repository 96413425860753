import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

const DocumentViewerComponentWeb = ({ filePath, fileName, fileExtension }) => {
    //extension and other data required to source path is required
    return (
        <>
            <div className="flex flex-col justify-content items-center">
                <img src={`data:${fileExtension};base64,${filePath}`} id={fileName} alt={fileName} data-name={fileName } className="w-full" style={{ maxWidth: "600px", maxHeight: "400px" }} />
            </div>
        </>
    )
}

const DocumentViewerComponentPDF = ({ filePath, fileExtension }) => {
    //asing this property to work with pdf
    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    //Custom function to go previous or next page
    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }
     //allow button to show previouspage
    function previousPage() {
        changePage(-1);
    }
      //allow button to show nextpage
    function nextPage() {
        changePage(1);
    }

    //extension and other data required to source path is required
    return (
        <div className="flex flex-col justify-content items-center">
            <h1 className="font-bold font-sans text-base">Documento ingresado</h1>
            <div>
                <Document file={`data:${fileExtension};base64,${filePath}`} onLoadSuccess={onDocumentLoadSuccess} loading={<ArrowPathIcon className="animate-spin h-16 w-16 mr-3 text-red-RS inset-y-2 right-0"></ArrowPathIcon>} error="Fallo al cargar el PDF" noData="PDF no específicado">
                    <Page pageNumber={pageNumber} renderTextLayer={false} loading="Cargando página..." error="Fallo al cargar la página" noData="Página no específicada"/>
                </Document>
            </div>
            <h3>Página {pageNumber || (numPages ? 1 : '--')} de {numPages || '--'}</h3>
            <div>
                <div className="items-center">

                    <button
                        type="button"
                        disabled={pageNumber <= 1}
                        onClick={previousPage}
                        className="py-2 px-4 mx-2 my-5 text-white bg-grayDark-RS  hover:bg-primary-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                        Anterior
                    </button>
                    <button
                        type="button"
                        disabled={pageNumber >= numPages}
                        onClick={nextPage}
                        className="py-2 px-4 mx-2 my-5 text-white bg-grayDark-RS  hover:bg-primary-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                        Siguiente
                    </button>
                </div>
            </div>
        </div>

       
    )
}


function DocumentViewerComponent({ fileType, typeView, filePath, fileName, fileExtension }) {
   
    return (
       
        <>

            {(() => {
                switch (typeView) {
                    case 'web':
                        return <DocumentViewerComponentWeb filePath={filePath} fileName={fileName} fileExtension={fileExtension} />
                    case 'pdf':
                        return <DocumentViewerComponentPDF filePath={filePath} fileExtension={fileExtension} />
                    default:
                        return null
                }
            })()}
        </>
       

    )
}

export default DocumentViewerComponent;