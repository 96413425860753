import React, { useState, useEffect, useRef, useMemo } from 'react';
import MyPdf from './PlantillaCurriculum'
import { ObtenerInfoPersonalPorId } from '../../../servicios/ServicioInfoPersonal'
import { ObtenerExperienciaPorIdPersona } from '../../../servicios/ServicioExperiencia'
import { ObtenerEducacionsinCertficacionesPorIdPersona, ObtenerCertificacionPorIdPersona } from '../../../servicios/ServicioEducacion'
import { ObtenerProyectosPorIdPersona } from '../../../servicios/ServicioProyecto'
import { ObtenerTecnologiasPorIdPersona } from '../../../servicios/ServicioTecnologia'
import { ObtenerContactoPorId } from '../../../servicios/ServicioContacto'
import { ArrowPathIcon } from '@heroicons/react/24/outline';


const PdfComponent = ({ idPersona, directDownload, setShowDownloadComponent, isLoading, idContacto }) => {

    const [infoPersonal, setInfoPersonal] = useState({});
    const [infoExperiencia, setInfoExperiencia] = useState([]);
    const [infoEducacion, setInfoEducacion] = useState([]);
    const [infoCertificacion, setInfoCertificacion] = useState([]);
    const [infoProyecto, setInfoProyecto] = useState([]);
    const [infoTecnologia, setInfoTecnologia] = useState([]);
    const [contactInfo, setContactInfo] = useState({});
    const isMounted = useRef(false);


    async function getPersonalInfo() {

        const response = await ObtenerInfoPersonalPorId(idPersona);
        setInfoPersonal(response);
      
    }

    async function getExperiencia() {

        const response = await ObtenerExperienciaPorIdPersona(idPersona);
        setInfoExperiencia(response)
    }

    async function getEducacion() {

        const response = await ObtenerEducacionsinCertficacionesPorIdPersona(idPersona);
        setInfoEducacion(response)
    }

    async function getCertificacion() {

        const response = await ObtenerCertificacionPorIdPersona(idPersona);
        setInfoCertificacion(response)
    }

    async function getProyecto() {

        const response = await ObtenerProyectosPorIdPersona(idPersona);
        setInfoProyecto(response)
    }

    async function getTecnologia() {

        const response = await ObtenerTecnologiasPorIdPersona(idPersona);
        setInfoTecnologia(response)
    }

    async function getContactInfo() {

        const response = await ObtenerContactoPorId(idContacto);
        setContactInfo(response)
    }


    async function callAsyncList() {

        await getPersonalInfo();
        await getExperiencia();
        await getEducacion();
        await getCertificacion();
        await getProyecto();
        await getTecnologia();
        await getContactInfo();
     
    }

    useEffect(() => {
        callAsyncList();
    },[idPersona])



    if (directDownload) {
        return (
            <>{
                isLoading === false ?
                <MyPdf infoPersonal={infoPersonal} infoExperiencia={infoExperiencia} infoEducacion={infoEducacion} infoCertificacion={infoCertificacion} infoProyecto={infoProyecto} infoTecnologia={infoTecnologia} contactInfo={contactInfo} directDownload={directDownload} setShowDownloadComponent={setShowDownloadComponent}/>
                    : null
                }
            </>
        );
    } else {
        return (
            <>
                {isLoading ? (<div className="h-screen">
                    <div className="flex justify-center items-center h-full">
                        <ArrowPathIcon className="animate-spin h-16 w-16 mr-3 text-red-RS inset-y-2 right-0"></ArrowPathIcon>
                    </div>
                </div>) :
                    <div style={{ width: "100%", height: "90vh" }}>
                        <MyPdf infoPersonal={infoPersonal} infoExperiencia={infoExperiencia} infoEducacion={infoEducacion} infoCertificacion={infoCertificacion} infoProyecto={infoProyecto} infoTecnologia={infoTecnologia} contactInfo={contactInfo} directDownload={directDownload} />
                    </div>}
            </>
        )
    }

    return null;
};

export default PdfComponent;