import React from 'react';
import AdjuntarCurriculumComponent from './AdjuntarCurriculum';

const AdjuntarCurriculum = ({ InfoDocumentData, setInfoDocumentData, allowEdit, listDocumentByPerson, setListDocumentByPerson, isFirstLoadDocument, setIsFirstLoadDocument, idCurriculum, InfoDocumentDataEdit, setInfoDocumentDataEdit, getDocumentByIdPerson }) => {

    //validations
    const validationMessages = {
        nameTitle: [
            {
                type: 'required',
                message: 'Favor ingresar el nombre de título',
            },
            {
                type: 'maxLength',
                message: 'El nombre de la título no puede contener mas de 60 caracteres ',
            },
            {
                type: 'pattern',
                message: 'Solo se admiten letras y números.',
            }
        ],
        institution: [
            {
                type: 'required',
                message: 'Favor ingresar el nombre de la institución',
            },
            {
                type: 'maxLength',
                message: 'El nombre de la institución no puede contener mas de 60 caracteres ',
            },
            {
                type: 'pattern',
                message: 'Solo se admiten letras.',
            }
        ],
        fileInput: [
            {
                type: 'required',
                message: 'Favor ingresar el titulo',
            }
        ],
    }


    return (
        <div>
            <AdjuntarCurriculumComponent InfoDocumentData={InfoDocumentData} setInfoDocumentData={setInfoDocumentData} allowEdit={allowEdit} listDocumentByPerson={listDocumentByPerson} setListDocumentByPerson={setListDocumentByPerson} isFirstLoadDocument={isFirstLoadDocument} setIsFirstLoadDocument={setIsFirstLoadDocument}
                idCurriculum={idCurriculum} InfoDocumentDataEdit={InfoDocumentDataEdit} setInfoDocumentDataEdit={setInfoDocumentDataEdit} getDocumentByIdPerson={getDocumentByIdPerson} />
        </div>
    );
}

export default AdjuntarCurriculum;