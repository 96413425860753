import React, { useState, useEffect, useRef, useMemo, useLayoutEffect } from 'react';

import AlertContext from '../../GlobalComponents/Alert/contexts/alertContext';

//import global components
import { Modal, SideBar, AccordionCV, Table } from '../../../imports';

//import services
import { ObtenerPersonasCV } from '../../../servicios/ServicioPersonaCV';

//import services
import { ObtenerInfoContacto } from '../../../servicios/ServicioContacto';

import FilterComponent from '../FilterComponent/filterComponent';

//import icons from heroicons
import { FunnelIcon, XMarkIcon, ArrowPathIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline';

import { saveAs } from 'file-saver';
import PdfComponent from '../CurriculumPdf/index';
import SelectContactComponent from './contactSelection';

export default function GridCurriculum({ }) {

    //list to save and send data to table
    const [listCV, setListCV] = useState([]);

    //use to get Education Type options
    const [contactOptions, setContactOptions] = useState([]);

    //use modal and show for add
    const [showAddModal, setShowAddModal] = useState(false);

    //use modal and show for add
    const [showViewModal, setShowViewModal] = useState(false);

    const [showDownloadComponent, setShowDownloadComponent] = useState(false);

    const [selectedRows, setSelectedRows] = useState([]);

    //use modal and show for add
    const [currentIdCV, setCurrentIdCV] = useState();

    //user data lists
    const [listUserData, setListUserData] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const [downloadAction, setDownloadAction] = useState(false);

    //function to fill person list
    async function getPersonsCV() {

        const response = await ObtenerPersonasCV();

        setListCV(response);
    }

    //function to save 'estadoCivil' in list
    async function getContactInfo() {
        const response = await ObtenerInfoContacto();

        if (response !== undefined) {

            setContactOptions(response);
        }
    }


    const [showPDFModal, setShowPDFModal] = useState(false);//Show or not pdf modal

    //******columns for custom table******
    //**change this colums with entities names or need data**
    const columns = [
        {
            name: 'Nombre',
            selector: row => row?.nombre,
            width: '290px',
        },
        {
            name: 'Especialidad',
            selector: row => row?.nombreEspecializacion,
        },
        {
            name: 'Años experiencia',
            selector: row => row?.annoexperiencia,
        },

        {
            name: 'Acciones',
            selector: row => row?.idPerson,
            cell: (row) =>
                selectedRows?.length > 0 ?
                    <button type="button" id={row?.idPerson} onClick={() => { setShowViewModal(true); setCurrentIdCV(row?.idPerson) }} className={`rounded-full text-sm w-32 py-2 px-6 border-solid border-2 border-gray-400 text-gray-400 cursor-not-allowed font-bold  m-2`} disabled={true} >Ver</button> : <button type="button" id={row?.idPerson} onClick={() => { setShowViewModal(true); setCurrentIdCV(row?.idPerson) }} className={`rounded-full text-sm w-32 py-2 px-6 border-solid border-2 border-black-RS text-black-RS font-bold  m-2 hover:border-gray-200`} disabled={false} >Ver</button>

            ,
            right: true,
            ignoreRowClick: true,
            allowOverflow: true,
        },
        {
            cell: (row) =>
                selectedRows?.length > 0 ?
                    <button type="button" id={row?.idPerson} onClick={() => { setShowPDFModal(true); setCurrentIdCV(row?.idPerson); }} className="rounded-full text-sm w-32 py-2 px-4 border-solid border-2 border-gray-400 text-gray-400 font-bold m-2 cursor-not-allowed" disabled={true}>Currículum</button > : <button type="button" id={row?.idPerson} onClick={() => { setShowPDFModal(true); setCurrentIdCV(row?.idPerson); }} className="rounded-full text-sm w-32 py-2 px-4  border-solid border-2 border-greenButton text-greenButton font-bold  m-2 hover:border-gray-200 " disabled={false}>Currículum</button >

        },

    ]

    //when component start, get list
    useState(() => {

        getPersonsCV();
        getContactInfo();
    }, [])


    return (
        <div className="flex flex-row ">
            <div className=" md:basis-1/4 xl:basis-1/5">
                <SideBar />
            </div>
            <div className=" md:basis-3/4 xl:basis-11/12">
                <div className="bg-white rounded-lg shadow-lg shadow-slate-400 px-2 py-2 ml-4  mt-2 m-2 mr-4">
                    <div className="px-2 mt-2 m-2 flow-root">
                        <div className="float-left mt-8">
                            <h1 className="text-xl font-bold text-black-RS">Currículums Vitae</h1>
                        </div>
                        <div className="flex flex-row float-right mt-2" >
                            {selectedRows?.length > 0 &&
                                <div className="px-2 mr-1">
                                    <button type="button" onClick={() => { setShowDownloadComponent(true); setDownloadAction(true); setShowPDFModal(true); }} className="bg-greenButton hover:bg-primary-700 text-white text-sm  py-2 px-4 rounded inline-flex items-center">
                                        <span>Descargar</span>
                                        <ArrowDownTrayIcon className="w-8 h-8 ml-2"></ArrowDownTrayIcon>
                                    </button>
                                </div>
                            }
                            <button type="button" onClick={() => setShowAddModal(!showAddModal)} className="bg-grayDark-RS hover:bg-primary-700 text-white text-sm  py-2 px-4 rounded inline-flex items-center">
                                <span>Filtrar</span>
                                <FunnelIcon className="w-8 h-8 ml-2"></FunnelIcon>
                            </button>
                            <button onClick={getPersonsCV} className="ml-2 bg-grayDark-RS text-white text-sm   py-2 px-4 rounded inline-flex items-center">

                                <svg
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="w-8 h-8"
                                >
                                    <path d="M19.36 2.72l1.42 1.42-5.72 5.71c1.07 1.54 1.22 3.39.32 4.59L9.06 8.12c1.2-.9 3.05-.75 4.59.32l5.71-5.72M5.93 17.57c-2.01-2.01-3.24-4.41-3.58-6.65l4.88-2.09 7.44 7.44-2.09 4.88c-2.24-.34-4.64-1.57-6.65-3.58z" />
                                </svg>
                            </button>

                        </div>
                    </div>

                    {/*Here we show add modal compononent*/}
                    <Modal isVisible={showAddModal} onClose={() => { setShowAddModal(false) }} handleSubmit={() => null} onSaveOptions={null} showFotter={false} showCloseIcon={false}>
                        {/*Button to close modal*/}
                        <div className="flex justify-end m-2">
                            <button className="cursor-pointer text-black-400 hover:text-gray-600 transition duration-150 ease-in-out rounded border-solid border-2 border-gray-400 focus:outline-none" onClick={() => setShowAddModal(false)} aria-label="close modal" role="button">
                                <XMarkIcon className="text-red-RS h-6 w-6" />
                            </button>
                        </div>
                        <FilterComponent listUserData={listUserData} setListUserData={setListUserData} setListCV={setListCV} setShowAddModal={setShowAddModal} />
                    </Modal>

                    {/*Here we show view modal compononent*/}
                    <Modal isVisible={showViewModal} onClose={() => { setShowViewModal(false) }} handleSubmit={() => null} onSaveOptions={null} showFotter={false} showCloseIcon={false}>
                        <AccordionCV idCurriculum={currentIdCV} setShowViewModal={setShowViewModal} showViewModal={showViewModal} />
                    </Modal>
                    <div className="px-2 mt-2 m-2 flow-root">
                        <Table columns={columns} title="nombre" filteredItems={""} data={listCV} filterBy="nombre" showFilter={true} selectable={true} setSelectedRows={setSelectedRows} />
                    </div>
                </div>

            </div>

                <Modal isVisible={showPDFModal} onClose={() => { setShowPDFModal(false) }} handleSubmit={() => null} onSaveOptions={() => { console.log('Salir') }} showFotter={false}>
                    {
                        showDownloadComponent && downloadAction && selectedRows?.length > 0 ? <SelectContactComponent showPDFModal={showPDFModal} idPersona={currentIdCV} setShowPDFModal={setShowPDFModal} downloadAction={downloadAction} selectedRows={selectedRows} showDownloadComponent={showDownloadComponent} setShowDownloadComponent={setShowDownloadComponent} setDownloadAction={setDownloadAction} />
                        : <SelectContactComponent showPDFModal={showPDFModal} idPersona={currentIdCV} downloadAction={false} showDownloadComponent={showDownloadComponent} setShowDownloadComponent={setShowDownloadComponent} setShowPDFModal={setShowPDFModal} setDownloadAction={setDownloadAction} />
                    }
                </Modal>
        </div>
    )

}
