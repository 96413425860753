import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import AlertContext from '../../../GlobalComponents/Alert/contexts/alertContext';

//import global components
import { AccordionCV, Card, Modal, ModalActionConfirm } from '../../../../imports';

//import services
import { ObtenerGradoAcademico, ObtenerGradoAcademicoSinCertificacion, EliminarEducacion, EditarEducacion, AgregarEducacionIndividual } from '../../../../servicios/ServicioEducacion';

//import icons from heroicons
import { AcademicCapIcon, PlusCircleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';

import PDFJS from 'pdfjs-dist';



export default function Educacion({ validationMessages, userData, setUserData, finalData, setstateEducacion, stateInfoPersonal, setstateInfoPersonal, allowEdit, listEducationByPerson, setListEducationByPerson, educationDataEdit, setEducationDataEdit, isFirstLoadEducation, setIsFirstLoadEducation, idCurriculum }) {


    //use alert context
    const alertCtx = useContext(AlertContext);

    //here we pass a default input property to save in userData
    //all inputs use this same event in 'onchange'
    const handleChange = (e) => {
        const { name, value } = e.target;

        setValue(name, e.target.value)
    };

    //use react hook form to get values and make validations
    const { register, reset, getValues, setValue, handleSubmit, formState } = useForm({
        mode: 'all',
        defaultValues: {
            nameTitle: '',
            institution: '',
            educationLevel: '',
            fileInput: '',
            startDate: '',
            finishDate: '',
            inCourse: '',
            endDate: '',
            
        }
    });

    const { errors } = formState;
    const { isValid } = formState;

    //use to get validations from props and later iterate
    const [validations, setValidations] = useState([]);

    const [showModal, setShowModal] = useState(false);

    //use modal and show for save
    const [showConfirmSaveModal, setShowConfirmSaveModal] = useState(false);
    const [actionTypeConfirmModal, setActionTypeConfirmModal] = useState("");
    const [showConfirmEditModal, setShowConfirmEditModal] = useState(false);


    const [showCertificate, setshowCertificate] = useState(false);

    const [selectedFile, setSelectedFile] = useState(null);

    //use to get education options
    const [educationOptions, setEducationOptions] = useState([]);

    //use to get education options without certificate
    const [educationOptionsWithoutCertificate, setEducationOptionsWithoutCertificate] = useState([]);

    const [educationGrade, setEducationGrade] = useState('');
    const [Gradename, setGradeName] = useState('');
    const [idFromCard, setIdFromCard] = useState(0);

    //validate if user already complete cv
    const userCompleteCV = sessionStorage.getItem("USER_COMPLETE_CV");

    const [userSaveActionSuccess, setUserSaveActionSuccess] = useState(false);

    const [dataIndividualEducation, setDataIndividualEducation] = useState({});

    //flag to point into box selected 
    const [detectCheckBox, setDetect] = useState(false);

    const [InCourse, setCourse] = useState(false);

    const [listEducation, setListEducation] = useState([]);

    const [currentIdCard, setCurrentIdCard] = useState(null);

    const [fileName, setFileName] = useState('');

    const [filePath, setFilePath] = useState('');

    const [fileExtension, setFileExtension] = useState('');

    const [pdf, setPdf] = React.useState("");

    //asing this property to work with pdf
    PDFJS.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.js`;

    //call services to delete elements from education
    async function EliminarEducacionPersona(idEducacion) {
        const response = await EliminarEducacion(idEducacion);
        return response;
    }

    //function to save 'educaion' in list
    async function getEducationType() {
        const response = await ObtenerGradoAcademico();
        if (response !== undefined) {
            setEducationOptions(response);
        }

    }

    //function to save 'educacion opciones' in list
    async function getEducationTypeWithoutCertifications() {
        const response = await ObtenerGradoAcademicoSinCertificacion();
        if (response !== undefined) {
            setEducationOptionsWithoutCertificate(response);
        }

    }



    //method to add new project
    const AgregarEducacionPersona = async (e) => {

        const EducacionData = {
            nombreTitulo: dataIndividualEducation.dataForGlobalList?.nameTitle,
            institucion: dataIndividualEducation.dataForGlobalList?.institution,
            fechaInicio: dataIndividualEducation.dataForGlobalList?.startDate === "" ? null : dataIndividualEducation.dataForGlobalList?.startDate,
            fechafinalizacion: dataIndividualEducation.dataForGlobalList?.finishDate === "" ? null : dataIndividualEducation.dataForGlobalList?.finishDate,
            cursando: dataIndividualEducation.dataForGlobalList?.inCourse ,
            imgTitulo: dataIndividualEducation.dataForGlobalList?.filePathValue, 
            idGrado: parseInt(dataIndividualEducation.dataForGlobalList?.educationLevel, 10),
            idPersona: idCurriculum,
            imgNombre: dataIndividualEducation.dataForGlobalList?.fileNameValue,
            extension: dataIndividualEducation.dataForGlobalList.fileExtensionValue,
        }
      
        const educacionResponse = await AgregarEducacionIndividual(EducacionData);
       
        if (educacionResponse.indicador === 0) {
            //save options in list
            if (currentIdCard) {
                if (listEducation?.length > currentIdCard - 1 && listEducation[currentIdCard] !== undefined) {

                    //filter array of key value to validate if object exists and then update value
                    for (let item of Object.keys(listEducation)) {
                        if (listEducation[item] === listEducation[currentIdCard]) {

                            //update key value list to show recent info in cards by currentItem
                            listEducation[item] = dataIndividualEducation
                        }
                    }
                }
            } else {

                setListEducation([...listEducation, dataIndividualEducation])

            }

            alertCtx.success(educacionResponse.mensaje);
            setUserSaveActionSuccess(true)
            setShowConfirmSaveModal(false)
            setDetect(false)
            setDataIndividualEducation({})
            setCurrentIdCard(null);
        }
   
    }

    //method to edit project from person by id
    const EditarEducacionPersona = async (e) => {

        const EducacionData = {
            idEducacion: dataIndividualEducation.dataForGlobalList?.idEducation,
            nombreTitulo: dataIndividualEducation.dataForGlobalList?.nameTitle,
            institucion: dataIndividualEducation.dataForGlobalList?.institution,
            fechaInicio: dataIndividualEducation.dataForGlobalList?.startDate === "" ? null : dataIndividualEducation.dataForGlobalList?.startDate,
            fechafinalizacion: dataIndividualEducation.dataForGlobalList?.finishDate === "" ? null : dataIndividualEducation.dataForGlobalList?.finishDate,
            cursando: dataIndividualEducation.dataForGlobalList?.inCourse ,
            imgTitulo: dataIndividualEducation.dataForGlobalList?.filePathValue,   
            idGrado: parseInt(dataIndividualEducation.dataForGlobalList?.educationLevel, 10),
            idPersona: idCurriculum,
            imgNombre: dataIndividualEducation.dataForGlobalList?.fileNameValue,
            extension: dataIndividualEducation.dataForGlobalList?.fileExtensionValue,

        }  

        const educacionResponse = await EditarEducacion(EducacionData);
    
        if (educacionResponse.indicador === 0) {
            //save options in list
            if (currentIdCard) {
                if (listEducation?.length > currentIdCard - 1 && listEducation[currentIdCard] !== undefined) {

                    //filter array of key value to validate if object exists and then update value
                    for (let item of Object.keys(listEducation)) {
                        if (listEducation[item] === listEducation[currentIdCard]) {

                            //update key value list to show recent info in cards by currentItem
                            listEducation[item] = dataIndividualEducation
                        }
                    }
                }
            } else {

                setListEducation([...listEducation, dataIndividualEducation])

            }

            alertCtx.success(educacionResponse.mensaje);
            setUserSaveActionSuccess(true)
        
            setShowConfirmEditModal(false)
            setFileName("");
            setFilePath("");
            setDataIndividualEducation({})
            setCurrentIdCard(null);
            setDetect(false)
        }
    

    }

    const certificacion = (e) => {
        e.preventDefault();
        const selectedText = e.target.options[e.target.selectedIndex].text;
        setGradeName(selectedText);
        const certificacion = (e.target.value);
       

        if (certificacion == 9) {
            setshowCertificate(true)
            resetAllDates()
        } else {
            setshowCertificate(false)
        }
     
    }



    //use to change and detect checkbox
    const detectCheck = event => {
        event.preventDefault();
        
        setCourse(!InCourse);
       
        setDetect(!detectCheckBox);
        resetinputs()
        
    }

    const SaveEducationOptions = () => {

        const startDate = getValues("startDate");
        const finishDate = getValues("finishDate");

        if (startDate === "" && finishDate === "" && showCertificate === false) {
            alertCtx.error("Por favor ingrese las fechas");
        }
        else if (startDate > finishDate && InCourse === false) {
            alertCtx.error("La fecha de finalización debe ser mayor a la fecha de inicio");
        } else {
            if (isValid) {

                const nameTitle = getValues("nameTitle");
                const institution = getValues("institution");
                const educationLevel = getValues("educationLevel");
                const startDate = getValues("startDate");
                const inCourse = InCourse;
                const finishDate = InCourse === true ? "" : getValues("finishDate");
                const fileNameValue = fileName;
                const filePathValue = filePath;
                const grade = Gradename;
                const fileExtensionValue = fileExtension;
               
                

                //we save global object and list with key and value to get easy in card iteration over data
                const dataForGlobalList = {
                    nameTitle: nameTitle,
                    institution: institution,
                    educationLevel: parseInt(educationLevel, 10),
                    startDate: startDate,
                    finishDate: finishDate,
                    inCourse: inCourse,
                    fileInput: filePath,
                    fileNameValue: fileNameValue,
                    filePathValue: filePathValue,
                    idEducation: idFromCard,
                    grade: grade,
                    fileExtensionValue: fileExtensionValue,
                  
                }

                const object = {
                    "dataHeader":
                    {
                        "Titulo": nameTitle,
                        "NivelEducacion": grade,
                    },
                    "dataBody":
                    {
                        "Institucion": institution,
                        "FechaInicio": startDate,
                    },
                    "dataFooter":
                    {
                        "Cursando": inCourse ? "Si" : "No"
                    },
                    "dataID": {
                        "idEducacion": idFromCard
                    },
                    "dataForGlobalList": dataForGlobalList
                }
               
                //validation
                if (userCompleteCV === "true") {
                    setDataIndividualEducation(object);

                    if (actionTypeConfirmModal === "Agregar") setShowConfirmSaveModal(true);


                    if (actionTypeConfirmModal === "Editar") setShowConfirmEditModal(true);

                    resetAllInputs();
                   
                } else {

                    //save options in list
                    if (currentIdCard) {
                        if (listEducation?.length > currentIdCard - 1 && listEducation[currentIdCard] !== undefined) {

                            //filter array of key value to validate if object exists and then update value
                            for (let item of Object.keys(listEducation)) {
                                if (listEducation[item] === listEducation[currentIdCard]) {
                                   
                                        listEducation[item] = object
                                   
                                    //update key value list to show recent info in cards by currentItem
                                   
                                }
                            }
                        }
                    } else {

                        setListEducation([...listEducation, object])

                    }
                    resetAllInputs();

                    setCurrentIdCard(null);
                    //close modal and show alert
                    setShowModal(false);
                    alertCtx.success("Datos guardados temporalmente");
                    setSelectedFile("");
                    setIdFromCard(0);
                    
                }
            }
            else {
                alertCtx.error("¡Datos incorrectos!");
            }
        }
    }

    const resetAllInputs = () => {
        //reset all default values
        reset({
            nameTitle: "",
            institution: "",
            educationLevel: "",
            fileInput: "",
            startDate: "",
            finishDate: "",
            inCourse: false,
            endDate: "",
           
        }, {
            keepErrors: false,
            keepDirty: false,
        })
        setshowCertificate(false);
        setCourse(false)
        setSelectedFile("");
        setEducationGrade("");
        setFileName("");
        setFilePath("");
        setFileExtension("");
        setDetect(false);
        
    }

    const resetAllDates = () => {
        //reset all default values
        reset({

            startDate: "",
            finishDate: "",

        }, {
            keepErrors: false,
            keepDirty: false,
        })
    }
    const resetinputs= () => {
        //reset all default values
        reset({

            finishDate: "",
            fileInput: "",
        }, {
            keepErrors: false,
            keepDirty: false,
        })
        setSelectedFile("");
        setFileName("");
        setFilePath("");
    }

    function setValuesById(list) {

        setValue("nameTitle", list.dataForGlobalList?.nameTitle)
        setValue("institution", list.dataForGlobalList?.institution)
        setValue("educationLevel", list.dataForGlobalList?.educationLevel)
        setEducationGrade(list.dataForGlobalList?.grade)
        setValue("fileInput", list.dataForGlobalList?.filePathValue)
        setValue("startDate", list.dataForGlobalList?.startDate)
        setValue("finishDate", list.dataForGlobalList?.finishDate)
      
        setDetect( list.dataForGlobalList?.inCourse ? true : false)
       
        setFileName(list.dataForGlobalList?.fileNameValue)
        setFilePath(list.dataForGlobalList?.filePathValue)
        setSelectedFile(list.dataForGlobalList?.fileNameValue)
        setCourse(list.dataForGlobalList?.inCourse)
        setFileExtension(list.dataForGlobalList?.fileExtensionValue)
      
        if (list.dataForGlobalList?.educationLevel === 9) setshowCertificate(true)
        setIdFromCard(list?.dataID?.idEducacion);
       
    }

    function setValuesByIdListPerson(list) {
       
        const startMonth = new Date(list?.fechaInicio).getMonth() + 1;
        const startYear = new Date(list?.fechaInicio).getFullYear();
        const startDay = new Date(list?.fechaInicio).getDate();
        const startDateFormatted = `${startYear}-${startMonth}-${startDay}`;
        const startDate = new Date(startDateFormatted)

        const endMonth = new Date(list?.fechaFin).getMonth() + 1;
        const endYear = new Date(list?.fechaFin).getFullYear();
        const endDay = new Date(list?.fechaFin).getDate();
        const endDateFormatted = `${endYear}-${endMonth}-${endDay}`;
        const endDate = new Date(endDateFormatted)

        setValue("nameTitle", list?.nombreTitulo)
        setValue("institution", list?.institucion)
        setValue("educationLevel", list?.educationLevel)
        setValue("fileInput", list?.imgTitulo)
        setValue("startDate", startDate.toLocaleDateString('fr-CA'))
        setValue("finishDate", endDate.toLocaleDateString('fr-CA'))
        setValue("inCourse", list?.cursando)
        setEducationGrade(list?.grado)
        setFileName(list?.imgNombre)
        setFilePath(list?.imgTitulo)
        setSelectedFile(list?.imgNombre)
        setCourse(list?.cursando)
        setIdFromCard(list?.idEducacion);
        setFileExtension(list?.extension);
       
    }

    async function showPdf(blob) {
        try {
       
            var _PDF_DOC = await PDFJS.getDocument({ url: blob });
            setPdf(_PDF_DOC);
        } catch (error) {
            console.log(error.message);
        }
    }


    const selectFile = (event) => {
       event.preventDefault();

        const userfile = event.target.files[0]; //get file selected
        const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];

        if (userfile.type === 'application/pdf') {
            setValue("fileInput", userfile.name)
            setFileName(userfile.name)//show name of the file to user
            setSelectedFile(userfile.name);
       
          
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result.split(",")[1];
                const route = `data:application/pdf;base64,${base64String}`;
                showPdf(route);
               
            };
            reader.readAsDataURL(userfile);
        } else {
            if (userfile && validTypes.includes(userfile.type)) {

                setValue("fileInput", userfile.name)
                setFileName(userfile.name)//show name of the file to user
                setSelectedFile(userfile.name);
                setFileExtension(userfile.type);
                const reader = new FileReader();
                reader.onload = () => {
                    const base64String = reader.result.split(",")[1];
                    setFilePath(base64String);
                };
                reader.readAsDataURL(userfile);
            } else {
                alertCtx.error("¡Solo se aceptan imagenes o pdf!");
            }
        }
          
    }

    //create custom object with list by person id to render cards
    const customObjectByPersonList = useCallback(() => {
 
        listEducationByPerson.map((item) => {
            const startMonth = new Date(item?.fechaInicio).getMonth() + 1;
            const startYear = new Date(item?.fechaInicio).getFullYear();
            const startDay = new Date(item?.fechaInicio).getDay();
            const startDateFormatted = `${startYear}-${startMonth}-${startDay}`;
            const startDate = new Date(startDateFormatted)

            const endMonth = new Date(item?.fechaFin).getMonth() + 1;
            const endYear = new Date(item?.fechaFin).getFullYear();
            const endDay = new Date(item?.fechaFin).getDay();
            const endDateFormatted = `${endYear}-${endMonth}-${endDay}`;
            const endDate = new Date(endDateFormatted);
            

            //we save global object and list with key and value to get easy in card iteration over data
            const dataForGlobalList = {
                nameTitle: item?.nombreTitulo,
                institution: item?.institucion,
                educationLevel: item?.idGrado,
                startDate: item?.fechaInicio ? startDate.toLocaleDateString('fr-CA') : "",
                finishDate: item?.fechaFin ? endDate.toLocaleDateString('fr-CA') : "",
                inCourse: item?.cursando ? true : false,
                fileInput: item?.imgTitulo,
                fileNameValue: item?.imgNombre,
                filePathValue: item?.imgTitulo,
                grade: item?.grado,
                fileExtensionValue: item?.extension,
            }

            const object = {
                "dataHeader":
                {
                    "Titulo": item?.nombreTitulo,
                    "Nivel Educacion": item?.grado,
                },
                "dataBody":
                {
                    "Institucion": item?.institucion,
                    "Fecha Inicio": item?.fechaInicio ? startDate.toLocaleDateString('fr-CA') : "",
                   
                },
                "dataFooter":
                {
                    "Cursando": item?.cursando ? "Si" : "No"
                },
                "dataID": {
                    "idEducacion": item?.idEducacion
                },
                "dataForGlobalList": dataForGlobalList
            }
           
            if (listEducation !== undefined) {
               
                    listEducation.push(object);
                
            }
          
        })

    },[])

    useState(() => {
        getEducationType();
        getEducationTypeWithoutCertifications();

        setValidations(validationMessages);

    }, [])

    async function renderPage() {
      
        const canvas = document.createElement("canvas");
        canvas.setAttribute("className", "canv");
      
        var page = await pdf.getPage(1);

        var width = '800';
        var height = '550';

        canvas.height = height;
        canvas.width = width;

        var unscaledViewport = page.getViewport(1);
        var scale = Math.min((canvas.height / unscaledViewport.height), (canvas.width / unscaledViewport.width));
        var viewport = page.getViewport(scale);

        var render_context = {
            canvasContext: canvas.getContext("2d"),
            viewport: viewport,       
        };
  
        await page.render(render_context).promise;
        let img = canvas.toDataURL("image/png");
        setFilePath(img.split(",")[1]);
        setFileExtension("image/png");
   
    }

    useEffect(() => {
        //here whe render pdf to image only when pdf is load
        pdf && renderPage();

    }, [pdf])


    useEffect(() => {
        //validate for candidate view or admin view
        if (userCompleteCV === "true" && isFirstLoadEducation || allowEdit === true && isFirstLoadEducation) {
            //fill data by idPerson
            if (listEducationByPerson?.length > 0) {
                customObjectByPersonList();
                if(isFirstLoadEducation) setIsFirstLoadEducation(false)
            }

        }

         //validate for candidate view or admin view
        if (userCompleteCV === "true" || allowEdit === true) {
            if (listEducation?.length > 0) {
                if (educationDataEdit) {
                    setEducationDataEdit(listEducation);
                }

            } else {
                if (educationDataEdit) {
                    setListEducation(educationDataEdit);
                }
            }
          
      
        } else {
            //here we detect if list were updated, then validate if final data contain data for list and update then global data
            if (listEducation?.length > 0) {
                //set form to valid
                setstateEducacion(true);

                if (userData) {
                    setUserData({ ...userData, "listEducation": listEducation })
                }
            } else {
                 //set form to inValid
                setstateEducacion(false);

                if (userData["listEducation"]) {
                    setListEducation(userData["listEducation"])
                }
            }
        }
            
        if (userCompleteCV === "true" && !showModal || allowEdit === true && !showModal) {
            const timeout = setTimeout(() => {
                if (educationDataEdit) {

                    setEducationDataEdit(listEducation);
                }
            }, 1000);
            return () => clearTimeout(timeout);

        }else if(!showModal) {
            const timeout = setTimeout(() => {
                if (listEducation) {
                    if (userData) {
                        setUserData({ ...userData, "listEducation": listEducation });
                    }
                 
                }
            }, 1000);
            return () => clearTimeout(timeout);

        }
        
        //component unmount
        return (() => {
            if (userCompleteCV === "true" || allowEdit === true) {
                if (isFirstLoadEducation) {
                    setIsFirstLoadEducation(false);
                } 
                if (educationDataEdit) {

                    setEducationDataEdit(listEducation);
                }
            } else {
          
                if (listEducation) {
                    setUserData({ ...userData, "listEducation": listEducation })
                }
            }
            setShowModal(false);

         
        })
     

    }, [showModal, listEducation, userSaveActionSuccess, dataIndividualEducation, actionTypeConfirmModal])

    return (
        <div className="w-full flex flex-col md:flex-row items-center justify-center px-1  mx-auto lg:py-0">
            {/*Block to manage form input 'Educacion'--*/}
            <div className={userCompleteCV === "false" ? "w-full pt-1 bg-white rounded-lg shadow" : "w-full pt-1"}>
                <div className="relative container mx-auto items-center justify-center">
                    {listEducation?.length === 0 && userCompleteCV === "true" && !allowEdit ?
                 
                    <div className="flex justify-center items-center mb-4 px-2 space-x-2">
                        <div className="flex-shrink-0">
                            <InformationCircleIcon className="h-8 w-8 mr-2 text-red-RS" />
                        </div>
                        <div>
                            <h1 className=" font-bold font-base">Esta información es requerida para tomar en cuenta su Curriculum Vitae</h1>
                        </div>
 
                    </div>
                    : null
                    }

                    {!allowEdit && <div>
                        {listEducation?.length > 0 ? null : (
                            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                <h1 className="text-decoration-line: underline">Por favor, presione Agregar para completar el apartado de educación</h1>
                            </div>
                        )}
                    </div>
                    }

                    {listEducation?.length > 0 &&
                        <div className="container flex flex-wrap w-full py-4 mt-6 mx-auto items-start justify-center">
                            {/*iterate over list techs */}
                            {listEducation?.map((item, index) => (
                                <div className="w-full m-1 mt-8 p-1 py-6 md:w-96" key={index}>
                                    <Card id={index} data={item} list={listEducation} setList={setListEducation} setCurrentIdCard={setCurrentIdCard} setShowModal={setShowModal} setValuesById={setValuesById} setValuesByIdListPerson={setValuesByIdListPerson} allowEdit={allowEdit}
                                        listByPerson={listEducationByPerson} setListEducationByPerson={setListEducationByPerson} userCompleteCV={userCompleteCV} deleteElementCV={EliminarEducacionPersona} setActionTypeConfirmModal={setActionTypeConfirmModal}/>
                                </div>
                            ))}
                        </div>
                    }
                    {/*here move button to top right if list is not null*/}
                    {!allowEdit && <div>
                        {listEducation?.length > 0 ? <div className="flex absolute top-0 right-0 md:justify-center items-center mb-8 px-2 py-6">
                            <button disabled={allowEdit} className="bg-greenButton hover:bg-primary-700 text-white text-sm  py-2 px-4 rounded inline-flex items-center" onClick={() => { setShowModal(true); setActionTypeConfirmModal("Agregar") }}>
                                <span>Agregar</span>
                                <PlusCircleIcon className="w-8 h-8 ml-2"></PlusCircleIcon>
                            </button>
                        </div> :
                            <div className="flex justify-center items-center mb-4 px-2">
                                <button disabled={allowEdit} className="bg-greenButton hover:bg-primary-700 text-white text-sm  py-2 px-4 rounded inline-flex items-center" onClick={() => { setShowModal(true); setActionTypeConfirmModal("Agregar") }}>
                                    <span>Agregar</span>
                                    <PlusCircleIcon className="w-8 h-8 ml-2"></PlusCircleIcon>
                                </button>
                            </div>
                        }
                    </div>
                    }

                    {/*Here we show view confirm save modal compononent*/}
                    <Modal isVisible={showConfirmSaveModal} onClose={() => { setShowConfirmSaveModal(false) }} handleSubmit={() => null} onSaveOptions={null} showFotter={false} showCloseIcon={false}>
                        <ModalActionConfirm onSaveAction={AgregarEducacionPersona} actionType={actionTypeConfirmModal} setshowConfirmModal={setShowConfirmSaveModal} />
                    </Modal>

                    {/*Here we show view confirm edit modal compononent*/}
                    <Modal isVisible={showConfirmEditModal} onClose={() => { setShowConfirmEditModal(false) }} handleSubmit={() => null} onSaveOptions={null} showFotter={false} showCloseIcon={false}>
                        <ModalActionConfirm onSaveAction={EditarEducacionPersona} actionType={actionTypeConfirmModal} setshowConfirmModal={setShowConfirmEditModal} />
                    </Modal>

                    <Modal isVisible={showModal} onClose={() => { setShowModal(false); resetAllInputs(); }} handleSubmit={handleSubmit} onSaveOptions={SaveEducationOptions} insideContainer={true}>
                        <form className="mt-2 mb-2">
                            {/*First row*/}
                            <div className="flex flex-col  mx-auto items-center justify-center md:flex-row">
                                {/*Title current page*/}
                                <div className="w-full mx-px px-2 mt-4 md:w-40">
                                    <h1 className="block mb-2 text-xl font-bold text-black-RS">Educación</h1>
                                </div>
                               
                                {/*Nombre titulo Input*/}
                                <div className="w-full mx-px px-2 mb-2 md:w-80">
                                    <label className="block mb-2 text-sm font-medium text-gray-900">Nombre del titulo</label>
                                    <label className="relative block">
                                        {/*here we don´t pass setUserData directly, because we are managing a list*/}
                                        <input
                                            type="text"
                                            name="nameTitle"
                                            {...register("nameTitle", { required: true, maxLength: 100 })}
                                            id="nameTitle"
                                            className="text-gray-900 sm:text-sm block w-full p-2.5 mb-2 pl-3 pr-10 rounded border-solid border-2 border-grayDark-RS " placeholder="Nombre del titulo"
                                        />
                                        <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                                            <AcademicCapIcon className="h-5 w-5 pointer-events-none text-dark-500" />
                                        </span>
                                    </label>
                                           
                                    {/*iterate over validations and show message tittle name*/}
                                    {validations.nameTitle?.map((values) => {
                                        return (
                                            errors.nameTitle?.type === values.type &&
                                            <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                                <p className="text-sm font-bold text-red-RS mt-2">{values.message}</p>
                                            </span>
                                        )
                                    })}
                                </div>


                             
                                {/*Nivel educativo Input*/}
                                <div className="w-full mx-px px-2 mb-2 md:w-72">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 ">Nivel Educacion</label>
                                    <div className="relative flex items-center">
                                        <select
                                            id="educationLevel"
                                            type="select"
                                            {...register('educationLevel', { required: true })}
                                            onChange={certificacion}
                                            className="text-black-RS sm:text-sm block w-full p-2.5 rounded border-solid border-2 border-grayDark-RS mb-2 "
                                            name="educationLevel" >
                                            <option value={""} > --Seleccione tipo de educacion --</option>

                                            {educationOptions.map((item, index) => (
                                                <option key={index} value={item.idGrado}>{item.grado}</option>
                                            ))}
                                        </select> 
                                           
                                           
                                    </div>
                                </div>
                               

                                {/*Institucion Input*/}
                                <div className="w-full mx-px px-2 mb-2 md:w-72">
                                    <label className="block mb-2 text-sm font-medium text-gray-900">Institucion</label>
                                    <label className="relative block">
                                       
                                        <input type="text"
                                            name="institution"
                                            {...register("institution", { required: true, maxLength: 100, pattern: /^[a-zA-ZñÑ\s\W]/ })}
                                            id="institution"
                                            className="text-gray-900 sm:text-sm block w-full p-2.5 pl-3 pr-10 rounded border-solid border-2 border-grayDark-RS mb-2 " placeholder="Institución"
                                        />
                                        <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                                            <AcademicCapIcon className="h-5 w-5 pointer-events-none text-dark-500" />
                                        </span>
                                    </label>
                                    {/*iterate over validations and show message institution*/}
                                    {validations.institution?.map((values) => {
                                        return (
                                            errors.institution?.type === values.type &&
                                            <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                                <p className="text-sm font-bold text-red-RS mt-2">{values.message}</p>
                                            </span>
                                        )
                                    })}
                                </div>

                             
                            </div>
                            {/*Second row*/}
                            <div className="flex flex-col mt-4 mx-2 mx-auto items-center justify-center md:flex-row">
                                {/*Cursando Input*/}
                                {!showCertificate && <div className="w-full mx-px px-2 mb-2 md:w-40">
                                    <label className="block mb-2 text-sm font-medium text-gray-900">¿En Curso?</label>
                                    <div className="relative flex items-center">
                                        <div className="flex items-center mb-2">
                                            <fieldset value={InCourse}  {...register("inCourse")} onChange={(event) => detectCheck(event)}>
                                                <label htmlFor="1" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                    <input type="radio"
                                                        id="1"
                                                        name="InCourse"
                                                        value={InCourse}
                                                      
                                                        className={detectCheckBox ? "cursor-pointer bg-black-RS  border-solid border-2 border-grayDark-RS rounded-full" : "cursor-pointer bg-white    border-solid border-2 border-grayDark-RS rounded-full"}

                                                    />
                                                    Si
                                                </label>
                                                <label htmlFor="2" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                    <input type="radio"
                                                        id="2"
                                                        name="InCourse"
                                                        value={InCourse}
                                                        className={detectCheckBox ? "cursor-pointer bg-white  border-solid border-2 border-grayDark-RS rounded-full" : "cursor-pointer bg-black-RS  border-solid border-2 border-grayDark-RS rounded-full"}
                                                    />
                                                    No
                                                </label>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                                }

                                {/*Adjuntar Input*/}
                                {InCourse === false ? <div className="w-full mx-px px-2 mb-2 md:w-80">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">Adjuntar titulo</label>
                                    <div className="relative">
                                        <div className="flex flex-col">
                                            <input type="file"
                                                id="fileInput"
                                                name="fileInput"
                                                accept=".jpg,.jpeg,.png,.pdf"
                                                onChange={selectFile}
                                                className="text-gray-900 sm:text-sm bg-white block w-full p-2.5 mb-2 rounded border-solid border-2 border-grayDark-RS "
                                                placeholder="Adjuntar titulo"
                                            />


                                        </div>


                                    </div>


                                    {/*iterate over validations and show message tittle*/}
                                    {validations.fileInput?.map((values) => {
                                        return (
                                            errors.fileInput?.type === values.type &&
                                            <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                                <p className="text-sm font-bold text-red-RS mt-2">{values.message}</p>
                                            </span>
                                        )
                                    })}
                                </div>:null
                            }

                                {/*Fecha Input*/}
                                {!showCertificate && <div className="w-full mx-px px-2 mb-2  md:w-72">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 ">Fecha inicio</label>
                                    <div className="relative flex items-center">
                                        <input type="date"
                                            name="startDate"
                                            {...register("startDate", { onChange: handleChange, required: InCourse })}
                                            id="startDate"
                                            className="text-gray-900 sm:text-sm block w-full p-2.5 mb-2 rounded border-solid border-2 border-grayDark-RS "
                                            placeholder="Fecha inicio"
                                        />
                                    </div>
                                    {/*iterate over validations and show message startDate*/}
                                    {validations.startDate?.map((values) => {
                                        return (
                                            errors.startDate?.type === values.type &&
                                            <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                                <p className="text-sm font-bold text-red-RS mt-2">{values.message}</p>
                                            </span>
                                        )
                                    })}
                                </div>
                                }

                                {/*Fecha Input*/}
                                {!showCertificate && InCourse === false ? <div className="w-full mx-px px-2 mb-2 md:w-72">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 ">Fecha finalización</label>
                                    <div className="relative flex items-center">
                                        <input type="date"
                                            name="finishDate"
                                            id="finishDate"
                                            {...register("finishDate", { required: InCourse })}
                                            className="text-gray-900 sm:text-sm block w-full mb-2 p-2.5 rounded border-solid border-2 border-grayDark-RS "
                                            placeholder="Fecha finalización"
                                        />
                                        {/*iterate over validations and show message startDate*/}
                                        {validations.finishDate?.map((values) => {
                                            return (
                                                errors.finishDate?.type === values.type &&
                                                <span key="{values.type}" className="flex flex-row justify-content inset-y-0 left-0">
                                                    <p className="text-sm font-bold text-red-RS mt-2">{values.message}</p>
                                                </span>
                                            )
                                        })}
                                    </div>

                                </div> : null}
                            </div>
                            {/*Row for file*/}
                            {InCourse === false ? <div className="container flex flex-col mx-2 mx-auto items-center justify-center md:flex-row w-40 md:w-96">
                                {selectedFile && (
                                    <>
                                        <div className="w-full mx-auto items-center justify-center">
                                            <span className="justify-content mt-1 mb-1">
                                                <p className="text-sm text-truncate  overflow-hidden font-bold text-black-RS ">Archivo seleccionado: {selectedFile.split('/').pop()} </p>
                                            </span>

                                        </div>
                                    </>
                                )}
                            </div> : null}
                        </form>
                    </Modal>
                </div>
            </div>
        </div>
    );
}
