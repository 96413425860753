import React from 'react';
import ReporteProfesionalesTecnologias from './ReporteProfesionalesTecnologia';

const ReporteProfesionales_Tecnologias = () => {


    return (
        <div>
            <ReporteProfesionalesTecnologias />
        </div>
    );
};

export default ReporteProfesionales_Tecnologias;